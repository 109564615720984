import { put } from 'redux-saga/effects'
import axiosInstance from '../../environment/axiosInstances'
import * as globalpopupAlert from '../Actions/globalpopupAlert'
import * as profileActionCreators from '../Actions/ActionCreator'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
toast.configure()
export function* init_profile_data(action: any): Generator {
    try {
        yield put(profileActionCreators.getStatus(true))
        const response: any = yield axiosInstance.get("/my-activities");
        if (response.data.data) {
            const profileData: any = response.data.data;
            yield put(profileActionCreators.getStatusRequest(profileData))
            yield put(profileActionCreators.onChangeDNDstatus(profileData.dndStatus))
            yield put(profileActionCreators.getStatus(false))
        } else {

            yield put(profileActionCreators.getStatusRequest(null))
            yield put(profileActionCreators.getStatus(false))
        }
    } catch (error: any) {
        yield put(profileActionCreators.getStatus(false))
        yield put(globalpopupAlert.initglobalPopup(error.message === 'Network Error' ? 'Network Error' : error?.response?.data?.message, 'LOGIN_ROUTE_FAIL', 'error'))
    }
}
export function* init_patch_profile_data(action: any): Generator {

    try {
        yield put(profileActionCreators.getPatchStatus(true))
        const response: any = yield axiosInstance.patch("/my-activities/update-dnd", action.data);
        if (response.data.data) {
            const profileData: any = response.data.data;
            yield put(profileActionCreators.getPatchStatusRequest(profileData))
            yield put(profileActionCreators.onChangeDNDstatus(profileData.dndStatus))
            yield put(profileActionCreators.getPatchStatus(false))
            toast.success(response.data.message, {
                position: toast.POSITION.TOP_RIGHT,
                style: {
                    borderRadius: "2px",
                    border: "1px solid green"
                }, autoClose: 1000
            })
        } else {

            yield put(profileActionCreators.getPatchStatusRequest(null))
            yield put(profileActionCreators.getPatchStatus(false))
            toast.error(response.data.message, { position: toast.POSITION.TOP_RIGHT, autoClose: 1000 })
        }
    } catch (error: any) {
        if (error.response.status === 402) {

        }
        toast.error(error.response.data.message, { position: toast.POSITION.TOP_RIGHT, autoClose: 1000 })
        yield put(profileActionCreators.getPatchStatus(false))
        yield put(globalpopupAlert.initglobalPopup(error.message === 'Network Error' ? 'Network Error' : error.response.data.message, 'LOGIN_ROUTE_FAIL', 'error'))
    }
}


