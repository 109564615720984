import React from 'react';
import {
    Card,
    CardContent,
    Grid,
    Typography,
    Divider,
    withStyles,
    Switch,
    Button,
    Tabs,
    Tab,
    TableContainer,
    Table,
    TableBody,
    TableRow,
    TextField,
    TableCell,
    IconButton,
    CircularProgress,
    MenuItem,
    FormGroup,
    FormControlLabel,
    FormControl,
} from '@material-ui/core';
import { BsRecord2Fill, BsCardText } from 'react-icons/bs'
import { VscMerge } from 'react-icons/vsc'
import { AiOutlineCalendar } from 'react-icons/ai'
import Avatar from '@material-ui/core/Avatar';
import Ellipse from '../../../assets/icons/Ellipse.png';
import * as authActionCreators from '../../../Store/Actions/AuthActionCreator'
import TabPanel from '@material-ui/lab/TabPanel';
import TabContext from '@material-ui/lab/TabContext';
import { useStyles } from '../Styles';
import PhoneCallbackIcon from '@material-ui/icons/PhoneCallback';
import CallEndIcon from '@material-ui/icons/CallEnd';
import PeopleIcon from '@material-ui/icons/People';
import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver';
import VolumeUpIcon from '@material-ui/icons/VolumeUp';
import Blacksearchicon from '../../assets/icons/blacksearchicon.png';
import AppleIcon from '@material-ui/icons/Apple';
import SmsRoundedIcon from '@material-ui/icons/SmsRounded';
import AndroidOutlinedIcon from '@material-ui/icons/AndroidOutlined';
import BallotIcon from '@material-ui/icons/Ballot';
import { TableHeaderComponent } from '../../../Components/Table/Table';
// import { MyactivityMessages } from '../../Dashboard/Messages';
// import { MyactivityCalls } from '../../Dashboard/Calls';
// import { MyactivityFollowup } from '../../Dashboard/Followup';
// import MyactivityVoicemail from '../MyActivityPage/index';
import PhoneMissedIcon from '@material-ui/icons/PhoneMissed';
import PhoneIcon from '@material-ui/icons/Phone';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import AddIcCallIcon from '@material-ui/icons/AddIcCall';
import { Badge } from '@material-ui/core';
import ListAltSharpIcon from '@material-ui/icons/ListAltSharp';
import Tooltip from '@material-ui/core/Tooltip';
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import axiosInstance from '../../../environment/axiosInstances'
import ReactAudioPlayer from 'react-audio-player';
import { useParams } from "react-router-dom";
import * as DepartmentActionCreators from '../../../Store/Actions/DepartmentsActionXCreators'
import { Link } from 'react-router-dom';
import Moment from 'moment'
import { HiPhoneOutgoing } from 'react-icons/hi'
import profile from "../../../assets/icons/avtar.jpg";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import Grow from "@material-ui/core/Grow";
import MenuList from "@material-ui/core/MenuList";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Checkbox from '@material-ui/core/Checkbox';
import { Window, WindowActionsEvent } from "@progress/kendo-react-dialogs";
import OutgoingCall from '../../../Components/SidebarComponent/SidebarElement/Dialpad/CallsModal/OutgoingCall'
import { useDispatch, useSelector } from "react-redux";
import * as CallActionCreator from '../../../Store/Actions/CallActionCreator'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import TableBodyData from './TableBodyData';

function Calls(props: any) {
    const classes = useStyles();
    const params = useParams();
    const dispatch = useDispatch();
    const callingData: any = useSelector((state: any) => state.CallingData);
    const anchorRef: any = React.useRef(null);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [open, setOpen] = React.useState(false);
    const [loading, setLoading] = React.useState<any>(false);
    const [calls, setCalls] = React.useState<any>([])
    const [id, setId] = React.useState<any>('')
    const [windowStage, setWindowStage] = React.useState("MAXIMISED");
    const [callModal, setCallModal] = React.useState(false)
    const [data, setData] = React.useState({})
    const [outboundData, setOutBoundData] = React.useState<any>([])
    const [option, setOption] = React.useState<any>()
    function handleListKeyDown(event: any) {
        if (event.key === "Tab") {
            event.preventDefault();
            setOpen(false);
        }
    }
    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };
    const handleClose = (event: any) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };
    const [paginate, setPaginate] = React.useState<any>({
        page: 1,
        totalDocs: 0,
        limit: 10
    })

    const handleChangePage = (value: any) => {
        setPaginate((prev: any) => {
            return {
                ...prev,
                limit: paginate.limit + 10,
            }
        })
        //  fetchCalls(paginate.limit, props.type)
        fetchCalls(paginate.limit, props.type)
    }


    const fetchCalls = (limit: any, type: any) => {
        setLoading(true)
        axiosInstance.get(`/departments/${params.id}/calls?limit=${limit}&type=${type}`).then((res) => {

            if (res.data) {
                setCalls(res.data.data)
                setLoading(false)
                setPaginate((prev: any) => {
                    return {
                        ...prev,
                        page: res.data.page,
                        totalDocs: res.data.totalDocs,
                        limit: res.data.limit
                    }
                })
            }
        }).catch((err) => {
            setLoading(false)
            if (err?.response?.status == 402) {
                window.location.href = "https://newbp.efone.ca"
                sessionStorage.removeItem("user")

            }
            if (err.response?.status == 401) {
                dispatch(authActionCreators.initRefreshToken())
            }
        })
    }
    React.useEffect(() => {
        fetchCalls(paginate.limit, props.type)
    }, [props.type])
    React.useEffect(() => {
        setId(params?.id)
    }, [])

    const [state, setState] = React.useState({
        gilad: true,
        jason: false,
        antoine: false,
    });



    const { gilad, jason, antoine } = state;
    const error = [gilad, jason, antoine].filter((v) => v).length !== 2;
    const handleCallModal = () => {
        setCallModal(!callModal)
    }
    const handleCall = (data: any, option: any) => {

        handleCallModal()
        setData({
            name: data.callerIdName,
            phoneNumber: data.phoneNumber
        })
        if (callingData?.callData && callingData?.callData?.phoneNumber !== "") {
            toast.error("Already you are in call", { position: toast.POSITION.TOP_RIGHT, autoClose: 2000 })
        }
        else {
            const oNumber = option.id ? option.id : ""
            const oName = option.name ? option.name : ""
            var Options: any = props.callOptions
            Options.extraHeaders = [`oNumber:${oNumber}`, `oName: ${oName} `]
            const newdata = {
                name: data.callerIdName,
                phoneNumber: data.phoneNumber,
                options: Options
            }
            dispatch(CallActionCreator.fetchStatus(newdata))
            dispatch(CallActionCreator.fetchData(true))


        }
    }
    const handleStageChange = (e: any) => {
        setWindowStage(e.state);
    };
    const getOutboundCallers = () => {
        axiosInstance.get('/calls/outbound-caller-id').then((res) => {
            if (res.data) {
                const data = res.data.data
                setOutBoundData(data)
                setOption(data[0])
            }
        }).catch((err) => {
            if (err.response?.status == 402) {
                window.location.href = "https://newbp.efone.ca"
                sessionStorage.removeItem("user")

            }
            if (err.response?.status == 401) {
                dispatch(authActionCreators.initRefreshToken())
            }
        })
    }
    const handleChange = (val: any) => {
        setOption(val)
    }
    React.useEffect(() => {
        getOutboundCallers()
    }, [])
    const tableHeader = ['Phone Number', 'eFone Number', 'Duration', 'Agent', 'Action', 'Date']
    return (
        <>
            {/* {
                callModal && (
                    <div style={{ padding: 0 }}>
                        <Window
                            title={""}
                            stage={windowStage}
                            onStageChange={handleStageChange}
                        >
                            <OutgoingCall callModal={handleCallModal} data={data}
                                coolPhone={props.coolPhone}
                                callOptions={props.callOptions}
                                callStatus={props.callStatus} />

                        </Window>
                    </div>

                )
            } */}
            <TableContainer>
                <Table className={classes.table}>
                    <TableHeaderComponent theadData={tableHeader} />
                    <TableBody>
                        {calls && calls.length > 0 ? calls.map((item: any, i: any) => {
                            var data = parseInt(item?.createdDate)
                            var dataa = Moment(data).format('YYYY MMM DD hh:mm A')
                            return (
                                <TableBodyData
                                    item={item}
                                    option={option}
                                    handleCall={handleCall}
                                    handleChange={handleChange}
                                    outboundData={outboundData}
                                />
                                // <TableRow className={classes.tablebackgroundcolor} key={item.id}>
                                //     <TableCell className={classes.tablecell}>
                                //         <div style={{ display: 'flex', alignItems: 'center' }}>
                                //             <Avatar>{item.callerIdName[0]}</Avatar>
                                //             <Link to={{ pathname: `/department/userDetails/${'calls'}/${item?.id}/${item?.departmentId?.id}` }} className={classes.linkUnderline}>
                                //                 <Typography className={classes.reschedule}> {item.callerIdName}</Typography>
                                //             </Link>

                                //         </div>

                                //     </TableCell>
                                //     <TableCell className={classes.tablecell}>{item.phoneNumber ? item.phoneNumber : ''}</TableCell>

                                //     <TableCell className={classes.tablecell}>
                                //         {item.isCallDirection == 1 && item.duration > 0 ?
                                //             <div>
                                //                 <IconButton>
                                //                     <PhoneCallbackIcon className={classes.incomingiconcolor} />
                                //                 </IconButton>
                                //                 Incoming Call/{Math.ceil(item.duration / 60)} mins
                                //             </div>
                                //             :
                                //             item.isCallDirection == 1 && item.duration == 0 ?
                                //                 <div>
                                //                     <IconButton>
                                //                         <PhoneMissedIcon className={classes.missediconcolor} />
                                //                     </IconButton>
                                //                     Missed call
                                //                 </div>
                                //                 :
                                //                 item.isCallDirection == 2 ?
                                //                     <div>
                                //                         <IconButton>
                                //                             <HiPhoneOutgoing className={classes.outgoingcalliconcolor} />
                                //                         </IconButton>
                                //                         Outgoing/{Math.ceil(item.duration / 60)} min
                                //                     </div> : ""
                                //         }
                                //     </TableCell>
                                //     {item.agentId ?
                                //         <TableCell className={classes.tablecell}>
                                //             <div style={{ display: 'flex', alignItems: 'center' }}>
                                //                 {/* <img alt="Remy Sharp" src={profile} className={classes.profile} /> */}
                                //                 <Typography className={classes.reschedule}>  {item.agentId && item.agentId.firstName}  {item.agentId && item.agentId.lastName}</Typography>
                                //             </div>
                                //         </TableCell>
                                //         :
                                //         <TableCell className={classes.tablecell}>

                                //         </TableCell> 
                                //     }
                                //     <TableCell className={classes.tablecell}>
                                //         <div
                                //             className={classes.buttonicons}>
                                //             <IconButton
                                //                 onClick={() => handleCall(item, option)}>
                                //                 <PhoneIcon className={classes.iconcolor} /></IconButton>
                                //             <Divider orientation="vertical" flexItem className={classes.dividericon} />
                                //             <IconButton onClick={handleToggle} ref={anchorRef}>
                                //                 <ArrowDropDownIcon className={classes.iconcolor} />
                                //             </IconButton>
                                //             <Popper
                                //                 open={open}
                                //                 anchorEl={anchorRef.current}
                                //                 role={undefined}
                                //                 transition
                                //             >
                                //                 {({ TransitionProps, placement }) => (
                                //                     <Grow
                                //                         {...TransitionProps}
                                //                         style={{
                                //                             transformOrigin:
                                //                                 placement === "bottom" ? "center bottom" : "center bottom",

                                //                         }}
                                //                     >
                                //                         <Paper className={classes.paper}>
                                //                             <ClickAwayListener onClickAway={handleClose}>
                                //                                 <MenuList
                                //                                     autoFocusItem={open}
                                //                                     id="menu-list-grow"
                                //                                     onKeyDown={handleListKeyDown}
                                //                                 >
                                //                                     <MenuItem>
                                //                                         <FormControl>
                                //                                             {outboundData.map((opt: any, i: any) => {
                                //                                                 return <FormGroup>
                                //                                                     <FormControlLabel
                                //                                                         control={<Checkbox onChange={() => handleChange(opt)} name={opt.id}
                                //                                                             checked={opt.id == option?.id} />}
                                //                                                        label={opt.value + "  (" + opt.name + ")"}
                                //                                                     />

                                //                                                 </FormGroup>
                                //                                             })}

                                //                                         </FormControl>
                                //                                     </MenuItem>
                                //                                 </MenuList>
                                //                             </ClickAwayListener>

                                //                         </Paper>
                                //                     </Grow>
                                //                 )}
                                //             </Popper>
                                //         </div>
                                //     </TableCell>
                                //     <TableCell className={classes.tablecell}>{dataa}</TableCell>
                                // </TableRow>
                            )
                        })
                            :

                            loading ? <div className='loader_class'>
                                <div style={{ display: 'grid', alignItems: 'center', margin: '30px 500px' }}>
                                    <CircularProgress disableShrink />
                                </div>
                            </div> : calls.length <= 0 &&
                            <TableRow>
                                <TableCell className={classes.tablecell}></TableCell>
                                <TableCell className={classes.tablecell}></TableCell>
                                <TableCell className={classes.tablecell}>No Data</TableCell>
                                <TableCell className={classes.tablecell}></TableCell>
                                <TableCell className={classes.tablecell}></TableCell>
                            </TableRow>
                            // <p style={{ textAlign: 'center' }}>No Data</p>

                        }


                    </TableBody>
                </Table>
            </TableContainer>
            {calls.length <= 0 || paginate.totalDocs <= paginate.limit ? "" :
                <div style={{ marginTop: 25, marginBottom: 25, float: "right", marginLeft: '88%' }} onClick={handleChangePage}>
                    <Button variant='outlined' color="secondary">Load More...</Button>
                </div>
            }
        </>
    )
}

export default Calls