import React, { useState } from 'react';
import { Button } from '@material-ui/core'
import { Routes, Route, BrowserRouter } from 'react-router-dom'
import '@progress/kendo-theme-default/dist/all.css';
import './App.css';
import Layout from './Container/Layout/Layout';
import sideBarRoutes from './services/Routes/Sidebar_routes'
import { Window, WindowActionsEvent } from "@progress/kendo-react-dialogs";
import jwt_decode from "jwt-decode";
import CryptoJS from 'crypto-js';
import JsSIP from 'jssip'
import { useDispatch, useSelector } from "react-redux";
import IncomingCall from './Container/CallsModal/IncomingCall';
import * as CallActionCreator from './Store/Actions/CallActionCreator'
import io from 'socket.io-client'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
// JsSIP.debug.enable('JsSIP:*');
function App(): JSX.Element {

    let start = 0

    let intrvl: any = React.useRef(null)
    const dispatch = useDispatch()
    const [timer, setTimer] = React.useState({
        s: "00",
        m: "00",
        h: "00"
    })
    const [status, setStatus] = useState('Registering')
    const userAuthState = useSelector((state: any) => state.authState)
    const [callType, setCallType] = React.useState('')
    const [session, setSession] = useState<any>()
    const [callStatus, setCallStatus] = useState<any>()
    const [name, setName] = useState<any>('')
    const [socketData, setSocketData] = useState<any>('')
    const [messages, setMessages] = React.useState([])
    const [sipUser, setSipUser] = React.useState<any>()
    const [tokendata, setTokenData] = useState<any>()
    const [tokenStatus, setTokenStatus] = useState(false)
    const [data, setData] = useState<any>()
    const [isActive, setIsActive] = useState(false);
    const [isPaused, setIsPaused] = useState(true);
    const [time, setTime] = useState(0);
    const [notify, setNotify] = useState(false);
    React.useEffect(() => {
        let interval: any = null;

        if (isActive && isPaused === false) {
            interval = setInterval(() => {
                setTime((time) => time + 10);
            }, 10);
        } else {
            clearInterval(interval);
        }
        return () => {
            clearInterval(interval);
        };
    }, [isActive, isPaused]);

    const handleStart = () => {
        setIsActive(true);
        setIsPaused(false);
    };

    const handlePauseResume = () => {
        setIsPaused(!isPaused);
    };

    const handleReset = () => {
        setIsActive(false);
        setTime(0);
    };
    const incomingCallRing = require('./assets/Sounds/ring.mp3');
    var ringAudio = new Audio(incomingCallRing);
    ringAudio.loop = true;
    const [answerModal, setAnswerModal] = useState(false)

    const handleAnswerCall = () => {
        setAnswerModal(!answerModal)
    }
    function setZeros(i: any) {
        if (i < 10) return "0" + i;
        return i;
    }

    //start timer
    const startTimer = () => {
        // // if startTimer is already running
        if (start == 1) return;

        start = 1; // set startTimer is running
        let ss = 0,
            mm = 0,
            hh = 0;

        intrvl.current = setInterval(() => {
            ss++;
            if (ss == 60) {
                ss = 0;
                mm++;
            }
            if (mm == 60) {
                mm = 0;
                hh++;
            }
            setTimer({
                s: setZeros(ss),
                m: setZeros(mm),
                h: setZeros(hh)
            })

        }, 1000);

    }; // start timer ends
    const stopTimer = () => {
        clearInterval(intrvl.current);
        // if (start == 0) return;
        // start = 0;
        setTimer({
            s: "00",
            m: "00",
            h: "00"
        });

        // clearInterval(intrvl.current);

    }; // stop timer ends
    const callEnded = () => {
        stopTimer()
    }
    React.useEffect(() => {

        var local_user = sessionStorage.getItem("user")
        var current_user = local_user ? JSON.parse(local_user) : {}

        const token = Object.keys(current_user).length && current_user.access ? current_user.access.token : null;
        if (token) {
            setTokenData(token)
            setTokenStatus(true)
            const decodedHeader: any = jwt_decode(token);
            if (decodedHeader?.key) {
                const decryptData2 = (encryptedData: any) => {
                    var C = CryptoJS;
                    var Key = C.enc.Utf8.parse("oSOhgvKFi2AbgyVwtKMKwFV8pSc5kyxU");
                    var IV = C.enc.Utf8.parse("oSOhgvKFi2AbgyVw");
                    var decryptedText = C.AES.decrypt(encryptedData, Key, {
                        iv: IV,
                        mode: C.mode.CBC,
                        padding: C.pad.Pkcs7
                    });
                    return decryptedText.toString(CryptoJS.enc.Utf8);
                }
                var userData = JSON.parse(decryptData2(decodedHeader?.key)) ? JSON.parse(decryptData2(decodedHeader?.key)) : ""
                setSipUser(userData.sipUsername)
            }
            setName(userData?.name)
            //Creating sip 
            var socket = new JsSIP.WebSocketInterface('wss://devwphone.efone.ca:7443');
            //sip configuration with username and password by decrypting token
            var configuration = {
                sockets: [socket],
                uri: 'sip:' + userData.sipUsername + '@devwphone.efone.ca',
                password: userData.sipPassword,
                session_timers: false
            };
            //asigning sip with configurations
            var coolPhone = new JsSIP.UA(configuration);
            coolPhone.start()
            setData(coolPhone)
            coolPhone.on('connected', function (e: any) {
                setStatus('Connected')

            });
            coolPhone.on('disconnected', function (e: any) {
                setStatus('Disconnected')
                // console.log('disconnected');
            });
            coolPhone.on('registrationFailed', function (e: any) {
                setStatus('Registration Failed')
                // console.log('registrationFailed');
            });

            coolPhone.on('newRTCSession', (data: any) => {
                // console.log('New RTC Session')
                const session = data.session
                setSession(session)

                if (session.direction === "incoming") {
                    // incoming call here
                    setCallType('incoming')
                    handleAnswerCall()

                    ringAudio.play()
                    session.on("accepted", function () {
                        // the call has answered
                        // console.log("Accepted")
                        startTimer()
                        ringAudio.pause()
                        setCallStatus("Accepted")
                        handleStart()
                    });
                    session.on("confirmed", function () {
                        // this handler will be called for incoming calls too
                        // console.log("confirmed")
                        ringAudio.pause()
                        // startTimer()
                        setCallStatus("confirmed")

                    });
                    session.on("ended", function (request: any) {
                        // the call has ended
                        var cause = request.cause; //sometimes this is request.reason_phrase
                        stopTimer()

                        handleReset()
                        // console.log("ended")
                        setAnswerModal(false)
                        ringAudio.pause()
                        setCallStatus("ended")

                    });
                    session.on("failed", function () {
                        // unable to establish the call
                        stopTimer()
                        // console.log("failed")
                        setAnswerModal(false)
                        ringAudio.pause()
                        setCallStatus("failed")
                    });
                    session.on('peerconnection', function (data: any) {
                        data.peerconnection.addEventListener('addstream', function (e: any) {
                            // set remote audio stream
                            const remoteAudio1 = new window.Audio()
                            remoteAudio1.srcObject = e.stream
                            //remoteAudio1.src = window.URL.createObjectURL(e.stream);
                            remoteAudio1.play();
                        });
                    });
                }
                else if (session.direction === "outgoing") {
                    setCallType('outgoing')
                    // outgoing call here
                    setCallStatus("")

                    session.on("accepted", function () {
                        // the call has answered
                        // startTimer()
                        // console.log("Accepted")
                        setCallStatus("Accepted")
                        handleStart()
                    });
                    session.on("confirmed", function () {
                        // this handler will be called for incoming calls too
                        // console.log("confirmed")
                        // startTimer()
                    });
                    session.on("ended", function (request: any) {

                        // the call has ended
                        // stopTimer()
                        // console.log("ended")

                        setCallStatus("ended")
                        dispatch(CallActionCreator.fetchStatus(null))
                        dispatch(CallActionCreator.fetchData(false))
                        handleReset()
                    });
                    session.on("failed", function (request: any) {
                        // unable to establish the call
                        // stopTimer()
                        var cause = request.cause; //sometimes this is request.reason_phrase
                        if (cause === JsSIP.C.causes.REJECTED) {

                        }
                        handleReset()
                        // console.log("failed")
                        setCallStatus("failed")

                        dispatch(CallActionCreator.fetchStatus(null))
                        dispatch(CallActionCreator.fetchData(false))
                    });
                    session.on('peerconnection', function (data: any) {
                        data.peerconnection.addEventListener('addstream', function (e: any) {
                            // set remote audio stream
                            const remoteAudio1 = new window.Audio()
                            remoteAudio1.srcObject = e.stream
                            //remoteAudio1.src = window.URL.createObjectURL(e.stream);
                            remoteAudio1.play();

                        });
                    });
                }
                session.on('addstream', function (e: any) {
                    // set remote audio stream (to listen to remote audio)
                    // remoteAudio is <audio> element on page
                    const remoteAudio = audio
                    remoteAudio.src = window.URL.createObjectURL(e.stream);
                    remoteAudio.play();

                });

            })
        }

    }, [sessionStorage.getItem('user'), userAuthState.isAuthenticated])
    // const path = require('./assets/Sounds/ring.mp3');
    React.useEffect(() => {
        ringAudio.load();
    }, [])


    var eventHandlers = {
        'progress': function (e: any) {
            // console.log('call is in progress');
        },
        'failed': function (e: any) {
            setCallStatus("failed")
            stopTimer()
            // console.log('call failed with cause: ' + e.data.cause);
        },
        'ended': function (e: any) {
            setCallStatus("ended")
            stopTimer()
            // console.log('call ended with cause: ' + e.data.cause);
        },
        'confirmed': function (e: any) {
            // console.log('call confirmed');
            startTimer()
        },
        'addstream': (e: any) => {
            // console.log('Add stream (event handlers)')
            audio.srcObject = e.stream
            audio.play()
        }
    };
    const audio = new window.Audio()
    // 'iceServers': [
    //     {
    //         'urls': ['stun:stun.linphone.org:3478', 'stun:stun.freeswitch.org:3478', 'stun:stun.l.google.com:19302', 'stun:stun1.l.google.com:19302']
    //     },
    //     {
    //         'urls': 'turn:numb.viagenie.ca', 'credential': 'muazkh', 'username': 'webrtc@live.com'
    //     }
    var options = {
        'eventHandlers': eventHandlers,
        'mediaConstraints': { 'audio': true, 'video': false },
        'sessionTimersExpires': 120,
        'pcConfig': {
            'iceServers': [
                {
                    'urls': ['stun:stun.linphone.org:3478']
                },
                // {
                //     'urls': 'turn:numb.viagenie.ca', 'credential': 'muazkh', 'username': 'webrtc@live.com'
                // }
            ],
            "iceTransportPolicy": "all",
            "rtcpMuxPolicy": "negotiate"
        }
    };





    // coolPhone.on('registered', function (e) {
    //     setStatus('Registered')
    //     console.log('registered');
    // });
    // coolPhone.on('unregistered', function (e) {
    //     setStatus('Unregistered')
    //     console.log('unregistered');
    // });
    // coolPhone.on('registrationFailed', function (e) {
    //     setStatus('Registration Failed')
    //     console.log('registrationFailed');
    // });
    React.useEffect(() => {
        //Connection

        // const socket = io("ws://134.122.36.172:7785", {
        //     transports: ["websocket"],
        //     extraHeaders: {
        //         deviceUDID: sipUser
        //     }

        // })
        //ws://newbpsocket.efone.ca:7785
        //https://newdemo.efone.ca:7789
        sessionStorage.setItem('Notification', JSON.stringify(0))
        const socket = io("https://newbpsocket.efone.ca:7785", {
            "path": "",
            "transports": ["websocket", "polling"],
            "transportOptions": {
                "polling": {
                    "extraHeaders": {
                        "deviceUDID": "business_1648794781463_FSkvvvhudNpm"
                    }
                }
            },
            "auth": {
                "deviceUDID": "business_1648794781463_FSkvvvhudNpm"
            },

            "rejectUnauthorized": false,
            "timeout": 20000,
            "reconnection": false,
            "reconnectionAttempts": 3,
            "reconnectionDelay": 1000,
            "reconnectionDelayMax": 5000
        });
        socket.on("connect", () => {
            // console.log("Socket Connected", socket)
            setSocketData(socket)

        })

        socket.on("eb_inboundSMS", (data: any) => {
            const count: any = sessionStorage.getItem("Notification")
            var countnotifi: any = parseInt(count)
            countnotifi++
            sessionStorage.setItem("Notification", countnotifi)
            console.log("listner====>", data, countnotifi)
            setMessages(data)
            setNotify(true)
        })
        socket.on("eb_outboundACK", (newData: any) => {
            console.log("listner====>", newData)
            // if (newData.statusCode == 200 || newData.statusCode == 201) {
            // toast.success(newData.message, {
            //     position: toast.POSITION.TOP_RIGHT,
            //     style: {
            //         borderRadius: "2px",
            //         border: "1px solid green"
            //     }, autoClose: 1000
            // })


            // } else {
            //     toast.error(newData.message, { position: toast.POSITION.TOP_RIGHT, autoClose: 1000 })
            // }
        })

        socket.on('connect_error', function (err) {
            // console.log('Connection Failed', err);
        });
    }, [])
    const handleNotify = (child: any) => {
        setNotify(child)
    }

    return (
        <div className="App" >

            {/* {
                answerModal && (
                    <div style={{ padding: 0 }}>
                        <Window
                            title={"Incoming call..."}
                            stage={windowStage}
                            onStageChange={handleStageChange}
                        >
                            {windowStage === "FULLSCREEN" ? "" :
                                <IncomingCall callModal={handleAnswerCall}
                                    session={session}
                                    options={options}
                                    coolPhone={data} />
                            }
                        </Window>
                    </div>

                )
            } */}

            <Layout socket={status}
                callModal={handleAnswerCall}
                callType={callType}
                time={time}
                parentEnd={callEnded}
                session={session}
                options={options}
                answerModal={answerModal}
                callStatus={callStatus}
                name={name}
                handleNotify={handleNotify}
                notify={notify}
                timer={timer}
                coolPhone={data}>
                <>
                    {/* {timer?.m + " : " + timer?.s}
                    <Button onClick={startTimer}>start</Button>
                    <Button onClick={stopTimer}>stop</Button> */}
                    <Routes>
                        {
                            sideBarRoutes.map((Rout: any, i: any) => {
                                const Component: any = Rout.Component !== undefined && Rout.Component
                                return <Route path={Rout.path} key={i} element={(
                                    <>
                                        <Component
                                            callOptions={tokenStatus && options}
                                            coolPhone={tokenStatus && data} status={status}
                                            callStatus={callStatus}
                                            messages={messages}
                                            socketData={socketData}
                                        />
                                    </>)} />

                            })
                        }
                    </Routes>
                </>
            </Layout>

        </div>
    );
}

export default App;
