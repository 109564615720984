import React from 'react';
import {
    Card,
    CardContent,
    Grid,
    Typography,
    Divider,
    withStyles,
    Switch,
    Button,
    Tabs,
    Tab,
    TableContainer,
    Table,
    TableBody,
    TableRow,
    TextField,
    TableCell,
    IconButton,
    CircularProgress
} from '@material-ui/core';
import { useStyles } from '../Styles';
import PhoneMissedIcon from '@material-ui/icons/PhoneMissed';
import PhoneIcon from '@material-ui/icons/Phone';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import AddIcCallIcon from '@material-ui/icons/AddIcCall';
import { Badge } from '@material-ui/core';
import ListAltSharpIcon from '@material-ui/icons/ListAltSharp';
import Tooltip from '@material-ui/core/Tooltip';
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import axiosInstance from '../../../environment/axiosInstances'
import ReactAudioPlayer from 'react-audio-player';
import { useParams } from "react-router-dom";
import * as DepartmentActionCreators from '../../../Store/Actions/DepartmentsActionXCreators'
import * as authActionCreators from '../../../Store/Actions/AuthActionCreator'
import { TableHeaderComponent } from '../../../Components/Table/Table'
import { Link } from 'react-router-dom';
import { AiOutlineCalendar } from 'react-icons/ai'
import Avatar from '@material-ui/core/Avatar';
import Ellipse from '../../../assets/icons/Ellipse.png';
import SmsRoundedIcon from '@material-ui/icons/SmsRounded';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import Moment from 'moment'
import { useDispatch } from 'react-redux';





function Messages(props: any) {
    const classes = useStyles();
    const params = useParams();
    const dispatch = useDispatch()
    const [messages, setMessages] = React.useState([])
    const [id, setId] = React.useState<any>('')
    const [loading, setLoading] = React.useState<any>(false);
    const [paginate, setPaginate] = React.useState<any>({
        page: 1,
        totalDocs: 0,
        limit: 10
    })
    const handleChangePage = (data: any, value: any) => {
        setPaginate((prev: any) => {
            return {
                ...prev,
                page: value
            }
        })

        fetchMesages(paginate.limit, value, props.type)

    }

    const fetchMesages = (limit: any, page: any, type: any) => {
        setLoading(true)
        axiosInstance.get(`/departments/${params.id}/sms?limit=${limit}&page=${page}&type=${type}`).then((res) => {

            if (res.data) {
                setMessages(res.data.data)
                setLoading(false)
                setPaginate((prev: any) => {
                    return {
                        ...prev,
                        page: res.data.page,
                        totalDocs: res.data.totalDocs,
                        limit: res.data.limit
                    }
                })
            }
        }).catch((err) => {
            setLoading(false)
            if (err.response?.status == 402) {
                window.location.href = "https://newbp.efone.ca/"
                sessionStorage.removeItem("user")

            }
            if (err.response?.status == 401) {
                dispatch(authActionCreators.initRefreshToken())
            }
        })
    }
    React.useEffect(() => {
        fetchMesages(paginate.limit, paginate.page, props.type)
    }, [props.type])
    React.useEffect(() => {
        setId(params?.id)
    }, [])
    const tableHeader = ['Phone Number', 'eFone Number', 'Message', 'Agent', 'Date']
    return (
        <div>
            <TableContainer>
                <Table className={classes.table}>
                    <TableHeaderComponent theadData={tableHeader} />
                    <TableBody>
                        {messages && messages.length > 0 ? messages.map((item: any, i: any) => {
                            var data = parseInt(item?.createdDate)
                            var dataa = Moment(data).format('YYYY MMM DD hh:mm A')

                            return (
                                <TableRow className={classes.tablebackgroundcolor} key={item.id}>
                                    <TableCell className={classes.tablecell}>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <Avatar>{item.contactName ? item.contactName.charAt(0) : item.callerIdName.charAt(0)}</Avatar>
                                            <Link to={{ pathname: `/department/userDetails/${"messages"}/${item?.id}/${item?.departmentId?.id}` }} className={classes.linkUnderline}>
                                                <Typography className={classes.reschedule}>{item.contactName ? item.contactName : item.callerIdName}<br />
                                                    <p style={{ fontSize: "10px", float: "right" }}>({item.phoneNumber})</p></Typography>
                                            </Link>

                                        </div>
                                        {/* {item.userId ?
                                            <div>
                                                {item.userId.firstName} {item.userId.lastName}
                                            </div>
                                            :
                                            item.deparmentId ?
                                                <div>
                                                    {item.deparmentId.deparmentName}
                                                </div>
                                                : ""
                                        } */}
                                    </TableCell>
                                    <TableCell className={classes.tablecell}>{item.phoneNumber}</TableCell>
                                    <TableCell className={classes.tablecell1} >
                                        <Link to={{ pathname: `/department/userDetails/${"messages"}/${item?.id}/${item?.departmentId?.id}` }} className={classes.linkUnderline}>
                                            <IconButton>
                                                <SmsRoundedIcon />
                                            </IconButton>
                                        </Link>
                                        {item.textMessage.length > 20 ? item.textMessage.substring(0, 20) + "..." : item.textMessage}

                                    </TableCell>
                                    <TableCell className={classes.tablecell1}>
                                        {item.deparmentId && item.agentId && item.agentId.firstName}

                                    </TableCell>
                                    <TableCell className={classes.tablecell}>{dataa}</TableCell>
                                </TableRow>
                            )
                        })
                            :

                            loading ? <div className='loader_class'>
                                <div style={{ display: 'grid', alignItems: 'center', margin: '30px 500px' }}>
                                    <CircularProgress disableShrink />
                                </div>
                            </div> : messages.length <= 0 &&
                            <TableRow>
                                <TableCell className={classes.tablecell}></TableCell>
                                <TableCell className={classes.tablecell}></TableCell>
                                <TableCell className={classes.tablecell}>No Data</TableCell>
                                <TableCell className={classes.tablecell}></TableCell>
                                <TableCell className={classes.tablecell}></TableCell>
                            </TableRow>
                            //  <p style={{ textAlign: 'center' }}>No Data</p>

                        }


                    </TableBody>
                </Table>
            </TableContainer>
            {messages.length <= 0 ? "" :
                <Stack spacing={2} style={{ marginTop: 25, marginBottom: 25, float: "right" }}>
                    <Pagination count={paginate.totalPages} page={paginate.page} onChange={handleChangePage} variant="outlined" color="secondary" />
                </Stack>
            }
        </div>
    )
}

export default Messages