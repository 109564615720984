import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  container: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 50,
    margin: 30,
  },
  container1: {
    // position: 'absolute',
    left: 328,
    top: 140,
    right: 20,

  },
  items: {

  },
  statsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    '& > div': {
      margin: '30px 0px 30px 0px',
    },
  },
  display: {
    display: 'block',
    flexWrap: 'nowrap',
  },
}));
