import React from 'react';
import {
    Card,
    CardContent,
    Grid,
    Typography,
    Divider,
    withStyles,
    Switch,
    Button,
    Tabs,
    Tab,
    TableContainer,
    Table,
    TableBody,
    TableRow,
    TextField,
    makeStyles,
    TableCell,
    CircularProgress,
    IconButton,
} from '@material-ui/core';
import { useStyles } from '../Styles';
import Ellipse from '../../../assets/icons/Ellipse.png';
import { Badge } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { useParams } from "react-router-dom";
import axiosInstance from '../../../environment/axiosInstances'
import * as authActionCreators from '../../../Store/Actions/AuthActionCreator'
import { FcInfo } from 'react-icons/fc'
import Backdrop from '../../../Components/Modal/Backdrop'
import Modal from '../../../Components/Modal/Modal'
import MoreDetails from './MoreDetails'
function Agents(props: any) {
    const classes = useStyles()
    const dispatch = useDispatch()
    const [id, setId] = React.useState<any>('')
    const params = useParams();
    const [loading, setLoading] = React.useState<any>(false);
    const [agentsData, setAgentsData] = React.useState<any>([])
    const [openModal, setOpenModal] = React.useState<any>(false);
    const [agentName, setAgentName] = React.useState<any>('')
    const handleOpenModal = (name: any) => {
        setOpenModal(!openModal)
        setAgentName(name)
    }
    const fetchAgents = () => {
        setLoading(true)
        axiosInstance.get(`/departments/${params.id}/agents`).then((res) => {

            if (res.data) {
                setAgentsData(res.data.data)
                setLoading(false)

            }
        }).catch((err) => {
            setLoading(false)
            if (err.response?.status == 402) {
                window.location.href = "https://newbp.efone.ca"
                sessionStorage.removeItem("user")

            }
            if (err.response?.status == 401) {
                dispatch(authActionCreators.initRefreshToken())
            }
        })
    }

    React.useEffect(() => {
        fetchAgents()
    }, [props.type])

    React.useEffect(() => {
        setId(params?.id)
    }, [])


    return (
        <div>
            {
                openModal && (
                    <div>
                        <Backdrop handlePopupModal={handleOpenModal} />
                        <div style={{}}>
                            <Modal title="Agent Details" handlePopupModal={handleOpenModal} >
                                <div className="add_user_popup" style={{ width: "600px" }}>
                                    <MoreDetails handleCose={handleOpenModal} agent={agentName} />
                                </div>
                            </Modal>
                        </div>
                    </div>

                )
            }
            <TableContainer className={classes.tableContainer} style={{ marginTop: 20 }}>
                <Table>
                    <TableBody>
                        {
                            loading === false && agentsData && agentsData.length > 0 ? agentsData.map((item: any, i: any) => {
                                return (
                                    <TableRow className={classes.rowBackground} key={item.name ? item.name : i}>
                                        <TableCell className={classes.tablecell}>
                                            <div className={classes.divContent}>
                                                <img src={Ellipse} />
                                                <Typography className={classes.space}>{item.name}</Typography>
                                            </div>
                                        </TableCell>
                                        <TableCell className={classes.tablecell}>
                                            {
                                                item.status === true ?
                                                    <div className={classes.divContent}>
                                                        <Badge className={classes.dotstyle2} ></Badge>
                                                        <Typography className={classes.idleAvailable}>{item.state}</Typography>
                                                    </div>
                                                    :
                                                    <div className={classes.divContent}>
                                                        <Badge className={classes.dotstyle} ></Badge>
                                                        <Typography className={classes.doNotDisturb}>{item.state}</Typography>
                                                    </div>
                                            }
                                        </TableCell>
                                        <TableCell className={classes.tablecell} onClick={() => handleOpenModal(item.agentName)}>
                                            <FcInfo style={{ cursor: "pointer" }} />
                                        </TableCell>
                                    </TableRow>
                                )
                            })
                                :
                                loading ? <div className='loader_class'>
                                    <div style={{ display: 'grid', alignItems: 'center', margin: '30px 250px' }}>
                                        <CircularProgress disableShrink />
                                    </div>
                                </div> : agentsData.length <= 0 && <p style={{ textAlign: 'center', padding: "10px 0px" }}>No Data</p>
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    )
}

export default Agents