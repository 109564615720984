import { MdPlaylistPlay, } from 'react-icons/md'
import { IoPersonSharp } from 'react-icons/io5'
import { IoMdKeypad } from 'react-icons/io'
import { IoMdSettings, IoMdSend } from 'react-icons/io'
import { RiBook2Line } from 'react-icons/ri'
import { FaMoneyCheckAlt, FaHistory, FaUserFriends, FaBook, FaUserTie } from 'react-icons/fa'
import { AiFillSetting, AiFillBook } from 'react-icons/ai'
import { BsInboxFill, BsFillBookmarkStarFill } from 'react-icons/bs'
import { IoIosUnlock } from 'react-icons/io'
import { GiBackwardTime } from 'react-icons/gi'
import { HiOfficeBuilding, HiDocumentReport } from "react-icons/hi";
import { BiDollarCircle } from 'react-icons/bi'
import { MdContactMail, MdOutlinePhoneCallback } from "react-icons/md"
import { CgPlayList } from 'react-icons/cg'
import { useSelector } from 'react-redux';
import DepartmentPage from '../../Container/DepartmentsPage/index'
const Name = () => {
    const departmentStateData = useSelector((state: any) => state.departmentModule);
    const { selectedDepartment, loading } = departmentStateData;

    return (selectedDepartment?.name)
}
const sidebarLinks: any = [

    {
        title: 'My Activities',
        path: '/',
        Icon: BsInboxFill,
        color: '',
        IconSize: 22,
        childTitle: '',
        routes: []
    },

    {
        title: 'Contacts',
        path: `/contacts`,
        Icon: MdContactMail,
        IconSize: 22,
        childTitle: '',
        routes: []
    },
    {
        title: 'Favorites',
        path: '/favorites',
        Icon: BsFillBookmarkStarFill,
        IconSize: 22,
        color: '',
        childTitle: '',
        routes: []
    },
    {
        title: 'Departments',
        path: '/department',
        Icon: RiBook2Line,
        color: '',
        IconSize: 22,
        routes: []
    },

    {
        title: 'Recent',
        path: '/recent',
        Icon: GiBackwardTime,
        color: '',
        IconSize: 22,
        childTitle: '',
        routes: []
    },
    {
        title: 'PlayLists',
        path: '/playlist',
        Icon: MdPlaylistPlay,
        color: '',
        IconSize: 22,
        childTitle: '',
        routes: []
    }

]

export default sidebarLinks