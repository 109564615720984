import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    childComponenets: {
        backgroundColor: 'inherit',
        /* margin-top: 70px; */
        marginTop: 90,
        marginLeft: 307,
        [theme.breakpoints.down('sm')]:{
            marginLeft: 0,
        }
    }
}))