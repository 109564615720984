import React from 'react'
import {
    Button, TextField,

} from '@material-ui/core';
import { AiFillCaretDown } from 'react-icons/ai'
import { FaPhoneAlt } from 'react-icons/fa'
import './sidebar.css'
import sidebarLinks from '../../services/Routes/Sidebar_links'
import Sidebarelemnt from './SidebarElement/SidebarElement'
import SearchIcon from '../../assets/icons/searchIcon.png';
import { useStyles } from './styles';
import axiosInstance from '../../environment/axiosInstances'
import { getInitStatus, getInitPatchStatus } from '../../Store/Actions/ActionCreator'
import { useDispatch, useSelector } from "react-redux";
import Backdrop from '../../Components/Modal/Backdrop'
import Modal from '../../Components/Modal/Modal'
import Dialpad from './SidebarElement/Dialpad/Dialpad';
import { Window, WindowActionsEvent } from "@progress/kendo-react-dialogs";
import OutgoingCall from './SidebarElement/Dialpad/CallsModal/OutgoingCall'
import * as DepartmentActionCreators from '../../Store/Actions/DepartmentsActionXCreators'
import { DepartmentStatus, fetchDepartmentsSuccess } from '../../Store/Actions/DepartmentsActionXCreators'
import * as authActionCreators from '../../Store/Actions/AuthActionCreator'
import * as CallActionCreator from '../../Store/Actions/CallActionCreator'
function SidebarComponent(props: any) {
    const classes = useStyles();
    const [dndselect, setdndSelect] = React.useState(false);
    const [callModal, setCallModal] = React.useState(false)
    const [calling, setCalling] = React.useState(false)
    const [number, setNumber] = React.useState('')
    const [data, setData] = React.useState({ phoneNumber: '' })
    const [depts, setDepts] = React.useState([])
    const profileDataStore: any = useSelector((state: any) => state.profileData);
    const userAuthState = useSelector((state: any) => state.authState)
    const { profileData, loading } = profileDataStore;
    const departmentState = useSelector((state: any) => state.departmentModule);
    const { departmentsData, selectedDepartment, DndStatus } = departmentState
    const [openModal, setModalOpen] = React.useState(false)
    const [windowStage, setWindowStage] = React.useState("MAXIMISED");
    const dispatch = useDispatch();
    React.useEffect(() => {
        dispatch(getInitStatus())
    }, [])
    const handleOpenModal = () => {
        setModalOpen(!openModal);


    };
    const handleOpen1Modal = (child: any) => {
        setModalOpen(!openModal);
        setCalling(child)
        if (child) {
            setCallModal(true)
        }

    };
    const handleCallModal = () => {
        setCallModal(!callModal)
    }
    const handleStageChange = (e: any) => {
        setWindowStage(e.state);
    };
    const parentCall = (child: any) => {
        setData(child)
        setNumber(child.phoneNumber)
        // console.log("----------numbetr")
        // dispatch(CallActionCreator.fetchStatus(data))
        // dispatch(CallActionCreator.fetchData(true))
    }
    const parentCalling = (child: any) => {
        setCalling(child)
    }

    const getDepartments = () => {
        dispatch(DepartmentActionCreators.fetchDepartmentsLoding(true))
        axiosInstance.get('/departments').then((res) => {

            if (res.data) {
                const departments: any = res.data.data;
                setDepts(departments)

                dispatch(DepartmentActionCreators.fetchDepartmentsSuccess(departments))

            } else {
                dispatch(DepartmentActionCreators.fetchDepartmentsLoding(false))
            }
        }).catch((err) => {
            dispatch(DepartmentActionCreators.fetchDepartmentsLoding(false))
            if (err.response?.status == 402) {
                window.location.href = "https://newbp.efone.ca"
                sessionStorage.removeItem("user")

            }
            if (err.response?.status == 401) {
                dispatch(authActionCreators.initRefreshToken())
            }
        })
        // axiosInstance.get('/departments').then((res) => {
        //     if (res.data) {
        //         const data = res.data.data
        //         setDepts(res.data.data)
        //         // console.log("---dep", res)
        //     }
        // }).catch((err) => {

        // })
    }
    React.useEffect(() => {
        getDepartments()
    }, [DndStatus])
    React.useEffect(() => {
        getDepartments()
    }, [userAuthState.isAuthenticated])
    return (
        <>
            <div>
                {
                    openModal && (
                        <div>
                            {/* <Backdrop handlePopupModal={handleOpenModal} /> */}
                            <Modal title="Dialpad" handlePopupModal={handleOpenModal} >
                                <div className="add_user_popup" style={{ width: "700px" }}>
                                    <Dialpad
                                        modalClose={handleOpen1Modal}
                                        callOptions={props.callOptions}
                                        parentCall={parentCall}
                                        coolPhone={props.coolPhone}
                                        openModal={openModal}
                                        parentCalling={parentCalling} />
                                </div>

                            </Modal>

                        </div>
                    )
                }
                {/* {
                    callModal && (
                        <div style={{ padding: 0 }}>
                            <Window
                                title={""}
                                stage={windowStage}
                                onStageChange={handleStageChange}
                            >

                                <OutgoingCall callModal={handleCallModal}
                                    data={data} coolPhone={props.coolPhone}
                                    callOptions={props.callOptions}
                                    callStatus={props.callStatus}
                                />

                            </Window>
                        </div>

                    )
                } */}
            </div>
            <div className="sidebar_body_section">
                <div className={classes.wrapper}>
                    <div className={classes.container}>
                        <Button
                            variant="contained"
                            className={classes.makecallbutton}
                            disableRipple
                            onClick={handleOpenModal}
                            startIcon={<FaPhoneAlt />}
                            // endIcon={<img src={ChipIcon} alt="ChipIcon" />}
                            endIcon={<AiFillCaretDown />}
                        >
                            Make A Call
                        </Button>

                        {
                            sidebarLinks.map((itm: any, i: any) => {
                                return <Sidebarelemnt sidebar={itm} key={i}
                                    depmnts={depts} dndselect={profileData?.dndStatus}
                                    getDepartments={getDepartments} />
                            })
                        }

                        <div className={classes.cardContent}>
                            <TextField
                                size="small"
                                className={classes.searchtextfield}
                                placeholder={'global search'}
                                // value={searchTerm}
                                variant="outlined"
                            // onChange={handleUserChange}
                            />
                            <Button
                                variant="outlined"
                                className={classes.searchicon}
                                startIcon={<img className={classes.search} src={SearchIcon} alt="search" />}
                            />
                        </div>
                    </div>
                </div>

            </div>
        </>


    )
}



export default SidebarComponent;