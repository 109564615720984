import React from 'react'
import {
    TableContainer,
    Table,
    TableBody,
    TableRow,
    TableCell,
    IconButton,
    MenuItem,
    Avatar,
    Button,
    FormGroup,
    FormControlLabel,
    FormControl,
    Typography,
    Divider,
    CircularProgress,
} from '@material-ui/core';
import { useStyles } from './Styles';
import { Link } from 'react-router-dom'
import PhoneCallbackIcon from '@material-ui/icons/PhoneCallback';
import * as authActionCreators from '../../../Store/Actions/AuthActionCreator'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Checkbox from '@material-ui/core/Checkbox';
import PhoneMissedIcon from '@material-ui/icons/PhoneMissed';
import PhoneIcon from '@material-ui/icons/Phone';
import { HiPhoneOutgoing } from 'react-icons/hi'
import AddIcCallIcon from '@material-ui/icons/AddIcCall';
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import Grow from "@material-ui/core/Grow";
import MenuList from "@material-ui/core/MenuList";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { BsThreeDots } from "react-icons/bs";
import profile from "../../../assets/icons/avtar.jpg";
import { useDispatch, useSelector } from "react-redux";
import * as CallActionCreator from '../../../Store/Actions/CallActionCreator'
import axiosInstance from '../../../environment/axiosInstances'
export default function Tablebdy(props: any) {
    const classes = useStyles();
    const dispatch = useDispatch()
    const [startDate, setStartDate] = React.useState<Date | null>(new Date());
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [option, setOption] = React.useState<any>()
    const [open, setOpen] = React.useState(false);
    const anchorRef: any = React.useRef(null);
    function handleListKeyDown(event: any) {
        if (event.key === "Tab") {
            event.preventDefault();
            setOpen(false);
        }
    }
    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };
    const handleClose = (event: any) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    const handleChange = (val: any) => {
        setOption(val)
    }
    React.useEffect(() => {
        setOption(props.option)
    }, [props.option])
    function toTime(d: any) {
        d = Number(d);
        var h = Math.floor(d / 3600);
        var m = Math.floor(d % 3600 / 60);
        var s = Math.floor(d % 3600 % 60);

        var hDisplay = h > 0 ? h : "00";
        var mDisplay = m > 0 ? m : "00";
        var sDisplay = s > 0 ? s : "00";
        return hDisplay + ":" + mDisplay + ":" + sDisplay;
    }
    return (
        <TableRow className={classes.tablebackgroundcolor} key={props.item.id}>
            <TableCell className={classes.tablecell}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Avatar>{props.item.contactName ? props.item.contactName.charAt(0) : props.item.callerIdName.charAt(0)}</Avatar>
                    <Link to={{ pathname: `/activities/userDetails/${'calls'}/${props.item.id}` }} className={classes.linkUnderline}>
                        <Typography className={classes.reschedule}> {props.item.contactName ? props.item.contactName : props.item.callerIdName}<br />
                            <p style={{ fontSize: "10px", float: "right" }}>({props.item.phoneNumber})</p></Typography>
                    </Link>
                </div>
            </TableCell>

            <TableCell className={classes.tablecell}>{props.item.eFoneNumber}</TableCell>

            <TableCell className={classes.tablecell}>
                {props.item.isCallDirection == 1 && props.item.duration > 0 ?
                    <div>
                        <IconButton >
                            <PhoneCallbackIcon className={classes.incomingiconcolor} />
                        </IconButton>
                        Incoming/{toTime(props.item.duration)}
                    </div>
                    :
                    props.item.isCallDirection == 1 && props.item.duration == 0 ?
                        <div>
                            <IconButton>
                                <PhoneMissedIcon className={classes.missediconcolor} />
                            </IconButton>
                            Missed call
                        </div>
                        :
                        props.item.isCallDirection == 2 ?
                            <div>
                                <IconButton>
                                    <HiPhoneOutgoing className={classes.outgoingcalliconcolor} />
                                </IconButton>
                                Outgoing/{toTime(props.item.duration)}
                            </div> : ""
                }
            </TableCell>
            {props.item.agentId &&
                <TableCell className={classes.tablecell}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <img alt="Remy Sharp" src={profile} className={classes.profile} />
                        <Typography className={classes.reschedule}>  {props.item.agentId && props.item.agentId.firstName}</Typography>
                    </div>


                </TableCell>
            }
            <TableCell className={classes.tablecell}>
                <div
                    className={classes.buttonicons}
                    style={{ marginLeft: "10px" }}>
                    <IconButton
                        onClick={() => props.handleCall(props.item, option)}
                    ><PhoneIcon className={classes.iconcolor} /></IconButton>
                    <Divider orientation="vertical" flexItem className={classes.dividericon} />
                    <IconButton onClick={handleToggle} ref={anchorRef}>
                        <ArrowDropDownIcon className={classes.iconcolor} />
                    </IconButton>
                    <Popper
                        open={open}
                        anchorEl={anchorRef.current}
                        role={undefined}
                        transition
                    >
                        {({ TransitionProps, placement }) => (
                            <Grow
                                {...TransitionProps}
                                style={{
                                    transformOrigin:
                                        placement === "bottom" ? "center bottom" : "center bottom",

                                }}
                            >
                                <Paper className={classes.paper}>
                                    <ClickAwayListener onClickAway={handleClose}>
                                        <MenuList
                                            autoFocusItem={open}
                                            id="menu-list-grow"
                                            onKeyDown={handleListKeyDown}
                                        >
                                            <MenuItem>
                                                <FormControl>
                                                    {props.outboundData.map((opt: any, i: any) => {
                                                        return <FormGroup key={i}>
                                                            <FormControlLabel
                                                                control={<Checkbox onChange={() => handleChange(opt)} name={opt.id}
                                                                    checked={opt.id == option?.id} />}
                                                                label={opt.value + "  (" + opt.name + ")"}
                                                            />

                                                        </FormGroup>
                                                    })}

                                                </FormControl>
                                            </MenuItem>

                                        </MenuList>
                                    </ClickAwayListener>

                                </Paper>
                            </Grow>
                        )}
                    </Popper>
                </div>
            </TableCell>

            <TableCell className={classes.tablecell}>{props.dataa}</TableCell>
        </TableRow>
    )
}
