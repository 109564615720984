import { makeStyles } from '@material-ui/core/styles';
import { textSpanOverlap } from 'typescript';

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    height: '100%',
    position: 'fixed',
    top: 0,
    bottom: 0,
    left: 0,
    zIndex: 10,

  },
  container: {
    minWidth: '307px',
    height: '1081px',
    paddingTop: 20,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: '#2645AD',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    zIndex: 1024,
    '& img': {
      cursor: 'pointer',
    },
    '& .webphone': {
      marginTop: 'auto',
      width: 33,
      height: 33,
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  makecallbutton: {
    marginTop: '87px',
    marginBottom: "20px",
    justifyContent: 'space-between',
    width: '232px',
    borderRadius: '10px',
    backgroundColor: '#F3F5FF',
    fontFamily: 'Work Sans',
    color: '#2645AD',
    textTransform: 'capitalize',
    '&.hover': {
      backgroundColor: '#F3F5FF',
      color: '#2645AD',
    }
  },
  button: {
    justifyContent: 'space-evenly',
    marginTop: 40,
    marginBottom: 5,
    height: 40,
    width: '100%',
    padding: 0,
    backgroundColor: '#FFFFFF',
    borderRadius: '0px 0px 0px 0px',
    '&.active': {
      background: 'white',
      color: '#2645AD',
    },
  },
  logoutImage: {
    width: 23,
  },
  logoutImage2: {
    width: 17,
  },
  activitytext: {
    color: '#2645AD',
    textTransform: 'none',
    fontSize: '15px',
    fontFamily: 'Work Sans',
    fontWeight: 600,
    marginTop: '3px',
  },
  contactLink: {
    // color: '#dfdfdf',
    // marginLeft: 12,
    textTransform: 'none',
    fontSize: '15px',
    fontFamily: 'Work Sans',
    fontWeight: 600,
    // marginTop: '3px',
    '&.active': {
      background: 'white',
      color: 'green',
    },
  },
  contactLinkOne: {
    // color: '#dfdfdf',
    marginLeft: 12,
    textTransform: 'none',
    fontSize: '15px',
    fontFamily: 'Work Sans',
    fontWeight: 600,
    // marginTop: '3px',
    '&.active': {
      background: 'white',
      color: 'green',
    },
  },
  contactlessLink: {
    // color: '#dfdfdf',
    textTransform: 'none',
    fontSize: '15px',
    fontFamily: 'Work Sans',
    fontWeight: 600,
    marginTop: '3px',

  },
  navText: {
    color: '#dfdfdf',
    textTransform: 'none',
    fontSize: '15px',
    fontFamily: 'Work Sans',
    fontWeight: 600,
    marginTop: '3px',
    '&.active': {
      background: 'white',
      color: ''
    },
  },
  dndOption: {

    marginLeft: '10px',
  },
  dndOption2: {
    // marginRight: '10px',
    marginLeft: 3
  },
  dndText: {
    fontSize: '11px',
    fontWeight: 600,
    color: '#858585',
    marginTop: '3px',
    marginLeft: 8
  },
  listitemscolor: {
    color: '#DFDFDF',
    fontFamily: 'Work Sans',
    // marginBottom: '3px',
    '&.active': {
      background: 'white',
      color: '#2645AD',
    },

  },
  listitemscolor12: {
    color: '#DFDFDF',
    fontFamily: 'Work Sans',
    marginBottom: '3px',


  },
  listitems: {
    // display: 'flex',
    // alignItems: "center",
    color: '#dfdfdf',
    marginBottom: '0px',
    marginTop: "-10px",
    '&.active': {
      background: 'white',
      color: '#DFDFDF',
    },

  },
  contactIcon: {
    color: '#DFDFDF',
    fontSize: 20,
    '&:active': {
      color: '#2645AD',
    },
  },
  activeicon: {
    color: '#2645AD',
    fontSize: 20,
  },
  favoriteIcon: {
    color: '#DFDFDF',
    fontSize: 20,
  },
  departmentIcon: {
    color: '#DFDFDF',
    fontSize: 20,
  },
  recentIcon: {
    color: '#DFDFDF',
    fontSize: 20,
  },
  playListIcon: {
    color: '#DFDFDF',
    fontSize: 20,
  },
  salesLink: {
    color: 'white',
    fontFamily: 'Work Sans',
    textDecoration: 'none',
    justifyContent: 'space-between',
    alignItems: 'center',
    display: 'flex',
    '&.active': {
      background: 'white',
      color: 'green',
    },
  },
  typographycolor: {
    fontSize: '11px',
    fontWeight: 600,
    fontFamily: 'Work Sans',
    color: '#dfdfdf',
    // marginTop: '10px',
    // marginLeft: '-10px'
  },
  cardContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: '20px',
  },
  searchtextfield: {
    backgroundColor: '#FFFFFF',
    borderRadius: '100px',
    height: '40px',
    color: '#D1D1D1',
    overflow: 'hidden',
    left: '11px',
    border: 'transparent',
    fontFamily: 'Work Sans',
  },
  subNav: {
    marginLeft: '12px',
    width: "100%"
  },
  dndGroup: {
    // right: '-71px',
    position: 'relative'
  },
  searchicon: {
    right: '52px',
    backgroundColor: '#DFDFDF',
    height: '38px',
    borderBottomRightRadius: '100px',
    borderTopRightRadius: '100px',
  },
  search: {
    position: 'absolute',
    top: 10,
    left: 20,
  },
  dotstyle1: {
    // right: '16px',
    height: '10px',
    width: '10px',
    borderRadius: '50%',
    // top: '2px',
    background: '#00effb'
  },
  dotstyle2: {
    right: '16px',
    height: '10px',
    width: '10px',
    borderRadius: '50%',
    top: '2px',
    background: '#1bff00'
  },
  dotstyle3: {
    right: '16px',
    height: '10px',
    width: '10px',
    borderRadius: '50%',
    color: '#43AF00',
    top: '2px',
    background: 'yellow'
  },
}));
