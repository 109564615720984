import React from 'react';
import {
  Card,
  CardContent,
  Grid,
  Typography,
  Divider,
  withStyles,
  Switch,
  Button,
  Tabs,
  Tab,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  Link,
  TextField,

} from '@material-ui/core';
// import TabPanel from '@material-ui/lab/TabPanel';
import Cookies from 'js-cookie'
import { TabPanel, TabContext } from '@material-ui/lab'
import { getInitStatus, getInitPatchStatus, onChangeDNDstatus } from '../../../Store/Actions/ActionCreator'
import { useStyles } from './styles';
import * as authActionCreators from '../../../Store/Actions/AuthActionCreator'
import ReactSelect from 'react-select'
import MyactivityMessages from '../Messages';
import MyactivityCalls from '../Calls';
import MyactivityFollowup from '../Followup';
import MyactivityVoicemail from '../Voicemail';
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import axiosInstance from '../../../environment/axiosInstances'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { useDispatch, useSelector } from "react-redux";
import TableBodyData from './LiveCallsTable/TableBodyData';
import LiveCallsTable from './LiveCallsTable/LiveCallsTable';
import { useNavigate } from 'react-router'

toast.configure()
function Livecalls(props: any): JSX.Element {
  const classes = useStyles();
  const [value, setValue] = React.useState('1');
  const profileDataStore: any = useSelector((state: any) => state.profileData);
  const userAuthState = useSelector((state: any) => state.authState)
  const { profileData, loading } = profileDataStore;

  const [dndselect, setdndSelect] = React.useState(false);

  const [LiveCallsloading, setLiveCallsLoading] = React.useState<any>(false);
  const [searchData, setSearchData] = React.useState<any>({
    searchValue: '',
  })
  const navigate = useNavigate()

  const [clear, setClear] = React.useState(false)
  const [type, setype] = React.useState<any>(1)
  const handleTabs = (e: any, newValue: any) => {
    setValue(newValue);
  };
  const [state, setState] = React.useState({
    checkedA: true,
    checkedB: true,
    checkedC: true,
  });
  const dispatch = useDispatch();
  const handlecheckChange = (event: any) => {
    setdndSelect(!dndselect)
    const data: any = {
      "dndStatus": !dndselect
    }
    dispatch(getInitPatchStatus(data))
    dispatch(onChangeDNDstatus(!dndselect))
    dispatch(getInitStatus())
  };
  const onChangeSearchData = (e: any) => {
    setSearchData((prev: any) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      }
    })
  }

  const AntSwitch = withStyles((theme) => ({
    root: {
      width: 28,
      height: 17,
      padding: 0,
      display: 'flex'
    },
    switchBase: {
      padding: 2,
      color: theme.palette.grey[500],
      '&$checked': {
        transform: 'translateX(12px)',
        color: theme.palette.common.white,
        '& + $track': {
          opacity: 1,
          backgroundColor: theme.palette.primary.main,
          borderColor: theme.palette.primary.main,
        },
      },
    },
    thumb: {
      width: 12,
      height: 12,
      boxShadow: 'none',
    },
    track: {
      border: `1px solid ${theme.palette.grey[500]}`,
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor: theme.palette.common.white,
    },
    checked: {},
  }))(Switch);
  React.useEffect(() => {

    setdndSelect(profileData?.dndStatus)

  }, [loading])
  React.useEffect(() => {
    dispatch(getInitStatus())

  }, [])

  const [liveCallsData, setLiveCallsData] = React.useState([])
  const fetchLiveCalls = () => {
    setLiveCallsLoading(true)
    axiosInstance.get('/my-activities/live-calls').then((res: any) => {
      if (res.data) {
        setLiveCallsData(res.data.data)
        setLiveCallsLoading(false)
      } else {
        setLiveCallsLoading(false)
      }

    }).catch((err) => {
      setLiveCallsLoading(false)

      if (err.response?.status == 402) {
        window.location.href = "https://newbp.efone.ca"
        sessionStorage.removeItem("user")
        Cookies.remove('access')
        Cookies.remove('refresh')
      }
      if (err.response?.status == 401) {
        dispatch(authActionCreators.initRefreshToken())
      }
    })
  }

  React.useEffect(() => {
    if (userAuthState.isAuthenticated && value === '1')
      fetchLiveCalls()
  }, [userAuthState.isAuthenticated, value])


  const keyOptions = [
    { label: "Today", value: 1 },
    { label: "Yesterday", value: 2 },
    { label: "Last 7 days", value: 3 },
    { label: "Last 30 days", value: 4 },

  ]
  const handleOptions = (e: any) => {
    setype(e.value)

  }
  const handleSearch = () => {
    if (searchData.searchValue !== "") {
      setClear(true)


    } else {
      setClear(false)
    }
  }


  return (
    <>
      <div>
        <Card>
          <Grid container spacing={3}>
            <Grid item md={12} sm={12}>
              <CardContent>
                <Typography className={classes.activitytext}>My Activities</Typography>
                <Grid container spacing={2} className={classes.buttondisplay}>
                  <Grid item md={4} lg={4} sm={6} xs={12}>
                    <div className={classes.dnddisplay}>
                      <Typography className={classes.dndtext}>Do Not Distrub</Typography>
                      <AntSwitch
                        className={classes.typographyleft}
                        checked={dndselect}
                        onChange={handlecheckChange}

                      />
                    </div>
                  </Grid>
                  {value !== '1' &&
                    <Grid item md={4} lg={4} sm={4} xs={12} style={{ marginLeft: "179px" }}>
                      <TextField
                        variant="outlined"
                        size="small"
                        fullWidth
                        placeholder="Search with call,all,voicemail..."
                        onChange={onChangeSearchData}
                        value={searchData.searchValue}
                        name="searchValue"
                        InputProps={{
                          endAdornment: (

                            <InputAdornment position="start" style={{ cursor: "pointer" }} onClick={handleSearch}>
                              <SearchIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                  }
                  {value !== '1' &&
                    <Grid item xs={4} lg={2} md={4} sm={4} xl={2}>
                      <ReactSelect
                        placeholder="Select key"
                        options={keyOptions}
                        onChange={handleOptions}
                        value={keyOptions.filter((opt: any) => opt.value === type)}
                      />
                    </Grid>
                  }
                </Grid>
                {/* <Grid container spacing={2}>
                  <Grid item md={10} lg={6} sm={10}>
                    <div className={classes.linkdisplay}>
                      <Link className={classes.linkColor}>Permanent</Link>
                      <Link className={classes.linkColor}>Until: Today 5:30 pm</Link>
                      <Link className={classes.linkColor}>Until: 29th March 5:30 pm</Link>
                    </div>
                  </Grid>
                </Grid> */}

                <Divider className={classes.dividercolor} />
                <TabContext value={value}>
                  <Tabs
                    value={value}
                    indicatorColor="primary"
                    textColor="primary"
                    onChange={handleTabs}
                    aria-label="disabled tabs example" className={classes.tabHeight}>
                    <Tab
                      label="Live Calls"
                      value="1"
                      className={value === '1' ? classes.tabscoloractive : classes.tabscolor}
                    />
                    {/* <Tab
                      label="Follow Up"
                      value="2"
                      className={value === '2' ? classes.tabscoloractive : classes.tabscolor}
                    /> */}
                    <Tab
                      label="Calls"
                      value="3"
                      className={value === '3' ? classes.tabscoloractive : classes.tabscolor}
                    />
                    <Tab
                      label="Messages"
                      value="4"
                      className={value === '4' ? classes.tabscoloractive : classes.tabscolor}
                    />
                    <Tab
                      label="Voice mail"
                      value="5"
                      className={value === '5' ? classes.tabscoloractive : classes.tabscolor}
                    />
                    {/* <Tab
                      label="Starred"
                      value="6"
                      className={value === '6' ? classes.tabscoloractive : classes.tabscolor}
                    /> */}
                  </Tabs>
                  <Divider className={classes.dividerType2} />
                  <TabPanel value="1">
                    <Grid container spacing={3}>
                      <Grid item md={12} lg={12} sm={12}>
                        <LiveCallsTable fetchLiveCalls={fetchLiveCalls} LiveCallsloading={LiveCallsloading} data={liveCallsData} />
                      </Grid>
                    </Grid>
                  </TabPanel>
                  <TabPanel value='2'>
                    <Grid container spacing={3}>
                      <Grid item md={12}>
                        <MyactivityFollowup />
                      </Grid>
                    </Grid>
                  </TabPanel>
                  <TabPanel value='3'>
                    <Grid container spacing={3}>
                      <Grid item md={12} sm={12}>
                        <MyactivityCalls type={type} clear={clear}
                          coolPhone={props.coolPhone}
                          callOptions={props.callOptions}
                          callStatus={props.callStatus} />
                      </Grid>
                    </Grid>
                  </TabPanel>
                  <TabPanel value="4">
                    <Grid container spacing={3}>
                      <Grid item md={12} sm={12}>
                        <MyactivityMessages type={type} clear={clear} />
                      </Grid>
                    </Grid>
                  </TabPanel>
                  <TabPanel value='5'>
                    <Grid container spacing={3}>
                      <Grid item md={12} sm={12} xs={12}>
                        <MyactivityVoicemail type={type} clear={clear} />
                      </Grid>
                    </Grid>
                  </TabPanel>
                </TabContext>
              </CardContent>
            </Grid>
          </Grid>
        </Card>
      </div>
    </>
  );
}
export default Livecalls
