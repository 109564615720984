import React from 'react'
import Header from '../Header/Header'
import { Card, Typography } from '@material-ui/core'
import SidebarComponent from '../../Components/SidebarComponent/Sidebar-componet'
import { useStyles } from './styles';
import './layout.css'
import { AiOutlineMinus } from 'react-icons/ai'
import { BiMinusBack } from 'react-icons/bi'
import { VscChromeMaximize } from 'react-icons/vsc'
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import axios from 'axios'
import axiosInstance from '../../environment/axiosInstances'
import Cookies from 'js-cookie'
import OutgoingCall from '../../Components/SidebarComponent/SidebarElement/Dialpad/CallsModal/OutgoingCall'
import { useSelector, useDispatch } from 'react-redux'
import * as userAuthActionCreators from '../../Store/Actions/AuthActionCreator'
import * as CallActionCreator from '../../Store/Actions/CallActionCreator'
import { useLocation } from 'react-router';
import CallingPage from '../CallingPage/CallPage'
import IncomingCall from '../CallsModal/IncomingCall'

function Layout(props: any) {
    const classes = useStyles();
    let start = 0
    let intrvl: any

    const [timer, setTimer] = React.useState({
        s: "00",
        m: "00",
        h: "00"
    })
    const location = useLocation();
    const [data, setData] = React.useState<any>({})
    const [event, setEvent] = React.useState<any>()
    const [callStatus, setCallStatus] = React.useState(false)
    const [maximise, setMaximise] = React.useState(false)
    const [depts, setDepts] = React.useState([])
    const [minimise, setMinimise] = React.useState(true)
    const [Incomingminimise, setIncominMinimise] = React.useState(true)
    const [status, setStatus] = React.useState(false)
    const [windowStage, setWindowStage] = React.useState("MINIMIZED");
    const callingData: any = useSelector((state: any) => state.CallingData);
    const dispatch = useDispatch()
    const queryParams = new URLSearchParams(window.location.search);
    const token: any = queryParams.get('token');
    const navigate = useNavigate();
    React.useEffect(() => {

        if (token) {

            const data = {
                token: token,
                nav: navigate

            }
            dispatch(userAuthActionCreators.initAuth(data))
            if (!depts) {
                window.location.reload();
            }

            navigate('/')
            // axios.post(`${process.env.REACT_APP_API_URL}/auth/user/login`, data).then((res: any) => {

            //     if (res.data) {
            //         const data = res.data.data
            //         sessionStorage.setItem('user', JSON.stringify(res.data.data))
            //         Cookies.set('access', JSON.stringify(data.access.token))
            //         Cookies.set('refresh', JSON.stringify(data.refresh.token))
            //         setData(res.data.data)
            //         window.location.reload();
            //         navigate('/')
            //         getDepartments()

            //     }
            //     else {

            //     }
            // }).catch((err: any) => {

            // })
        }
    }, [token])

    const handleMinimise = () => {
        setMinimise(!minimise)
    }
    React.useEffect(() => {
        var local_user = sessionStorage.getItem("user")
        if (local_user == null && token == null) {
            window.location.href = "https://newbp.efone.ca/"
        }
        // else {
        //     dispatch(authActionCreators.initRefreshToken())
        // }
    }, [])

    function setZeros(i: any) {
        if (i < 10) return "0" + i;
        return i;
    }

    //start timer

    React.useEffect(() => {
        if (props.callStatus === "Accepted") {

        }
        else if (props.callStatus === "ended") {
            setStatus(false)

            dispatch(CallActionCreator.fetchStatus(null))
            dispatch(CallActionCreator.fetchData(false))
        }

    }, [props.callStatus])
    React.useEffect(() => {
        setStatus(callingData?.CallStatus)
        setMinimise(callingData?.CallStatus)
    }, [callingData?.CallStatus])
    React.useEffect(() => {
        setMinimise(props.answerModal)
    }, [props.answerModal])
    React.useEffect(() => {
        if (props.callStatus === "Accepted") {
            setCallStatus(true)
        } else if (props.callStatus === "ended" || props.callStatus === "failed") {
            setCallStatus(false)
            props.parentEnd()
        }
    }, [props.callStatus])

    const handleEndCall = (child: any) => {
        setStatus(child)
        props.parentEnd()
    }
    const parentMinimise = (child: any) => {
        setMinimise(child)
    }
    const parentIncomingMinimise = (child: any) => {
        setIncominMinimise(child)
    }
    const handleIncomingMinimise = () => {
        setIncominMinimise(!Incomingminimise)
    }

    return (
        <div className="layout_section">
            <div className="sidebar_container">
                <div className="sidebar_wrapper">
                    <SidebarComponent data={depts}
                        callOptions={props.options}
                        coolPhone={props.coolPhone}
                        callStatus={props.callStatus}
                    />
                </div>
                {/* <div className="sidebar_child_component"> */}
                {/* <SidebarChildren /> */}
                {/* </div> */}
            </div>

            <div className="main_body_section">
                <div className="header_section">
                    <Header socket={props.socket}
                        name={props.name}
                        handleNotify={props.handleNotify}
                        notify={props.notify} />
                </div>
                <div className={classes.childComponenets}>
                    {props.children}
                </div>
                {status ?
                    <div className="footer_container">
                        {/* footer container */}
                        {/* <FooterComponent /> */}
                        {minimise ?
                            <Card>
                                <div>
                                    <CallingPage parentMinimise={parentMinimise}
                                        callingData={callingData}
                                        callOptions={props.options}
                                        coolPhone={props.coolPhone}
                                        callStatus={callStatus}
                                        time={props.time}
                                        parentEndCall={handleEndCall}
                                        timer={props.timer} />
                                </div>
                            </Card>
                            :
                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                <p>Calling...</p>
                                <div style={{ cursor: "pointer" }} onClick={handleMinimise}>
                                    {/* <AiOutlineMinus /> */}
                                    <BiMinusBack />
                                </div>

                            </div>
                        }




                    </div> : ""}
                {props.answerModal ?
                    <div className="footer_container">
                        {/* footer container */}
                        {/* <FooterComponent /> */}
                        {Incomingminimise ?
                            <Card>
                                <div>
                                    <IncomingCall parentMinimise={parentIncomingMinimise}
                                        callModal={props.callModal}
                                        session={props.session}
                                        options={props.options}
                                        coolPhone={props.coolPhone}
                                        callStatus={callStatus}
                                        parentEndCall={props.callModal}
                                        end={props.parentEnd}
                                        timer={props.timer} />
                                </div>
                            </Card>
                            :
                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                <p>Incoming Call...</p>
                                <div style={{ cursor: "pointer" }} onClick={handleIncomingMinimise}>
                                    {/* <AiOutlineMinus /> */}
                                    <BiMinusBack />
                                </div>

                            </div>
                        }




                    </div> : ""}
            </div>

        </div>
    )
}

export default Layout
