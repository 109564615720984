import React from 'react';
import {
  Card,
  CardContent,
  Grid,
  Typography,
  Divider,
  withStyles,
  Switch,
  Button,
  Tabs,
  Tab,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  Link,
  TextField,

} from '@material-ui/core';
import TableBodyData from './TableBodyData';
import CircularProgress from '@mui/material/CircularProgress';



function LiveCallsTable(props: any) {
  const data = props.data;
  return (
    <div>
      <TableContainer>
        <Table>
          <TableBody>
            {
              props.LiveCallsloading === false && data.length > 0 && data.map((dt: any, i: any) => <TableBodyData fetchLiveCalls={props.fetchLiveCalls} rowData={dt} id={i} />)
            }
          </TableBody>
        </Table>
        {
          props.LiveCallsloading ? <div className='loader_class'>
            <div style={{ display: 'grid', alignItems: 'center', margin: '30px 500px' }}>
              <CircularProgress disableShrink />
            </div>
          </div> : data.length <= 0 && <p style={{ textAlign: 'center' }}>No Data</p>
        }
      </TableContainer>
    </div>
  )
}

export default LiveCallsTable