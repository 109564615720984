import React from 'react';
import {
    Button,
    Badge,
    Chip,
    Card,
    CardContent,
    Avatar,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Divider,
    Typography,
    Switch,
    Box,
    Popper,
    withStyles,
    Grid,
} from '@material-ui/core';
import ChipIcon from '../../assets/icons/chipDown.png';
import Popup from "reactjs-popup";
import {
    Notifications,
    AccountCircleOutlined,
    VpnKeyOutlined,
    PowerSettingsNew,
    ArrowForwardIos,
} from '@material-ui/icons';
import SidebarLogoIcon from '../../assets/icons/sidebar-logo.png';
import { NavLink } from 'react-router-dom';
import { connect } from "react-redux";
import { useStyles } from './styles';
// import { Modal } from '@material-ui/core';
import { useState } from 'react';
import { CardHeader } from './CardHeader';
import ReactSelect from 'react-select';
// import { Icon } from '@iconify/react';
import { AiFillSetting, AiOutlineStar } from 'react-icons/ai'
import { RiNotification2Line } from 'react-icons/ri'
import { FaPhoneAlt } from 'react-icons/fa'
import { BsBoxArrowInDownRight } from 'react-icons/bs'
import { BiMessageRoundedDetail } from 'react-icons/bi'
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import axios from 'axios'
import Backdrop from '../../Components/Modal/Backdrop'
import Modal from '../../Components/Modal/Modal'
import AppSettings from './AppSettings/AppSettings';



// import Modal from '../../Components/Modal/Modal'
// import settingFilled from '@iconify-icons/ant-design/setting-filled';
// import arrowSquareDownRight from '@iconify-icons/ph/arrow-square-down-right';
// import notification2Line from '@iconify-icons/ri/notification-2-line';
// import starIcon from '@iconify-icons/akar-icons/star';
// import phoneFill from '@iconify-icons/eva/phone-fill';
// import boxArrowInDownRight from '@iconify-icons/bi/box-arrow-in-down-right';
// import feedbackLine from '@iconify-icons/ri/feedback-line';
// import playCircleFilled from '@iconify-icons/ant-design/play-circle-filled';


// function getModalStyle() {
//     const top = 15;
//     const left = 30;
//     const right = 30;
//     return {
//         top: `${top}%`,
//         left: `${left}%`,
//         right: `${right}%`,
//         transform: `translate(-${top}%, -${left}%, -${right}%)`,
//     };
// }

const Header = (props: any) => {
    const classes = useStyles();
    const [chip, setChip] = React.useState<boolean>(false);
    const container = React.createRef<HTMLDivElement>();
    const [open, setOpen] = React.useState<boolean>(false);
    const [alert, setAlert] = React.useState<boolean>(false);
    const navigate = useNavigate();
    // const [modalStyle] = React.useState(getModalStyle);
    const [openModal, setModalOpen] = React.useState(false)
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const openBox = Boolean(anchorEl);
    const id = openBox ? 'simple-popper' : undefined;
    const handlePopupModal = () => {
        setModalOpen(!openModal);
    };
    const handleAlert = () => {
        setAlert(!alert)
        sessionStorage.setItem("Notification", JSON.stringify(0))
        props.handleNotify(false)
    }

    const [state, setState] = React.useState({
        checkedA: true,
        checkedB: true,
        checkedC: true,
    });

    const handlecheckChange = (event: any) => {
        setState({ ...state, [event.target.name]: event.target.checked });
    };

    const handleOpen = () => {
        setOpen(!open);
    }





    const queryParams = new URLSearchParams(window.location.search);
    const token: any = queryParams.get('token');
    // const logout = () => {
    //     sessionStorage.clear();
    //     sessionStorage.clear();
    //     history.push('/');
    // };

    const handleClickOutside = (event: any) => {
        if (container.current && !container.current.contains(event.target)) {
            setChip(false);
        }
    };

    React.useEffect(() => {
        document.addEventListener('click', handleClickOutside);
    });


    const AntSwitch = withStyles((theme) => ({
        root: {
            width: 28,
            height: 16,
            padding: 0,
            display: 'flex',
        },
        switchBase: {
            padding: 2,
            color: theme.palette.grey[500],
            '&$checked': {
                transform: 'translateX(12px)',
                color: theme.palette.common.white,
                '& + $track': {
                    opacity: 1,
                    backgroundColor: theme.palette.primary.main,
                    borderColor: theme.palette.primary.main,
                },
            },
        },
        thumb: {
            width: 12,
            height: 12,
            boxShadow: 'none',
        },
        track: {
            border: `1px solid ${theme.palette.grey[500]}`,
            borderRadius: 16 / 2,
            opacity: 1,
            backgroundColor: theme.palette.common.white,
        },
        checked: {},
    }))(Switch);
    const status = props.socket
    var myStr = props.name;
    var matches = myStr?.match(/\b(\w)/g);
    React.useEffect(() => {
        setAlert(props.notify)

    }, [props.notify])
    var count = sessionStorage.getItem("Notification")
    return (
        <>

            <div>
                {
                    openModal && (
                        <div>
                            <Backdrop handlePopupModal={handlePopupModal} />
                            <div style={{ width: 500 }}>
                                <Modal title="App Settings" handlePopupModal={handlePopupModal} >
                                    <AppSettings />
                                </Modal>
                            </div>
                        </div>
                    )
                }{
                    open && (
                        <div>
                            <Backdrop handlePopupModal={handleOpen} />
                            <div style={{ width: 500 }}>
                                <Modal title="" handlePopupModal={handleOpen} >
                                    <Typography>You Received New Message</Typography>
                                </Modal>
                            </div>
                        </div>
                    )
                }
            </div>

            <div className={classes.topbar} ref={container}>
                <div className={classes.wrapper}>
                    <img className={classes.imageSWdth} src={SidebarLogoIcon} alt="efone" />
                    {props.name &&
                        <Chip
                            className={classes.chip}
                            avatar={<Avatar className={classes.avatar}>{props.name && matches.join('')}</Avatar>}
                            label={props.name}
                            clickable={true}
                            // onClick={handleDelete}
                            // onDelete={handleDelete}
                            deleteIcon={<img className={classes.chipIcon} src={ChipIcon} alt="chip" />}
                        />
                    }
                    {status === "Connected" ?
                        <Button
                            variant="outlined"
                            disableRipple
                            className={classes.connectedbutton}>
                            {status}...

                        </Button>
                        :
                        status === "Registering" ?
                            <Button
                                variant="outlined"
                                disableRipple
                                className={classes.warningbutton}>
                                {status}...

                            </Button> :
                            <Button
                                variant="outlined"
                                disableRipple
                                className={classes.Notconnectedbutton}>
                                {status}...

                            </Button>}
                    {/* <Button
                        variant="outlined"
                        className={classes.connectedbutton}>
                        {/* <Icon icon={AiOutlineStar} className={classes.staricon} /> */}
                    {/* <AiOutlineStar className={classes.startIcon} />
                        <Divider orientation="vertical" flexItem className={classes.dividericon} /> */}
                    {/* <Icon icon={FaPhoneAlt} className={classes.phoneicon} /> */}
                    {/* <FaPhoneAlt className={classes.endIc} />
                    </Button> */}
                    <div className={classes.divdisplay}  >
                        {/* <Icon
              icon={AiFillSetting}
              className={classes.settingright}
              onClick={handleOpen}

            /> */}
                        <AiFillSetting onClick={handlePopupModal} className={classes.rightIcons} />

                        {/* <Icon icon={BsBoxArrowInDownRight} className={classes.settingright} /> */}
                        <BsBoxArrowInDownRight className={classes.rightIcons} />
                        {alert ?
                            <div>
                                {/* <Badge color="error" badgeContent={1} className={classes.arrowright}
                                aria-describedby={id} onClick={handleClick}>
                                {/* <Icon icon={RiNotification2Line} /> */}
                                {/* <RiNotification2Line className={classes.notIcon} />

                            </Badge> */}
                                {/* <Popper id={id} open={openBox} anchorEl={anchorEl} style={{ zIndex: 15000, border: '1px solid #4D72F8', marginTop: '11px' }}>
                                <Box sx={{ border: 1, p: 1, bgcolor: 'background.paper' }}>
                                    You received a new message
                                </Box>
                            </Popper> */}
                                <Popup
                                    arrowStyle={{ color: "pink" }}
                                    trigger={
                                        <Badge color="error" badgeContent={count} className={classes.arrowright}
                                            aria-describedby={id} >
                                            {/* <Icon icon={RiNotification2Line} /> */}
                                            <RiNotification2Line className={classes.notIcon} />

                                        </Badge>
                                    }

                                    position="bottom center">
                                    <Card style={{ background: '#ffe6e6', padding: "6px 10px", borderColor: "#ff6666" }}>
                                        <div style={{ display: "flex", gap: 2 }}>
                                            <span><BiMessageRoundedDetail /></span>
                                            <Typography onClick={handleAlert}>You received a new message</Typography>
                                        </div>

                                    </Card>
                                </Popup>
                            </div>
                            :


                            <RiNotification2Line className={classes.notIcon} />


                        }
                    </div>
                </div>
                {/* {chip ? (
                    <div className={classes.root}>
                        <List component="nav">
                            <ListItem button disabled></ListItem>
                            <ListItem className={classes.listButton} button component={NavLink} to="/profile">
                                <ListItemIcon className={classes.group}>
                                    <AccountCircleOutlined className={classes.icon} />
                                </ListItemIcon>
                                <ListItemText primary="My Profile" />
                                <ListItemIcon className={classes.listItem}>
                                    <ArrowForwardIos className={classes.icon} />
                                </ListItemIcon>
                            </ListItem>
                            <ListItem className={classes.listButton} button>
                                <ListItemIcon className={classes.group}>
                                    <VpnKeyOutlined className={classes.icon} />
                                </ListItemIcon>
                                <ListItemText primary="Password Change" />
                                <ListItemIcon className={classes.listItem}>
                                    <ArrowForwardIos className={classes.icon} />
                                </ListItemIcon>
                            </ListItem>
                            <ListItem button >
                                <ListItemIcon className={classes.group}>
                                    <PowerSettingsNew className={classes.icon} />
                                </ListItemIcon>
                                <ListItemText primary="Logout" />
                                <ListItemIcon className={classes.listItem}>
                                    <ArrowForwardIos className={classes.icon} />
                                </ListItemIcon>
                            </ListItem>
                        </List>
                    </div>
                ) : null} */}
            </div>
        </>
    );
};


export default Header
