import React from 'react'
import {
    Card,
    IconButton,
    MenuItem,
    Avatar,
    Grid,
    Typography,
} from '@material-ui/core';
import PhoneMissedIcon from '@material-ui/icons/PhoneMissed';
import PhoneCallbackIcon from '@material-ui/icons/PhoneCallback';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import PhoneIcon from '@material-ui/icons/Phone';
import { HiPhoneOutgoing } from 'react-icons/hi'
import { useStyles } from './Styles';
import Moment from 'moment'
export default function Calls(props: any) {
    const classes = useStyles();
    const messagesEndRef: any = React.useRef(null)
    var data = parseInt(props.data?.createdDate)
    var dataa = Moment(data).format('hh:mm A')
    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
    }

    React.useEffect(() => {
        scrollToBottom()
    }, [props.data]);
    return (
        <div ref={messagesEndRef} >
            {/* <Typography style={{ textAlign: "center", fontWeight: "500px", margin: "10px 0px" }}>{dataa}</Typography> */}
            <Grid container spacing={2}>
                <Grid item md={6} style={{ marginBottom: "25px" }}>

                    {props.data.direction == 1 && props.data.duration > 0 ?
                        <div style={{ padding: "5px", borderRadius:5, marginBottom: "25px", marginTop: "15px", display: "flex",alignItems:'center', backgroundColor: "#DCDCDC" }} >
                            <IconButton style={{ width: 30,height:30,backgroundColor: "#4D72F8", borderRadius: "10px" }}>
                                <PhoneCallbackIcon style={{fontSize:18}} className={classes.incomingiconcolor} />
                            </IconButton>

                            <div style={{ display: "flex",alignItems: 'center', flexDirection: "column", marginLeft: "10px" }}>
                                <Typography style={{ fontSize: "13px", fontWeight: "bold" }}>Incoming Call</Typography>
                                <Typography style={{ fontSize: "13px",}}>{props.data.phoneNumber}</Typography>

                            </div>
                            <div style={{ display: "flex", flexDirection: "column", marginLeft: "10px" }}>
                                <Typography style={{fontSize: "13px",marginLeft: "3px" }}>({Math.ceil(props.data.duration / 60)} mins )</Typography>
                                <div style={{ fontSize: "11px",fontWeight:500, marginTop: "5px", marginLeft: "92px" }}>
                                    {dataa}
                                </div>
                            </div>
                        </div>
                        :
                        props.data.direction == 1 && props.data.duration == 0 ?
                            <div style={{ padding: "5px", borderRadius:5, display: "flex",alignItems:'center', marginTop: "15px", backgroundColor: "#FAEBD7" }} >
                                <IconButton style={{width: 30,height:30, backgroundColor: "red", borderRadius: "10px" }}>
                                    <PhoneMissedIcon style={{fontSize:18}} className={classes.missediconcolor} />
                                </IconButton>
                                <div style={{ display: "flex", flexDirection: "column", marginLeft: "10px" }}>
                                    <Typography style={{ fontSize: "13px", fontWeight: "bold" }}> Missed call</Typography>
                                    <div style={{display:'flex'}}>
                                        <Typography style={{ fontSize: "13px"}}>{props.data.phoneNumber}</Typography>
                                        <div style={{ fontSize: "11px",fontWeight:500, marginLeft: "92px", marginTop: "5px" }}>
                                            {dataa}
                                        </div>
                                    </div>
                                </div>

                            </div>
                            : ""
                    }
                </Grid>
                <Grid item md={6}>
                    {props.data.direction == 2 ?
                        <div style={{ padding: "5px", borderRadius:5, display: "flex",alignItems:'center', marginTop: "15px", backgroundColor: "#F0F8FF", float: "right" }} >
                            <IconButton style={{width:30,height:30,padding:7, backgroundColor: "#4D72F8", borderRadius: "10px" }}>
                                <HiPhoneOutgoing  className={classes.outgoingcalliconcolor} />
                            </IconButton>
                            <div style={{ display: "flex", flexDirection: "column", marginLeft: "10px" }}>
                                <Typography style={{ fontSize: "13px", fontWeight: "bold" }}>Outgoing</Typography>
                                <Typography style={{ fontSize: "12px"}}>{props.data.phoneNumber}</Typography>
                            </div>
                            <div style={{ display: "flex", flexDirection: "column" }}>
                                <Typography style={{fontSize: "13px",fontWeight:400, marginTop: "1px",  }}>({Math.ceil(props.data.duration / 60)} mins )</Typography>
                                <div style={{ fontSize: "11px",fontWeight:500, marginLeft: "92px" }}>
                                    {dataa}
                                </div>
                            </div>

                        </div> : ""}
                </Grid>
            </Grid>



        </div >
    )
}
