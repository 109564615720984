import React from 'react'
import {
    Card,
    IconButton,
    Grid,
    Typography,
} from '@material-ui/core';
import { HiPhoneOutgoing } from 'react-icons/hi'
import { useStyles } from './Styles';
import Moment from 'moment'
import AudioPlayer from './AudioPlayer'
export default function VoiceMail(props: any) {
    const classes = useStyles();
    const messagesEndRef: any = React.useRef(null)
    var data = parseInt(props.data?.createdDate)
    var dataa = Moment(data).format(' ddd hh:mm A')
    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
    }

    React.useEffect(() => {
        scrollToBottom()
    }, [props.data]);
    return (
        <div ref={messagesEndRef} >

            {/* <Typography style={{ textAlign: "center", fontWeight: "500px", margin: "10px 0px" }}>{dataa}</Typography> */}
            <Grid container spacing={2}>
                <Grid item md={6} style={{ marginBottom: "25px" }}>

                    {props.data.direction == 1 &&
                        <div style={{ padding: "10px", alignItems: "center", marginBottom: "25px", marginTop: "15px", display: "flex", backgroundColor: "#FAEBD7" }} >
                            <div style={{ borderRadius: "10px" }}>
                                <AudioPlayer path={props.data.vmAudioPath} />
                            </div>
                            <div style={{ display: "flex", flexDirection: "column", marginLeft: "10px" }}>
                                <Typography style={{ fontSize: "16px", fontWeight: "bold" }}> VoiceMail</Typography>
                                <Typography>{props.data.phoneNumber}</Typography>
                            </div>
                        </div>
                    }
                </Grid>
                <Grid item md={6}>
                    {props.data.direction == 2 &&
                        <div style={{ padding: "10px", display: "flex", alignItems: 'center', marginTop: "15px", backgroundColor: "#F0F8FF", float: "right" }} >
                            <div style={{ borderRadius: "10px" }}>
                                <AudioPlayer path={props.data.vmAudioPath} />
                            </div>
                            <div style={{ display: "flex", flexDirection: "column", marginLeft: "10px" }}>
                                <Typography style={{ fontSize: "16px", fontWeight: "bold" }}> VoiceMail</Typography>
                                <Typography>{props.data.phoneNumber}</Typography>
                            </div>

                        </div>
                    }
                </Grid>
            </Grid>


        </div>
    )
}
