import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
    screenHeader: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    divider: {
        borderTop: '2px solid #D2D2D2',
        marginTop: '10px',
    },
    playlists: {
        color: '#565656',
        fontFamily: 'Work Sans',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: 20,
        letterSpacing: 0.5,
    },
    iconFontsize: {
        fontSize: 20,
        color: '#565656',
    },
    cardBackground: {
        background: '#FCFCFC',
        borderRadius: 10,
    },
    badConnection: {
        color: '#565656',
        fontFamily: 'Work Sans',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: 18,
    },
    smallCard: {
        background: '#EBEBEB',
        borderRadius: '0px 0px 10px 10px'
    },
    earMark: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    earMarkMusic: {
        color: '#2645AD',
        fontSize: 40,
        marginTop: 20,
    },
    audio: {
        marginTop: 10,
        color: '#838383',
        fontFamily: 'Work Sans',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: 13,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    playDiv: {
        marginTop: 23,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: '#2645AD',
        height: 30,
    },
    playCircleIcon: {
        marginRight: 6,
        color: '#DFDFDF',
        fontSize: 15,
        cursor: 'pointer',
    },
    play: {
        fontFamily: 'Work Sans',
        fontStyle: 'normal',
        fontWeight: 500,
        color: '#DFDFDF',
        fontSize: 12,
    },
    divCard: {
        marginTop: 30,
    },
    smallCardTwo: {
        border: '1px solid #DFDFDF',
        background: '#FCFCFC',
        borderRadius: 10,
    },
    bestExample: {
        margin: 8,
        color: '#565656',
        fontFamily: 'Work Sans',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: 18,
    },
}))