import React from 'react';
import {
    TableContainer,
    Table,
    TableBody,
    TableRow,
    TableCell,
    IconButton,
    makeStyles,
    CircularProgress
} from '@material-ui/core';
import { useStyles, PrettoSlider } from './Styles';
import CallEndIcon from '@material-ui/icons/CallEnd';
import axiosInstance from '../../../environment/axiosInstances'
import Pagination from '@mui/material/Pagination';
import { TableHeaderComponent } from '../../../Components/Table/Table'
import Stack from '@mui/material/Stack';
import Moment from 'moment'
import { Link } from 'react-router-dom';
import * as authActionCreators from '../../../Store/Actions/AuthActionCreator'
import ReactAudioPlayer from 'react-audio-player';
import { useDispatch } from 'react-redux';
function MyactivityVoicemail(props: any): JSX.Element {
    const classes = useStyles();
    const [id, setId] = React.useState<any>('')
    const dispatch = useDispatch()
    const [VoiceMail, setVoicemail] = React.useState([])
    const [loading, setLoading] = React.useState<any>(false);
    const [paginate, setPaginate] = React.useState<any>({
        page: 1,
        totalDocs: 0,
        limit: 10
    })
    const handleChangePage = (data: any, value: any) => {
        setPaginate((prev: any) => {
            return {
                ...prev,
                page: value
            }
        })

        fetchVoiceMail(paginate.limit, value, props.type)

    }
    const fetchVoiceMail = (limit: any, page: any, type: any) => {
        setLoading(true)
        axiosInstance.get(`/my-activities/voice-mails?limit=${limit}&page=${page}&type=${type}`).then((res) => {
            if (res.data) {
                setVoicemail(res.data.data)
                setLoading(false)
                setPaginate((prev: any) => {
                    return {
                        ...prev,
                        page: res.data.page,
                        totalDocs: res.data.totalDocs,
                        limit: res.data.limit
                    }
                })
            }
        }).catch((err) => {
            setLoading(false)
            if (err.response?.status == 402) {
                window.location.href = "https://newbp.efone.ca"
                sessionStorage.removeItem("user")

            }
            if (err.response?.status == 401) {
                dispatch(authActionCreators.initRefreshToken())
            }
        })
    }
    React.useEffect(() => {
        fetchVoiceMail(paginate.limit, paginate.page, props.type)
    }, [props.type])
    const handleLoadMetadata = (meta: any) => {
        const { duration } = meta.target;
        const styles = makeStyles({

        })
        console.log(duration);
    }
    const tableHeader = ['User', 'Phone Number', 'eFone Number', 'VoiceMail', 'Date']
    return (
        <>
            <TableContainer>
                <Table className={classes.table}>
                    <TableHeaderComponent theadData={tableHeader} />
                    <TableBody>
                        {VoiceMail && VoiceMail.length > 0 ? VoiceMail.map((item: any, i: any) => {
                            var data = parseInt(item?.createdDate)
                            var dataa = Moment(data).format('YYYY MMM DD hh:mm A')

                            return (
                                <TableRow className={classes.tablebackgroundcolor} key={item.id}>
                                    <TableCell className={classes.tablecell}>
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            <IconButton>
                                                <CallEndIcon />
                                            </IconButton>
                                            {item.userId ?
                                                <div>
                                                    <Link to={{ pathname: `/activities/userDetails/${'voiceMails'}/${item.id}` }} className={classes.linkUnderline}>
                                                        {item.userId.firstName} {item.userId.lastName}
                                                    </Link>


                                                </div>
                                                :
                                                item.deparmentId ?
                                                    <div>
                                                        {item.deparmentId.deparmentName}
                                                    </div>
                                                    : ""
                                            }
                                        </div>
                                    </TableCell>


                                    <TableCell className={classes.tablecell}>{item.phoneNumber}</TableCell>
                                    <TableCell className={classes.tablecell}>{item.eFoneNumber}</TableCell>
                                    <TableCell className={classes.tablecell}>
                                        <ReactAudioPlayer
                                            controls
                                            className={classes.audioPlayer}
                                            onLoadedMetadata={handleLoadMetadata}
                                            src={item.vmAudioPath}
                                        />
                                    </TableCell>
                                    <TableCell className={classes.tablecell}>{dataa}</TableCell>
                                </TableRow>
                            )
                        })
                            :

                            loading ? <div className='loader_class'>
                                <div style={{ display: 'grid', alignItems: 'center', margin: '30px 500px' }}>
                                    <CircularProgress disableShrink />
                                </div>
                            </div> : VoiceMail.length <= 0 &&
                            <TableRow>
                                <TableCell className={classes.tablecell}></TableCell>
                                <TableCell className={classes.tablecell}></TableCell>
                                <TableCell className={classes.tablecell}>No Data</TableCell>
                                <TableCell className={classes.tablecell}></TableCell>
                                <TableCell className={classes.tablecell}></TableCell>
                            </TableRow>
                            //  <p style={{ textAlign: 'center' }}>No Data</p>

                        }

                        {/* <TableRow>
                            <TableCell className={classes.tablecell}>
                                <IconButton>
                                    <CallEndIcon />
                                </IconButton>
                            </TableCell>
                            <TableCell className={classes.tablecell}>+1 (123)878 9998</TableCell>
                            <TableCell className={classes.tablecell}></TableCell>
                            <TableCell className={classes.tablecell}></TableCell>
                            <TableCell className={classes.prettoslider}>

                                {/* <PrettoSlider

                                    valueLabelDisplay="auto"
                                    aria-label="pretto slider"
                                    defaultValue={20}
                                />&nbsp; &nbsp;00:50 */}
                        {/* </TableCell>
                            <TableCell className={classes.tablecell}>Tuesday</TableCell>
                        </TableRow> */}
                    </TableBody>
                </Table>
            </TableContainer>
            {VoiceMail.length <= 0 ? "" :
                <Stack spacing={2} style={{ marginTop: 25, marginBottom: 25, float: "right" }}>
                    <Pagination count={paginate.totalPages} page={paginate.page} onChange={handleChangePage} variant="outlined" color="secondary" />
                </Stack>
            }
        </>
    )
}
export default MyactivityVoicemail