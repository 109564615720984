import React from 'react';
import {
    Card,
    CardContent,
    Grid,
    Typography,
    Divider,
    Button,
    TableContainer,
    Table,
    TableBody,
    TableRow,
    TableCell,
    TextField,
    IconButton,
    Popper,
    Grow,
    Paper,
    ClickAwayListener,
    MenuList,
    MenuItem,
} from '@material-ui/core';
import { useStyles } from './styles';
import PhoneIcon from '@material-ui/icons/Phone';
import Blacksearchicon from '../../assets/icons/blacksearchicon.png';
import Customer from '../../assets/icons/customer.png';
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import Services from './service'
import CircularProgress from '@mui/material/CircularProgress';
import Moment from 'moment'
import Backdrop from '../../Components/Modal/Backdrop'
import Modal from '../../Components/Modal/Modal'
import AddFavourite from './AddFavourite/AddFavourite';
import { BiMessageSquareAdd } from 'react-icons/bi'
import { BsThreeDots } from "react-icons/bs";
import { AiFillEdit } from "react-icons/ai";
import { MdDelete } from "react-icons/md"
import UpdateFavourite from './AddFavourite/UpdateFavourite';
import { Stack } from '@mui/material';
import { Pagination } from '@material-ui/lab';
import Tablebdy from './Tablebdy';
import axiosInstance from '../../environment/axiosInstances'
import * as authActionCreators from '../../Store/Actions/AuthActionCreator'
import { useDispatch, useSelector } from "react-redux";
function MyFavourites(props: any): JSX.Element {
    const classes = useStyles();
    const dispatch = useDispatch()
    const [favouriteData, setFavouriteData] = React.useState([])
    const [editModal, setEditModal] = React.useState(false)
    const [loading, setLoading] = React.useState<any>(false);
    const [DeleteModal, setDeleteModal] = React.useState(false)
    const [getid, setId] = React.useState<any>('')
    const [openModal, setModalOpen] = React.useState(false)
    const [open, setOpen] = React.useState(false);
    const [delId, setDelID] = React.useState<any>('')
    const anchorRef: any = React.useRef(null);
    const [outboundData, setOutBoundData] = React.useState<any>([])
    const [option, setOption] = React.useState<any>()
    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handlePopupModal = () => {
        setModalOpen(!openModal);
    };
    const [paginate, setPaginate] = React.useState<any>({
        page: 1,
        totalDocs: 0,
        limit: 10
    })

    const [searchData, setSearchData] = React.useState<any>({
        type: '',
        typeKey: '',
        typevalue: '',
        searchValue: '',
        name: '',
        nameKey: '',
        nameValue: '',
        phoneNumber: '',
        phoneNuberValue: '',
        phoneNumberKey: ''

    })
    const [searching, setSearching] = React.useState<any>(false)

    const onChangeSearchData = (name: any, value: any) => {
        if (name == 'name') {
            if (value !== "") {
                setSearchData((prev: any) => {
                    return {
                        ...prev,
                        nameKey: name,
                        nameValue: value,
                        name: value
                    }
                })
            }
        } else {
            setSearchData((prev: any) => {
                return {
                    ...prev,
                    nameKey: '',
                    nameValue: '',
                    name: ''
                }
            })
        }
    }
    const searchedData = () => {
        if (searchData.searchValue !== '') {

            setLoading(true)
            Services.getFaVouritesearch(paginate.limit, paginate.page, searchData).then((res: any) => {
                if (res.data) {
                    setLoading(false)
                    setFavouriteData(res.data.data)
                    setPaginate((prev: any) => {
                        return {
                            ...prev,
                            page: res.data.page,
                            totalDocs: res.data.totalDocs,
                            limit: res.data.limit
                        }
                    })
                    setSearching(false)
                } else {
                    setSearching(false)
                    setLoading(false)
                }
            }).catch((err: any) => {
                setSearching(false)
                setLoading(false)
            })
        }
        else {
            getFavourits(paginate.limit, paginate.page)
        }
    }



    const handleChangeSearch = (e: any) => {
        setSearchData((prev: any) => {
            return {
                ...searchData,
                searchValue: e.target.value
            }
        })
    }





    const getFavourits = (limit: any, page: any,) => {
        setLoading(true)
        Services.getfavouritesList(limit, page).then((res: any) => {

            if (res.success) {
                setFavouriteData(res.data.data)
                setLoading(false)
                setFavouriteData(res.data.data)
                setPaginate((prev: any) => {
                    return {
                        ...prev,
                        page: res.data.page,
                        totalDocs: res.data.totalDocs,
                        limit: res.data.limit
                    }
                })
                setSearching(false)
            }
            else {
                setSearching(false)
                setLoading(false)
            }
        }).catch((err: any) => {
            setSearching(false)
            setLoading(false)


        })
    }

    React.useEffect(() => {
        getFavourits(paginate.limit, paginate.page)
    }, [])

    const deleteFavourite = () => {
        Services.deltefavouritesList(delId).then((res: any) => {
            if (res.success) {
                getFavourits(paginate.limit, paginate.page)
                setDeleteModal(!DeleteModal)
            } else {

            }
        }).catch((err: any) => {

        })
    }

    function handleListKeyDown(event: any) {
        if (event.key === "Tab") {
            event.preventDefault();
            setOpen(false);
        }
    }

    const handleClose = (event: any) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };

    const handlePopupDeleteFavourites = (id: any) => {
        setDelID(id)
        setDeleteModal(!DeleteModal)
    }

    const handlePopupModalUpdate = (id: any) => {
        setId(id);
        setEditModal(!editModal)
    }

    const handleChangePage = (data: any, value: any) => {
        setPaginate((prev: any) => {
            return {
                ...prev,
                page: value
            }
        })
        if (searching) {
            //searchedData(paginate.limit, value)
        } else {
            getFavourits(paginate.limit, value)
        }
    }

    React.useEffect(() => {
        if (searchData.searchValue !== '') {
            setSearching(true)
        } else {
            setSearching(false)
        }
    }, [searchData])
    const getOutboundCallers = () => {
        axiosInstance.get('/calls/outbound-caller-id').then((res) => {
            if (res.data) {
                const data = res.data.data
                setOutBoundData(data)
                setOption(data[0])
            }
        }).catch((err) => {
            if (err.response?.status == 402) {
                window.location.href = "https://newbp.efone.ca"
                sessionStorage.removeItem("user")

            }
            if (err.response?.status == 401) {
                dispatch(authActionCreators.initRefreshToken())
            }
        })
    }
    React.useEffect(() => {
        getOutboundCallers()
    }, [])

    return (
        <>
            <div>
                {
                    openModal && (
                        <div>
                            <Backdrop handlePopupModal={handlePopupModal} />
                            <div style={{ width: 300 }}>
                                <Modal title="Add New Favourite" handlePopupModal={handlePopupModal} >
                                    <AddFavourite handlePopupModal={handlePopupModal} getFavourits={getFavourits} paginate={paginate} />

                                    {/* <AddContact handleModal={handlePopupModal} getContacts={getContacts} paginate={paginate} /> */}
                                </Modal>
                            </div>
                        </div>
                    )
                }
                {
                    editModal && (
                        <div>
                            <Backdrop handlePopupModal={handlePopupModalUpdate} />
                            <Modal title="Update Favourites" handlePopupModal={handlePopupModalUpdate} >
                                <UpdateFavourite getFavourits={getFavourits} handlePopupModal={handlePopupModalUpdate} paginate={paginate} id={getid} />
                            </Modal>
                        </div>
                    )
                }
                {
                    DeleteModal && (
                        <div>
                            <Backdrop handlePopupModal={handlePopupDeleteFavourites} />
                            <Modal title="Are you sure to delete this Favourite ?" handlePopupModal={handlePopupDeleteFavourites} >
                                <Grid container spacing={2} style={{ margin: 15 }}>
                                    <div className="add_user_popup" style={{ width: "500px" }}>
                                        <Grid item md={12} style={{ textAlign: 'center' }}>
                                            <div>
                                                <Button size="medium" className={classes.deleteButton} onClick={deleteFavourite}>Delete</Button>
                                                <Button size="medium" onClick={handlePopupDeleteFavourites} className={classes.CancelButton}>Cancel</Button>
                                            </div>
                                        </Grid>
                                    </div>
                                </Grid>
                            </Modal>
                        </div>
                    )
                }
            </div>
            <div>
                <Card>
                    <Grid container spacing={3}>
                        <Grid item md={12} sm={12}>
                            <CardContent>
                                <div className={classes.typographydisplay}>
                                    <Grid item md={6} sm={6} lg={8} xs={6}>
                                        <Typography className={classes.activitytext}>Favourites</Typography>
                                    </Grid>
                                    <Grid item md={6} lg={4} sm={6} xs={6} className={classes.buttonpadding}>
                                        <TextField
                                            variant="outlined"
                                            size="small"
                                            fullWidth
                                            placeholder="Search contact,number,name.."
                                            onChange={handleChangeSearch}
                                            name="search"
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="start">
                                                        <SearchIcon onClick={searchedData} style={{ cursor: 'pointer' }} />
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </Grid>
                                </div>
                                <Divider className={classes.dividercolor} />
                                <Grid container spacing={3}>
                                    <Grid item md={8} xl={9} lg={9} sm={9} xs={6}></Grid>
                                    <Grid item md={4} xl={3} lg={3} sm={3} xs={6}>
                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            className={classes.addcontactbutton}
                                            onClick={handlePopupModal}
                                            startIcon={<BiMessageSquareAdd />}>
                                            Add New
                                        </Button>
                                    </Grid>
                                </Grid>
                                <CardContent>
                                    <TableContainer>
                                        <Table>
                                            <TableBody>
                                                {
                                                    loading === false && favouriteData.map((item: any) => {

                                                        var data = parseInt(item.createdDate)

                                                        var dataa = Moment(data).format('DD/MM/YYYY ')
                                                        return (
                                                            <Tablebdy item={item}
                                                                key={item.id}
                                                                coolPhone={props.coolPhone}
                                                                outboundData={outboundData}
                                                                option={option}
                                                                callOptions={props.callOptions}
                                                                handlePopupDeleteFavourites={handlePopupDeleteFavourites}
                                                                handlePopupModalUpdate={handlePopupModalUpdate}
                                                                callStatus={props.callStatus} />
                                                        )
                                                    })
                                                }

                                            </TableBody>
                                        </Table>
                                        {
                                            loading ? <div className='loader_class'>
                                                <div style={{ display: 'grid', alignItems: 'center', margin: '30px 500px' }}>
                                                    <CircularProgress disableShrink />
                                                </div>
                                            </div> : favouriteData.length <= 0 && <p style={{ textAlign: 'center' }}>No Data</p>
                                        }
                                    </TableContainer>
                                    {favouriteData.length <= 0 ? "" :
                                        <Stack spacing={2} style={{ marginTop: 25, marginBottom: 25, float: "right" }}>
                                            <Pagination count={paginate.totalPages} page={paginate.page} onChange={handleChangePage} variant="outlined" color="secondary" />

                                        </Stack>
                                    }
                                </CardContent>
                            </CardContent>
                        </Grid>
                    </Grid>
                </Card>
            </div>
        </>
    )
}
export default MyFavourites
