import { departmentModle } from '../Actions/Actions';
const departmentState = {
    departmentsData: [],
    loading: false,
    selectedDepartment: null,
    DndStatus: false
}

export const departmentsReducerFunction = (state: any = departmentState, action: any) => {
    switch (action.type) {
        case departmentModle.FETCH_DEPARTMENTS_SUCCESS:
            return {
                ...state,
                departmentsData: action.departmentsData
            }
        case departmentModle.ONCHANGE_SELECT_DEPARTMENT:
            return {
                ...state,
                selectedDepartment: action.selectedDepartment
            }
        case departmentModle.FETCH_LOADING_DEPARTMENTS:
            return {
                ...state,
                loading: action.status
            }
        case departmentModle.ONCHANGE_DND_STATUS:
            return {
                ...state,
                DndStatus: action.DndStatus
            }
        default:
            return state
    }
}