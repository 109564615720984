import { CallingModule } from './Actions';
export const fetchStatus = (data: any) => {
    return {
        type: CallingModule.GET_STATUS,
        data: data
    }
}

export const fetchData = (status: any) => {
    return {
        type: CallingModule.GET_CALL_STATUS_REQUEST,
        status: status
    }
}