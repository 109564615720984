import React from 'react';
import ReactDOM from 'react-dom';
import { createStore, applyMiddleware, combineReducers, compose } from 'redux'
import { Provider } from 'react-redux'
import createSagaMiddleware from 'redux-saga'
import './index.css';
import IndexSaga from './Store/ReduxSaga/IndexSaga'
import CombineReducers from './Store/AllReducers'
import App from './App';
import { BrowserRouter } from 'react-router-dom'
const combinedAllReducerss: any = combineReducers(CombineReducers);
declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}
// const loogerMiddleware = createLogger();
const sagaMiddleWare = createSagaMiddleware()
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(combinedAllReducerss, composeEnhancers(applyMiddleware(sagaMiddleWare)))
sagaMiddleWare.run(IndexSaga)


ReactDOM.render(
  // <React.StrictMode>
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>,
  // </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
