import { activityDNDstatus, activityPatchDND } from '../Actions/Actions'
const userProfileData = {
    DndStatus: false,
    loading: false
}
export const userDNDProfileData = (state = userProfileData, action: any) => {
    switch (action.type) {
        case activityDNDstatus.GET_DND_STATUS_SUCCESS:
            return {
                ...state,
                profileData: action.data
            }
        case activityDNDstatus.GET_DND_STATUS_REQUEST:
            return {
                ...state,
                loading: action.status
            }

        default:
            return state
    }
}
export const userDNDPatchProfileData = (state = userProfileData, action: any) => {
    switch (action.type) {
        case activityPatchDND.SET_DND_STATUS:
            return {
                ...state,
                profileData: action.data
            }
        case activityPatchDND.SET_DND_STATUS_REQUEST:
            return {
                ...state,
                profileData: action.data
            }
        case activityPatchDND.SET_DND_STATUS_SUCCESS:
            return {
                ...state,
                loading: action.status
            }
        case activityPatchDND.ONCHANGE_DND_STATUS:
            return {
                ...state,
                DndStatus: action.DndStatus
            }
        default:
            return state
    }
}
