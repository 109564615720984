import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
    tablebackgroundcolor: {
        backgroundColor: '#F6F6F6',
    },
    reschedule: {
        color: '#2645AD',
        marginLeft: 6,
        fontSize: 15,
        fontWeight: 500,
        fontFamily: 'Work Sans',

    },
    table: {
        tableLayout: 'fixed',
    },
    linkUnderline: {
        textDecoration: 'none',
    },

    tablecell: {
        borderBottom: '10px',
        padding: '8px',
        //textAlign: 'center',
        color: '#838383',
        fontWeight: 500,
        fontFamily: 'Work Sans'
    },
    tablecell1: {
        borderBottom: '10px',
        padding: '5px',
        //textAlign: 'center',
        color: '#838383',
        fontWeight: 500,
        fontFamily: 'Work Sans'
    },
}));