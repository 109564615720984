import React, { useState } from 'react';
import { makeStyles, CardContent, Grid, Typography, TextField, Button } from '@material-ui/core';
import { Cancel, RemoveCircleOutline } from '@material-ui/icons';
import AddIcon from '../../assets/icons/addIcon.png';
import SearchIcon from '../../assets/icons/searchIcon.png';
import CropIcon from '../../assets/icons/cropIcon.png';

const useStyles = makeStyles({
    cardContent: {
        display: 'content',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    grid: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
    },
    gridItem: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
    },
    userText: {
        fontSize: 18,
        fontFamily: 'Work Sans',
        fontStyle: 'normal',
        fontWeight: 600,
        color: '#565656',
        letterSpacing: 0.208955,
        textTransform: 'none',
    },
    addIcon: {
        marginLeft: 30,
        color: '#4D72F8',
    },
    button: {
        height: 40,
        borderTopLeftRadius: 1,
        borderBottomLeftRadius: 1,
        borderTopRightRadius: 8,
        borderBottomRightRadius: 8,
        transform: 'translate(-16px, 0px)',
        color: '#FFFFFF',
        backgroundColor: '#4D72F8',
        borderColor: '#4D72F8',
        '&:hover': {
            backgroundColor: '#4D72F8',
        },
        '&:active': {
            backgroundColor: '#4D72F8',
        },
    },
    search: {
        position: 'absolute',
        top: 10,
        left: 20,
    },
    gridItemIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
    },
    removeCircleColor: {
        margin: 10,
        color: '#828282',
    },
    removeCircleColor1: {
        color: '#787878',
    },
});

interface CardHeaderProps {
    name: string;
    [key: string]: any;
}

export function CardHeader(props: CardHeaderProps): JSX.Element {
    const classes = useStyles();
    const [textfield] = useState<boolean>(props.textfield ? props.textfield : false);
    const [addbox] = useState<boolean>(props.addbox ? props.addbox : false);
    const [remove] = useState<boolean>(props.remove ? props.remove : false);
    const [close] = useState<boolean>(props.close ? props.close : false);

    return (
        <>
            <CardContent className={classes.cardContent}>
                <Grid container className={classes.grid}>
                    <Grid item xs={4} lg={4} md={4} sm={4} xl={4} className={classes.gridItem}>
                        <Typography className={classes.userText}>{props.name}</Typography>
                        {addbox ? (
                            <img
                                className={classes.addIcon}
                                src={AddIcon}
                                onClick={props.onButtonClick}
                                alt="add"
                            />
                        ) : null}
                    </Grid>
                    {textfield ? (
                        <Grid item xs={5} lg={5} md={5} sm={5} xl={5} className={classes.gridItem}>
                            <TextField
                                fullWidth
                                size="small"
                                placeholder={props.placeholder}
                                value={props.value}
                                variant="outlined"
                                onChange={props.onChange}
                            />
                            <Button
                                variant="outlined"
                                className={classes.button}
                                startIcon={<img className={classes.search} src={SearchIcon} alt="search" />}
                            />
                        </Grid>
                    ) : (
                        <Grid item xs={5} lg={5} md={5} sm={5} xl={5}></Grid>
                    )}
                    <Grid item xs={3} lg={3} md={3} sm={3} xl={3} className={classes.gridItemIcon}>
                        {remove ? (
                            <>
                                <RemoveCircleOutline className={classes.removeCircleColor} />
                                {/* <img className={classes.removeCircleColor} src={CropIcon} alt="crop" /> */}
                            </>
                        ) : null}
                        {close ? (
                            <Cancel className={classes.removeCircleColor1} onClick={props.onCloseClick} />
                        ) : null}
                    </Grid>
                </Grid>
            </CardContent>
        </>
    );
}
