import React from 'react'
import { useStyles } from './Styles';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Card,
    CardContent,
    Divider,
    FormGroup,
    FormControlLabel,
    Grid,
    Avatar,
    Typography,
    TextField,
    Tooltip,
    TextareaAutosize,
    Table,

    Button,
    Switch,
    IconButton,
    withStyles,

} from '@material-ui/core/';
import Slider from '@material-ui/core/Slider';

import { MdSend, MdScheduleSend, MdOutlineDialpad, MdCall, MdOutlineExpandMore, MdPhonePaused, MdCallEnd } from 'react-icons/md';
import { BiCheckDouble, BiMessageSquareAdd, BiDialpadAlt, BiChevronDown } from 'react-icons/bi';



import { BsFillCaretDownFill, BsCheck2All, BsDownload, BsFillMicMuteFill, BsPlusSquareFill, BsWifi, BsFillMicFill, BsFillTelephoneOutboundFill } from 'react-icons/bs';

import Moment from 'moment'

function addPlayListModalStyle() {
    const top = 15;
    const left = 35;
    const right = 35;

    return {
        top: `${top}%`,
        left: `${left}%`,
        right: `${right}%`,
        transform: `translate(-${top}%, -${left}%, -${right}%)`,
    };
}
function getModalStyle() {
    const top = 15;
    const left = 30;
    const right = 30;

    return {
        top: `${top}%`,
        left: `${left}%`,
        right: `${right}%`,
        transform: `translate(-${top}%, -${left}%, -${right}%)`,
    };
}
export default function (props: any) {
    const classes = useStyles();
    const messagesEndRef: any = React.useRef(null)
    const [open, setOpen] = React.useState<boolean>(false);
    const [location, setLocation] = React.useState('');
    const [modalStyle] = React.useState(getModalStyle);
    const [addPlaylistOpen, setAddPlaylistOpen] = React.useState<boolean>(false);
    const [playModalStyle] = React.useState(addPlayListModalStyle);
    const [appNoteopen, setAppNoteOpen] = React.useState<boolean>(false)
    const [day, setDay] = React.useState('')
    const [count, setCount] = React.useState(0)

    const handlePlaylistOpen = () => {
        setAddPlaylistOpen(true);
    }

    const handlePlaylistClose = () => {
        setAddPlaylistOpen(false);
    }
    const handleChange = (event: any) => {
        setLocation(event.target.value);
    };
    const appNotehandleOpen = () => {
        setAppNoteOpen(true);
    }

    const appNotehandleClose = () => {
        setAppNoteOpen(false);
    }

    var data = parseInt(props.data?.createdDate)
    var dataa = Moment(data).format('hh:mm A')
    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })

    }

    React.useEffect(() => {
        scrollToBottom()
    }, [props.data]);


    return (
        <div ref={messagesEndRef}  >
            {/* <Typography style={{ textAlign: "center" }}>{day}</Typography> */}

            <Grid container spacing={2}>
                <Grid item md={6}>
                    {
                        props.data.direction == 1 &&
                        <div style={{ display: "flex", justifyContent: "space-between", overflowWrap: 'break-word' }}>
                            <Card style={{ backgroundColor: "white", padding: '6px 15px', borderRadius: "35px" }}>
                                <Typography style={{ fontSize: "15px",fontWeight:400 }}>{props.data.textMessage}</Typography>
                                <div style={{ fontSize: "10px", float: "right",fontWeight:400 }}>
                                    {dataa}
                                </div>
                            </Card>
                        </div>
                    }
                </Grid>
                <Grid item md={6} >
                    {props.data.direction == 2 &&
                        <div style={{ float: "right", display: "flex", justifyContent: "space-between", overflowWrap: 'break-word' }}>
                            <Card style={{ backgroundColor: "#F0F8FF", padding: '6px 16px', borderRadius: "35px" }}>
                                <Typography >{props.data.textMessage}</Typography>
                                {props.data.isReadMessage === 1 ?
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <span style={{ fontSize: "10px", float: "right" }}> {dataa}</span> <BiCheckDouble className={classes.doubleCheck} />
                                    </div> :
                                    <div style={{ fontSize: "10px", float: "right",fontWeight:400 }}><span> {dataa}</span><BsCheck2All color="lightgrey" /></div>
                                }
                            </Card>
                        </div>
                    }

                </Grid>
            </Grid>

        </div >
    )
}
