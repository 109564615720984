import React from 'react';
import {
    Card,
    CardContent,
    Grid,
    Typography,
    Divider,
    withStyles,
    Switch,
    Button,
    Tabs,
    Tab,
    TableContainer,
    Table,
    TableBody,
    TableRow,
    TextField,
    TableCell,
    IconButton,
} from '@material-ui/core';
import { useStyles } from '../Styles';
import PhoneCallbackIcon from '@material-ui/icons/PhoneCallback';
import AppleIcon from '@material-ui/icons/Apple';
import VolumeUpIcon from '@material-ui/icons/VolumeUp';
import Tooltip from '@material-ui/core/Tooltip';
import PeopleIcon from '@material-ui/icons/People';
import { VscMerge } from 'react-icons/vsc'
import { BsRecord2Fill, BsCardText } from 'react-icons/bs'
import AndroidOutlinedIcon from '@material-ui/icons/AndroidOutlined';
import BallotIcon from '@material-ui/icons/Ballot';
import TableBodyData from './TableBodyData';
import CircularProgress from '@mui/material/CircularProgress';

function LiveCalls(props: any) {
    const classes = useStyles();
     const data = props.data;

  return (
    <div>
        <TableContainer>
            <Table>
                <TableBody>
                    {
                        props.LiveCallsloading === false && data.length > 0 && data.map((dt: any) => <TableBodyData fetchLiveCalls={props.fetchLiveCalls} rowData={dt} />)
                    }
                    
                </TableBody>
            </Table>
            {
              props.LiveCallsloading ? <div className='loader_class'>
                <div style={{ display: 'grid', alignItems: 'center', margin: '30px 500px' }}>
                  <CircularProgress disableShrink />
                </div>
              </div> : data.length <= 0 && <p style={{ textAlign: 'center' }}>No Data</p>
            }
        </TableContainer>
    </div>
  )
}

export default LiveCalls