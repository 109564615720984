import React from 'react';
import { useStyles } from './style';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Card,
  CardContent,
  Divider,
  FormGroup,
  FormControlLabel,
  Grid,
  Avatar,
  Typography,
  TextField,
  Tooltip,
  TextareaAutosize,
  Table,

  Button,
  Switch,
  IconButton,
  withStyles,

} from '@material-ui/core/';
import Slider from '@material-ui/core/Slider';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import PhoneIcon from '@material-ui/icons/Phone';
import callTransfer from '../../assets/icons/icon-call-transfer.png'
import { VscAdd } from "react-icons/vsc";
import { AiOutlineMinus, AiOutlineClose } from 'react-icons/ai'
import { FaUserPlus } from 'react-icons/fa';
import { MdSend, MdScheduleSend, MdOutlineDialpad, MdCall, MdOutlineExpandMore, MdPhonePaused, MdCallEnd } from 'react-icons/md';
import { BiCheckDouble, BiPlay, BiMinusBack, BiMessageSquareAdd, BiDialpadAlt, BiChevronDown, BiRectangle } from 'react-icons/bi';
import { RiEditBoxFill, RiUser3Fill, RiPauseCircleFill } from 'react-icons/ri';
import Ellipse from '../../assets/icons/Ellipse.png';
import { VscChromeMaximize } from 'react-icons/vsc'
import { AiFillCloseCircle, AiFillStar, AiOutlineEdit, AiOutlinePause } from 'react-icons/ai';
import { GrFormClose } from 'react-icons/gr'
import { InputAdornment } from '@material-ui/core';
import { ImBlocked, ImPhoneHangUp } from 'react-icons/im';
import { IoIosClose } from 'react-icons/io'
import { BsFillCaretDownFill, BsDownload, BsFillMicMuteFill, BsPlusSquareFill, BsWifi, BsFillMicFill, BsFillTelephoneOutboundFill } from 'react-icons/bs';
import { FiSettings } from 'react-icons/fi';
import { CgUserAdd } from 'react-icons/cg';
import CallParkIcon from '../../assets/icons/call-park.png'
import { FcCallTransfer } from 'react-icons/fc';
import { GrAdd } from 'react-icons/gr';
import Modal from '@material-ui/core/Modal';
import { CardHeader } from '../Header/CardHeader';
import { ImRedo2 } from 'react-icons/im'
import SaveIcon from '@material-ui/icons/Save';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import { TiArrowForward } from 'react-icons/ti';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import Popup from "reactjs-popup";
import { useSelector, useDispatch } from 'react-redux'
import Checkbox from '@material-ui/core/Checkbox';
import KeyboardVoiceIcon from '@material-ui/icons/KeyboardVoice';
import { Window, WindowActionsEvent } from "@progress/kendo-react-dialogs";
import * as CallActionCreator from '../../Store/Actions/CallActionCreator'
export default function OutgoingCall(props: any) {
  const classes = useStyles();
  const dispatch = useDispatch()

  const callingData: any = useSelector((state: any) => state.CallingData);
  const [transferOpen, setTransferOpen] = React.useState<boolean>(false);
  const [sessionData, setSessionData] = React.useState<any>()
  const [mute, setMute] = React.useState<boolean>(false);
  const [hold, setHold] = React.useState<boolean>(false);
  const [windowStage, setWindowStage] = React.useState("MAXIMISED");
  const [transferModalStyle] = React.useState(getTransferModalStyle);
  const pokemon = require('../../assets/Sounds/diaal.mp3');
  const audio = new Audio(pokemon)
  const handleTranferOpen = () => {
    setTransferOpen(true);
  }
  const handleTransferClose = () => {
    setTransferOpen(false);
  }

  const handleMute = () => {
    setMute(!mute)
    if (mute) {
      sessionData.unmute()
    } else {
      sessionData.mute()
    }
  }
  const handleHold = () => {
    setHold(!hold)
    if (hold) {
      sessionData.unhold()
    } else {
      sessionData.hold()
    }
  }
  function getTransferModalStyle() {
    const top = 15;
    const left = 30;
    const right = 30;

    return {
      top: `${top}%`,
      left: `${left}%`,
      right: `${right}%`,
      transform: `translate(-${top}%, -${left}%, -${right}%)`,
    };
  }

  const handleEndCall = () => {
    dispatch(CallActionCreator.fetchStatus(null))
    dispatch(CallActionCreator.fetchData(false))
    props.parentEndCall(false)
    props.coolPhone.terminateSessions()
  }
  const handleMinimise = () => {
    props.parentMinimise(false)
  }
  const handleCall = () => {
    const audio: any = new window.Audio()
    var session = props?.coolPhone.call(callingData?.callData?.phoneNumber, callingData?.callData?.options)
    setSessionData(session)
    if (session.connection) {
      console.log('Connection is valid')

      session.connection.addEventListener('addstream', (e: any) => {
        console.log('Add stream')
        audio.srcObject = e.stream
        audio.play()
      })

      session.on('addstream', function (e: any) {
        // set remote audio stream (to listen to remote audio)
        // remoteAudio is <audio> element on page
        const remoteAudio = audio
        remoteAudio.src = window.URL.createObjectURL(e.stream);
        remoteAudio.play();
      });
      session.connection.addEventListener('peerconnection', (e: any) => {
        console.log('Peer connection')
        audio.srcObject = e.stream
        audio.play()
      })

    } else {
      console.log('Connection is null')
    }

  }
  React.useEffect(() => {
    handleCall()
  }, [])

  const handleNumber = (num: any) => {
    // audio.load()
    // audio.muted = false;
    var promise = audio.play();
    if (promise) {
      //Older browsers may not return a promise, according to the MDN website
      promise.catch(function (error) { console.error(error); });
    }

  }



  return (

    <Card style={{ background: 'rgba(0, 0, 0, 0.9)' }}>
      <div style={{ display: "flex", gap: "10px", alignItems: "center", float: "right", marginTop: "8px" }}>
        <div onClick={handleMinimise} style={{ cursor: "pointer" }}>
          <AiOutlineMinus color="white" />
        </div>

        <VscChromeMaximize color="white" />
        <p></p>
      </div>
      <div className={classes.newJhon}>
        <Typography className={classes.wick}>{callingData?.callData?.name}</Typography>
      </div>
      <div className={classes.numberOne}>
        <Typography className={classes.numberTwo}>{callingData?.callData?.phoneNumber}</Typography>
      </div>
      {props.callStatus ?
        <div className={classes.timeDivTwo}>
          <Typography className={classes.timeContentTwo} >{props.timer?.m + " : " + props.timer?.s}</Typography>


        </div> :
        <div className={classes.timeDivTwo}>
          <Typography className={classes.timeContentTwo}>Ringing...</Typography>
        </div>}
      {/* <div className={classes.timeDivTwo}>
        <Typography className={classes.timeContentTwo} >10:15</Typography>
      </div> */}
      <CardContent>
        <div className={classes.dailDiv}>
          {mute ?
            <div onClick={handleMute}>
              <IconButton className={classes.voicemuteBoard}>
                <BsFillMicMuteFill />
              </IconButton>
              <Typography className={classes.muteOne}>Unmute</Typography>
            </div> :

            <div onClick={handleMute}>
              <IconButton className={classes.voiceBoard}>
                <BsFillMicFill />
              </IconButton>
              <Typography className={classes.muteOne}>Mute</Typography>
            </div>
          }
          <div>
            <IconButton className={classes.voiceBoard}
            //onClick={handleTranferOpen}
            >
              {/* <FcCallTransfer color="lightgrey" /> */}
              <img src={callTransfer} alt="" width="23px" height="23px" />
            </IconButton>
            <Typography className={classes.muteOne}>Transfer</Typography>
          </div>
          {/* <div>
                        <IconButton className={classes.voiceBoard} >
                            <GiSpeaker />
                        </IconButton>
                        <Typography className={classes.muteOne}>Speaker</Typography>
                    </div> */}
          {/* <div onClick={handlePopupModalDialpad}>
                        <IconButton className={classes.voiceBoard}>
                            <MdOutlineDialpad />
                        </IconButton>                       
                        <Typography className={classes.muteOne}>Dialpad</Typography>
                    </div> */}
          <div>
            <Popup
              trigger={
                <IconButton className={classes.voiceBoard}>
                  <MdOutlineDialpad />
                </IconButton>
              }
              position="bottom right"
            >
              <Card style={{ padding: "10px 20px 10px 0px", background: 'rgb(163, 153, 152)' }}>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <div className={classes.iconNumbersOne}
                    onClick={() => handleNumber(1)}
                  >
                    <Typography className={classes.number}>1</Typography>

                  </div>
                  <div className={classes.iconNumbersOne}
                    onClick={() => handleNumber(2)}
                  >
                    <Typography className={classes.number}>2</Typography>
                    {/* <Typography className={classes.text}>abc</Typography> */}
                  </div>
                  <div className={classes.iconNumbersOne}
                    onClick={() => handleNumber(3)}
                  >
                    <Typography className={classes.number}>3</Typography>
                    {/* <Typography className={classes.text} >def</Typography> */}
                  </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'center', marginTop: 12 }}>
                  <div className={classes.iconNumbersOne}
                    onClick={() => handleNumber(4)}
                  >
                    <Typography className={classes.number}>4</Typography>
                    {/* <Typography className={classes.text}>ghi</Typography> */}
                  </div>
                  <div className={classes.iconNumbersOne}
                    onClick={() => handleNumber(5)}
                  >
                    <Typography className={classes.number}>5</Typography>
                    {/* <Typography className={classes.text}>jkl</Typography> */}
                  </div>
                  <div className={classes.iconNumbersOne}
                    onClick={() => handleNumber(6)}
                  >
                    <Typography className={classes.number}>6</Typography>
                    {/* <Typography className={classes.text} >mno</Typography> */}
                  </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'center', marginTop: 12 }}>
                  <div className={classes.iconNumbersOne}
                    onClick={() => handleNumber(7)}
                  >
                    <Typography className={classes.number}>7</Typography>
                    {/* <Typography className={classes.text}>pqrs</Typography> */}
                  </div>
                  <div className={classes.iconNumbersOne}
                    onClick={() => handleNumber(8)}
                  >
                    <Typography className={classes.number}>8</Typography>
                    {/* <Typography className={classes.text}>tuv</Typography> */}
                  </div>
                  <div className={classes.iconNumbersOne}
                    onClick={() => handleNumber(9)}
                  >
                    <Typography className={classes.number}>9</Typography>
                    {/* <Typography className={classes.text} >wxyz</Typography> */}
                  </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'center', marginTop: 12 }}>
                  <div className={classes.iconNumbersOne}
                    onClick={() => handleNumber('*')}
                  >
                    <Typography className={classes.number}>*</Typography>
                  </div>
                  <div className={classes.iconNumbersOne}
                    onClick={() => handleNumber(0)}
                  >
                    <Typography className={classes.number}>0</Typography>
                    {/* <Typography className={classes.text}>+</Typography> */}
                  </div>
                  <div className={classes.iconNumbersOne}
                    onClick={() => handleNumber('#')}
                  >
                    <Typography className={classes.number}>#</Typography>
                  </div>
                </div>

              </Card>
            </Popup>
            <Typography className={classes.muteOne}>Dialpad</Typography>
          </div>
        </div>
      </CardContent>
      <Modal open={transferOpen}>
        <Card style={transferModalStyle} className={classes.paper}>
          <CardHeader
            name={'Transfer a Call'}
            onButtonClick={handleTranferOpen}
            onCloseClick={handleTransferClose}
            close={true}
          />
          <Divider className={classes.divider} />
          <CardContent>
            <Grid container spacing={2}>
              <Grid item md={2}></Grid>
              <Grid item md={8}>
                <TextField
                  variant='standard'
                  size='medium'
                  placeholder="Type a name or number"
                  fullWidth
                  className={classes.textfieldColor}
                />
              </Grid>
              <Grid item md={2}></Grid>
            </Grid>
          </CardContent>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item md={2}></Grid>
              <Grid item md={2}>
                <Card className={classes.smallCardContentOne} >
                  <div className={classes.maichelCard}>
                    <Typography className={classes.maichel}>Maichel</Typography>
                    <Typography className={classes.maichelNumber}>(123) 456-9998</Typography>
                  </div>
                </Card>
              </Grid>
              <Grid item md={2}>
                <Card className={classes.smallCardContent}>
                  <div className={classes.maichelCard}>
                    <Typography className={classes.maichel}>Maichel</Typography>
                    <Typography className={classes.maichelNumber}>(123) 456-9998</Typography>
                  </div>
                </Card>
              </Grid>
              <Grid item md={2}>
                <Card className={classes.smallCardContent} >
                  <div className={classes.maichelCard}>
                    <Typography className={classes.maichel}>Maichel</Typography>
                    <Typography className={classes.maichelNumber}>(123) 456-9998</Typography>
                  </div>
                </Card>
              </Grid>
              <Grid item md={2}>
                <Card className={classes.smallCardContent}>
                  <div className={classes.maichelCard}>
                    <Typography className={classes.maichel}>Maichel</Typography>
                    <Typography className={classes.maichelNumber}>(123) 456-9998</Typography>
                  </div>
                </Card>
              </Grid>
              <Grid item md={2}></Grid>
            </Grid>
          </CardContent>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item md={2}></Grid>
              <Grid item md={2}>
                <Card className={classes.smallCardContent}>
                  <div className={classes.maichelCard}>
                    <Typography className={classes.maichel}>Maichel</Typography>
                    <Typography className={classes.maichelNumber}>(123) 456-9998</Typography>
                  </div>
                </Card>
              </Grid>
              <Grid item md={2}>
                <Card className={classes.smallCardContent}>
                  <div className={classes.maichelCard}>
                    <Typography className={classes.maichel}>Maichel</Typography>
                    <Typography className={classes.maichelNumber}>(123) 456-9998</Typography>
                  </div>
                </Card>
              </Grid>
              <Grid item md={2}>
                <Card className={classes.smallCardContent}>
                  <div className={classes.maichelCard}>
                    <Typography className={classes.maichel}>Maichel</Typography>
                    <Typography className={classes.maichelNumber}>(123) 456-9998</Typography>
                  </div>
                </Card>
              </Grid>
              <Grid item md={2}>
                <Card className={classes.smallCardContent}>
                  <div className={classes.maichelCard}>
                    <Typography className={classes.maichel}>Maichel</Typography>
                    <Typography className={classes.maichelNumber}>(123) 456-9998</Typography>
                  </div>
                </Card>
              </Grid>
              <Grid item md={2}></Grid>
            </Grid>
          </CardContent>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item md={2}> </Grid>
              <Grid item md={4} className={classes.transferButtons}>
                <Button
                  variant='outlined'
                  fullWidth
                  size='small'
                  disableRipple
                  className={classes.firstButton}
                >
                  Ask First
                </Button>
              </Grid>
              <Grid item md={4} className={classes.transferButtons}>
                <Button
                  variant='outlined'
                  fullWidth
                  size='small'
                  className={classes.transferButton}
                >
                  Transfer Now
                </Button>
              </Grid>
              <Grid item md={2}></Grid>
            </Grid>
          </CardContent>
        </Card>
      </Modal>
      <CardContent>
        <div className={classes.addDiv}>
          <div>
            <IconButton className={classes.voiceBoard} >
              <VscAdd />
            </IconButton>
            <Typography className={classes.muteOne}>Add Call</Typography>
          </div>
          {hold ?
            <div onClick={handleHold} style={{ marginLeft: 50 }}>
              <IconButton className={classes.voiceBoard}>
                <BiPlay />
              </IconButton>
              <Typography className={classes.muteOne} style={{ marginLeft: -1 }}>Unhold</Typography>
            </div> :
            <div onClick={handleHold}>
              <IconButton className={classes.voiceBoard} style={{ marginLeft: 50 }}>
                <AiOutlinePause />
              </IconButton>
              <Typography className={classes.muteOne} style={{ marginLeft: 50 }}>hold</Typography>
            </div>
          }
          {/* <div>
                        <IconButton className={classes.voiceBoard}>
                            <MdOutlineDialpad />
                        </IconButton>
                        <Typography className={classes.muteOne}>Dialpad</Typography>
                    </div> */}
        </div>
      </CardContent>
      <div className={classes.callEnd} onClick={handleEndCall} >
        <IconButton className={classes.callFilledOne}>
          <MdCallEnd className={classes.closeCall} />
        </IconButton>
      </div>

    </Card>

  )
}
