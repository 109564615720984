import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  typographydisplay: {
    display: 'flex',
    padding: '10px',
  },
  activitytext: {
    fontSize: '24px',
    fontWeight: 600,
    fontFamily: 'Work Sans',
    // padding: '10px',
    color: '#565656',
  },
  buttonpadding: {
    // padding: '10px',
  },
  errorMessage: {
    color: 'red',
    fontSize: '0.8rem',
    fontWeight: 500,
    marginTop: 0,
    marginBottom: 0,
  },
  button4: {
    left: '281px',
    fontSize: 12,
    fontFamily: 'Work Sans',
    fontStyle: 'normal',
    fontWeight: 500,
    textAlign: 'center',
    letterSpacing: 0.1,
    textTransform: 'none',
    color: '#D1D1D1',
    background: '#FFFFFF',
    borderRadius: '50px',
    border: '0.649129px solid #D1D1D1',
    boxSizing: 'border-box',
    padding: '8px 26px',
    '&:hover': {
      backgroundColor: '#FFFFFF',
    },
    '&:active': {
      backgroundColor: '#FFFFFF',
    },
  },
  addcontactbutton: {
    // left: '82%',
    marginTop: '15px',
    fontSize: 14,
    fontFamily: 'Work Sans',
    fontStyle: 'normal',
    fontWeight: 500,
    textAlign: 'center',
    letterSpacing: 0.1,
    textTransform: 'none',
    color: '#FFFFFF',
    background: '#2645AD',
    borderRadius: '50px',
    border: '0.649129px solid #D1D1D1',
    boxSizing: 'border-box',
    padding: '6px 26px',
    '&:hover': {
      color: '#FFFFFF',
      background: '#2645AD',
    },
  },
  dividercolor: {
    borderTop: '2px solid #D2D2D2',
    // marginTop: '10px',
  },
  buttonicons: {
    height: '26px',
    display: "flex",
    width: '87px',
    borderRadius: '50px',
    backgroundColor: '#2645AD',
  },
  dividericon: {
    height: 20,
    borderRight: '2px solid #FFFFFF',
    marginLeft: '11px',
    marginTop: "2px",
    marginRight: '10px',
  },
  tablebackgroundcolor: {
    backgroundColor: '#F6F6F6',
  },
  tablecell: {
    borderBottom: '10px',
    // padding: '10px',
    color: '#838383',
    fontWeight: 500,
    fontFamily: 'Work Sans',
  },
  contactDiv: {
    display: 'flex',
    alignItems: 'center',
  },
  contact: {
    marginLeft: 10,
  },
  paper: {
    position: 'absolute',
    border: '1px solid #E5E5E5',
    borderRadius: 5,
    background: '#FFFFFF',
  },
  iconcolor: {
    color: '#FFFFFF',
  },
  SaveButton: {
    background: '#4D72F8',
    border: '1px solid #ccc',
    borderRadius: 5,
    fontFamily: 'Work Sans',
    fontWeight: 500,
    color: '#FFFFFF',
    fontSize: 15,
    padding: '6px 22px',
    '&:hover': {
      background: '#4D72F8',
      color: '#FFFFFF',
    },
  },
  cancelButton: {
    margin: 10,
    fontFamily: 'Work Sans',
    fontWeight: 500,
    marginLeft: 15,
    background: '#fbfbfb',
    border: '1px solid #ebebeb',
    borderRadius: 5,
    color: '#8392AB',
    fontSize: 15,
    padding: '6px 22px'
  },
  deviceWidth: {
    width: 30,
    height: 30,
    borderRadius: 50,
    color: 'black',
    // backgroundColor: '#F3F5FF',
    // fill:'#4D72F8',
    padding: '8px',
  },
  deleteButton: {
    margin: 10,
    background: '#4D72F8',
    border: '1px solid #AEAEAE',
    boxSizing: 'border-box',
    borderRadius: 5,
    fontFamily: 'Work Sans',
    fontStyle: 'normal',
    fontWeight: 500,
    padding: '6px 22px',
    color: '#FFFFFF',
    fontSize: 14,
    '&:hover': {
      background: '#4D72F8',
      color: '#FFFFFF',
    }
  },
  CancelButton: {
    fontFamily: 'Work Sans',
    fontWeight: 500,
    marginLeft: 15,
    background: '#EFEFEF',
    border: '1px solid #AEAEAE',
    borderRadius: 5,
    color: '#8392AB',
    fontSize: 14,
    padding: '6px 22px',
  },

}));
