import React from 'react';
import {
  Grid,
  Typography,
  Button,
  Table, TableBody, TableRow, TableCell,
  TextField,
} from '@material-ui/core';
import { BiMessageSquareAdd } from 'react-icons/bi'
import Select from 'react-select'
import { useStyles } from './styles';
import axiosInstance from '../../../environment/axiosInstances'
import { makeStyles, withStyles } from "@material-ui/core/styles";
import MuiTableCell from "@material-ui/core/TableCell";
import { AiOutlineCloseCircle } from 'react-icons/ai'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
toast.configure()
function AddContact(props: any) {

  const classes = useStyles();
  const [numChange, setNumChange] = React.useState(false)
  const [numberList, setNumberList] = React.useState<any>([{ phoneNumber: '', contactType: '' }]);
  const [emailList, setEmailList] = React.useState<any>([{ email: '' }]);
  const [urlList, setUrlList] = React.useState<any>([{ url: '' }]);
  const [email, setEmail] = React.useState('')
  const [emailChange, setEmailChange] = React.useState(false)
  const [url, setUrl] = React.useState('')
  const [urlChange, setUrlChange] = React.useState(false)
  const [submitted, setSubmitted] = React.useState(false);
  const [data, setData] = React.useState<any>({
    "name": "",
    "phoneNumbers": [],
    "contactType": 1,
    "emails": [],
    "company": "",
    "role": "",
    "urls": []
  })

  const handleChangeData = (e: any) => {
    setData((prev: any) => {
      return {
        ...data,
        [e.target.name]: e.target.value
      }
    })
  }
  const handleChangeNumber = (e: any, i: any) => {

    numberList[i].phoneNumber = e.target.value
    setNumberList(JSON.parse(JSON.stringify(numberList)));
  }
  const handleremoveNumber = (i: any) => {
    numberList.splice(i, 1)
    setNumberList(JSON.parse(JSON.stringify(numberList)));
  }
  const handleremoveEmail = (i: any) => {
    emailList.splice(i, 1)
    setEmailList(JSON.parse(JSON.stringify(emailList)));
  }
  const handleremoveUrls = (i: any) => {
    urlList.splice(i, 1)
    setUrlList(JSON.parse(JSON.stringify(urlList)));
  }
  const handleContactOpt = (data: any, option: any) => {

    numberList[option].contactType = data.value
    setNumberList(JSON.parse(JSON.stringify(numberList)));
  }
  const handleChangeEmails = (e: any, i: any) => {
    setEmail(e.target.value)
    setEmailChange(true)
    emailList[i].email = e.target.value
    setEmailList(JSON.parse(JSON.stringify(emailList)));
  }

  const handleChangeUrls = (e: any, index: any) => {
    // setUrl(e.target.value)
    // setUrlChange(true)
    urlList[index].url = e.target.value
    setUrlList(JSON.parse(JSON.stringify(urlList)));
  }


  const handleSubmit = () => {
    data.phoneNumbers = numberList
    data.emails = emailList
    data.urls = urlList
    setSubmitted(true);
    const errorData = validate(true);
    if (errorData.isValid) {
      axiosInstance.post('/contacts', data).then((res) => {
        if (res.data) {
          toast.success(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000
          })
          props.handleModal()
          props.getListContacts(props.paginate.limit, props.paginate.page)
        }
      }).catch((err) => {
        if (err.response?.status == 402) {
          window.location.href = "https://newbp.efone.ca"
          sessionStorage.removeItem("user")

        }
        toast.error(err.response.data.message, { position: toast.POSITION.TOP_RIGHT, autoClose: 1000 })
      })
    }
  }
  const contactOptions: any = [
    { label: "Mobile", value: "1" },
    { label: "Work", value: "2" },
    { label: "Home", value: "3" },
    { label: "Others", value: "4" },
  ]

  const onAddUrl = () => {
    let urlData: any = {}
    urlData.url = ''
    let urlArray = urlList
    let dataToBeUpdated = [...urlArray, urlData]
    setUrlList(dataToBeUpdated)
  }
  const validate = (submitted: any) => {
    const validData = {
      isValid: true,
      message: ""
    };
    const retData = {

      name: { ...validData },

      phoneNumber: { ...validData },
      contactType: { ...validData },
      url: { ...validData }

    };
    let isValid = true;
    if (submitted) {


      if (data.name === " ") {
        retData.name = {
          isValid: false,
          message: "Please enter name"
        };
        isValid = false;
      }
      if (!/^[a-zA-Z\s]*$/g.test(data.name)) {
        retData.name = {
          isValid: false,
          message: "Please enter only name"
        };
        isValid = false;
      }
      if (!data.name) {
        retData.name = {
          isValid: false,
          message: "Please enter name"
        };
        isValid = false;
      }

      // if (data.phoneNumbers.contactType === " ") {
      //   retData.contactType = {
      //     isValid: false,
      //     message: "Please enter contactType"
      //   };
      //   isValid = false;
      // }
      // if (!data.phoneNumbers.contactType) {
      //   retData.contactType = {
      //     isValid: false,
      //     message: "Please enter contactType"
      //   };
      //   isValid = false;
      // }

      // if (data.phoneNumber === " ") {
      //   retData.phoneNumber = {
      //     isValid: false,
      //     message: "Please enter phoneNumber"
      //   };
      //   isValid = false;
      // }
      // if (!/^[0-9]+$/.test(data.phoneNumbers.phoneNumber)) {
      //   retData.phoneNumber = {
      //     isValid: false,
      //     message: "Please select only phone number"
      //   };
      //   isValid = false;
      // }
      // if (!data.phoneNumber) {
      //   retData.phoneNumber = {
      //     isValid: false,
      //     message: "Please select phoneNumber"
      //   };
      //   isValid = false;
      // }


    }
    return {
      ...retData,
      isValid
    };
  };
  const errors = validate(submitted);

  const addNumber = () => {
    setNumberList([...numberList, {
      phoneNumber: "",
      contactType: ''
    }]);
  }
  const addEmail = () => {
    setEmailList([...emailList, {
      email: ''
    }])
  }
  return (
    <div style={{ padding: 8 }}>
      <Grid container spacing={2}>
        <Grid item md={12}>
          <Typography className={classes.contacttypography}>Contact Name</Typography>
          <TextField
            className={classes.contacttextfield}
            style={{ marginTop: 3 }}
            fullWidth
            value={data.name}
            size="small"
            name="name"
            placeholder="enter Contact name"
            variant="outlined"
            onChange={handleChangeData}
          />
          {
            !errors.name.isValid &&
            <p className={classes.errorMessage}>{errors.name.message}</p>
          }
          <Typography className={classes.contacttypography} style={{ marginTop: 8 }}>Phone Number</Typography>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item md={12}>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Typography className={classes.insertbody}>insert a pause with commas</Typography>
            <Typography className={classes.help}>Help center</Typography>
          </div>
          <Table style={{ border: "none", borderBottom: "none" }}>
            {numberList && numberList.map((item: any, i: any) => {
              return (
                <TableBody>
                  <TableRow style={{ borderBottom: "none" }}>
                    <TableCell style={{ borderBottom: "none", padding: "4px" }}>
                      <>
                        <TextField
                          style={{ marginTop: 5 }}
                          className={classes.contacttextfield}
                          fullWidth
                          value={item.phoneNumber}
                          size="small"
                          placeholder="ex: (***) ***-***"
                          variant="outlined"
                          // onMouseLeave={setNumbers}
                          // name="phoneNumbers"
                          onChange={(data) => handleChangeNumber(data, i)}

                        />

                        {
                          !errors.phoneNumber.isValid &&
                          <p className={classes.errorMessage}>{errors.phoneNumber.message}</p>
                        }
                      </>
                    </TableCell>
                    <TableCell style={{ borderBottom: "none", padding: "4px" }}>
                      <>
                        <Select
                          options={contactOptions}
                          placeholder='mobile'
                          value={contactOptions.filter((opt: any) => opt.value == item.contactType)}
                          className={classes.selectcontact}
                          onChange={(e: any) => handleContactOpt(e, i)}
                        />
                        {
                          !errors.contactType.isValid &&
                          <p className={classes.errorMessage}>{errors.contactType.message}</p>
                        }
                      </>
                    </TableCell>
                    {numberList.length > 1 && <TableCell style={{ borderBottom: "none", padding: "4px" }}>
                      <AiOutlineCloseCircle color="red" style={{ cursor: "pointer" }} onClick={() => handleremoveNumber(i)} />
                    </TableCell>}
                  </TableRow>
                </TableBody>
              )
            })}
          </Table>



          <Button
            className={classes.addcontactbutton1}
            onClick={addNumber}
            // onClick={onAddNumberList}
            startIcon={<BiMessageSquareAdd className={classes.addBox} />}>
            Add Number
          </Button>
        </Grid>

      </Grid >
      <Grid container spacing={2}>
        <Grid item md={12}>
          <Typography className={classes.contacttypography} style={{ marginTop: 6 }}>Email </Typography>
          <Table>
            {emailList && emailList.map((item: any, i: any) => {
              return (
                <TableBody>
                  <TableRow style={{ borderBottom: "none" }}>
                    <TableCell style={{ borderBottom: "none", padding: "4px" }}>
                      <>
                        <TextField
                          className={classes.contacttextfield}
                          fullWidth
                          style={{ marginTop: 3 }}
                          size="small"
                          placeholder="enter your email id"
                          variant="outlined"
                          name="emails"
                          onChange={(e: any) => handleChangeEmails(e, i)}

                          value={item.email}
                        />

                      </>
                    </TableCell>
                    {emailList.length > 1 && <TableCell style={{ borderBottom: "none", padding: "4px" }}>
                      <AiOutlineCloseCircle color="red" style={{ cursor: "pointer" }} onClick={() => handleremoveEmail(i)} />
                    </TableCell>}
                  </TableRow>
                </TableBody>
              )
            })}
          </Table>
          <Button
            className={classes.addcontactbutton1}
            onClick={addEmail}
            startIcon={<BiMessageSquareAdd className={classes.addBox} />}>
            Add Email
          </Button>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item md={12}>
          <Typography className={classes.contacttypography} style={{ marginTop: 6 }}>Company </Typography>
          <TextField
            className={classes.contacttextfield}
            style={{ marginTop: 3 }}
            fullWidth
            size="small"
            placeholder="Comapny"
            variant="outlined"
            name="company"
            onChange={handleChangeData}

          />


        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item md={12}>
          <Typography className={classes.contacttypography}>Role </Typography>
          <TextField
            className={classes.contacttextfield}
            style={{ marginTop: 3 }}
            fullWidth
            size="small"
            placeholder="Role"
            variant="outlined"
            name="role"
            onChange={handleChangeData}
          />


        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item md={12}>
          <Typography className={classes.contacttypography}>URL </Typography>
          <Table style={{ border: "#F8F8F8" }}>
            {urlList && urlList.map((item: any, i: any) => {
              return (
                <> <TableBody key={i}>
                  <TableRow style={{ borderBottom: "none" }}>
                    <TableCell style={{ borderBottom: "none", padding: "4px" }}>
                      <TextField
                        className={classes.contacttextfield}
                        style={{ marginTop: 3 }}
                        fullWidth
                        size="small"
                        placeholder="Url"
                        variant="outlined"
                        name="urls"
                        onChange={(e) => handleChangeUrls(e, i)}
                        value={item.url}

                      />

                    </TableCell>
                    {!urlList[0] && urlList.length > 1 && <TableCell style={{ borderBottom: "none", padding: "4px" }}>
                      <AiOutlineCloseCircle color="red" style={{ cursor: "pointer" }} onClick={() => handleremoveUrls(i)} />
                    </TableCell>}

                  </TableRow>
                </TableBody>
                </>
              )
            })}
          </Table>

          <Button
            className={classes.addcontactbutton1}
            onClick={onAddUrl}
            startIcon={<BiMessageSquareAdd className={classes.addBox} />}>
            Add url
          </Button>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item md={12} >
          <Button
            style={{ float: 'right', marginTop: -15 }}

            className={classes.addcontactbutton1}
            onClick={handleSubmit}
          >
            Save Contact
          </Button>
        </Grid>
      </Grid>
    </div >

  )
}

export default AddContact