import React from 'react'
import {
    Card,
    IconButton,
    MenuItem,
    Avatar,
    Grid,
    Typography,
} from '@material-ui/core';
import { HiPhoneOutgoing } from 'react-icons/hi'
import { useStyles } from './Styles';
import Moment from 'moment'
import AudioPlayer from './AudioPlayer'
import Messages from './Messages'
import Calls from './Calls'
import VoiceMail from './VoiceMail'
export default function Alltypes(props: any) {
    return (
        <div>
            {props.data.recordType === 1 ?
                <Calls key={props.data.id} data={props.data} /> :
                props.data?.recordType === 2 ?
                    <Messages key={props.data.id} data={props.data} /> :
                    props.data?.recordType === 3 ?
                        <VoiceMail key={props.data.id} data={props.data} /> : ""
            }
        </div>
    )
}
