
import ActivityPage from "../../Container/MyActivityPage/index"
import ContactsPage from '../../Container/ContactsPage/index'
import FavoritiesPage from '../../Container/FavoritiesPage/index'
import PlaylistPage from '../../Container/PlaylistPage/index'
import DepartmentPage from '../../Container/DepartmentsPage/index'
import UsersPage from '../../Container/UsersPage/index'
import DepartmentUserPage from '../../Container/DepartmentUsersPage/index'

const sideBarRoutes: any = [
    {
        title: 'My Activities',
        path: '/',
        Component: ActivityPage,
        // childRoutes: false,
        // dynamicRoutes: false,
        // childTitle: ''
    },
    {
        title: 'Contacts',
        path: '/contacts',
        // childRoutes: true,
        // dynamicRoutes: false,
        Component: ContactsPage,
        // childTitle: '',
    },
    {
        title: 'Favorites',
        path: '/favorites',
        // childRoutes: false,
        // dynamicRoutes: false,
        Component: FavoritiesPage,
        // childTitle: '',
    },
    {
        title: 'Departments',
        path: '/department',
        // childRoutes: true,
        // dynamicRoutes: true,

        childTitle: 'Departments',
    },
    {
        title: 'Department',
        path: '/department/:name/:id',
        // childRoutes: true,
        // dynamicRoutes: true,
        Component: DepartmentPage,
        childTitle: 'Departments',
    },

    {
        title: 'Recent',
        path: '/recent',
        // childRoutes: true,
        // dynamicRoutes: false,
        Component: () => <h1>Recent</h1>,
        childTitle: '',
    },
    {
        title: 'PlayLists',
        path: '/playlist',
        // childRoutes: true,
        // dynamicRoutes: false,
        Component: PlaylistPage,
        childTitle: '',
    },

    {
        title: 'UserDetails',
        path: '/activities/userDetails/:id',
        // childRoutes: true,
        // dynamicRoutes: false,
        Component: UsersPage,
        childTitle: '',
    },
    {
        title: 'UserDetails',
        path: '/activities/userDetails/:name/:id',
        // childRoutes: true,
        // dynamicRoutes: false,
        Component: UsersPage,
        childTitle: '',
    },
    {
        title: 'UserDetails',
        path: '/department/userDetails/:name/:id/:deptId',
        // childRoutes: true,
        // dynamicRoutes: false,
        Component: DepartmentUserPage,
        childTitle: '',
    },
    {
        title: 'Departments',
        path: '/department/:name',
        // childRoutes: true,
        // dynamicRoutes: false,
        component: DepartmentPage,
        childTitle: '',
    }

]

export default sideBarRoutes;