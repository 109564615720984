import React from 'react';
import { useStyles } from './Styles';
import { AiOutlineExpand, AiFillPlayCircle } from 'react-icons/ai'
import { BsFileEarmarkMusic } from 'react-icons/bs'
import { AccordionDetails, Card, Typography, CardContent, Divider, Grid, Accordion, AccordionSummary } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
// import { Icon, InlineIcon } from '@iconify/react';
// import fileEarmarkMusic from '@iconify-icons/bi/file-earmark-music';
// import playCircleFilled from '@iconify-icons/ant-design/play-circle-filled';
// import fullScreenMaximize24Filled from '@iconify-icons/fluent/full-screen-maximize-24-filled';


const Playlists = (): JSX.Element => {
    const classes = useStyles();
    return (
        <>
            <Card>
                <CardContent>
                    <CardContent>
                        <div className={classes.screenHeader}>
                            <Typography className={classes.playlists}>Playlists</Typography>
                            <AiOutlineExpand className={classes.iconFontsize} />
                        </div>
                    </CardContent>
                    <Divider className={classes.divider} />
                    <CardContent>
                        {/* <Card className={classes.cardBackground}> */}
                            {/* <CardContent> */}
                                <div>
                                    <Accordion>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <Typography className={classes.badConnection}>Bad Connection</Typography>
                                        </AccordionSummary>
                                        <Divider className={classes.divider} />
                                        <AccordionDetails>
                                            <Grid container spacing={2} style={{ marginTop: 25, }}>
                                                <Grid item md={2} sm={2} xs={4}>
                                                    <Card className={classes.smallCard}>
                                                        <div className={classes.earMark}>
                                                            <BsFileEarmarkMusic className={classes.earMarkMusic} />
                                                        </div>
                                                        <Typography className={classes.audio}>audio1.mkv</Typography>
                                                        <div className={classes.playDiv}>
                                                            <AiFillPlayCircle className={classes.playCircleIcon} />
                                                            <Typography className={classes.play}>Play</Typography>
                                                        </div>
                                                    </Card>
                                                </Grid>
                                                <Grid item md={2} sm={2} xs={4}>
                                                    <Card className={classes.smallCard}>
                                                        <div className={classes.earMark}>
                                                            <BsFileEarmarkMusic className={classes.earMarkMusic} />
                                                        </div>
                                                        <Typography className={classes.audio}>audio1.mkv</Typography>
                                                        <div className={classes.playDiv}>
                                                            <AiFillPlayCircle className={classes.playCircleIcon} />
                                                            <Typography className={classes.play}>Play</Typography>
                                                        </div>
                                                    </Card>
                                                </Grid>
                                                <Grid item md={2} sm={2} xs={4}>
                                                    <Card className={classes.smallCard}>
                                                        <div className={classes.earMark}>
                                                            <BsFileEarmarkMusic className={classes.earMarkMusic} />
                                                        </div>
                                                        <Typography className={classes.audio}>audio1.mkv</Typography>
                                                        <div className={classes.playDiv}>
                                                            <AiFillPlayCircle className={classes.playCircleIcon} />
                                                            <Typography className={classes.play}>Play</Typography>
                                                        </div>
                                                    </Card>
                                                </Grid>
                                                <Grid item md={2} sm={2} xs={4}>
                                                    <Card className={classes.smallCard}>
                                                        <div className={classes.earMark}>
                                                            <BsFileEarmarkMusic className={classes.earMarkMusic} />
                                                        </div>
                                                        <Typography className={classes.audio}>audio1.mkv</Typography>
                                                        <div className={classes.playDiv}>
                                                            <AiFillPlayCircle className={classes.playCircleIcon} />
                                                            <Typography className={classes.play}>Play</Typography>
                                                        </div>
                                                    </Card>
                                                </Grid>
                                                <Grid item md={2} sm={2} xs={4}>
                                                    <Card className={classes.smallCard}>
                                                        <div className={classes.earMark}>
                                                            <BsFileEarmarkMusic className={classes.earMarkMusic} />
                                                        </div>
                                                        <Typography className={classes.audio}>audio1.mkv</Typography>
                                                        <div className={classes.playDiv}>
                                                            <AiFillPlayCircle className={classes.playCircleIcon} />
                                                            <Typography className={classes.play}>Play</Typography>
                                                        </div>
                                                    </Card>
                                                </Grid>
                                                <Grid item md={2} sm={2} xs={4}>
                                                    <Card className={classes.smallCard}>
                                                        <div className={classes.earMark}>
                                                            <BsFileEarmarkMusic className={classes.earMarkMusic} />
                                                        </div>
                                                        <Typography className={classes.audio}>audio1.mkv</Typography>
                                                        <div className={classes.playDiv}>
                                                            <AiFillPlayCircle className={classes.playCircleIcon} />
                                                            <Typography className={classes.play}>Play</Typography>
                                                        </div>
                                                    </Card>
                                                </Grid>
                                            </Grid>
                                        </AccordionDetails>
                                    </Accordion>
                                </div>
                            {/* </CardContent> */}
                        {/* </Card> */}
                        <div className={classes.divCard}>
                            <Grid container>
                                <Grid item md={12} sm={12}>
                                    <Accordion>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <Typography className={classes.bestExample}>Best Examples</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Grid container spacing={2} style={{ marginTop: 25, }}>
                                                <Grid item md={2} sm={2} xs={4}>
                                                    <Card className={classes.smallCard}>
                                                        <div className={classes.earMark}>
                                                            <BsFileEarmarkMusic className={classes.earMarkMusic} />
                                                        </div>
                                                        <Typography className={classes.audio}>audio1.mkv</Typography>
                                                        <div className={classes.playDiv}>
                                                            <AiFillPlayCircle className={classes.playCircleIcon} />
                                                            <Typography className={classes.play}>Play</Typography>
                                                        </div>
                                                    </Card>
                                                </Grid>
                                                <Grid item md={2} sm={2} xs={4}>
                                                    <Card className={classes.smallCard}>
                                                        <div className={classes.earMark}>
                                                            <BsFileEarmarkMusic className={classes.earMarkMusic} />
                                                        </div>
                                                        <Typography className={classes.audio}>audio1.mkv</Typography>
                                                        <div className={classes.playDiv}>
                                                            <AiFillPlayCircle className={classes.playCircleIcon} />
                                                            <Typography className={classes.play}>Play</Typography>
                                                        </div>
                                                    </Card>
                                                </Grid>
                                                <Grid item md={2} sm={2} xs={4}>
                                                    <Card className={classes.smallCard}>
                                                        <div className={classes.earMark}>
                                                            <BsFileEarmarkMusic className={classes.earMarkMusic} />
                                                        </div>
                                                        <Typography className={classes.audio}>audio1.mkv</Typography>
                                                        <div className={classes.playDiv}>
                                                            <AiFillPlayCircle className={classes.playCircleIcon} />
                                                            <Typography className={classes.play}>Play</Typography>
                                                        </div>
                                                    </Card>
                                                </Grid>
                                                <Grid item md={2} sm={2} xs={4}>
                                                    <Card className={classes.smallCard}>
                                                        <div className={classes.earMark}>
                                                            <BsFileEarmarkMusic className={classes.earMarkMusic} />
                                                        </div>
                                                        <Typography className={classes.audio}>audio1.mkv</Typography>
                                                        <div className={classes.playDiv}>
                                                            <AiFillPlayCircle className={classes.playCircleIcon} />
                                                            <Typography className={classes.play}>Play</Typography>
                                                        </div>
                                                    </Card>
                                                </Grid>
                                                <Grid item md={2} sm={2} xs={4}>
                                                    <Card className={classes.smallCard}>
                                                        <div className={classes.earMark}>
                                                            <BsFileEarmarkMusic className={classes.earMarkMusic} />
                                                        </div>
                                                        <Typography className={classes.audio}>audio1.mkv</Typography>
                                                        <div className={classes.playDiv}>
                                                            <AiFillPlayCircle className={classes.playCircleIcon} />
                                                            <Typography className={classes.play}>Play</Typography>
                                                        </div>
                                                    </Card>
                                                </Grid>
                                                <Grid item md={2} sm={2} xs={4}>
                                                    <Card className={classes.smallCard}>
                                                        <div className={classes.earMark}>
                                                            <BsFileEarmarkMusic className={classes.earMarkMusic} />
                                                        </div>
                                                        <Typography className={classes.audio}>audio1.mkv</Typography>
                                                        <div className={classes.playDiv}>
                                                            <AiFillPlayCircle className={classes.playCircleIcon} />
                                                            <Typography className={classes.play}>Play</Typography>
                                                        </div>
                                                    </Card>
                                                </Grid>
                                            </Grid>
                                        </AccordionDetails>
                                    </Accordion>
                                </Grid>
                            </Grid>
                        </div>
                        <div className={classes.divCard}>
                            <Grid container>
                                <Grid item md={12} sm={12}>
                                    <Card className={classes.smallCardTwo}>
                                        <Accordion>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel1a-content"
                                                id="panel1a-header"
                                            >
                                                <Typography className={classes.bestExample}>Customer Services</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Grid container spacing={2} style={{ marginTop: 25, }}>
                                                    <Grid item md={2} sm={2} xs={4}>
                                                        <Card className={classes.smallCard}>
                                                            <div className={classes.earMark}>
                                                                <BsFileEarmarkMusic className={classes.earMarkMusic} />
                                                            </div>
                                                            <Typography className={classes.audio}>audio1.mkv</Typography>
                                                            <div className={classes.playDiv}>
                                                                <AiFillPlayCircle className={classes.playCircleIcon} />
                                                                <Typography className={classes.play}>Play</Typography>
                                                            </div>
                                                        </Card>
                                                    </Grid>
                                                    <Grid item md={2} sm={2} xs={4}>
                                                        <Card className={classes.smallCard}>
                                                            <div className={classes.earMark}>
                                                                <BsFileEarmarkMusic className={classes.earMarkMusic} />
                                                            </div>
                                                            <Typography className={classes.audio}>audio1.mkv</Typography>
                                                            <div className={classes.playDiv}>
                                                                <AiFillPlayCircle className={classes.playCircleIcon} />
                                                                <Typography className={classes.play}>Play</Typography>
                                                            </div>
                                                        </Card>
                                                    </Grid>
                                                    <Grid item md={2} sm={2} xs={4}>
                                                        <Card className={classes.smallCard}>
                                                            <div className={classes.earMark}>
                                                                <BsFileEarmarkMusic className={classes.earMarkMusic} />
                                                            </div>
                                                            <Typography className={classes.audio}>audio1.mkv</Typography>
                                                            <div className={classes.playDiv}>
                                                                <AiFillPlayCircle className={classes.playCircleIcon} />
                                                                <Typography className={classes.play}>Play</Typography>
                                                            </div>
                                                        </Card>
                                                    </Grid>
                                                    <Grid item md={2} sm={2} xs={4}>
                                                        <Card className={classes.smallCard}>
                                                            <div className={classes.earMark}>
                                                                <BsFileEarmarkMusic className={classes.earMarkMusic} />
                                                            </div>
                                                            <Typography className={classes.audio}>audio1.mkv</Typography>
                                                            <div className={classes.playDiv}>
                                                                <AiFillPlayCircle className={classes.playCircleIcon} />
                                                                <Typography className={classes.play}>Play</Typography>
                                                            </div>
                                                        </Card>
                                                    </Grid>
                                                    <Grid item md={2} sm={2} xs={4}>
                                                        <Card className={classes.smallCard}>
                                                            <div className={classes.earMark}>
                                                                <BsFileEarmarkMusic className={classes.earMarkMusic} />
                                                            </div>
                                                            <Typography className={classes.audio}>audio1.mkv</Typography>
                                                            <div className={classes.playDiv}>
                                                                <AiFillPlayCircle className={classes.playCircleIcon} />
                                                                <Typography className={classes.play}>Play</Typography>
                                                            </div>
                                                        </Card>
                                                    </Grid>
                                                    <Grid item md={2} sm={2} xs={4}>
                                                        <Card className={classes.smallCard}>
                                                            <div className={classes.earMark}>
                                                                <BsFileEarmarkMusic className={classes.earMarkMusic} />
                                                            </div>
                                                            <Typography className={classes.audio}>audio1.mkv</Typography>
                                                            <div className={classes.playDiv}>
                                                                <AiFillPlayCircle className={classes.playCircleIcon} />
                                                                <Typography className={classes.play}>Play</Typography>
                                                            </div>
                                                        </Card>
                                                    </Grid>
                                                </Grid>
                                            </AccordionDetails>
                                        </Accordion>
                                    </Card>
                                </Grid>
                            </Grid>
                        </div>
                        <div className={classes.divCard}>
                            <Grid container>
                                <Grid item md={12} sm={12}>
                                    <Card className={classes.smallCardTwo}>
                                        <Accordion>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel1a-content"
                                                id="panel1a-header"
                                            >
                                                <Typography className={classes.bestExample}>Mistakes</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Grid container spacing={2} style={{ marginTop: 25, }}>
                                                    <Grid item md={2} sm={2} xs={4}>
                                                        <Card className={classes.smallCard}>
                                                            <div className={classes.earMark}>
                                                                <BsFileEarmarkMusic className={classes.earMarkMusic} />
                                                            </div>
                                                            <Typography className={classes.audio}>audio1.mkv</Typography>
                                                            <div className={classes.playDiv}>
                                                                <AiFillPlayCircle className={classes.playCircleIcon} />
                                                                <Typography className={classes.play}>Play</Typography>
                                                            </div>
                                                        </Card>
                                                    </Grid>
                                                    <Grid item md={2} sm={2} xs={4}>
                                                        <Card className={classes.smallCard}>
                                                            <div className={classes.earMark}>
                                                                <BsFileEarmarkMusic className={classes.earMarkMusic} />
                                                            </div>
                                                            <Typography className={classes.audio}>audio1.mkv</Typography>
                                                            <div className={classes.playDiv}>
                                                                <AiFillPlayCircle className={classes.playCircleIcon} />
                                                                <Typography className={classes.play}>Play</Typography>
                                                            </div>
                                                        </Card>
                                                    </Grid>
                                                    <Grid item md={2} sm={2} xs={4} >
                                                        <Card className={classes.smallCard}>
                                                            <div className={classes.earMark}>
                                                                <BsFileEarmarkMusic className={classes.earMarkMusic} />
                                                            </div>
                                                            <Typography className={classes.audio}>audio1.mkv</Typography>
                                                            <div className={classes.playDiv}>
                                                                <AiFillPlayCircle className={classes.playCircleIcon} />
                                                                <Typography className={classes.play}>Play</Typography>
                                                            </div>
                                                        </Card>
                                                    </Grid>
                                                    <Grid item md={2} sm={2} xs={4}>
                                                        <Card className={classes.smallCard}>
                                                            <div className={classes.earMark}>
                                                                <BsFileEarmarkMusic className={classes.earMarkMusic} />
                                                            </div>
                                                            <Typography className={classes.audio}>audio1.mkv</Typography>
                                                            <div className={classes.playDiv}>
                                                                <AiFillPlayCircle className={classes.playCircleIcon} />
                                                                <Typography className={classes.play}>Play</Typography>
                                                            </div>
                                                        </Card>
                                                    </Grid>
                                                    <Grid item md={2} sm={2} xs={4}>
                                                        <Card className={classes.smallCard}>
                                                            <div className={classes.earMark}>
                                                                <BsFileEarmarkMusic className={classes.earMarkMusic} />
                                                            </div>
                                                            <Typography className={classes.audio}>audio1.mkv</Typography>
                                                            <div className={classes.playDiv}>
                                                                <AiFillPlayCircle className={classes.playCircleIcon} />
                                                                <Typography className={classes.play}>Play</Typography>
                                                            </div>
                                                        </Card>
                                                    </Grid>
                                                    <Grid item md={2} sm={2} xs={4}>
                                                        <Card className={classes.smallCard}>
                                                            <div className={classes.earMark}>
                                                                <BsFileEarmarkMusic className={classes.earMarkMusic} />
                                                            </div>
                                                            <Typography className={classes.audio}>audio1.mkv</Typography>
                                                            <div className={classes.playDiv}>
                                                                <AiFillPlayCircle className={classes.playCircleIcon} />
                                                                <Typography className={classes.play}>Play</Typography>
                                                            </div>
                                                        </Card>
                                                    </Grid>
                                                </Grid>
                                            </AccordionDetails>
                                        </Accordion>
                                    </Card>
                                </Grid>
                            </Grid>
                        </div>
                        <div className={classes.divCard}>
                            <Grid container>
                                <Grid item md={12} sm={12}>
                                    <Card className={classes.smallCardTwo}>
                                        <Accordion>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel1a-content"
                                                id="panel1a-header"
                                            >
                                                <Typography className={classes.bestExample}>Sales Calls</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Grid container spacing={2} style={{ marginTop: 25, }}>
                                                    <Grid item md={2} sm={2} xs={4}>
                                                        <Card className={classes.smallCard}>
                                                            <div className={classes.earMark}>
                                                                <BsFileEarmarkMusic className={classes.earMarkMusic} />
                                                            </div>
                                                            <Typography className={classes.audio}>audio1.mkv</Typography>
                                                            <div className={classes.playDiv}>
                                                                <AiFillPlayCircle className={classes.playCircleIcon} />
                                                                <Typography className={classes.play}>Play</Typography>
                                                            </div>
                                                        </Card>
                                                    </Grid>
                                                    <Grid item md={2} sm={2} xs={4}>
                                                        <Card className={classes.smallCard}>
                                                            <div className={classes.earMark}>
                                                                <BsFileEarmarkMusic className={classes.earMarkMusic} />
                                                            </div>
                                                            <Typography className={classes.audio}>audio1.mkv</Typography>
                                                            <div className={classes.playDiv}>
                                                                <AiFillPlayCircle className={classes.playCircleIcon} />
                                                                <Typography className={classes.play}>Play</Typography>
                                                            </div>
                                                        </Card>
                                                    </Grid>
                                                    <Grid item md={2} sm={2} xs={4}>
                                                        <Card className={classes.smallCard}>
                                                            <div className={classes.earMark}>
                                                                <BsFileEarmarkMusic className={classes.earMarkMusic} />
                                                            </div>
                                                            <Typography className={classes.audio}>audio1.mkv</Typography>
                                                            <div className={classes.playDiv}>
                                                                <AiFillPlayCircle className={classes.playCircleIcon} />
                                                                <Typography className={classes.play}>Play</Typography>
                                                            </div>
                                                        </Card>
                                                    </Grid>
                                                    <Grid item md={2} sm={2} xs={4}>
                                                        <Card className={classes.smallCard}>
                                                            <div className={classes.earMark}>
                                                                <BsFileEarmarkMusic className={classes.earMarkMusic} />
                                                            </div>
                                                            <Typography className={classes.audio}>audio1.mkv</Typography>
                                                            <div className={classes.playDiv}>
                                                                <AiFillPlayCircle className={classes.playCircleIcon} />
                                                                <Typography className={classes.play}>Play</Typography>
                                                            </div>
                                                        </Card>
                                                    </Grid>
                                                    <Grid item md={2} sm={2} xs={4}>
                                                        <Card className={classes.smallCard}>
                                                            <div className={classes.earMark}>
                                                                <BsFileEarmarkMusic className={classes.earMarkMusic} />
                                                            </div>
                                                            <Typography className={classes.audio}>audio1.mkv</Typography>
                                                            <div className={classes.playDiv}>
                                                                <AiFillPlayCircle className={classes.playCircleIcon} />
                                                                <Typography className={classes.play}>Play</Typography>
                                                            </div>
                                                        </Card>
                                                    </Grid>
                                                    <Grid item md={2} sm={2} xs={4}>
                                                        <Card className={classes.smallCard}>
                                                            <div className={classes.earMark}>
                                                                <BsFileEarmarkMusic className={classes.earMarkMusic} />
                                                            </div>
                                                            <Typography className={classes.audio}>audio1.mkv</Typography>
                                                            <div className={classes.playDiv}>
                                                                <AiFillPlayCircle className={classes.playCircleIcon} />
                                                                <Typography className={classes.play}>Play</Typography>
                                                            </div>
                                                        </Card>
                                                    </Grid>
                                                </Grid>
                                            </AccordionDetails>
                                        </Accordion>
                                    </Card>
                                </Grid>
                            </Grid>
                        </div>
                    </CardContent>
                </CardContent>
            </Card>
        </>
    )
}
export default Playlists