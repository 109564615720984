import React from 'react';
import { useStyles } from './style';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Card,
    CardContent,
    Divider,
    FormGroup,
    FormControlLabel,
    Grid,
    Avatar,
    Typography,
    TextField,
    Tooltip,
    TextareaAutosize,
    Table,

    Button,
    Switch,
    IconButton,
    withStyles,

} from '@material-ui/core/';
import Slider from '@material-ui/core/Slider';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import PhoneIcon from '@material-ui/icons/Phone';
import { AiOutlineMinus, AiOutlineClose } from 'react-icons/ai'
import { FaUserPlus } from 'react-icons/fa';
import { MdSend, MdScheduleSend, MdOutlineDialpad, MdCall, MdOutlineExpandMore, MdPhonePaused, MdCallEnd } from 'react-icons/md';
import { BiCheckDouble, BiPlay, BiMessageSquareAdd, BiDialpadAlt, BiChevronDown, BiRectangle } from 'react-icons/bi';
import { RiEditBoxFill, RiUser3Fill, RiPauseCircleFill } from 'react-icons/ri';
import Ellipse from '../../assets/icons/Ellipse.png';
import { AiFillCloseCircle, AiFillStar, AiOutlineEdit, AiOutlinePause } from 'react-icons/ai';
import { GrFormClose } from 'react-icons/gr'
import { InputAdornment } from '@material-ui/core';
import { ImBlocked, ImPhoneHangUp } from 'react-icons/im';
import { IoIosClose, IoMdCall } from 'react-icons/io'
import { BsFillCaretDownFill, BsChatRightText, BsDownload, BsFillMicMuteFill, BsPlusSquareFill, BsWifi, BsFillMicFill, BsFillTelephoneOutboundFill } from 'react-icons/bs';
import { FiSettings } from 'react-icons/fi';
import { CgUserAdd } from 'react-icons/cg';
import CallParkIcon from '../../assets/icons/call-park.png'
import { FcCallTransfer } from 'react-icons/fc';
import { GrAdd } from 'react-icons/gr';
import { VscChromeMaximize } from 'react-icons/vsc'
import Modal from '@material-ui/core/Modal';
import { CardHeader } from '../Header/CardHeader';
import { ImRedo2 } from 'react-icons/im'
import SaveIcon from '@material-ui/icons/Save';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import { TiArrowForward } from 'react-icons/ti';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import Popup from "reactjs-popup";
import Checkbox from '@material-ui/core/Checkbox';
import KeyboardVoiceIcon from '@material-ui/icons/KeyboardVoice';
import { Window, WindowActionsEvent } from "@progress/kendo-react-dialogs";
import './styles.css'
import profile from "../../assets/icons/avtar.jpg";
import callTransfer from '../../assets/icons/callTransfer.jpg'

export default function IncomingCall(props: any) {
    const classes = useStyles();
    let start = 0
    let intrvl: any
    const [open, setOpen] = React.useState<boolean>(false);
    const [answered, setAnswered] = React.useState<boolean>(false);
    const [mute, setMute] = React.useState<boolean>(false);
    const [hold, setHold] = React.useState<boolean>(false);
    const [rotation, setRotation] = React.useState(0)
    const [transferOpen, setTransferOpen] = React.useState<boolean>(false);
    const [windowStage, setWindowStage] = React.useState("MAXIMISED");
    const [transferModalStyle] = React.useState(getTransferModalStyle);
    const [timer, setTimer] = React.useState({
        s: "00",
        m: "00",
        h: "00"
    })
    const handleTranferOpen = () => {
        setTransferOpen(true);
    }
    const handleTransferClose = () => {
        setTransferOpen(false);
    }
    const handleOpen = () => {
        setOpen(true);
    }
    const handleMute = () => {
        setMute(!mute)
        if (mute) {
            props.session.unmute()
        } else {
            props.session.mute()
        }
    }
    function setZeros(i: any) {
        if (i < 10) return "0" + i;
        return i;
    }

    //start timer
    const startTimer = () => {
        // if startTimer is already running
        if (start == 1) return;

        start = 1; // set startTimer is running
        let ss = 0,
            mm = 0,
            hh = 0;
        intrvl = setInterval(() => {
            ss++;
            if (ss == 60) {
                ss = 0;
                mm++;
            }
            if (mm == 60) {
                mm = 0;
                hh++;
            }
            setTimer({
                s: setZeros(ss),
                m: setZeros(mm),
                h: setZeros(hh)
            })

        }, 1000);
    }; // start timer ends
    const stopTimer = () => {
        if (start == 0) return;
        start = 0;
        setTimer({
            s: "00",
            m: "00",
            h: "00"
        });
        clearInterval(intrvl);
    }; // stop timer ends
    const handleHold = () => {
        setHold(!hold)
        if (hold) {
            props.session.unhold()
        } else {
            props.session.hold()
        }
    }
    function getTransferModalStyle() {
        const top = 15;
        const left = 30;
        const right = 30;

        return {
            top: `${top}%`,
            left: `${left}%`,
            right: `${right}%`,
            transform: `translate(-${top}%, -${left}%, -${right}%)`,
        };
    }
    const handleAnswerCall = () => {
        props.session.answer(props.options);
        setAnswered(true)
        startTimer()
    }
    const handleEndCall = () => {
        props.session.terminate();
        props.callModal()
        props.parentMinimise(false)
        props.parentEndCall(false)
        props.end()
    }
    const handleMinimise = () => {
        props.parentMinimise(false)
    }


    return (
        <Card style={{ background: 'white' }}>
            <div style={{ display: "flex", gap: "10px", alignItems: "center", float: "right", marginTop: "8px" }}>
                <div onClick={handleMinimise} style={{ cursor: "pointer" }}>
                    <AiOutlineMinus color="black" />
                </div>

                <VscChromeMaximize color="black" />
                <p></p>
            </div>


            {/* <div style={{ display: "flex", float: "right", justifyContent: "space-between", marginTop: "-30px", marginRight: "3px" }}>
                        <AiOutlineMinus color="white" style={{ fontSize: "12px", marginTop: "3px", marginRight: "6px", cursor: "pointer" }} />
                        <BiRectangle
                            className={rotation == 1 ? classes.fullscreen : ""}
                            color="white" style={{ fontSize: "12px", marginTop: "3px", marginRight: "6px", cursor: "pointer" }} />
                        <IoIosClose onClick={handleEndCall} color="white" style={{ fontSize: "19px", marginTop: "0px", cursor: "pointer" }} />
                    </div> */}

            <div className={classes.callVolumeOne}>
                <Avatar className={classes.avtar} style={{ marginLeft: "56px" }}>  <img alt="Remy Sharp" src={profile} className={classes.profile} /></Avatar>
            </div>
            <div className={classes.newJhon}>
                <Typography className={classes.wick}>{props.session?._remote_identity?._display_name}</Typography>
            </div>
            <div className={classes.numberOne}>
                <Typography className={classes.numberTwo}>{props.session?._remote_identity?._uri?._user}</Typography>
            </div>
            {props.callStatus && <div className={classes.timeDivTwo}>
                <Typography className={classes.timeContentTwo} >{props.timer?.m + " : " + props.timer?.s}</Typography>
            </div>
            }
            {/* <div className={classes.timeDivTwo}>
                <Typography className={classes.timeContentTwo} >10:15</Typography>
            </div> */}

            {!answered ?

                <div style={{ display: "flex", justifyContent: "space-evenly", marginBottom: "25px", marginTop: "30px" }}>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        <div className={classes.callEnd} onClick={handleEndCall} >
                            <IconButton className={classes.filledIcon}>
                                <MdCallEnd />
                            </IconButton>
                            <Typography className={classes.iconmsg}>Hang Up</Typography>
                        </div>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        <div className={classes.callEnd} id="ringing" onClick={handleAnswerCall}>
                            <IconButton className={classes.iconSizeanswer}>
                                {/* <Icon icon={callEnd16Filled} /> */}
                                <IoMdCall />
                            </IconButton>
                            <Typography className={classes.iconmsg}>Answer</Typography>
                        </div>
                    </div>
                    {/* <div style={{ display: "flex", flexDirection: "row" }}>
                        <div className={classes.callEnd} onClick={handleEndCall} >
                            <IconButton className={classes.filledIconchat}>
                                <BsChatRightText />
                            </IconButton>
                            <Typography className={classes.iconmsg}>Message</Typography>
                        </div>
                    </div> */}
                </div>
                :
                <div>
                    <CardContent>
                        <div className={classes.dailDiv}>
                            {mute ?
                                <div onClick={handleMute}>
                                    <IconButton className={classes.voicemuteBoard}>
                                        <BsFillMicMuteFill />
                                    </IconButton>
                                    <Typography className={classes.muteOne}>Unmute</Typography>
                                </div> :

                                <div onClick={handleMute}>
                                    <IconButton className={classes.voiceBoard}>
                                        <BsFillMicFill />
                                    </IconButton>
                                    <Typography className={classes.muteOne}>Mute</Typography>
                                </div>
                            }
                            <div>
                                <IconButton className={classes.voiceBoard}>
                                    {/* <FcCallTransfer /> */}
                                    <img src={callTransfer} alt="" width="23px" height="23px" />
                                </IconButton>
                                <Typography className={classes.muteOne}>Transfer</Typography>
                            </div>
                            {hold ?
                                <div onClick={handleHold}>
                                    <IconButton className={classes.voiceBoard}>
                                        <BiPlay />
                                    </IconButton>
                                    <Typography className={classes.muteOne}>Unhold</Typography>
                                </div> :
                                <div onClick={handleHold}>
                                    <IconButton className={classes.voiceBoard}>
                                        <AiOutlinePause />
                                    </IconButton>
                                    <Typography className={classes.muteOne}>hold</Typography>
                                </div>
                            }
                        </div>
                    </CardContent>
                    <CardContent style={{ marginTop: "-24px" }}>
                        <div className={classes.addDiv}>
                            <div>
                                <IconButton className={classes.voiceBoard}>
                                    <GrAdd color="white" />
                                </IconButton>
                                <Typography className={classes.muteOne}>Add Call</Typography>
                            </div>
                            <div>
                                <IconButton className={classes.voiceBoard} style={{ marginLeft: 50 }}>
                                    <MdOutlineDialpad />
                                </IconButton>
                                <Typography className={classes.muteOne} style={{ marginLeft: 50 }}>Dialpad</Typography>
                            </div>
                            {/* <div>
                                <IconButton className={classes.voiceBoard}>
                                    <MdOutlineDialpad />
                                </IconButton>
                                <Typography className={classes.muteOne}>Dialpad</Typography>
                            </div> */}

                        </div>
                    </CardContent>
                    <div className={classes.callEndone} onClick={handleEndCall} >
                        <IconButton className={classes.callFilledOne}>
                            <MdCallEnd className={classes.closeCall} />
                        </IconButton>
                    </div>
                </div>
            }

        </Card>


    )
}
