import { Button, Grid, TextField } from '@material-ui/core'
import React from 'react'
import axiosInstance from '../../../environment/axiosInstances'
import * as authActionCreators from '../../../Store/Actions/AuthActionCreator'
import { useStyles } from '../styles'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { useDispatch } from 'react-redux'
toast.configure()


function AddFavourite(props: any) {
    const classes = useStyles();
    const dispatch = useDispatch()
    const [submitted, setSubmitted] = React.useState(false);
    const [data, setData] = React.useState<any>({
        "name": "",
        "phoneNumber": ""
    })

    const handleChangeData = (e: any) => {
        setData((prev: any) => {
            return {
                ...data,
                [e.target.name]: e.target.value
            }
        })
    }


    const handleChangeNumber = (e: any) => {
        const val = e.target.value
        if (val !== " ") {
            setData((prev: any) => {
                return {
                    ...prev,
                    [e.target.name]: val.replace(/[^0-9,.]+/g, "")
                }
            })
        }
    }

    const validate = (submitted: any) => {
        const validData = {
            isValid: true,
            message: ""
        };
        const retData = {
            newnumber: { ...validData },
            newName: { ...validData }

        };
        let isValid = true;
        if (submitted) {
            if (data.phoneNumber === "") {
                retData.newnumber = {
                    isValid: false,
                    message: "Please enter phone number"
                };
                isValid = false;
            }
            else if (data.phoneNumber.length < 10) {
                retData.newnumber = {
                    isValid: false,
                    message: "Phone Number should be less than 10 digits"
                };
                isValid = false;
            }
            else if (data.phoneNumber.length > 15) {
                retData.newnumber = {
                    isValid: false,
                    message: "Phone Number should not be greater than 15 digits"
                };
                isValid = false;
            }
            if (data.name === "") {
                retData.newName = {
                    isValid: false,
                    message: "Please enter name "
                };
                isValid = false;
            }
            else if (!/^[a-zA-Z]*$/g.test(data.name)) {
                retData.newName = {
                    isValid: false,
                    message: "Please enter  only characters"
                };
                isValid = false;
            }
        }
        return {
            ...retData,
            isValid
        };
    };




    const handleSubmit = () => {
        var newData
        if (data.phoneNumber) {
            newData = data
        }
        else {
            newData = {
                phoneNumber: data.phoneNumber,

            }
        }
        setSubmitted(true);
        const errorData = validate(true);
        if (errorData.isValid) {
            axiosInstance.post('/favourites', data).then((res: any) => {
                if (res.data) {
                    props.handlePopupModal()
                    toast.success(res.data.message, {
                        position: toast.POSITION.TOP_RIGHT,
                        style: {
                            borderRadius: "2px",
                            border: "1px solid green"
                        }, autoClose: 1000
                    })
                    props.getFavourits(props.paginate.limit, props.paginate.page)
                }
            }).catch((err) => {
                if (err.response?.status == 402) {
                    window.location.href = "https://newbp.efone.ca"
                    sessionStorage.removeItem("user")

                }
                if (err.response?.status == 401) {
                    dispatch(authActionCreators.initRefreshToken())
                }
                toast.error(err.response.data.message, { position: toast.POSITION.TOP_RIGHT, autoClose: 1000 })
            })
        }
        else {
            props.getFavourits(props.paginate.limit, props.paginate.page)
        }
    }

    const errors = validate(submitted);

    return (
        <div style={{ padding: 16 }}>
            <Grid container spacing={2}>
                <Grid item md={6}>
                    <TextField
                        variant='outlined'
                        fullWidth
                        size='small'
                        placeholder='Name'
                        value={data.name}
                        name="name"
                        onChange={handleChangeData}
                        error={!errors.newName.isValid}
                    />
                    {
                        !errors.newName.isValid &&
                        <p className={classes.errorMessage}>{errors.newName.message}</p>
                    }

                </Grid>
                <Grid item md={6}>
                    <TextField
                        variant='outlined'
                        fullWidth
                        size='small'
                        placeholder='Phone number'
                        name='phoneNumber'
                        value={data.phoneNumber}
                        onChange={handleChangeNumber}
                        error={!errors.newnumber.isValid}
                    />
                    {
                        !errors.newnumber.isValid &&
                        <p className={classes.errorMessage}>{errors.newnumber.message}</p>
                    }
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item md={12} >
                    <div style={{ textAlign: 'center', marginTop: 10 }}>
                        <Button variant='outlined' size='small' className={classes.SaveButton} onClick={handleSubmit}>
                            Add
                        </Button>

                        <Button variant='outlined' size='small' className={classes.cancelButton} onClick={props.handlePopupModal}>
                            Cancel
                        </Button>
                    </div>
                </Grid>
            </Grid>
        </div>
    )
}

export default AddFavourite