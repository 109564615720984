import React from 'react';
import {
    TableContainer,
    Table,
    TableBody,
    TableRow,
    TableCell,
    TableHead,
    IconButton,
    MenuItem,
    Avatar,
    Button,
    FormGroup,
    FormControlLabel,
    FormControl,
    Typography,
    Divider,
    CircularProgress,
} from '@material-ui/core';
import { useStyles } from './Styles';
import { TableHeaderComponent } from '../../../Components/Table/Table';
import * as authActionCreators from '../../../Store/Actions/AuthActionCreator'
import Moment from 'moment'
import axiosInstance from '../../../environment/axiosInstances'
import { Window, WindowActionsEvent } from "@progress/kendo-react-dialogs";
import Modal from '../../../Components/Modal/Modal'
import { useDispatch, useSelector } from "react-redux";
import * as CallActionCreator from '../../../Store/Actions/CallActionCreator'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Tablebdy from './Tablebdy';
function MyactivityCalls(props: any): JSX.Element {
    const classes = useStyles();

    const callingData: any = useSelector((state: any) => state.CallingData);
    const [outboundData, setOutBoundData] = React.useState<any>([])
    const [callModal, setCallModal] = React.useState(false)
    const [data, setData] = React.useState({})
    const dispatch = useDispatch()
    const [loading, setLoading] = React.useState<any>(false);
    const [calls, setCalls] = React.useState<any>([])
    const [option, setOption] = React.useState<any>()


    const [paginate, setPaginate] = React.useState<any>({
        page: 1,
        totalDocs: 0,
        limit: 10
    })
    const handleChangePage = (value: any) => {
        setPaginate((prev: any) => {
            return {
                ...prev,
                limit: paginate.limit + 10,
            }
        })

        fetchCalls(paginate.limit, props.type)

    }
    const fetchCalls = (limit: any, type: any) => {
        setLoading(true)
        axiosInstance.get(`/my-activities/calls?limit=${limit}&type=${type}`).then((res) => {

            if (res.data) {
                setCalls(res.data.data)
                setLoading(false)
                setPaginate((prev: any) => {
                    return {
                        ...prev,
                        page: res.data.page,
                        totalDocs: res.data.totalDocs,
                        limit: res.data.limit
                    }
                })
            }
        }).catch((err) => {
            if (err.response?.status == 402) {
                window.location.href = "https://newbp.efone.ca"
                sessionStorage.removeItem("user")

            }
            if (err.response?.status == 401) {
                dispatch(authActionCreators.initRefreshToken())
            }
            setLoading(false)
        })
    }
    React.useEffect(() => {
        fetchCalls(paginate.limit, props.type)
    }, [props.type])
    const handleCallModal = () => {
        setCallModal(!callModal)
    }
    const getOutboundCallers = () => {
        axiosInstance.get('/calls/outbound-caller-id').then((res) => {
            if (res.data) {
                const data = res.data.data
                setOutBoundData(data)
                setOption(data[0])
            }
        }).catch((err) => {
            if (err.response?.status == 402) {
                window.location.href = "https://newbp.efone.ca"
                sessionStorage.removeItem("user")

            }
            if (err.response?.status == 401) {
                dispatch(authActionCreators.initRefreshToken())
            }
        })
    }

    React.useEffect(() => {
        getOutboundCallers()
    }, [])
    const tableHeader = ['Phone Number', 'eFone Number', 'Duration', 'Action', 'Date']
    const handleCall = (data: any, option: any) => {

        handleCallModal()
        setData({
            name: data.callerIdName,
            phoneNumber: data.phoneNumber
        })
        if (callingData?.callData && callingData?.callData?.phoneNumber !== "") {
            toast.error("Already you are in call", { position: toast.POSITION.TOP_RIGHT, autoClose: 2000 })
        }
        else {
            const oNumber = option.id ? option.id : ""
            const oName = option.name ? option.name : ""
            var Options: any = props.callOptions
            Options.extraHeaders = [`oNumber:${oNumber}`, `oName: ${oName} `]

            const newdata = {
                name: data.callerIdName,
                phoneNumber: data.phoneNumber,
                options: Options
            }
            dispatch(CallActionCreator.fetchStatus(newdata))
            dispatch(CallActionCreator.fetchData(true))


        }
        // const audio: any = new window.Audio()
        // const session = props.coolPhone.call(data.toPhoneNumber, props.callOptions)

        // console.log("session", session)
        // if (session.connection) {
        //     console.log('Connection is valid')

        //     session.connection.addEventListener('addstream', (e: any) => {
        //         console.log('Add stream')
        //         audio.srcObject = e.stream
        //         audio.play()
        //     })

        //     session.on('addstream', function (e: any) {
        //         // set remote audio stream (to listen to remote audio)
        //         // remoteAudio is <audio> element on page
        //         const remoteAudio = audio
        //         remoteAudio.src = window.URL.createObjectURL(e.stream);
        //         remoteAudio.play();
        //     });
        //     session.connection.addEventListener('peerconnection', (e: any) => {
        //         console.log('Peer connection')
        //         audio.srcObject = e.stream
        //         audio.play()
        //     })

        // } else {
        //     console.log('Connection is null')
        // }
    }


    return (
        <>
            {/* {
                callModal && (
                    <div style={{ padding: 0 }} >
                        <Modal>
                            <Window
                                title={""}
                                stage={windowStage}
                                onStageChange={handleStageChange}

                            >

                                <OutgoingCall callModal={handleCallModal} data={data}
                                    coolPhone={props.coolPhone}
                                    callOptions={props.callOptions}
                                    callStatus={props.callStatus} />

                            </Window>
                        </Modal>
                    </div>

                )
            } */}
            <TableContainer>
                <Table className={classes.table}>
                    <TableHeaderComponent theadData={tableHeader} />
                    <TableBody>
                        {calls && calls.length > 0 ? calls.map((item: any, i: any) => {
                            var data = parseInt(item?.createdDate)
                            var dataa = Moment(data).format('YYYY MMM DD hh:mm A')
                            return (
                                <Tablebdy dataa={dataa} item={item}
                                    key={i}
                                    outboundData={outboundData}
                                    option={option}
                                    handleCall={handleCall} />
                            )
                        })
                            :

                            loading ? <div className='loader_class'>
                                <div style={{ display: 'grid', alignItems: 'center', margin: '30px 500px' }}>
                                    <CircularProgress disableShrink />
                                </div>
                            </div> : calls.length <= 0 &&
                            <TableRow>
                                <TableCell className={classes.tablecell}></TableCell>
                                <TableCell className={classes.tablecell}></TableCell>
                                <TableCell className={classes.tablecell}>No Data</TableCell>
                                <TableCell className={classes.tablecell}></TableCell>
                                <TableCell className={classes.tablecell}></TableCell>
                            </TableRow>
                            //  <p style={{ textAlign: 'center' }}>No Data</p>

                        }


                        {/* <TableRow>
                            <TableCell className={classes.tablecell}>User 2</TableCell>
                            <TableCell className={classes.tablecell}>

                            </TableCell>
                            <TableCell className={classes.tablecell}>Richardson</TableCell>
                            <TableCell className={classes.tablecell}>
                                <Button
                                    variant="outlined"
                                    className={classes.buttonicons}>
                                    <IconButton><PhoneIcon className={classes.iconcolor} /></IconButton>
                                    <Divider orientation="vertical" flexItem className={classes.dividericon} />
                                    <IconButton>
                                        <ArrowDropDownIcon className={classes.iconcolor} />
                                    </IconButton>

                                </Button>
                            </TableCell>
                            <TableCell className={classes.tablecell}>
                                <Button
                                    ref={anchorRef}
                                    aria-controls={open ? "menu-list-grow" : undefined}
                                    aria-haspopup="true"
                                    onClick={handleToggle}
                                //className={classes.inconbutton}
                                >
                                    <ArrowDropDownIcon />
                                </Button>
                                <Popper
                                    open={open}
                                    anchorEl={anchorRef.current}
                                    role={undefined}
                                    transition
                                >
                                    {({ TransitionProps, placement }) => (
                                        <Grow
                                            {...TransitionProps}
                                            style={{
                                                transformOrigin:
                                                    placement === "bottom" ? "center bottom" : "center bottom",

                                            }}
                                        >
                                            <Paper className={classes.paper}>
                                                <ClickAwayListener onClickAway={handleClose}>
                                                    <MenuList
                                                        autoFocusItem={open}
                                                        id="menu-list-grow"
                                                        onKeyDown={handleListKeyDown}
                                                    >
                                                        <MenuItem >
                                                            <FormControlLabel
                                                                control={<Checkbox checked={gilad} onChange={handleChange} name="gilad" />}
                                                                label="Other (123) 123-3333"
                                                            />
                                                        </MenuItem>
                                                        <MenuItem className={classes.items}>From 123 (123)-3333 </MenuItem>
                                                        <MenuItem>
                                                            <FormControl>
                                                                <FormGroup>
                                                                    <FormControlLabel
                                                                        control={<Checkbox checked={gilad} onChange={handleChange} name="gilad" />}
                                                                        label="Default(123) 123-3333"
                                                                    />
                                                                    <FormControlLabel
                                                                        control={<Checkbox checked={jason} onChange={handleChange} name="jason" />}
                                                                        label="Dailpad (123) 123-3333"
                                                                    />
                                                                    <FormControlLabel
                                                                        control={<Checkbox checked={antoine} onChange={handleChange} name="antoine" />}
                                                                        label="Block My Caller Id (Calling Only)"
                                                                    />
                                                                </FormGroup>
                                                                {/* <FormHelperText>Be careful</FormHelperText> */}
                        {/* </FormControl>
                                                        </MenuItem>
                                                        <MenuItem className={classes.items}>Other Ways to call </MenuItem>
                                                        <MenuItem>
                                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                <Avatar>U</Avatar>
                                                                <Typography className={classes.reschedule}>Uber Conference</Typography>
                                                            </div>
                                                        </MenuItem>
                                                    </MenuList>
                                                </ClickAwayListener>

                                            </Paper>
                                        </Grow>
                                    )}
                                </Popper>
                                <IconButton></IconButton>
                            </TableCell>

                        </TableRow> */}
                        {/* <TableRow className={classes.tablebackgroundcolor}>
                            <TableCell className={classes.tablecell}>User3</TableCell>
                            <TableCell className={classes.tablecell}>
                                <IconButton>
                                    <PhoneCallbackIcon className={classes.incomingiconcolor} />
                                </IconButton>
                                Incoming Call/5mins</TableCell>
                            <TableCell className={classes.tablecell}></TableCell>
                            <TableCell className={classes.tablecell}></TableCell>
                            <TableCell className={classes.tablecell}>Tuesday</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className={classes.tablecell}>User4</TableCell>
                            <TableCell className={classes.tablecell}>
                                <IconButton>
                                    <AddIcCallIcon className={classes.addcalliconcolor} />
                                </IconButton>
                                Transffered / 5mins</TableCell>
                            <TableCell className={classes.tablecell}>Richardson</TableCell>
                            <TableCell className={classes.tablecell}></TableCell>
                            <TableCell className={classes.tablecell}></TableCell>
                        </TableRow>
                        <TableRow className={classes.tablebackgroundcolor}>
                            <TableCell className={classes.tablecell}>user5</TableCell>
                            <TableCell className={classes.tablecell}>
                                <IconButton>
                                    <PhoneMissedIcon className={classes.missediconcolor} />
                                </IconButton>
                                Missed call</TableCell>
                            <TableCell className={classes.tablecell}></TableCell>
                            <TableCell className={classes.tablecell}></TableCell>
                            <TableCell className={classes.tablecell}>Tuesday</TableCell>
                        </TableRow> */}
                    </TableBody>
                </Table>
                {/* {
                    loading ? <div className='loader_class'>
                        <div style={{ display: 'grid', alignItems: 'center', margin: '30px 500px' }}>
                            <CircularProgress disableShrink />
                        </div>
                    </div> : calls.length <= 0 && <p style={{ textAlign: 'center' }}>No Data</p>
                } */}
            </TableContainer>
            {/* {calls.length <= 0 ? "" :
                <Stack spacing={2} style={{ marginTop: 25, marginBottom: 25, float: "right" }}>
                    <Pagination count={paginate.totalPages} page={paginate.page} onChange={handleChangePage} variant="outlined" color="secondary" />
                </Stack>
            } */}
            {calls.length <= 0 || paginate.totalDocs <= paginate.limit ? "" :
                <div style={{ marginTop: 25, marginBottom: 25, float: "right" }} onClick={handleChangePage}>
                    <Button variant='outlined' color="secondary">Load More...</Button>

                </div>
            }
        </>
    )
}
export default MyactivityCalls