import { makeStyles, TableHead, TableRow, TableCell } from '@material-ui/core';

const useStyles = makeStyles({
  tableHead: {
    backgroundColor: '#fbfbfb',
  },
  tableCellRow: {
    fontSize: 15,
    fontFamily: 'Work Sans',
    fontStyle: 'normal',
    fontWeight: 500,
    //textAlign: 'center',
    letterSpacing: 0.217535,
    color: '#464646',
  },
});

interface TableHeader {
  theadData: any;
}

export function TableHeaderComponent(props: TableHeader): JSX.Element {
  const classes = useStyles();

  interface TableHeaderItem {
    item: any;
  }

  const TableHeadItem = (props: TableHeaderItem) => {
    return (
      <TableCell className={classes.tableCellRow} title={props.item}>
        {props.item}
      </TableCell>
    );
  };

  return (
    <>
      <TableHead className={classes.tableHead}>
        <TableRow>
          {props.theadData.map((h: any) => {
            return <TableHeadItem key={h} item={h} />;
          })}
        </TableRow>
      </TableHead>
    </>
  );
}
