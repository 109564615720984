import React from 'react'
import { Tooltip } from '@material-ui/core'
import { AiFillCaretDown } from 'react-icons/ai'
import { FaPhoneAlt } from 'react-icons/fa'
import { useLocation, Link } from 'react-router-dom'
import * as departmentActionCreators from '../../../Store/Actions/DepartmentsActionXCreators'
import * as authActionCreators from '../../../Store/Actions/AuthActionCreator'
import './sidebarelement.css'
import {
    Button, Typography, TextField, withStyles, Switch, List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Collapse,
    Badge,
    Grid
} from '@material-ui/core';
import { useParams } from "react-router-dom";
import { NavLink } from 'react-router-dom';
import { getInitStatus, getInitPatchStatus } from '../../../Store/Actions/ActionCreator'
import { DepartmentStatus, fetchDepartmentsSuccess } from '../../../Store/Actions/DepartmentsActionXCreators'
import { useStyles } from '../styles';
import { useNavigate } from 'react-router-dom';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import axiosInstance from '../../../environment/axiosInstances'
import { useDispatch, useSelector } from "react-redux";
import * as DepartmentActionCreators from '../../../Store/Actions/DepartmentsActionXCreators'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
toast.configure()
function SidebarElement(props: any) {
    const profileDataStore: any = useSelector((state: any) => state.profileData);
    const { profileData, loading } = profileDataStore;
    const params = useParams();
    const departmentState = useSelector((state: any) => state.departmentModule);
    const { departmentsData, selectedDepartment, DndStatus } = departmentState
    const dispatch = useDispatch();
    const { title, Icon, IconSize, path, routes } = props.sidebar;
    const [dndselect, setdndSelect] = React.useState(false);
    const [selectedDepartmentData, setSelectedDepartment] = React.useState<any>({})
    const [open, setOpen] = React.useState(false);
    const [depts, setDepts] = React.useState([])
    const [deptStatus, setDeptStatus] = React.useState(false);
    let location = useLocation();
    const classes = useStyles();

    let navigate: any = useNavigate();
    const dndhandleChange = () => {
        setdndSelect(!dndselect)
        const data: any = {
            "dndStatus": !dndselect
        }
        dispatch(getInitPatchStatus(data))
        dispatch(getInitStatus())
    }

    const handleDeptStatus = (id: any, status: any) => {
        setDeptStatus(!status)
        const data: any = {
            "dndStatus": !status,

        }
        axiosInstance.patch(`/departments/${id}/update-dnd`, data).then((res) => {
            if (res.data) {
                toast.success(res.data.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    style: {
                        borderRadius: "2px",
                        border: "1px solid green"
                    }, autoClose: 1000
                })
                props.getDepartments()
                // console.log("-----------res", res.data)
                dispatch(departmentActionCreators.onChangeDNDstatus(!status))
            }
        }).catch((err) => {
            toast.error(err.response.data.message, { position: toast.POSITION.TOP_RIGHT, autoClose: 1000 })
            if (err.response?.status == 402) {
                window.location.href = "https://newbp.efone.ca"
                sessionStorage.removeItem("user")

            }
        })

    }

    const handleClick = () => {
        setOpen(!open);
        // if (!open) {
        //     props.getDepartments()
        // }

    };
    React.useEffect(() => {
        setDepts(props.depmnts)
    }, [props.depmnts])

    React.useEffect(() => {

        setdndSelect(profileData?.dndStatus)

    }, [loading])
    const AntSwitch = withStyles((theme) => ({
        root: {
            width: 28,
            height: 17,
            padding: 0,
            display: 'flex'
        },
        switchBase: {
            padding: 2,
            color: theme.palette.grey[500],
            '&$checked': {
                transform: 'translateX(12px)',
                color: theme.palette.common.white,
                '& + $track': {
                    opacity: 1,
                    backgroundColor: theme.palette.primary.main,
                    borderColor: theme.palette.primary.main,
                },
            },
        },
        thumb: {
            width: 12,
            height: 12,
            boxShadow: 'none',
        },
        track: {
            border: `1px solid ${theme.palette.grey[500]}`,
            borderRadius: 16 / 2,
            opacity: 1,
            backgroundColor: theme.palette.common.white,
        },
        checked: {},
    }))(Switch);
    const onChangeDeaptemntTab = (id: any) => {

        const filterDepartment = depts.find((depart: any) => depart.id === id)
        // console.log('departmentList', departmentList, 'departmentData', departmentTitle, 'filterDepartment', filterDepartment)

        axiosInstance.get(`/departments/${id}`).then((ress) => {
            if (ress.data.data) {
                setSelectedDepartment(selectedDepartment)
                dispatch(departmentActionCreators.onChangeDepartments(ress.data.data))

            } else {
                //err
            }
        }).catch((err) => {
            toast.error(err.response.data.message, { position: toast.POSITION.TOP_RIGHT, autoClose: 1000 })
            if (err.response?.status == 402) {
                window.location.href = "https://newbp.efone.ca"
                sessionStorage.removeItem("user")

            }
            if (err.response?.status == 401) {
                dispatch(authActionCreators.initRefreshToken())
            }
        })


    }

    // React.useEffect(() => {
    //     if (selectedDepartment) {
    //         const dept: any = depts.find((itm: any) => itm.departmentName === selectedDepartment.name)
    //         onChangeDeaptemntTab(dept.departmentId)
    //     }

    // }, [selectedDepartment])



    return (
        <>

            <ListItem button component={NavLink} to={title !== "Departments" && path} className={title !== "Departments" ? classes.listitemscolor : classes.listitemscolor12} style={{ marginTop: "10px" }}>
                <ListItemIcon className={path === location.pathname ? classes.activeicon : classes.contactIcon} >
                    <Icon size={IconSize} />
                </ListItemIcon>
                {title === "Departments" ?
                    <ListItemText primary={title} className={classes.contactlessLink} onClick={handleClick} /> :
                    <ListItemText primary={title} className={classes.contactLink} onClick={handleClick} />}
                {title === "Departments" ? open ? <ExpandMore onClick={handleClick} /> : <ExpandLess onClick={handleClick} /> : ""}
                {title === "My Activities" ?
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center", fontFamily: 'Work Sans', }

                    }>
                        <AntSwitch
                            size='medium'
                            className={classes.dndOption}
                            checked={dndselect}
                            onChange={dndhandleChange}
                        />
                        <Typography className={classes.dndText}>DND</Typography>
                    </div>
                    : ""
                }

            </ListItem>
            {title === "Departments" ?
                <Collapse in={open} timeout="auto" unmountOnExit>

                    {depts.map((route: any, i: any) => {

                        return (
                            <List component="div" key={i} className={classes.subNav}>

                                <ListItem button className={classes.listitems}  >

                                    <Link to={{ pathname: `/department/${route.departmentName}/${route?.departmentId}` }} className={classes.salesLink}>
                                        <Grid container spacing={2} >
                                            <Grid item md={6} style={{ display: "flex", alignItems: "center", width: "130px" }}
                                                onClick={() => onChangeDeaptemntTab(route?.departmentId)}>
                                                <Badge className={classes.dotstyle1} ></Badge>
                                                <ListItemText primary={route.departmentName} className={classes.contactLinkOne} />
                                            </Grid>
                                            {
                                                route.showDnd === true ?
                                                    <div>
                                                        <Grid item md={2} style={{ display: 'flex', alignItems: 'center' }}>
                                                            <Switch className={classes.dndOption2} name="checkedC"
                                                                onChange={() => handleDeptStatus(route.departmentId, route.dndStatus)}
                                                                checked={route.dndStatus}
                                                            />
                                                            <Typography className={classes.typographycolor} style={{ marginLeft: 20 }}>DND</Typography>
                                                        </Grid>
                                                    </div> : ""
                                            }

                                            <Grid item md={4} >


                                            </Grid>
                                        </Grid>
                                        {/* <Badge className={classes.dotstyle1} ></Badge>
                                        <ListItemText primary={route.departmentName} className={classes.contactLinkOne} /> */}
                                        {/* <ListItemIcon className={classes.dndGroup}> */}
                                        {/* <Switch className={classes.dndOption2} name="checkedC" checked={route.dndStatus} />
                                            <Typography className={classes.typographycolor} style={{marginLeft:20}}>DND</Typography> */}
                                        {/* </ListItemIcon> */}
                                    </Link>

                                </ListItem>

                            </List>
                        )
                    })}
                </Collapse> : ""}
        </>
    )
}

export default SidebarElement
