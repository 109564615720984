import { AuthActions } from '../Actions/Actions'

const authState = {
    isAuthenticated: false,
    userData: null,
    tokenData: null,
    loading: false,
    success: null
}

export const authReducer = (state: any = authState, action: any) => {
    switch (action.type) {
        case AuthActions.INIT_AUTH_SUCCESS:
            return {
                ...state,
                isAuthenticated: true,
                userData: action.data.userData,
                tokenData: action.data.tokenData,
                success: action.data
            }
        case AuthActions.INIT_AUTH_LOADING:
            return {
                ...state,
                loading: action.status
            }
        case AuthActions.INIT_AUTH_LOGOUT_SUCCESS:
            return {
                ...state,
                isAuthenticated: false,
                userData: null,
                tokenData: null,
            }
        default:
            return state
    }
}