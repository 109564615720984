import { Avatar, Checkbox, ClickAwayListener, Divider, FormControl, FormControlLabel, FormGroup, Grow, IconButton, MenuItem, MenuList, Paper, Popper, TableCell, TableRow, Typography, } from '@material-ui/core'
import React from 'react'
import { Link } from 'react-router-dom'
import { useStyles } from '../Styles'
import PhoneCallbackIcon from '@material-ui/icons/PhoneCallback';
import PhoneMissedIcon from '@material-ui/icons/PhoneMissed';
import { HiPhoneOutgoing } from 'react-icons/hi'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import PhoneIcon from '@material-ui/icons/Phone';
import Moment from 'moment'

function TableBodyData(props: any) {
    const classes = useStyles()
    const [open, setOpen] = React.useState(false);
    const anchorRef: any = React.useRef(null);
    const [outboundData, setOutBoundData] = React.useState<any>([])

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event: any) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    function handleListKeyDown(event: any) {
        if (event.key === "Tab") {
            event.preventDefault();
            setOpen(false);
        }
    }
    var data = parseInt(props.item?.createdDate)
    var dataa = Moment(data).format('YYYY MMM DD hh:mm A')
    function toTime(d: any) {
        d = Number(d);
        var h = Math.floor(d / 3600);
        var m = Math.floor(d % 3600 / 60);
        var s = Math.floor(d % 3600 % 60);

        var hDisplay = h > 0 ? h : "00";
        var mDisplay = m > 0 ? m : "00";
        var sDisplay = s > 0 ? s : "00";
        return hDisplay + ":" + mDisplay + ":" + sDisplay;
    }
    return (
        <TableRow className={classes.tablebackgroundcolor} key={props.item.id}>
            <TableCell className={classes.tablecell}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Avatar>{props.item.contactName ? props.item.contactName.charAt(0) : props.item.callerIdName.charAt(0)}</Avatar>
                    <Link to={{ pathname: `/department/userDetails/${'allLogs'}/${props.item?.id}/${props.item?.departmentId?.id}` }} className={classes.linkUnderline}>
                        <Typography className={classes.reschedule}> {props.item.contactName ? props.item.contactName : props.item.callerIdName}<br />
                            <p style={{ fontSize: "10px", float: "right" }}>({props.item.phoneNumber})</p></Typography>
                    </Link>
                </div>
            </TableCell>
            <TableCell className={classes.tablecell}>{props.item.phoneNumber ? props.item.phoneNumber : ""}</TableCell>
            <TableCell className={classes.tablecell}>
                {props.item.isCallDirection == 1 && props.item.duration > 0 ?
                    <div>
                        <IconButton>
                            <PhoneCallbackIcon className={classes.incomingiconcolor} />
                        </IconButton>
                        Incoming Call/{toTime(props.item.duration)}
                    </div>
                    :
                    props.item.isCallDirection == 1 && props.item.duration == 0 ?
                        <div>
                            <IconButton>
                                <PhoneMissedIcon className={classes.missediconcolor} />
                            </IconButton>
                            Missed call
                        </div>
                        :
                        props.item.isCallDirection == 2 ?
                            <div>
                                <IconButton>
                                    <HiPhoneOutgoing className={classes.outgoingcalliconcolor} />
                                </IconButton>
                                {/* {Outgoing/Math.ceil(props.item.duration / 60)} min */}
                                Outgoing/{toTime(props.item.duration)}
                            </div> : ""
                }
            </TableCell>
            {props.item.agentId ?
                <TableCell className={classes.tablecell}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        {/* <img alt="Remy Sharp" src={profile} className={classes.profile} /> */}
                        <Typography className={classes.reschedule}>  {props.item.agentId && props.item.agentId.firstName}  {props.item.agentId && props.item.agentId.lastName}</Typography>
                    </div>
                </TableCell>
                :
                <TableCell className={classes.tablecell}>

                </TableCell>
            }
            <TableCell className={classes.tablecell}>
                <div className={classes.buttonicons}
                >
                    <IconButton
                        onClick={() => props.handleCall(props.item, props.option)}>
                        <PhoneIcon className={classes.iconcolor} />
                    </IconButton>
                    <Divider orientation="vertical" flexItem className={classes.dividericon} />
                    <IconButton onClick={handleToggle} ref={anchorRef}>
                        <ArrowDropDownIcon className={classes.iconcolor} />
                    </IconButton>
                    <Popper
                        open={open}
                        anchorEl={anchorRef.current}
                        role={undefined}
                        transition
                    >
                        {({ TransitionProps, placement }) => (
                            <Grow
                                {...TransitionProps}
                                style={{
                                    transformOrigin:
                                        placement === "bottom" ? "center bottom" : "center bottom",

                                }}
                            >
                                <Paper className={classes.paper}>
                                    <ClickAwayListener onClickAway={handleClose}>
                                        <MenuList
                                            autoFocusItem={open}
                                            id="menu-list-grow"
                                            onKeyDown={handleListKeyDown}
                                        >
                                            <MenuItem>
                                                <FormControl>
                                                    {props.outboundData.map((opt: any, i: any) => {
                                                        return <FormGroup key={i}>
                                                            <FormControlLabel
                                                                control={<Checkbox onChange={() => props.handleChange(opt)} name={opt.id}
                                                                    checked={opt.id == props.option?.id} />}
                                                                label={opt.value + "  (" + opt.name + ")"}
                                                            />

                                                        </FormGroup>
                                                    })}

                                                </FormControl>
                                            </MenuItem>
                                        </MenuList>
                                    </ClickAwayListener>

                                </Paper>
                            </Grow>
                        )}
                    </Popper>
                </div>
            </TableCell>

            <TableCell className={classes.tablecell}>{dataa}</TableCell>
        </TableRow>
    )
}

export default TableBodyData