import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  tablebackgroundcolor: {
    backgroundColor: '#F6F6F6',
  },
  tablecell: {
    borderBottom: '10px',
    padding: '5px',
    color: '#838383',
    fontWeight: 500,
    fontFamily: 'Work Sans',
    textAlign: 'center',
  },
  inconbutton: {
    background: '#2645AD',
    borderRadius: 50,
    height: 30,
    '&:hover': {
      background: '#2645AD',
    }
  },
  text: {
    fontSize: 12,
    color: '#fff',
    fontFamily: 'Work Sans',
  },
  paper: {
    width: 280,
    background: '#F3F5FF',
    boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.25)',
    borderRadius: 5
  },
  items: {
    background: '#EBEBEB',
    color: '#565656',
    fontSize: 14
  },
  icons: {
    color: '#2645AD',
  },
  reschedule: {
    color: '#2645AD',
    marginLeft: 6,
    fontSize: 15,
    fontWeight: 500,
    fontFamily: 'Work Sans',

  },
  tablecellOne: {
    display: 'flex',
    alignItems: 'center',
    borderBottom: '10px',
    padding: '10px',
    color: '#838383',
    fontWeight: 500,
    fontFamily: 'Work Sans',
  },
  richard: {
    marginLeft: 10,
    fontFamily: 'Work Sans',
  },
  contactOne: {
    marginLeft: 10,
  },
  typographytext: {
    textAlign: 'center',
    fontSize: '10px',
    padding: '5px',
    color: '#838383',
    fontWeight: 500,
    fontFamily: 'Work Sans',
  },
  tablemissedcallcell: {
    borderBottom: '10px',
    padding: '5px',
    color: '#AF0000',
    fontWeight: 500,
    fontFamily: 'Work Sans',
    textAlign: 'center',
  },
  buttonicons: {
    height: '26px',
    width: '87px',
    borderRadius: '50px',
    backgroundColor: '#2645AD',
  },
  iconcolor: {
    color: '#FFFFFF',
  },
  dividericon: {
    height: '20px',
    borderRight: '2px solid #FFFFFF',
    marginTop: '14px',
  },
  listalticoncolor: {
    color: '#2645AD',
  },
}));
