import React from 'react';
import {
    Card,
    CardContent,
    Grid,
    Typography,
    Divider,
    withStyles,
    Switch,
    Button,
    Tabs,
    Tab,
    TableContainer,
    Table,
    TableBody,
    TableRow,
    TextField,
    TableCell,
    IconButton,
} from '@material-ui/core';
import { BsRecord2Fill, BsCardText } from 'react-icons/bs'
import { VscMerge } from 'react-icons/vsc'
import { AiOutlineCalendar } from 'react-icons/ai'
import Avatar from '@material-ui/core/Avatar';
import Ellipse from '../../assets/icons/Ellipse.png';
import TabPanel from '@material-ui/lab/TabPanel';
import TabContext from '@material-ui/lab/TabContext';
import { useStyles } from './Styles';
import PhoneCallbackIcon from '@material-ui/icons/PhoneCallback';
import CallEndIcon from '@material-ui/icons/CallEnd';
import PeopleIcon from '@material-ui/icons/People';
import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver';
import VolumeUpIcon from '@material-ui/icons/VolumeUp';
import Blacksearchicon from '../../assets/icons/blacksearchicon.png';
import * as authActionCreators from '../../Store/Actions/AuthActionCreator'
import AppleIcon from '@material-ui/icons/Apple';
import SmsRoundedIcon from '@material-ui/icons/SmsRounded';
import AndroidOutlinedIcon from '@material-ui/icons/AndroidOutlined';
import BallotIcon from '@material-ui/icons/Ballot';
// import { MyactivityMessages } from '../../Dashboard/Messages';
// import { MyactivityCalls } from '../../Dashboard/Calls';
// import { MyactivityFollowup } from '../../Dashboard/Followup';
import MyactivityVoicemail from '../MyActivityPage/index';
import PhoneMissedIcon from '@material-ui/icons/PhoneMissed';
import PhoneIcon from '@material-ui/icons/Phone';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import AddIcCallIcon from '@material-ui/icons/AddIcCall';
import { Badge } from '@material-ui/core';
import ListAltSharpIcon from '@material-ui/icons/ListAltSharp';
import Tooltip from '@material-ui/core/Tooltip';
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import axiosInstance from '../../environment/axiosInstances'
import ReactAudioPlayer from 'react-audio-player';
import { useParams } from "react-router-dom";
import * as DepartmentActionCreators from '../../Store/Actions/DepartmentsActionXCreators'
import { Link } from 'react-router-dom';
import LiveCalls from './LiveCalls/LiveCalls';
import { useSelector, useDispatch } from 'react-redux'
import Messages from './Messages/Messages';
import Calls from './Calls/Calls';
import Voicemail from './Voicemail/Voicemail';
import Agents from './Agents/Agents';
import AllLogs from './AllLogs/AllLogs';
import ReactSelect from 'react-select'
import BlockCalls from './BlockCalls/BlockCalls';
import DepartmentServices from './DepartmentServices'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
function LivecallsTab(props: any) {
    const classes = useStyles();
    const params = useParams();
    const [value, setValue] = React.useState('1');
    const [LiveCallsloading, setLiveCallsLoading] = React.useState<any>(false);
    const [liveCallsData, setLiveCallsData] = React.useState([])
    const [depts, setDepts] = React.useState([])
    const [department, setDepartment] = React.useState<any>({})
    const [id, setId] = React.useState<any>('')
    const [clear, setClear] = React.useState(false)
    const [type, setype] = React.useState<any>(1)
    const [deptStatus, setDeptStatus] = React.useState(false);
    const [searchData, setSearchData] = React.useState<any>({
        searchValue: '',
    })
    const dispatch = useDispatch()
    const departmentStateData = useSelector((state: any) => state.departmentModule);
    const { selectedDepartment, loading, DndStatus } = departmentStateData;
    const handleTabs = (e: any, newValue: any) => {
        setValue(newValue);
    };
    const [state, setState] = React.useState({
        checkedA: true,
        checkedB: true,
        checkedC: true,
    });

    React.useEffect(() => {

        setDeptStatus(DndStatus)

    }, [DndStatus, selectedDepartment])

    const updateDepartmentData = (data: any) => {
        setDeptStatus(data?.dndStatus)
        DepartmentServices.updateDepartmentsData(params.id, data).then((res: any) => {
            //send success message
            if (res.data) {
                getDepartmentsbyId()
                dispatch(DepartmentActionCreators.onChangeDNDstatus(data?.dndStatus))
            }

        }).catch((err: any) => {
            //send error message
        })
    };

    const AntSwitch = withStyles((theme) => ({
        root: {
            width: 28,
            height: 16,
            padding: 0,
            display: 'flex',
        },
        switchBase: {
            padding: 2,
            color: theme.palette.grey[500],
            '&$checked': {
                transform: 'translateX(12px)',
                color: theme.palette.common.white,
                '& + $track': {
                    opacity: 1,
                    backgroundColor: theme.palette.primary.main,
                    borderColor: theme.palette.primary.main,
                },
            },
        },
        thumb: {
            width: 12,
            height: 12,
            boxShadow: 'none',
        },
        track: {
            border: `1px solid ${theme.palette.grey[500]}`,
            borderRadius: 16 / 2,
            opacity: 1,
            backgroundColor: theme.palette.common.white,
        },
        checked: {},
    }))(Switch);

    const fetchLiveCalls = () => {
        setLiveCallsLoading(true)
        axiosInstance.get(`/departments/${params.id}/live-calls`).then((res: any) => {

            if (res.data) {
                setLiveCallsData(res.data.data)
                setLiveCallsLoading(false)
            } else {
                setLiveCallsLoading(false)
            }

        }).catch((err) => {
            setLiveCallsLoading(false)
            if (err.response?.status == 402) {
                window.location.href = "https://newbp.efone.ca"
                sessionStorage.removeItem("user")

            }
            if (err.response?.status == 401) {
                dispatch(authActionCreators.initRefreshToken())
            }
        })
    }

    React.useEffect(() => {
        fetchLiveCalls()
    }, [])




    const handleSearch = () => {
        if (searchData.searchValue !== "") {
            setClear(true)
            if (value == "3") {

            }
        } else {
            setClear(false)
        }
    }

    const onChangeSearchData = (e: any) => {
        setSearchData((prev: any) => {
            return {
                ...prev,
                [e.target.name]: e.target.value,
            }
        })
    }

    const keyOptions = [
        { label: "Today", value: 1 },
        { label: "Yesterday", value: 2 },
        { label: "Last 7 days", value: 3 },
        { label: "Last 30 days", value: 4 },

    ]
    const handleOptions = (e: any) => {
        setype(e.value)
    }
    const fetchDepartments = () => {
        dispatch(DepartmentActionCreators.fetchDepartmentsLoding(true))
        DepartmentServices.getDepartments().then((res) => {
            if (res.success) {
                const departments: any = res.data.data;
                dispatch(DepartmentActionCreators.fetchDepartmentsSuccess(departments))
                if (departments.length > 0) {
                    DepartmentServices.getDepartmentsData(params.id).then((ress) => {
                        if (ress.success) {
                            dispatch(DepartmentActionCreators.onChangeDepartments(ress.data.data))
                        } else {
                            //err
                        }
                    }).catch((err) => {
                        //err
                    })

                }
                dispatch(DepartmentActionCreators.fetchDepartmentsLoding(false))
            } else {
                dispatch(DepartmentActionCreators.fetchDepartmentsLoding(false))
            }
        }).catch((err) => {
            dispatch(DepartmentActionCreators.fetchDepartmentsLoding(false))
        })
    }
    const getDepartmentsbyId = () => {
        DepartmentServices.getDepartmentsData(params.id).then((ress) => {
            if (ress.success) {
                dispatch(DepartmentActionCreators.onChangeDepartments(ress.data.data))
            } else {
                //err
            }
        }).catch((err) => {
            //err

        })
    }
    React.useEffect(() => {
        setId(params?.id)

    }, [])
    React.useEffect(() => {
        if (value === "1") {
            fetchLiveCalls()
        }
    }, [params.id, value])
    React.useEffect(() => {
        getDepartmentsbyId()

        setValue("1")
    }, [params.id])
    return (
        <div>
            <Card>
                <Grid container spacing={3}>
                    <Grid item md={12} sm={12} xs={12}>
                        <CardContent>
                            <Typography className={classes.activitytext}>{selectedDepartment?.name}</Typography>
                            <Grid container spacing={2} className={classes.buttondisplay}>
                                {selectedDepartment?.showDnd ?
                                    <Grid item md={4} lg={6}>
                                        <div className={classes.dnddisplay}>
                                            <Typography className={classes.dndtext}>Do Not Distrub</Typography>
                                            <AntSwitch
                                                className={classes.typographyleft}
                                                checked={deptStatus}
                                                onChange={() => updateDepartmentData({ "dndStatus": !DndStatus })}

                                            />
                                        </div>
                                    </Grid>
                                    :
                                    <Grid item md={4} lg={6}></Grid>
                                }
                                {value !== '1' &&
                                    <Grid item md={4}>
                                        <TextField
                                            variant="outlined"
                                            size="small"
                                            className={classes.textStyles}
                                            fullWidth
                                            value={searchData.searchValue}
                                            onChange={onChangeSearchData}
                                            placeholder="Search with call,all,voicemail..."
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="start">
                                                        <SearchIcon
                                                            onClick={handleSearch}
                                                            style={{ cursor: 'pointer' }}
                                                        />
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </Grid>
                                }
                                {value !== '1' &&
                                    <Grid item xs={4} lg={2} md={4} sm={4} xl={2}>
                                        <ReactSelect
                                            placeholder="Select key"
                                            options={keyOptions}
                                            onChange={handleOptions}
                                            value={keyOptions.filter((opt: any) => opt.value === type)}
                                        />
                                    </Grid>
                                }

                            </Grid>
                            {/* <Grid container spacing={3}>
                                <Grid item md={6}></Grid>
                            </Grid> */}
                            <Divider className={classes.dividercolor} />
                            <TabContext value={value}>
                                <Tabs

                                    value={value}
                                    indicatorColor="primary"
                                    textColor="primary"
                                    onChange={handleTabs}
                                    aria-label="disabled tabs example" className={classes.heightTab}>
                                    <Tab
                                        label="Hold Queue"
                                        value="0"
                                        className={value === '0' ? classes.tabscoloractive : classes.tabscolor}
                                    />
                                    <Tab
                                        label="Live Calls"
                                        value="1"
                                        className={value === '1' ? classes.tabscoloractive : classes.tabscolor}
                                    />
                                    <Tab
                                        label="Agents"
                                        value="2"
                                        className={value === '2' ? classes.tabscoloractive : classes.tabscolor}
                                    />
                                    {/* <Tab
                                        label="Follow Up"
                                        value="3"
                                        className={value === '3' ? classes.tabscoloractive : classes.tabscolor}
                                    /> */}
                                    <Tab
                                        label="All"
                                        value="4"
                                        className={value === '4' ? classes.tabscoloractive : classes.tabscolor}
                                    />
                                    <Tab
                                        label="Calls"
                                        value="5"
                                        className={value === '5' ? classes.tabscoloractive : classes.tabscolor}
                                    />
                                    <Tab
                                        label="Messages"
                                        value="6"
                                        className={value === '6' ? classes.tabscoloractive : classes.tabscolor}
                                    />
                                    <Tab
                                        label="Voice mail"
                                        value="7"
                                        className={value === '7' ? classes.tabscoloractive : classes.tabscolor}
                                    />
                                    <Tab
                                        label="Blocked"
                                        value="8"
                                        className={value === '8' ? classes.tabscoloractive : classes.tabscolor}
                                    />
                                </Tabs>
                                <Divider className={classes.dividercolorTwo} />
                                <TabPanel value="0"></TabPanel>
                                <TabPanel value="1">
                                    <Grid container spacing={3}>
                                        <Grid item md={12}>
                                            <LiveCalls fetchLiveCalls={fetchLiveCalls} LiveCallsloading={LiveCallsloading} data={liveCallsData} />
                                        </Grid>
                                    </Grid>
                                </TabPanel>

                                <TabPanel value='2'>
                                    <Typography>Current Agents</Typography>
                                    <Divider className={classes.dividercolor} />
                                    <Agents type={type} clear={clear} />
                                </TabPanel>

                                <TabPanel value='3'>
                                    <Grid container spacing={3}>
                                        <Grid item md={12}>
                                            <>
                                                <TableContainer>
                                                    <Table>
                                                        <TableBody>
                                                            <TableRow className={classes.tablebackgroundcolor}>
                                                                <TableCell className={classes.tablecell}>
                                                                    <div className={classes.divContent}>
                                                                        <Avatar className={classes.avatarWidth} alt="Remy Sharp" src="/broken-image.jpg">
                                                                            <Typography className={classes.avatarFontsize}>R</Typography>
                                                                        </Avatar>
                                                                        <Typography className={classes.space}>Customer 1</Typography>
                                                                    </div>
                                                                </TableCell>
                                                                <TableCell className={classes.tablecell}>
                                                                    <IconButton>
                                                                        <AiOutlineCalendar className={classes.calenderIcon} />
                                                                    </IconButton>
                                                                    Sechedule for 22 May&nbsp; &nbsp;12:00 pm
                                                                </TableCell>
                                                                <TableCell className={classes.tablecell}>
                                                                    <Tooltip title="Lorem ipsum is simply dummy text of the printing." placement="bottom">
                                                                        <BsCardText className={classes.cardIcon} />
                                                                    </Tooltip>
                                                                </TableCell>
                                                                <TableCell className={classes.tablecell}></TableCell>
                                                                <TableCell className={classes.tablecell}>
                                                                    <div className={classes.divContentOne}>
                                                                        <img src={Ellipse} />
                                                                        <Typography className={classes.space}>Richardson</Typography>
                                                                    </div>
                                                                </TableCell>
                                                                <TableCell className={classes.tablecell}>
                                                                    <Button
                                                                        variant="outlined"
                                                                        className={classes.buttonicons}>
                                                                        <IconButton><PhoneIcon className={classes.iconcolor} /></IconButton>
                                                                        <Divider orientation="vertical" flexItem className={classes.dividericon} />
                                                                        <IconButton><ArrowDropDownIcon className={classes.iconcolor} /></IconButton>
                                                                    </Button>
                                                                </TableCell>
                                                                <TableCell className={classes.tablecell}>Today 4:45pm</TableCell>
                                                            </TableRow>
                                                            <TableRow >
                                                                <TableCell className={classes.tablecell}>
                                                                    <div className={classes.divContent}>
                                                                        <Avatar className={classes.avatarWidth} alt="Remy Sharp" src="/broken-image.jpg">
                                                                            <Typography className={classes.avatarFontsize}>R</Typography>
                                                                        </Avatar>
                                                                        <Typography className={classes.space}>Customer 2</Typography>
                                                                    </div>
                                                                </TableCell>
                                                                <TableCell className={classes.tablemissedcallcell}>
                                                                    <div className={classes.divContentOne}>
                                                                        <PhoneMissedIcon className={classes.missediconcolor} />
                                                                        <Typography className={classes.missedCall}>Missed call</Typography>
                                                                    </div>
                                                                </TableCell>
                                                                <TableCell className={classes.tablecell}>
                                                                    <Tooltip
                                                                        title="Lorem ipsum is simply dummy text of the printing."
                                                                        placement="bottom"
                                                                        aria-label="add"
                                                                    >
                                                                        <BsCardText className={classes.cardIcon} />

                                                                    </Tooltip>
                                                                </TableCell>
                                                                <TableCell className={classes.tablecell}></TableCell>
                                                                <TableCell className={classes.tablecell}>
                                                                    <div className={classes.divContentOne}>
                                                                        <img src={Ellipse} />
                                                                        <Typography className={classes.space}>Richardson</Typography>
                                                                    </div>
                                                                </TableCell>
                                                                <TableCell className={classes.tablecell}>
                                                                    <Button
                                                                        variant="outlined"
                                                                        className={classes.buttonicons}>
                                                                        <IconButton><PhoneIcon className={classes.iconcolor} /></IconButton>
                                                                        <Divider orientation="vertical" flexItem className={classes.dividericon} />
                                                                        <IconButton><ArrowDropDownIcon className={classes.iconcolor} /></IconButton>
                                                                    </Button>
                                                                </TableCell>
                                                                <TableCell className={classes.tablecell}>Today 4:45pm</TableCell>
                                                            </TableRow>
                                                            <TableRow className={classes.tablebackgroundcolor}>
                                                                <TableCell className={classes.tablecell}>
                                                                    <div className={classes.divContent}>
                                                                        <Avatar className={classes.avatarWidth} alt="Remy Sharp" src="/broken-image.jpg">
                                                                            <Typography className={classes.avatarFontsize}>R</Typography>
                                                                        </Avatar>
                                                                        <Typography className={classes.space}>Customer 3</Typography>
                                                                    </div>
                                                                </TableCell>
                                                                <TableCell className={classes.tablecell}>
                                                                    <IconButton>
                                                                        <AiOutlineCalendar className={classes.calenderIcon} />
                                                                    </IconButton>
                                                                    Sechedule for 22 May&nbsp; &nbsp;12:00 pm
                                                                </TableCell>
                                                                <TableCell className={classes.tablecell}>
                                                                    <Tooltip
                                                                        title="Lorem ipsum is simply dummy text of the printing."
                                                                        placement="bottom"
                                                                        aria-label="add"
                                                                    >
                                                                        <BsCardText className={classes.cardIcon} />
                                                                    </Tooltip>
                                                                </TableCell>
                                                                <TableCell className={classes.tablecell}></TableCell>
                                                                <TableCell className={classes.tablecell}>
                                                                    <div className={classes.divContentOne}>
                                                                        <img src={Ellipse} />
                                                                        <Typography className={classes.space}>Richardson</Typography>
                                                                    </div>
                                                                </TableCell>
                                                                <TableCell className={classes.tablecell}>
                                                                    <Button
                                                                        variant="outlined"
                                                                        className={classes.buttonicons}>
                                                                        <IconButton><PhoneIcon className={classes.iconcolor} /></IconButton>
                                                                        <Divider orientation="vertical" flexItem className={classes.dividericon} />
                                                                        <IconButton><ArrowDropDownIcon className={classes.iconcolor} /></IconButton>
                                                                    </Button>
                                                                </TableCell>
                                                                <TableCell className={classes.tablecell}>Today 4:45pm</TableCell>
                                                            </TableRow>
                                                            <TableRow >
                                                                <TableCell className={classes.tablecell}>
                                                                    <div className={classes.divContent}>
                                                                        <Avatar className={classes.avatarWidth} alt="Remy Sharp" src="/broken-image.jpg">
                                                                            <Typography className={classes.avatarFontsize}>R</Typography>
                                                                        </Avatar>
                                                                        <Typography className={classes.space}>Customer 4</Typography>
                                                                    </div>
                                                                </TableCell>
                                                                <TableCell className={classes.tablemissedcallcell}>
                                                                    <div className={classes.divContentOne}>
                                                                        <PhoneMissedIcon className={classes.missediconcolor} />
                                                                        <Typography className={classes.missedCall}>Missed call</Typography>
                                                                    </div>
                                                                </TableCell>
                                                                <TableCell className={classes.tablecell}>
                                                                    <Tooltip title="Lorem ipsum is simply dummy text of the printing.">
                                                                        <BsCardText className={classes.cardIcon} />
                                                                    </Tooltip>
                                                                </TableCell>
                                                                <TableCell className={classes.tablecell}></TableCell>
                                                                <TableCell className={classes.tablecell}>
                                                                    <div className={classes.divContentOne}>
                                                                        <img src={Ellipse} />
                                                                        <Typography className={classes.space}>Richardson</Typography>
                                                                    </div>
                                                                </TableCell>
                                                                <TableCell className={classes.tablecell}>
                                                                    <Button
                                                                        variant="outlined"
                                                                        className={classes.buttonicons}>
                                                                        <IconButton><PhoneIcon className={classes.iconcolor} /></IconButton>
                                                                        <Divider orientation="vertical" flexItem className={classes.dividericon} />
                                                                        <IconButton><ArrowDropDownIcon className={classes.iconcolor} /></IconButton>
                                                                    </Button>
                                                                </TableCell>
                                                                <TableCell className={classes.tablecell}>Today 4:45pm</TableCell>
                                                            </TableRow>
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            </>
                                        </Grid>
                                    </Grid>
                                </TabPanel>

                                <TabPanel value='4'>
                                    <Grid container spacing={3}>
                                        <AllLogs type={type} clear={clear}
                                            coolPhone={props.coolPhone}
                                            callOptions={props.callOptions}
                                            callStatus={props.callStatus} />
                                    </Grid>
                                </TabPanel>

                                <TabPanel value='5'>
                                    <Grid container spacing={3}>
                                        <Grid item md={12}>
                                            <Grid container spacing={3}>
                                                <Calls type={type} clear={clear}
                                                    coolPhone={props.coolPhone}
                                                    callOptions={props.callOptions}
                                                    callStatus={props.callStatus} />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </TabPanel>

                                <TabPanel value="6">
                                    <Grid container spacing={3}>
                                        <Grid item md={12}>
                                            <Messages type={type} clear={clear} />
                                        </Grid>
                                    </Grid>
                                </TabPanel>
                                <TabPanel value='7'>
                                    <Grid container spacing={3}>
                                        <Grid item md={12} sm={12} xs={12}>
                                            <Voicemail type={type} clear={clear} />
                                            {/* <MyactivityVoicemail /> */}
                                        </Grid>
                                    </Grid>
                                </TabPanel>
                                <TabPanel value="8">
                                    <Grid container spacing={3}>
                                        <Grid item md={12} sm={12} xs={12}>
                                            <BlockCalls type={type} clear={clear} />
                                        </Grid>
                                    </Grid>
                                </TabPanel>
                            </TabContext>
                        </CardContent>
                    </Grid>
                </Grid>
            </Card>
        </div >
    )
}
export default LivecallsTab