import { globalPopupActions } from './Actions'

export const initglobalPopup = (message: any, instance: any, popuptype: any) => {
    return {
        type: globalPopupActions.INIT_GLOBAL_ERROR,
        status: true,
        message: message,
        instance: instance,
        popuptype: popuptype
    }
}

export const clearGlobalPopup = () => {
    return {
        type: globalPopupActions.CLEAR_GLOBAL_ERROR
    }
}
