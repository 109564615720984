import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({

    dividercolor2: {
        borderTop: '1px solid #D2D2D2',
        margin: 0,
    },
    heightTab: { height: '69px' },
    activitytext: {
        fontSize: '24px',
        fontWeight: 700,
        fontFamily: 'Work Sans',
        padding: '10px',
    },
    table: {
        tableLayout: 'fixed',
    },
    items: {

    },
    profile: {
        width: 32,
        height: 28,
        background: 'white',
    },
    button4: {
        fontSize: 12,
        fontFamily: 'Work Sans',
        fontStyle: 'normal',
        fontWeight: 500,
        textAlign: 'center',
        letterSpacing: 0.1,
        textTransform: 'none',
        color: '#D1D1D1',
        background: '#FFFFFF',
        borderRadius: '50px',
        border: '0.649129px solid #D1D1D1',
        boxSizing: 'border-box',
        padding: '8px 26px',
        '&:hover': {
            backgroundColor: '#FFFFFF',
        },
        '&:active': {
            backgroundColor: '#FFFFFF',
        },
    },
    dnddisplay: {
        display: 'flex',
    },
    buttondisplay: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    paper: {
        width: 300,
        background: '#F3F5FF',
        boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.25)',
        borderRadius: 5
    },
    reschedule: {
        color: '#2645AD',
        marginLeft: 6,
        fontSize: 15,
        fontWeight: 500,
        fontFamily: 'Work Sans',

    },
    dndtext: {
        fontSize: '14px',
        color: '#565656',
        marginLeft: '10px',
    },
    typographyleft: {
        marginLeft: '10px',
    },
    textStyles: {
        color: '#D1D1D1',
        fontSize: 15,
        fontFamily: 'Work Sans',
        fontStyle: 'normal',
    },
    dividercolor: {
        borderTop: '1px solid #D2D2D2',
        margin: '15px 0',
    },
    dividercolorTwo: {
        borderTop: '1px solid #D2D2D2',
        marginBottom: 10
    },
    tabscoloractive: {
        color: '#2645AD',
        fontSize: '16px',
        fontWeight: 600,
        fontFamily: 'work Sans',
        fontStyle: 'SemiBold',
        textTransform: 'none',
        borderRight: '2px solid #DFDFDF',
    },
    tabscolor: {
        color: '#565656',
        fontSize: '16px',
        fontWeight: 600,
        fontFamily: 'work Sans',
        fontStyle: 'SemiBold',
        textTransform: 'none',
        borderRight: '2px solid #DFDFDF',
    },
    callEnd2: {
        borderWidth: 1,
        borderRadius: 50,
        minWidth: 0,
        padding: 5,
        margin: 10,
        background: 'linear-gradient(180deg, #AD2626 0%, #E91C1C 100%)',
        border: '1px solid #EBEBEB',
        '&:active': {
            backgroundColor: '#D8D8D8',
        },
    },
    userIcon1: {
        width: 25,
        height: 25,
        color: '#FFFFFF',
    },
    liveCallsDiv: {
        display: 'flex',
        alignItems: 'center',
    },
    volumeIcon: {
        borderWidth: 1,
        borderRadius: 50,
        minWidth: 0,
        padding: 5,
        margin: 10,
        background: '#2645AD',
        border: '1px solid #EBEBEB',
        '&:hover': {
            background: '#2645AD',
        },
        '&:active': {
            background: '#2645AD',
        },
    },
    volume: {
        width: 15,
        height: 15,
        color: '#ffffff',
    },
    radioIcon: {
        borderWidth: 1,
        borderRadius: 50,
        minWidth: 0,
        padding: 5,
        margin: 10,
        background: '#DFDFDF',
        border: '1px solid #EBEBEB',
        '&:hover': {
            background: '#DFDFDF',
        },
        '&:active': {
            background: '#DFDFDF',
        },
    },
    recordThree: {
        width: 15,
        height: 15,
        color: 'white',
    },
    rowBackground: {
        background: '#F3F5FF',
    },
    tableContainer: {
        width: '50%',
        boxShadow: '0 0 0.5em #E2E2E2',
        borderRadius: 10,
        borderColor: '#E2E2E2',
    },
    dotstyle2: {
        // right: '16px',
        height: '10px',
        width: '10px',
        borderRadius: '50%',
        // top: '2px',
        background: '#1bff00'
    },
    idleAvailable: {
        marginLeft: 8,
        color: '#565656',
        fontSize: 14,
        fontFamily: 'Work Sans',
        fontWeight: 500,
    },
    dotstyle: {
        // right: '16px',
        height: '10px',
        width: '10px',
        borderRadius: '50%',
        // top: '2px',
        background: '#838383'
    },
    doNotDisturb: {
        marginLeft: 8,
        color: '#565656',
        fontSize: 13,
        fontFamily: 'Work Sans',
        fontWeight: 500,
    },
    listalticoncolor: {
        color: '#2645AD',
    },
    tablemissedcallcell: {
        borderBottom: '10px',
        padding: '5px',
        color: '#AF0000',
        fontWeight: 500,
        fontFamily: 'Work Sans',
        textAlign: 'center',
    },
    people2: {
        borderWidth: 1,
        borderRadius: 50,
        minWidth: 0,
        padding: 5,
        margin: 10,
        background: '#12DC00',
        border: '1px solid #EBEBEB',
        '&:hover': {
            background: '#12DC00',
        },
        '&:active': {
            backgroundColor: '#12DC00',
        },
    },
    volumeIcon2: {
        borderWidth: 1,
        borderRadius: 50,
        minWidth: 0,
        padding: 5,
        margin: 10,
        background: '#0C2DD1',
        border: '1px solid #EBEBEB',
        '&:hover': {
            background: '#0C2DD1',
        },
    },
    recordVoice2: {
        borderWidth: 1,
        borderRadius: 50,
        minWidth: 0,
        padding: 5,
        margin: 10,
        background: '#DE00BB',
        border: '1px solid #EBEBEB',
        '&:hover': {
            background: '#DE00BB',
        },
    },
    tablecell: {
        borderBottom: '10px',
        padding: '5px',
        color: '#838383',
        fontWeight: 500,
        fontFamily: 'Work Sans',
        //textAlign: 'center',
        fontSize: 13,
    },
    tablecell1: {
        borderBottom: '10px',
        padding: '5px',
        color: '#838383',
        fontWeight: 500,
        fontFamily: 'Work Sans',
        //textAlign: 'center',
        fontSize: 14,
    },
    duration: {
        fontFamily: 'Work Sans',
        fontSize: 14,
        fontWeight: 400,
        marginLeft: 5
    },
    week: {
        fontFamily: 'Work Sans',
        fontSize: 15,

    },
    number: {
        fontFamily: 'Work Sans',
    },
    audioPlayer: {
        // display: "block",
        // height: 48,
        // width: "100%",
        // '&.selected': {
        //     color: '#0921a9'
        // },
        // '&:hover': {
        //     color: '#4D72F8'
        // },
        border: '1px solid lightgray',
        borderRadius: 50,
        height: 40
    },

    divContentOne: {
        display: 'flex',
        alignItems: 'center',
        // justifyContent: 'center'

    },
    divContentTwo: {
        display: 'flex',
        alignItems: 'center',
    },
    missediconcolor: {
        color: '#AF0000',
        fontSize: 15,
    },
    outgoingcalliconcolor: {
        color: "#2645AD",
        fontSize: 15,
    },
    buttonicons: {
        height: '26px',
        width: '87px',
        display: 'flex',
        justifyContent: 'center',
        borderRadius: '50px',
        backgroundColor: '#2645AD',
        '&:hover': {
            backgroundColor: '#2645AD',
        },
    },
    iconcolor: {
        color: '#FFFFFF'
    },
    dividericon: {
        // height: '36px',
        borderRight: '2px solid #FFFFFF',
    },
    incomingiconcolor: {
        color: '#B79A00',
        fontSize: 13,
    },
    addcalliconcolor: {
        color: '#00A707',
        fontSize: 15,
    },
    divContent: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: '20px'
    },
    missedCall: {
        marginLeft: 10,
        color: '#AF0000',
        fontSize: 14,
        fontFamily: 'Work Sans',
        alignItems: 'center',
        fontWeight: 500,
    },
    space: {
        marginLeft: 10,
        color: '#565656',
        fontSize: 14,
        fontFamily: 'Work Sans',
        alignItems: 'center',
        padding: '10px 0',
        fontWeight: 500,
    },
    linkUnderline: {
        textDecoration: 'none',
    },
    missedCallColor: {
        marginLeft: 10,
        color: '#AF0000',
        fontSize: 12,
        fontFamily: 'Work Sans',
        alignItems: 'center',

    },
    phonecallmissedicongreen: {
        color: 'green',
        width: 40,
        height: 40,
        padding: 1,
        border: '1px solid green',
        [theme.breakpoints.down('md')]: {
            padding: 4
        },
    },
    tablebackgroundcolor: {
        backgroundColor: '#F6F6F6',
        padding: 8

    },
    avatarFontsize: {
        fontSize: 10,
        color: '#838383',
    },
    avatarWidth: {
        color: '#838383',
        width: 20,
        height: 20,
        fontSize: 5,
    },
    calenderIcon: {
        fontSize: 18,
    },
    cardIcon: {
        fontSize: 20,
        color: '#2645AD'
    },
    typographytext: {
        textAlign: 'center',
        fontSize: '12px',
        padding: '5px',
        color: '#838383',
        fontWeight: 500,
        fontFamily: 'Work Sans',
    },
    agent: {
        color: '#565656',
        fontFamily: 'Work Sans',
        textAlign: 'center',
        fontWeight: 500,
        borderBottom: 10
    },
    phonecallbackiconcolor: {
        color: '#27AE60',
        border: '1px solid #27AE60',
    },
    callBackIcon: {
        width: 20,
        height: 20,
    },
    phonecallmissediconcolor: {
        color: '#2F80ED',
        border: '1px solid #2F80ED',
    },
    linkdisplay: {
        display: 'flex',
        justifyContent: 'space-around',
    },
    messeageUser: {
        fontSize: 12,
        fontFamily: 'Work Sans',
        fontStyle: 'normal',
        fontWeight: 500,
        color: '#565656'
    },
    unlockButton: {
        fontFamily: 'Work Sans',
        fontStyle: 'normal',
        fontWeight: 500,
        letterSpacing: 0.28571,
        background: '#2645AD',
        borderRadius: 50,
        fontSize: 6.85714,
        color: '#ffffff',
        '&:hover': {
            background: '#2645AD',
        },
    }
}));
