import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    topbar: {
        position: 'fixed',
        zIndex: 1024,
        top: 0,
        left: 64,
        right: 0,
        height: 77,
        backgroundColor: '#FFFFFF',
        boxShadow: '2px 4px 4px rgba(0, 0, 0, 0.05)',
        borderBottomLeftRadius: '100px',
        [theme.breakpoints.down('sm')]: {
            left: 0,
            borderBottomLeftRadius: '0px',
            boxShadow: '2px 4px 4px rgba(0, 0, 0, 0.05)',
            height: 75
        }
    },
    wrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '14px 40px',
        [theme.breakpoints.down('sm')]: {
            padding: '14px 40px',
        }
    },
    imageSWdth: {
        [theme.breakpoints.down('sm')]: {
            width: '35px',
            height: '30px'
        }
    },
    chip: {
        //position: 'absolute',
        height: 40,
        // paddingLeft: 5,
        // paddingRight: 10,
        background: '#FFFFFF',
        borderRadius: 20,
        border: '1px solid #D1D1D1',
        fontFamily: 'Work Sans',
        boxSizing: 'border-box',
        '&:hover': {
            background: '#FFFFFF',
        },
        '&:active': {
            background: '#FFFFFF',
        },
        [theme.breakpoints.down('sm')]: {
            height: '35px',
            // marginLeft: 10,
        }
    },
    avatar: {
        fontSize: 12,
        padding: 15,
        fontFamily: 'Work Sans',
        fontStyle: 'normal',
        fontWeight: 'bold',
        color: '#C6C6C6',
        background: '#F3F5FF',
        letterSpacing: 0.208955,
        textTransform: 'none',
        marginRight: '10px !important',
        width: "3px", height: "3px"
    },
    startIcon: {
        top: "9px",
        left: "33px",
        color: "#2645AD",
        bottom: "0",
        position: "absolute",
        fontSize: "22px",
        alignItems: "center",
        borderTopLeftRadius: "20px",
        borderTopRightRadius: "1px",
        borderBottomLeftRadius: "20px",
        borderBottomRightRadius: "1px",
        [theme.breakpoints.down('sm')]: {
            top: "6px",
            fontSize: '20px',
            left: "24px",

        }
    },
    endIc: {
        color: "#2645AD",
        right: "28px",
        position: "absolute",
        fontSize: "20px",
        [theme.breakpoints.down('sm')]: {
            right: "25px",
            fontSize: '18px',
        }
    },
    chipIcon: {
        width: 15,
        height: 10,
        // marginLeft: 10,
    },
    button: {
        position: 'relative',
        width: 170,
        borderWidth: 1,
        borderRadius: 20,
        paddingLeft: 40,
        fontSize: 14,
        textTransform: 'none',
        color: '#4D72F8',
        backgroundColor: '#FFFFFF',
        borderColor: '#4D72F8',
        marginLeft: 'auto',
        marginRight: 40,
    },
    msg: {
        position: "absolute",
        marginTop: "-10px",
        backgroundColor: "red",
        borderRadius: "50%",
        color: "white",
        fontSize: "12px",
        padding: "2px",
        marginLeft: "-8px",
    },
    connectedbutton: {
        borderRadius: '50px',
        width: '167px',
        height: '40px',
        backgroundColor: '#F6F6F6',
        textTransform: 'none',
        fontSize: '16px',
        color: '#00A707',
        fontFamily: 'Work Sans',
        [theme.breakpoints.down('sm')]: {
            // width: '35px',
            height: '35px',
            // marginLeft: '10px',
            fontSize: '15px',
        }
    },
    Notconnectedbutton: {
        borderRadius: '50px',
        width: '167px',
        height: '40px',
        fontFamily: 'Work Sans',
        backgroundColor: '#F6F6F6',
        textTransform: 'none',
        fontSize: '13px',
        color: 'red',
        [theme.breakpoints.down('sm')]: {
            // width: '35px',
            height: '35px',
            // marginLeft: '10px',
            fontSize: '15px',
        }
    },
    warningbutton: {
        borderRadius: '50px',
        width: '167px',
        height: '40px',
        backgroundColor: '#F6F6F6',
        textTransform: 'none',
        fontFamily: 'Work Sans',
        fontSize: '16px',
        color: 'yellow',
        [theme.breakpoints.down('sm')]: {
            // width: '35px',
            height: '35px',
            // marginLeft: '10px',
            fontSize: '15px',
        }
    },
    dialler: {
        position: 'absolute',
        left: 0,
        top: 0,
        bottom: 0,
        padding: '3px 10px 3px 16px',
        backgroundColor: '#F3F5FF',
        borderTopLeftRadius: 20,
        borderBottomLeftRadius: 20,
        borderTopRightRadius: 1,
        borderBottomRightRadius: 1,
    },
    staricon: {
        position: 'absolute',
        left: 35,
        top: '9px',
        bottom: 0,
        borderTopLeftRadius: 20,
        borderBottomLeftRadius: 20,
        borderTopRightRadius: 1,
        borderBottomRightRadius: 1,
        color: '#2645AD',
        alignItems: 'center',
        fontSize: 20,
    },
    dividericon: {
        height: '39px',
        borderRight: '0px solid #DFDFDF',
        [theme.breakpoints.down('sm')]: {
            height: '35px',
        }
    },
    phoneicon: {
        position: 'absolute',
        right: '28px',
        color: '#2645AD',
        fontSize: 20,
    },
    divdisplay: {
        display: 'flex',
        alignItems: 'center',
    },
    settingright: {
        marginRight: '20px',
        fontSize: 22,
    },
    rightIcons: {
        fontSize: "22px",
        marginRight: "20px",
        [theme.breakpoints.down('sm')]: {
            marginRight: "0px",
            marginLeft: 10,
        }
    },
    notIcon: {
        margin: 0,
        padding: 0,
        fontSize: "22px",
        boxSizing: "border-box"
    },
    paper: {
        position: 'absolute',
        backgroundColor: theme.palette.background.paper,
        outline: 0, // Disable browser on-focus borders
        boxShadow: theme.shadows[5],
    },
    divider: {
        margin: 5,

        backgroundColor: '#E2E2E2',
    },
    notification: {
        color: '#565656',
        fontFamily: 'Work Sans',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: 16,
    },
    microphonetext: {
        fontFamily: 'Work Sans',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: 14,
        color: '#838383',
        marginTop: 5,
    },
    microphone: {
        fontFamily: 'Work Sans',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: 11,
        color: '#838383',
        marginTop: 5,
    },
    typogaphyText: {
        color: '#838383',
        fontFamily: 'Work Sans',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: 14,
    },
    speakerHeadset: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginTop: 9,
    },
    speakerSounds: {
        fontFamily: 'Work Sans',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: 14,
        color: '#838383'
    },
    test: {
        fontFamily: 'Work Sans',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: 16,
        color: '#2645AD'
    },
    selectBox: {
        marginTop: 2,
    },
    videoCamera: {
        color: '#838383',
        fontFamily: 'Work Sans',
        fontStyle: 'normal',
        fontWeight: 600,
        marginTop: 12,
    },
    noCamera: {
        fontFamily: 'Work Sans',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: 11,
        color: '#838383',
        marginTop: 3,
    },
    network: {
        marginTop: 12,
        color: '#2645AD',
        fontFamily: 'Work Sans',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: 15,
    },
    feedbackDiv: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    sendFeedback: {
        color: '#565656',
        fontFamily: 'Work Sans',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: 15,
    },
    feedbackIcon: {
        color: '#838383',
        fontSize: 15,

    },
    playRinging: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    sounds: {
        fontFamily: 'Work Sans',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: 14,
        color: '#838383'
    },
    ringtone: {
        fontFamily: 'Work Sans',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: 16,
        color: '#838383',
    },
    switchDiv: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    arrowright: {
        marginRight: '60px',
        curosr: "pointer",
        [theme.breakpoints.down('sm')]: {
            marginRight: '0px',
            marginLeft: 10
        }

    },
    root: {
        maxWidth: 300,
        marginLeft: 300,
        marginTop: -20,
        border: '1px solid #F8F8F8',
        borderRadius: 20,
        boxSizing: 'border-box',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        backgroundColor: '#FFFFFF',
    },
    listButton: {
        '&.active': {
            background: '#2455BB',
            color: 'white',
        },
    },
    group: {
        justifyContent: 'center',
        fontSize: 20,
        color: '#d1d6de',
    },
    icon: {
        fontSize: 20,
    },
    listItem: {
        justifyContent: 'flex-end',
        fontSize: 20,
    },
}));
