import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  button4: {
    left: '281px',
    fontSize: 12,
    fontFamily: 'Work Sans',
    fontStyle: 'normal',
    fontWeight: 500,
    textAlign: 'center',
    letterSpacing: 0.1,
    textTransform: 'none',
    color: '#D1D1D1',
    background: '#FFFFFF',
    borderRadius: '50px',
    border: '0.649129px solid #D1D1D1',
    boxSizing: 'border-box',
    padding: '8px 26px',
    '&:hover': {
      backgroundColor: '#FFFFFF',
    },
    '&:active': {
      backgroundColor: '#FFFFFF',
    },
  },
  addcontactbutton: {
    // left: '82%',
    marginTop: '15px',
    fontSize: 14,
    fontFamily: 'Work Sans',
    fontStyle: 'normal',
    fontWeight: 500,
    textAlign: 'center',
    letterSpacing: 0.1,
    textTransform: 'none',
    color: '#FFFFFF',
    background: '#2645AD',
    borderRadius: '50px',
    border: '0.649129px solid #D1D1D1',
    boxSizing: 'border-box',
    padding: '6px 26px',
    '&:hover': {
      color: '#FFFFFF',
      background: '#2645AD',
    },
  },
  addcontactbutton1: {
    left: '13px',
    color: ' #2645AD',
    // border: '0.649129px solid #D1D1D1',
    padding: '8px 26px',
    fontSize: '12px',
    background: '#DFDFDF',
    boxSizing: 'border-box',
    fontStyle: 'normal',
    marginTop: '15px',
    textAlign: 'center',
    fontFamily: 'Work Sans',
    fontWeight: 500,
    letterSpacing: '0.1px',
    textTransform: 'none',
  },
  buttonpadding: {
    // padding: '10px',
  },
  typographydisplay: {
    display: 'flex',
    padding: '10px',
  },
  activitytext: {
    fontSize: '24px',
    fontWeight: 600,
    fontFamily: 'Work Sans',
    // padding: '10px',
    color: '#565656',
  },
  dividercolor: {
    borderTop: '1px solid #D2D2D2',
    // marginTop: '10px',
  },
  addBox: {
    backg: '#FFFFFF',
  },
  tablebackgroundcolor: {
    backgroundColor: '#F6F6F6',
  },
  tablecellOne: {
    display: 'flex',
    alignItems: 'center',
    borderBottom: '10px',
    padding: '10px',
    color: '#838383',
    fontWeight: 500,
    fontFamily: 'Work Sans',
  },
  contactOne: {
    marginLeft: 10,
  },
  deviceWidth: {
    width: 30,
    height: 30,
    borderRadius: 50,
    color: 'black',
    // backgroundColor: '#F3F5FF',
    // fill:'#4D72F8',
    padding: '8px',
  },
  tablecell: {
    borderBottom: '10px',
    padding: '10px',
    color: '#838383',
    fontWeight: 500,
    fontFamily: 'Work Sans',
  },
  buttonicons: {
    height: '26px',
    display: "flex",
    width: '87px',
    borderRadius: '50px',
    backgroundColor: '#2645AD',
  },
  dividericon: {
    height: 20,
    borderRight: '2px solid #FFFFFF',
    marginLeft: '11px',
    marginTop: "2px",
    marginRight: '10px',
  },
  iconcolor: {
    color: '#FFFFFF',
  },
  deleteButton: {
    margin: 10,
    background: '#4D72F8',
    border: '1px solid #AEAEAE',
    boxSizing: 'border-box',
    borderRadius: 5,
    fontFamily: 'Work Sans',
    fontStyle: 'normal',
    fontWeight: 500,
    padding: '6px 22px',
    color: '#FFFFFF',
    fontSize: 14,
    '&:hover': {
      background: '#4D72F8',
      color: '#FFFFFF',
    }
  },
  CancelButton: {
    fontFamily: 'Work Sans',
    fontWeight: 500,
    marginLeft: 15,
    background: '#EFEFEF',
    border: '1px solid #AEAEAE',
    borderRadius: 5,
    color: '#8392AB',
    fontSize: 14,
    padding: '6px 22px',
  },
  paper: {
    position: 'absolute',
    border: '1px solid #E5E5E5',
    borderRadius: 5,
    background: '#FFFFFF',
  },
  divider: {
    margin: 5,
    backgroundColor: '#E2E2E2',
  },
  typographymodal: {
    color: '#565656',
    fontWeight: 700,
    fontSize: '26px',
    fontFamily: 'Work Sans',
  },
  contacttypography: {
    color: '#565656',
    fontSize: '16px',
    fontWeight: 600,
    padding: '13px',
    paddingBottom: '0px',
  },
  insertbody: {
    color: '#565656',
    fontSize: '12px',
    fontWeight: 500,
    paddingBottom: '10px',
    marginLeft: '13px',
  },
  contacttextfield: {
    color: '#BEBEBE',
    left: '12px',
    width: '96%',
  },
}));
