import React from 'react'
import {
    Card,
    CardContent,
    Grid,
    Typography,
    Divider,
    Button,
    TableContainer,
    Table,
    TableBody,
    TableRow,
    TableCell,
    IconButton,
    Popper,
    Grow,
    Paper,
    ClickAwayListener,
    MenuList,
    MenuItem,
    FormGroup,
    FormControlLabel,
    FormControl,

} from '@material-ui/core';
import { BsThreeDots } from "react-icons/bs";
import { useStyles } from './styles';
import PhoneIcon from '@material-ui/icons/Phone';
import Customer from '../../assets/icons/customer.png';
import Moment from 'moment'
import { MdDelete } from "react-icons/md"
import Checkbox from '@material-ui/core/Checkbox';
import { AiFillEdit } from "react-icons/ai";
import { Window, WindowActionsEvent } from "@progress/kendo-react-dialogs";
import OutgoingCall from '../../Components/SidebarComponent/SidebarElement/Dialpad/CallsModal/OutgoingCall'
import { useDispatch, useSelector } from "react-redux";
import * as CallActionCreator from '../../Store/Actions/CallActionCreator'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
export default function Tablebdy(props: any) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const callingData: any = useSelector((state: any) => state.CallingData);
    const [open, setOpen] = React.useState(false);
    const [callModal, setCallModal] = React.useState(false)
    const anchorRef: any = React.useRef(null);
    const numberRef: any = React.useRef(null);
    const [windowStage, setWindowStage] = React.useState("MAXIMISED");

    const [option, setOption] = React.useState<any>()
    const [openTog, setOpenTog] = React.useState(false);
    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event: any) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };
    var data = parseInt(props.item.createdDate)
    var dataa = Moment(data).format('DD/MM/YYYY ')

    function handleListKeyDown(event: any) {
        if (event.key === "Tab") {
            event.preventDefault();
            setOpen(false);
        }
    }
    const handleCall = (num: any, option: any) => {

        handleCallModal()
        if (callingData?.callData && callingData?.callData?.phoneNumber !== "") {
            toast.error("Already you are in call", { position: toast.POSITION.TOP_RIGHT, autoClose: 2000 })
        }
        else {
            const oNumber = option.id ? option.id : ""
            const oName = option.name ? option.name : ""
            var Options: any = props.callOptions
            Options.extraHeaders = [`oNumber:${oNumber}`, `oName: ${oName} `]
            const data = {
                phoneNumber: props.item.phoneNumber,
                name: props.item.name,
                options: Options
            }
            dispatch(CallActionCreator.fetchStatus(data))
            dispatch(CallActionCreator.fetchData(true))


        }
    }
    const handleCallModal = () => {
        setCallModal(!callModal)
    }
    const handleStageChange = (e: any) => {
        setWindowStage(e.state);

    };


    const handleChange = (val: any) => {
        setOption(val)
    }
    React.useEffect(() => {
        setOption(props.option)
    }, [props.option])
    const handleCloseTog = (event: any) => {
        if (numberRef.current && numberRef.current.contains(event.target)) {
            return;
        }
        setOpenTog(false);
    }
    const handleToggleContacts = () => {
        setOpenTog((prevOpen) => !prevOpen)
    }
    return (
        <>
            {/* {
                callModal && (
                    <div style={{ padding: 0 }}>
                        <Window
                            title={""}
                            stage={windowStage}
                            onStageChange={handleStageChange}
                        >

                            <OutgoingCall
                                coolPhone={props.coolPhone}
                                callOptions={props.callOptions}
                                callModal={handleCallModal} data={newData}
                                callStatus={props.callStatus} />

                        </Window>
                    </div>

                )
            } */}
            <TableRow className={classes.tablebackgroundcolor} key={props.item.id}>
                <TableCell className={classes.tablecell}>
                    <div className={classes.contactDiv}>
                        <img src={Customer} alt="Customer" />
                        <Typography className={classes.contact}> {props.item.phoneNumber}</Typography>


                    </div>
                </TableCell>
                <TableCell className={classes.tablecell}>{props.item.name}</TableCell>
                {/* <TableCell className={classes.tablecell}>PhoneNumber : {props.item.phoneNumber}</TableCell> */}
                <TableCell className={classes.tablecell}>
                    <div
                        className={classes.buttonicons}
                    >
                        <PhoneIcon className={classes.iconcolor} style={{ marginLeft: "11px", marginTop: "2px", cursor: "pointer" }} onClick={() => handleCall(props.item.phoneNumber, option)} />
                        <Divider orientation="vertical" flexItem className={classes.dividericon} />
                        <IconButton onClick={handleToggleContacts} ref={numberRef}>
                            <ArrowDropDownIcon className={classes.iconcolor} style={{ marginLeft: "-11px" }} />
                        </IconButton>
                        <Popper
                            open={openTog}
                            anchorEl={numberRef.current}
                            role={undefined}
                            transition
                        >
                            {({ TransitionProps, placement }) => (
                                <Grow
                                    {...TransitionProps}
                                    style={{
                                        transformOrigin:
                                            placement === "bottom" ? "center bottom" : "center bottom",

                                    }}
                                >
                                    <Paper className={classes.paper}>
                                        <ClickAwayListener onClickAway={handleCloseTog}>
                                            <MenuList
                                                autoFocusItem={open}
                                                id="menu-list-grow"
                                                onKeyDown={handleListKeyDown}
                                            >


                                                <MenuItem>
                                                    <FormControl>
                                                        {props.outboundData.map((opt: any, i: any) => {
                                                            return <FormGroup key={i}>
                                                                <FormControlLabel
                                                                    control={<Checkbox onChange={() => handleChange(opt)} name={opt.id}
                                                                        checked={opt.id == option?.id} />}
                                                                    label={opt.value + "  (" + opt.name + ")"}
                                                                />

                                                            </FormGroup>
                                                        })}

                                                    </FormControl>
                                                </MenuItem>

                                            </MenuList>
                                        </ClickAwayListener>

                                    </Paper>
                                </Grow>
                            )}
                        </Popper>
                    </div>
                </TableCell>
                <TableCell className={classes.tablecell}>Created On : {props.item.createdDate ? dataa : ""}</TableCell>
                <TableCell className={classes.tablecell}>
                    <Button
                        ref={anchorRef}
                        aria-controls={open ? "menu-list-grow" : undefined}
                        aria-haspopup="true"
                        onClick={handleToggle}
                    >
                        <BsThreeDots />
                    </Button>
                    <Popper
                        open={open}
                        anchorEl={anchorRef.current}
                        role={undefined}
                        transition
                    >
                        {({ TransitionProps, placement }) => (
                            <Grow
                                {...TransitionProps}
                                style={{
                                    transformOrigin:
                                        placement === "bottom" ? "center top" : "center bottom",
                                }}
                            >
                                <Paper>
                                    <ClickAwayListener onClickAway={handleClose}>
                                        <MenuList
                                            autoFocusItem={open}
                                            id="menu-list-grow"
                                            onKeyDown={handleListKeyDown}
                                        >
                                            <MenuItem onClick={() => props.handlePopupModalUpdate(props.item.id)}><AiFillEdit className={classes.deviceWidth} />
                                                Edit
                                            </MenuItem>
                                            <MenuItem onClick={() => props.handlePopupDeleteFavourites(props.item.id)}>
                                                <MdDelete className={classes.deviceWidth} />
                                                Delete
                                            </MenuItem>
                                        </MenuList>
                                    </ClickAwayListener>

                                </Paper>
                            </Grow>
                        )}
                    </Popper>

                </TableCell>
            </TableRow>
        </>
    )
}
