import React from 'react'
import ReactDom from 'react-dom'
import './modal.css'

function Backdrop(props: any) {
    const modalBody: any = (
        <div className="backdrop_container" onClick={props.handlePopupModal}>
            
        </div>
    )

    const domElement: any = document.getElementById('modal_popup_root');

    return ReactDom.createPortal(modalBody, domElement)
}

export default Backdrop
