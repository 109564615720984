import { makeStyles, withStyles, Slider } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  tablecell: {
    borderBottom: '10px',
    padding: '5px',
    color: '#838383',
    fontWeight: 500,
    fontFamily: 'Work Sans',
  },
  linkUnderline: {
    textDecoration: 'none',
  },
  table: {
    tableLayout: 'fixed',
  },
  tablebackgroundcolor: {
    backgroundColor: '#F6F6F6',
  },
  prettoslider: {
    borderBottom: '10px',
    padding: '5px',
    color: '#838383',
    fontWeight: 500,
    fontFamily: 'Work Sans',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  audioPlayer: {
    border: '1px solid lightgray',
    borderRadius: '50px',
    height: 40,
  },
}));
export const PrettoSlider = withStyles({
  root: {
    color: '#4D72F8',
    width: '75%',
    marginLeft: 10,
  },
  thumb: {
    height: 28,
    width: 28,
    backgroundColor: '#fff',
    border: '2px solid currentColor',

    marginLeft: -12,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  track: {
    height: 20,
    borderRadius: 14,
  },
  rail: {
    height: 20,
    borderRadius: 14,
  },
})(Slider);
