import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    notification: {
        color: '#565656',
        fontFamily: 'Work Sans',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: 16,  
    },
    typogaphyText: {
        color: '#838383',
        fontFamily: 'Work Sans',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: 14,
    },
    switchDiv: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    divider: {
        marginTop: 3,
        //  width:'100%',
        backgroundColor: '#E2E2E2',
    },
    playRinging: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    sounds: {
        fontFamily: 'Work Sans',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: 14,
        color: '#838383'
    },
    test: {
        fontFamily: 'Work Sans',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: 16,
        color: '#2645AD'
    },
    selectBox: {
        marginTop: 5,
    },
    ringtone: {
        marginTop: 8,
        fontFamily: 'Work Sans',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: 16,
        color: '#838383',
    },
     microphonetext: {
        fontFamily: 'Work Sans',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: 14,
        color: '#838383',
        marginTop: 5,
    },
    microphone: {
        fontFamily: 'Work Sans',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: 11,
        color: '#838383',
        marginTop: 5,
    },
    speakerHeadset: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginTop: 9,
    },
    speakerSounds: {
        fontFamily: 'Work Sans',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: 14,
        color: '#838383'
    },
    videoCamera: {
        color: '#838383',
        fontFamily: 'Work Sans',
        fontStyle: 'normal',
        fontWeight: 600,
        marginTop: 12,
    },
    noCamera: {
        fontFamily: 'Work Sans',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: 11,
        color: '#838383',
        marginTop: 3,
    },
    network: {
        marginTop: 12,
        color: '#2645AD',
        fontFamily: 'Work Sans',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: 15,
    },
    feedbackDiv: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    sendFeedback: {
        color: '#565656',
        fontFamily: 'Work Sans',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: 15,
    },
}))