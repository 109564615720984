import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    textArea: {
        marginTop: 30,
        width: '100%',
        border: '1px solid #838383',
        background: '#FFFFFF',
        boxSizing: 'border-box',
        padding: '10px',
        fontWeight: 500,
        fontFamily: 'Work Sans',
        fontStyle: 'normal',
    },
    button: {
        fontWeight: 500,
        fontFamily: 'Work Sans',
        fontStyle: 'normal',
        marginTop: 26,
        marginLeft: '49%',
        borderRadius: 18,
        color: '#DFDFDF',
        background: '#2645AD',
        height: '50%',
        width: '50%',
        '&:hover': {
            color: '#DFDFDF',
            background: '#2645AD',
        }
    },
    buttonOne: {
        fontWeight: 500,
        fontFamily: 'Work Sans',
        fontStyle: 'normal',
        marginTop: 26,
        marginLeft: '30%',
        borderRadius: 18,
        color: '#DFDFDF',
        background: '#2645AD',
        height: '60%',
        width: '60%',
        '&:hover': {
            color: '#DFDFDF',
            background: '#2645AD',
        }
    },
    routeCall: {
        fontWeight: 500,
        fontFamily: 'Work Sans',
        fontStyle: 'normal',
        color: '#838383',
        fontSize: 15,
    },
    routeType: {
        fontWeight: 500,
        fontFamily: 'Work Sans',
        fontStyle: 'normal',
        color: '#838383',
        fontSize: 13,
    },
    cardAlignment: {
        padding: 20,
        //margin: '20px 0px 20px 0px',
        borderRadius: 10,
        background: '#FFFFFF',
        boxShadow: '0 0 0.5em #E2E2E2',
    },
    cardBackground: {
        background: '#F3F5FF',
        borderRadius: 10,
        width: '100%',
    },
    dividercolor: {
        borderTop: '1px solid #D2D2D2',
        margin: '20px 0',
    },
    cardContent1: {
        display: 'flex',
        alignItems: 'center',
    },
    avatar: {
        background: '#2645AD',
        color: '#F3F5FF',
        fontSize: 13,
        fontWeight: 600,
    },
    align: {
        textAlign: 'center',
    },
    headSmall: {
        fontSize: '20px',
        margin: 5,
        fontWeight: 600,
        color: '#565656',
        textAlign: 'left',
    },
    othersText: {
        fontSize: 12,
        color: '#838383',
        fontWeight: 500,
        fontFamily: 'Work Sans',
        fontStyle: 'normal',
    },
    divButtons: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        marginTop: '18%',
        marginRight: 16,
    },
    userIcon: {
        fontSize: 25,
        alignItems: 'center',
        color: '#2645AD',
        marginRight: 10,
    },
    buttonicons: {
        height: '26px',
        width: '87px',
        borderRadius: '50px',
        backgroundColor: '#2645AD',
        '&:hover': {
            backgroundColor: '#2645AD',
        },
    },
    iconcolor: {
        color: '#FFFFFF',
        fontSize: '20px'
    },
    dividericon: {
        height: 20,
        borderRight: '2px solid #FFFFFF',
        marginLeft: '10px',
        marginRight: '10px',
    },
    paper: {
        position: 'absolute',
        backgroundColor: theme.palette.background.paper,
        outline: 0, // Disable browser on-focus borders
        boxShadow: theme.shadows[5],
    },
    divAlignment: {
        display: 'flex',
        alignItems: 'center',
    },
    space: {
        marginLeft: 14,
        color: '#565656',
        fontSize: 14,
        fontFamily: 'Work Sans',
        alignItems: 'center',
    },
    time: {
        marginLeft: 18,
        color: '#565656',
        fontSize: 12,
    },
    playIcon: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: 40,
        background: '#EBEBEB',
        padding: 7,
        borderRadius: 10,
        width: 150,
    },
    callIcon: {
        // width: 35,
        // height: 35,
        padding: 5,
        fontSize: 19,
        background: '#DD0000',
        '&:hover': {
            background: '#DD0000',
        }
    },
    callEndIcon: {
        color: '#FFFFFF',
    },
    callEndSpace: {
        marginLeft: 20,
    },
    callEndedText: {
        color: '#565656',
        fontSize: 12,
        fontFamily: 'Work Sans',
        fontStyle: 'normal',
        fontWeight: 500,
    },
    playCircleIcon: {
        color: '#2645AD',
    },
    popupWidth: {
        width: 360,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    timeContentOne: {
        color: '#838383',
        fontSize: 12,
        marginLeft: 6,
    },
    playlistIcons: {
        display: 'flex',
        alignItems: 'center',
    },
    pauseIcons: {
        display: 'flex',
        alignItems: 'center',
    },
    pauseCircleicon: {
        fontSize: 30,
        color: '#2645AD',
    },
    downloadIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    download: {
        marginLeft: 5,
        marginRight: 5,
        color: '#2645AD',
    },
    downloadOne: {
        marginLeft: 15,
        marginRight: 5,
        color: '#2645AD',
    },
    divider: {
        borderTop: '2px solid #D2D2D2',
        marginTop: 5,
    },
    checkboxContent: {
        color: '#838383',
        fontSize: 12,
    },
    CardBackground: {
        background: '#F7F7F7',
        boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.25)',
        borderRadius: 5,
        padding: 8,
    },
    playlist: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    createPlaylist: {
        display: 'flex',
        alignItems: 'center',
        margin: 5,
    },
    playlistMargin: {
        marginLeft: 20,
        color: '#2645AD',
        fontSize: 15,
        fontFamily: 'Work Sans',
        fontStyle: 'normal',
        fontWeight: 600,
        letterSpacing: 0.5,
    },
    angledown: {
        color: '#838383',
    },
    cancelButtonOne: {
        background: '#DFDFDF',
        color: '#565656',
        borderRadius: 20,
        width: '40%',
        '&:hover': {
            background: '#DFDFDF',
            color: '#565656',
        }
    },
    saveButtonOne: {
        background: '#2645AD',
        color: '#DFDFDF',
        borderRadius: 20,
        fontSize: 14,
        marginLeft: 20,
        width: '40%',
        '&:hover': {
            background: '#2645AD',
            color: '#DFDFDF',
        }
    },
    divAlignmentRight: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
    },
    reverseTime: {
        color: '#565656',
        fontSize: 12,
        marginRight: 18,
    },
    spaceRight: {
        marginRight: 14,
        color: '#565656',
        fontSize: 16,
        fontFamily: 'Work Sans',
        alignItems: 'center',
        fontWeight: 600,
    },
    subDiv: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        marginRight: 40,
    },
    doubleCheck: {
        fontSize: 20,
        marginRight: 10,
        color: '#2645AD',
    },
    checkText: {
        color: '#565656',
        fontFamily: 'Work Sans',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: 12,
    },
    subDivLeft: {
        display: 'flex',
        alignItems: 'center',
    },
    checkTextLeft: {
        color: '#565656',
        fontFamily: 'Work Sans',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: 12,
        marginLeft: 40,
    },
    editIcon: {
        marginLeft: 10,
    },
    sendIcon: {
        background: '#DFDFDF',
    },
    sendIconColor: {
        color: '#565656',
    },
    cirleFilled: {
        color: '#838383',
        float: 'right'
    },
    editContact: {
        display: 'flex',
        textAlign: 'center',
        justifyContent: 'felx-end',
        marginTop: 50,
        marginLeft: 110,
    },
    editContentText: {
        fontSize: 10,
        fontWeight: 500,
        fontFamily: 'Work Sans',
        fontStyle: 'normal',
        color: '#2645AD'
    },
    editIconSpace: {
        marginLeft: 10,
        color: '#2645AD'
    },
    userProfileIcons: {
        display: 'flex',
        alignItems: 'center',
        marginTop: 5,
    },
    bxUserIcon: {
        fontSize: '25',
        color: '#2645AD',
    },
    about: {
        fontSize: 15,
        color: '#565656',
        fontFamily: 'Work Sans',
        fontStyle: 'normal',
        fontWeight: 600,
        marginLeft: 14,
    },
    number: {
        color: '#565656',
        fontSize: 12,
        // marginLeft: 40,
        // marginTop: 6,
        fontFamily: 'Work Sans',
        fontStyle: 'normal',
        fontWeight: 600,
    },
    noteIcons: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    schudleSendIcon: {
        fontSize: 22,
        color: '#2645AD',
    },
    noteSchudle: {
        marginRight: 110,
        fontFamily: 'Work Sans',
        fontStyle: 'normal',
        fontWeight: 600,
        color: '#2645AD',
        fontSize: 16,
    },
    noteSchudleOne: {
        marginLeft: 20,
        fontFamily: 'Work Sans',
        fontStyle: 'normal',
        fontWeight: 600,
        color: '#2645AD',
        fontSize: 16,
    },
    angleIcon: {
        color: '#2645AD',
    },
    starIcon: {
        display: 'flex',
        justifyContent: 'flex-end',
        color: '#DE9300',
        fontSize: 20,
        float: 'right',
        position: 'relative',
        top: '10px',
        right: '10px',
    },
    richrdContent: {
        display: 'flex',
    },
    richrdText: {
        fontFamily: 'Work Sans',
        fontStyle: 'normal',
        fontWeight: 600,
        color: '#565656',
        fontSize: 16,

    },
    richardTime: {
        marginLeft: 120,
        color: '#565656',
        fontFamily: 'Work Sans',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: 14,
    },
    content: {
        marginTop: 4,
        color: '#565656',
        fontFamily: 'Work Sans',
        fontStyle: 'normal',
        fontSize: 13,
        fontWeight: 500,
        textAlign: 'justify'
    },
    squareAdd: {
        margin: 20,
        justifyContent: 'flex-end',
        background: '#2645AD',
        borderRadius: 22,
        alignItems: 'center',
        color: '#F3F5FF',
        fontSize: 10,
        float: 'right',
        fontWeight: 600,
        '&:hover': {
            background: '#2645AD',
            color: '#F3F5FF',
        }
    },
    grid: {
        margin: 10,
    },
    margin: {
        margin: 17
    },
    mainlineContent: {
        color: '#565656',
        fontWeight: 600,
        fontFamily: 'Work Sans',
        fontStyle: 'normal',
        fontSize: 18,
    },
    mainlineNumber: {
        fontWeight: 'bold',
        fontFamily: 'Work Sans',
        fontStyle: 'normal',
        fontSize: 15,
        color: '#838383',
    },
    departmentNameText: {
        fontSize: 16,
        fontFamily: 'Work Sans',
        fontWeight: 600,
        color: '#464646',
        letterSpacing: 0.01,
        textTransform: 'none',
    },
    switchCard: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
    },
    hoursText: {
        fontSize: 16,
        fontFamily: 'Work Sans',
        fontWeight: 600,
        color: '#787878',
        letterSpacing: 0.1,
        textAlign: 'center',
        textTransform: 'none',
    },
    specificContent: {
        display: 'content',
    },
    specificText: {
        marginBottom: 20,
        fontSize: 20,
        fontFamily: 'Work Sans',
        fontWeight: 600,
        color: '#464646',
        letterSpacing: 0.01,
        textTransform: 'none',
    },
    tableContainer1: {
        width: '100%',
        boxShadow: '0px 0px 0px',
        borderRadius: 0,
        borderColor: '#E2E2E2',
        overflow: 'unset',
    },
    scheduleText: {
        fontSize: 14,
        fontFamily: 'Work Sans',
        fontStyle: 'normal',
        fontWeight: 500,
        color: '#2645AD',
    },
    scheduleTime: {
        marginLeft: 30,
        color: '#2645AD',
        fontFamily: 'Work Sans',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: 14,
    },
    blockbutton: {
        background: '#DFDFDF',
        color: '#838383',
        fontFamily: 'Work Sans',
        fontStyle: 'normal',
        fontWeight: 500,
        borderRadius: 20,
        fontSize: 12,
        '&:hover': {
            background: '#DFDFDF',
            color: '#838383'
        }
    },
    blockedContactIcon: {
        color: '#838383',
    },

    //2nd
    newContactDiv: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    newContact: {
        color: '#565656',
        fontFamily: 'Work Sans',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: 15,
    },
    closeCircle: {
        color: '#838383',
        cursor: 'pointer'
    },
    name: {
        fontFamily: 'Work Sans',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: 15,
        color: '#565656',
    },
    phoneNumber: {
        color: '#565656',
        fontFamily: 'Work Sans',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: 15,
    },
    helpCenterDiv: {
        display: 'flex',
        alignItems: 'center',
    },
    pauseContent: {
        color: '#838383',
        fontFamily: 'Work Sans',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: 12,
    },
    helpCenter: {
        color: '#565656',
        fontFamily: 'Work Sans',
        fontStyle: 'normal',
        fontWeight: 500,
        marginLeft: 10,
        textDecoration: 'underline',
        fontSize: 14,
    },
    downfillIcon: {
        color: '#838383',
        fontSize: 20,
        marginLeft: 5,
    },
    addNumber: {
        background: '#DFDFDF',
        color: '#2645AD',
        fontSize: '12',
        '&:hover': {
            background: '#DFDFDF',
            color: '#2645AD',
        },
    },
    emailAddress: {
        color: '#565656',
        fontFamily: 'Work Sans',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: 15,
        marginBottom: '5px',
    },
    mailButton: {
        fontSize: 12,
        fontFamily: 'Work Sans',
        fontStyle: 'normal',
        fontWeight: 500,
        color: '#2645AD',
        background: '#DFDFDF',
        '&:hover': {
            color: '#2645AD',
            background: '#DFDFDF',
        },
    },
    company: {
        color: '#565656',
        fontFamily: 'Work Sans',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: 15,
        marginBottom: '10px',
    },
    cancelButton: {
        background: '#DFDFDF',
        color: '#838383',
        fontFamily: 'Work Sans',
        fontStyle: 'normal',
        fontWeight: 500,
        '&:hover': {
            background: '#DFDFDF',
            color: '#838383',
        },
    },
    saveButton: {
        marginLeft: 20,
        background: '#2645AD',
        color: '#F3F5FF',
        fontFamily: 'Work Sans',
        fontStyle: 'normal',
        fontWeight: 500,
        '&:hover': {
            background: '#2645AD',
            color: '#F3F5FF',
        },
    },
    inputField: {
        marginBottom: '-10px'
    },

    //next

    centerProfile: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 25,
    },
    avatarRichard: {
        background: '#2645AD',
        color: '#F3F5FF',
        fontSize: 18,

    },
    jhonDiv: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 7,

    },
    numberDiv: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    wickContent: {
        color: '#2645AD',
        fontFamily: 'Work Sans',
        fontStyle: 'normal',
        fontWeight: 500,
        marginLeft: '20px auto',
        fontSize: 15,
    },
    numberContent: {
        color: '#838383',
        fontFamily: 'Work Sans',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: 13,
    },
    timeDiv: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: 12,
    },
    timeContent: {
        color: '#838383',
        fontFamily: 'Work Sans',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: 20,
    },
    connectionDiv: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    wifiIcon: {
        color: '#838383',
    },
    connection: {
        color: '#838383',
        fontFamily: 'Work Sans',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: 15,
        marginLeft: 10,
    },
    dillconnectionsDiv: {
        marginTop: 18,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    smallCard: {
        background: 'rgba(0, 0, 0, 0.5)',
        width: '70%',
        borderRadius: 10,
        padding: 10,

    },
    divAlignments: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: 10,
    },
    keyboardIcon: {
        height: 40,
        width: 40,
        background: '#F3F5FF',
        color: '#2645AD',
        '&:hover': {
            background: '#F3F5FF',
            color: '#2645AD',
        }
    },
    mute: {
        color: '#565656',
        textAlign: 'center',
        fontSize: 10,
        fontFamily: 'Work Sans',
        fontStyle: 'normal',
        fontWeight: 500,
        marginTop: 4,
    },
    dialpadIcon: {
        background: '#F3F5FF',
        color: '#2645AD',
        '&:hover': {
            background: '#F3F5FF',
            color: '#2645AD',
        }
    },
    smallIcons: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    smallIconColor: {
        color: '#2645AD'
    },
    callFilledIcon: {
        marginTop: 20,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    callFilled: {
        // height: 40,
        // width: 40,
        padding: 8,
        fontSize: 20,
        background: '#DD0000',
        color: 'white',
        '&:hover': {
            background: '#DD0000',
            color: 'white',
        }
    },
    hangUpText: {
        fontFamily: 'Work Sans',
        fontStyle: 'normal',
        fontWeight: 500,
        color: '#838383',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: 10,
        marginTop: 3,
    },
    contactDiv: {
        marginTop: 20,
    },
    contactText: {
        color: '#FCFCFC',
        fontFamily: 'Work Sans',
        fontStyle: 'normal',
        fontWeight: 'bold',
    },
    // divider: {
    //     borderTop: '2px solid #D2D2D2',
    //     marginTop: 5,
    // },
    numbers: {
        marginTop: 15,
    },
    newPhoneNumber: {
        fontFamily: 'Work Sans',
        fontStyle: 'normal',
        fontWeight: 500,
        color: '#838383',
        fontSize: 14,
    },
    newNumber: {
        color: '#2645AD',
        fontFamily: 'Work Sans',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: 12,
    },
    dividerOne: {
        borderTop: '2px solid #D2D2D2',
        marginTop: 10,
    },

    //next

    smallCardOne: {
        background: 'rgba(0, 0, 0, 0.5)',
        width: '100%',
        padding: 15,
        borderRadius: 'none',
    },
    signalQuality: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    wifi: {
        color: '#FFFFFF',
        fontSize: 20,
    },
    network: {
        color: '#FFFFFF',
        fontFamily: 'Work Sans',
        fontStyle: 'normal',
        fontWeight: 500,
        marginLeft: 15,
        fontSize: 15,
    },
    callVolumeOne: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontFamily: 'Work Sans',
        fontStyle: 'normal',
        fontWeight: 500,
        marginTop: 5,
    },
    callVolume: {
        color: '#FFFFFF',
        fontFamily: 'Work Sans',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: 12,
    },
    newJhon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 10,

    },
    wick: {
        fontFamily: 'Work Sans',
        fontStyle: 'normal',
        fontWeight: 'bold',
        color: '#FFFFFF',
        marginLeft: 56,
        fontSize: 20,
    },
    numberOne: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    numberTwo: {
        fontFamily: 'Work Sans',
        fontStyle: 'normal',
        color: '#FFFFFF',
        fontSize: 12,
    },
    timeDivTwo: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    timeContentTwo: {
        color: '#838383',
        fontFamily: 'Work Sans',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: 15,

    },
    dailDiv: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        margin: 10
    },
    voiceBoard: {
        color: '#FFFFFF',
        border: '0.758152px solid #FFFFFF',
        '&:hover': {
            color: '#FFFFFF',
        }
    },
    voiceBoard11: {
        color: '#FFFFFF',
        border: '0.758152px solid #FFFFFF',
    },
    voicemuteBoard: {
        color: '#FFFFFF',
        background: "red",
        border: '0.758152px solid #FFFFFF',
        '&:hover': {
            color: '#FFFFFF',
        }
    },
    muteOne: {
        color: '#FFFFFF',
        textAlign: 'center',
        fontSize: 10,
        fontFamily: 'Work Sans',
        fontStyle: 'normal',
        fontWeight: 500,
        marginTop: 4,
    },
    textfieldColor: {
        textAlign: 'center',
        marginTop: 10,
    },
    smallCardContentOne: {
        background: '#F3F5FF',
        width: 100,
        height: 50,
        padding: 4,
        borderRadius: 10,
        // '&:hover': {
        //     background: '#2645AD',
        //     color: '#DFDFDF',
        // }
    },
    maichelCard: {
        alignItems: 'center',
        justifyContent: 'center'
    },
    maichel: {
        color: '#565656',
        textAlign: 'center',
        fontSize: 14,
        fontFamily: 'Work Sans',
        fontStyle: 'normal',
        fontWeight: 500,
    },
    maichelNumber: {
        color: '#565656',
        textAlign: 'center',
        fontSize: 11,
        fontFamily: 'Work Sans',
        fontStyle: 'normal',
        fontWeight: 500,

    },
    smallCardContent: {
        background: '#F3F5FF',
        width: 100,
        height: 50,
        padding: 4,
        borderRadius: 10,
    },
    transferButtons: {
        marginTop: 10,
    },
    firstButton: {
        background: '#F7F7F7',
        color: '#565656',
        fontSize: 12,
        fontFamily: 'Work Sans',
        fontStyle: 'normal',
        fontWeight: 500,
    },
    transferButton: {
        background: '#2645AD',
        color: '#FFFFFF',
        fontSize: 12,
        fontFamily: 'Work Sans',
        fontStyle: 'normal',
        fontWeight: 500,
        '&:hover': {
            background: '#2645AD',
            color: '#FFFFFF',
        }
    },
    iconNumbersOne: {
        background: '#FFFFFF',
        boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.25)',
        color: '#838383',
        width: '20px',
        height: '20px',
        borderRadius: '50px',
        display: 'grid',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        marginLeft: 20,
        padding: 2,
        '&:hover': {
            textDecoration: "none",
            backgroundColor: "rgba(0, 0, 0, 0.08)",
        },
    },
    text: {
        marginTop: -8, fontSize: 10,
        fontFamily: 'Work Sans',
        color: '#999999'
    },
    addDiv: {
        display: 'flex',
        alignItems: 'center',
        //justifyContent: 'space-between',
        margin: 10,
    },
    callEnd: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    callFilledOne: {
        //marginBottom: 40,
        margin: 20,
        height: 30,
        width: 100,
        background: '#DD0000',
        color: 'white',
        borderRadius: 10,
        '&:hover': {
            background: '#DD0000',
            color: 'white',
        }
    },
    closeCall: {
        fontSize: 35,
    },
    smallCardTwo: {
        background: '#DFDFDF',
        width: '100%',
    },
    addScheduleTwo: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 2,

    },

    //next

    smallCardThree: {
        background: "rgba(0, 0, 0, 0.5)",
        borderRadius: 16.166,
        width: '90%',
    },
    john: {
        color: '#FFFFFF',
        fontFamily: 'Work Sans',
        fontStyle: 'normal',
        fontWeight: 500,
    },
    connectionDivOne: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        color: '#27AE60',
        fontFamily: 'Work Sans',
        fontStyle: 'normal',
        fontWeight: 600,
    },
    typographyColor: {
        fontSize: 12,
    },
    iconSize: {
        // height: 40,
        // width: 40,
        padding: 8,
        fontSize: 19,
        background: '#27AE60',
        color: '#FFFFFF',
        '&:hover': {
            background: '#27AE60',
            color: '#FFFFFF',
        }
    },
    dividerTwo: {
        marginTop: 5,
        border: '0.96755px solid #333333',
    },
    ariyana: {
        fontFamily: 'Work Sans',
        fontStyle: 'normal',
        fontWeight: 500,
        color: '#979797'
    },
    connectionDivTwo: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        textAlign: 'center'

    },
    typographyTextColor: {
        fontFamily: 'Work Sans',
        fontStyle: 'normal',
        fontWeight: 600,
        color: '#EB5757',
        fontSize: 12,
    },
    filledIcon: {
        // height: 40,
        // width: 40,
        padding: 8,
        fontSize: 19,
        background: '#EB5757',
        color: '#FFFFFF',
        '&:hover': {
            background: '#EB5757',
            color: '#FFFFFF',
        }
    },

    //next

    smallCardFour: {
        background: "rgba(0, 0, 0, 0.5)",
        width: '90%',
        height: 55,
        borderRadius: '8',
    },
    dialCard: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        textAlign: 'center',
    },
    dialCardone: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    baselineCard: {
        marginLeft: 5,
        marginRight: 5,
    },
    outlineIconMute: {
        padding: 7,
        fontSize: 19,
        border: '0.7075px solid #FFFFFF',
        color: '#FFFFFF',
        background: '#B0B0B0',
        '&:hover': {
            color: '#FFFFFF',
            background: '#B0B0B0',
        }
    },
    outlineIcon: {
        padding: 7,
        fontSize: 19,
        border: '0.7075px solid #FFFFFF',
        color: '#FFFFFF',
    },
    outlineIconOne: {
        padding: 7,
        fontSize: 19,
        background: '#EB5757',
        border: '0.7075px solid #FFFFFF',
        color: '#FFFFFF',
        '&:hover': {
            background: '#EB5757',
            border: '0.7075px solid #FFFFFF',
        }
    },
    fullscreen: {
        height: "100%",
        position: "absolute",
        left: "0",
        width: "100%",
        overflow: "hidden",
    }
}));
