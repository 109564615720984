import React from 'react';
import {
  Card,
  CardContent,
  Grid,
  Typography,
  Divider,
  Button,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TextField,
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  MenuList,
  MenuItem,
  CircularProgress,
} from '@material-ui/core';
import * as PropTypes from 'prop-types';
import { useStyles } from './Styles';
import PhoneIcon from '@material-ui/icons/Phone';
import Customer from '../../assets/icons/customer.png';
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import { BiMessageSquareAdd } from 'react-icons/bi'
import Services from './service'
import * as authActionCreators from '../../Store/Actions/AuthActionCreator'
import Backdrop from '../../Components/Modal/Backdrop'
import Modal from '../../Components/Modal/Modal'
import AddContact from './AddContact/AddContact';
import UpdateContact from './AddContact/UpdateContact';
import { BsThreeDots } from "react-icons/bs";
import { AiFillEdit } from "react-icons/ai";
import { MdDelete } from "react-icons/md"
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import Tablebdy from './Tablebdy'
import { useDispatch, useSelector } from "react-redux";
import { height } from '@mui/system';
import axiosInstance from '../../environment/axiosInstances'
function MyContactList(props: any): JSX.Element {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [contact, setContact] = React.useState('');
  const [data, setData] = React.useState([])
  const [loading, setLoading] = React.useState<any>(false);
  const [editModal, setEditModal] = React.useState(false)
  const [open, setOpen] = React.useState(false);
  const [Updateid, setUpdateId] = React.useState<any>('')
  const [DeleteModal, setDeleteModal] = React.useState(false)
  const [delId, setDelID] = React.useState<any>('')
  const [outboundData, setOutBoundData] = React.useState<any>([])
  const [option, setOption] = React.useState<any>()
  const anchorRef: any = React.useRef(null);
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handlePopupModal = () => {
    setModalOpen(!openModal);
  };
  const [paginate, setPaginate] = React.useState<any>({
    page: 1,
    totalDocs: 0,
    limit: 10
  })
  // SipProvider.startCall("7093942290")
  const [searchData, setSearchData] = React.useState<any>({
    searchValue: '',
  })
  const [searching, setSearching] = React.useState<any>(false)




  const onChangeSearchData = (name: any, value: any) => {
    setSearchData((prev: any) => {
      return {
        ...prev,
        [name]: value,
      }
    })
  }



  React.useEffect(() => {
    if (searchData.searchValue !== '') {
      setSearching(true)
    } else {
      setSearching(false)
    }
  }, [searchData])

  const handleOpen = () => {
    setOpen(true);
  };

  const handleContactChange = (e: any) => {
    setContact(e.target.value);
  };

  const getContacts = (limit: any, page: any,) => {
    setLoading(true)
    Services.getContactsList(limit, page).then((res) => {

      if (res.success) {
        setData(res.data.data)
        setLoading(false)
        setData(res.data.data)
        setPaginate((prev: any) => {
          return {
            ...prev,
            page: res.data.page,
            totalDocs: res.data.totalDocs,
            limit: res.data.limit
          }
        })
        setSearching(false)
      }
      else {
        setSearching(false)
        setLoading(false)

      }
      if (res.status == 401) {
        dispatch(authActionCreators.initRefreshToken())
      }
    }).catch((err: any) => {
      setSearching(false)
      setLoading(false)

      // if (err.status == 401) {

      //   dispatch(authActionCreators.initRefreshToken())
      // }

    })

  }
  const handleChangePage = (data: any, value: any) => {
    setPaginate((prev: any) => {
      return {
        ...prev,
        page: value
      }
    })
    if (searching) {
      // searchedData(paginate.limit, value)
    } else {
      getContacts(paginate.limit, value)
    }
  }
  const user: any = sessionStorage.getItem("user")
  const tkUser = JSON.parse(user)
  React.useEffect(() => {
    getContacts(paginate.limit, paginate.page)
  }, [])
  const [openModal, setModalOpen] = React.useState(false)

  const handlePopupModalUpdate = (id: any) => {
    setUpdateId(id)
    setEditModal(!editModal)
  }

  function handleListKeyDown(event: any) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  const handleClose = (event: any) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const handlePopupDeleteFavourites = (id: any) => {
    setDelID(id)
    setDeleteModal(!DeleteModal)
  }

  const deleteContact = () => {
    Services.deleteContactList(delId).then((res: any) => {
      if (res.success) {
        getContacts(paginate.limit, paginate.page)
        setDeleteModal(!DeleteModal)
      } else {

      }
    }).catch((err: any) => {

    })
  }


  const handleChangeSearch = (e: any) => {
    setSearchData((prev: any) => {
      return {
        ...searchData,
        searchValue: e.target.value
      }
    })
  }

  const searchedData = () => {
    if (searchData.searchValue !== '') {

      setLoading(true)
      Services.getcontactsearch(paginate.limit, paginate.page, searchData).then((res: any) => {
        if (res.data) {
          setLoading(false)
          setData(res.data.data)
          setPaginate((prev: any) => {
            return {
              ...prev,
              page: res.data.page,
              totalDocs: res.data.totalDocs,
              limit: res.data.limit
            }
          })
          setSearching(false)
        } else {
          setSearching(false)
          setLoading(false)
        }
      }).catch((err: any) => {
        setSearching(false)
        setLoading(false)


      })
    }
    else {
      getContacts(paginate.limit, paginate.page)
    }
  }
  const getOutboundCallers = () => {
    axiosInstance.get('/calls/outbound-caller-id').then((res) => {
      if (res.data) {
        const data = res.data.data
        setOutBoundData(data)
        setOption(data[0])
      }
    }).catch((err) => {
      if (err.response?.status == 402) {
        window.location.href = "https://newbp.efone.ca"
        sessionStorage.removeItem("user")

      }
      if (err.response?.status == 401) {
        dispatch(authActionCreators.initRefreshToken())
      }
    })
  }

  React.useEffect(() => {
    getOutboundCallers()
  }, [])
  return (
    <>
      <div>
        {
          openModal && (
            <div>
              <Backdrop handlePopupModal={handlePopupModal} />
              <div style={{}}>
                <Modal title="Create New Contact" handlePopupModal={handlePopupModal} >
                  <div className="add_user_popup" style={{ width: "500px" }}>
                    <AddContact handleModal={handlePopupModal} getListContacts={getContacts} paginate={paginate} />
                  </div>
                </Modal>
              </div>
            </div>
          )
        }
        {
          editModal && (
            <div>
              <Backdrop handlePopupModal={handlePopupModalUpdate} />
              <Modal title="Updates Contact" handlePopupModal={handlePopupModalUpdate}>
                <div className="add_user_popup" style={{ width: "500px" }}>
                  <UpdateContact getListContacts={getContacts} handleModal={handlePopupModalUpdate} paginate={paginate} id={Updateid} />
                </div>
              </Modal>
            </div>
          )
        }
        {
          DeleteModal && (
            <div>
              <Backdrop handlePopupModal={handlePopupDeleteFavourites} />
              <Modal title="Are you sure to delete this contact ?" handlePopupModal={handlePopupDeleteFavourites} >
                <Grid container spacing={2} style={{ margin: 15 }}>
                  <div className="add_user_popup" style={{ width: "500px" }}>
                    <Grid item md={12} style={{ textAlign: 'center' }}>
                      <div>
                        <Button size="medium" className={classes.deleteButton} onClick={deleteContact}>Delete</Button>
                        <Button size="medium" className={classes.CancelButton} onClick={handlePopupDeleteFavourites}>Cancel</Button>
                      </div>
                    </Grid>
                  </div>
                </Grid>
              </Modal>
            </div>
          )
        }
      </div>
      <div>

        <Card>
          <Grid container spacing={2}>
            <Grid item md={12} sm={12} xs={12} >
              <CardContent>
                <div className={classes.typographydisplay}>
                  <Grid item md={6} lg={8} sm={6} xs={12}>
                    <Typography className={classes.activitytext}>Contact List</Typography>
                  </Grid>
                  <Grid item md={6} lg={4} sm={6} xs={12} className={classes.buttonpadding}>
                    <TextField
                      variant="outlined"
                      size="small"
                      fullWidth
                      placeholder="Search contact,number,name..."
                      onChange={handleChangeSearch}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon onClick={searchedData} style={{ cursor: 'pointer' }} />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                </div>
                <Divider className={classes.dividercolor} />
                <Grid container spacing={3}>
                  <Grid item md={8} xl={9} lg={9} sm={9} xs={6}></Grid>
                  <Grid item md={4} xl={3} lg={3} sm={3} xs={6}>
                    <Button
                      variant="outlined"
                      color="primary"
                      className={classes.addcontactbutton}
                      onClick={handlePopupModal}
                      startIcon={<BiMessageSquareAdd />}>
                      Add New
                    </Button>
                  </Grid>
                </Grid>

                <CardContent>
                  <TableContainer>
                    <Table>
                      <TableBody>
                        {loading === false && data?.map((item: any) => {

                          return (
                            <Tablebdy item={item}
                              handlePopupModalUpdate={handlePopupModalUpdate}
                              outboundData={outboundData}
                              option={option}
                              handlePopupDeleteFavourites={handlePopupDeleteFavourites}
                              coolPhone={props.coolPhone}
                              callOptions={props.callOptions}
                              callStatus={props.callStatus} />

                          )
                        })}

                      </TableBody>
                    </Table>
                    {
                      loading ? <div className='loader_class'>
                        <div style={{ display: 'grid', alignItems: 'center', margin: '30px 500px' }}>
                          <CircularProgress disableShrink />
                        </div>
                      </div> : data.length <= 0 && <p style={{ textAlign: 'center' }}>No Data</p>
                    }
                  </TableContainer>
                  {data.length <= 0 ? "" :
                    <Stack spacing={2} style={{ marginTop: 25, marginBottom: 25, float: "right" }}>
                      <Pagination count={paginate.totalPages} page={paginate.page} onChange={handleChangePage} variant="outlined" color="secondary" />
                    </Stack>
                  }
                </CardContent>
              </CardContent>
            </Grid>
          </Grid>
        </Card>

      </div>


    </>
  );
}
export default MyContactList
