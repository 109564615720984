import React from 'react';
import {
    TableContainer,
    Table,
    TableBody,
    TableRow,
    Avatar,
    Typography,
    TableCell,
    IconButton,
    CircularProgress
} from '@material-ui/core';
import { useStyles } from './Styles';
import SmsRoundedIcon from '@material-ui/icons/SmsRounded';
import axiosInstance from '../../../environment/axiosInstances'
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { useDispatch } from 'react-redux';
import { TableHeaderComponent } from '../../../Components/Table/Table';
import Moment from 'moment'
import { Link } from 'react-router-dom';
import * as authActionCreators from '../../../Store/Actions/AuthActionCreator'
function MyactivityMessages(props: any): JSX.Element {
    const dispatch = useDispatch()
    const classes = useStyles();
    const [messages, setMessages] = React.useState([])
    const [loading, setLoading] = React.useState<any>(false);
    const [paginate, setPaginate] = React.useState<any>({
        page: 1,
        totalDocs: 0,
        limit: 10
    })
    const handleChangePage = (data: any, value: any) => {
        setPaginate((prev: any) => {
            return {
                ...prev,
                page: value
            }
        })

        fetchMesages(paginate.limit, value, props.type)

    }
    const fetchMesages = (limit: any, page: any, type: any) => {
        setLoading(true)
        axiosInstance.get(`/my-activities/sms?limit=${limit}&page=${page}&type=${type}`).then((res) => {
            if (res.data) {
                setMessages(res.data.data)
                setLoading(false)
                setPaginate((prev: any) => {
                    return {
                        ...prev,
                        page: res.data.page,
                        totalDocs: res.data.totalDocs,
                        limit: res.data.limit
                    }
                })
            }
        }).catch((err) => {
            setLoading(false)
            if (err.response?.status == 402) {
                window.location.href = "https://newbp.efone.ca"
                sessionStorage.removeItem("user")

            }
            if (err.response?.status == 401) {
                dispatch(authActionCreators.initRefreshToken())
            }
        })
    }
    React.useEffect(() => {
        fetchMesages(paginate.limit, paginate.page, props.type)
    }, [props.type])
    const tableHeader = ['Phone Number', 'eFone Number', 'Message', 'Date']
    return (
        <>
            <TableContainer>
                <Table className={classes.table}>
                    <TableHeaderComponent theadData={tableHeader} />
                    <TableBody>
                        {messages && messages.length > 0 ? messages.map((item: any, i: any) => {
                            var data = parseInt(item?.createdDate)
                            var dataa = Moment(data).format('YYYY MMM DD hh:mm A')

                            return (
                                <TableRow className={classes.tablebackgroundcolor} key={item.id}>
                                    <TableCell className={classes.tablecell}>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <Avatar>{item.contactName ? item.contactName.charAt(0) : item.callerIdName.charAt(0)}</Avatar>
                                            <Link to={{ pathname: `/activities/userDetails/${'messages'}/${item.id}` }} className={classes.linkUnderline}>
                                                <Typography className={classes.reschedule}> {item.contactName ? item.contactName : item.callerIdName}<br />
                                                    <p style={{ fontSize: "10px", float: "right" }}>({item.phoneNumber})</p></Typography>
                                            </Link>

                                        </div>
                                        {/* {item.userId ?
                                            <div>
                                                {item.userId.firstName} {item.userId.lastName}
                                            </div>
                                            :
                                            item.deparmentId ?
                                                <div>
                                                    {item.deparmentId.deparmentName}
                                                </div>
                                                : ""
                                        } */}
                                    </TableCell>
                                    <TableCell className={classes.tablecell}>{item.eFoneNumber}</TableCell>
                                    <TableCell className={classes.tablecell1}>
                                        <Link to={{ pathname: `/activities/userDetails/${'messages'}/${item.id}` }} className={classes.linkUnderline}>
                                            <IconButton>
                                                <SmsRoundedIcon />
                                            </IconButton>
                                        </Link>
                                        {item.textMessage.length > 20 ? item.textMessage.substring(0, 20) + "..." : item.textMessage}
                                    </TableCell>
                                    {/* <TableCell className={classes.tablecell}>
                                        {item.deparmentId && item.agentId && item.agentId.firstName}
                                    </TableCell> */}
                                    <TableCell className={classes.tablecell}>{dataa}</TableCell>
                                </TableRow>
                            )
                        })
                            :

                            loading ? <div className='loader_class'>
                                <div style={{ display: 'grid', alignItems: 'center', margin: '30px 500px' }}>
                                    <CircularProgress disableShrink />
                                </div>
                            </div> : messages.length <= 0 &&
                            <TableRow>
                                <TableCell className={classes.tablecell}></TableCell>
                                <TableCell className={classes.tablecell}></TableCell>
                                <TableCell className={classes.tablecell}>No Data</TableCell>
                                <TableCell className={classes.tablecell}></TableCell>
                                <TableCell className={classes.tablecell}></TableCell>
                            </TableRow>
                            // <p style={{ textAlign: 'center' }}>No Data</p>

                        }


                    </TableBody>
                </Table>
            </TableContainer>
            {messages.length <= 0 ? "" :
                <Stack spacing={2} style={{ marginTop: 25, marginBottom: 25, float: "right" }}>
                    <Pagination count={paginate.totalPages} page={paginate.page} onChange={handleChangePage} variant="outlined" color="secondary" />
                </Stack>
            }
        </>
    )
}
export default MyactivityMessages