import React from 'react';
import {
    Card,
    CardContent,
    Grid,
    Typography,
    Divider,
    withStyles,
    Switch,
    Button,
    Tabs,
    Tab,
    TableContainer,
    Table,
    TableBody,
    TableRow,
    TextField,
    TableCell,
    IconButton,
} from '@material-ui/core';
import { useStyles } from '../Styles'
import PhoneCallbackIcon from '@material-ui/icons/PhoneCallback';
import AppleIcon from '@material-ui/icons/Apple';
import VolumeUpIcon from '@material-ui/icons/VolumeUp';
import Tooltip from '@material-ui/core/Tooltip';
import PeopleIcon from '@material-ui/icons/People';
import { VscMerge } from 'react-icons/vsc'
import { BsRecord2Fill, BsCardText } from 'react-icons/bs'
import AndroidOutlinedIcon from '@material-ui/icons/AndroidOutlined';
import BallotIcon from '@material-ui/icons/Ballot';
import { HiPhoneOutgoing } from 'react-icons/hi'
import LanguageIcon from '@mui/icons-material/Language';

function TableBodyData(props: any) {
    const classes = useStyles();
    const { callDirection, createdDt,phoneNumber, id, agentId, departmentId, duration, callerIdName, osType, answerStamp, formPhoneNumber, eFoneNumber } = props.rowData;
    return (

        <TableRow className={classes.tablebackgroundcolor}>
            <TableCell className={classes.tablecell}>
                {callDirection === 1 &&
                    <>
                        <IconButton className={classes.phonecallmissediconcolor}>
                            <PhoneCallbackIcon />
                        </IconButton>
                    </>
                }
                {callDirection === 2 &&
                    <>
                        <IconButton className={classes.phonecallmissedicongreen}>
                            <HiPhoneOutgoing />
                        </IconButton>
                    </>
                }
                {/* <IconButton className={classes.phonecallbackiconcolor}>
                <PhoneCallbackIcon className={classes.callBackIcon} />
              </IconButton> */}
            </TableCell>
            <TableCell className={classes.tablecell}>
                <div>
                    <Typography className={classes.number}> {callerIdName}<br />
                    <p style={{ fontSize: "10px" }}>({phoneNumber})</p></Typography>
                </div>
            </TableCell>
            <TableCell className={classes.tablecell}>
                {eFoneNumber}
                {/* <Typography className={classes.typographytext}>Sales</Typography> */}
            </TableCell>
            <TableCell className={classes.tablecell}  >
                {osType === 1 &&
                    <>
                        <AndroidOutlinedIcon style={{ fontSize: 15 }} />
                    </>
                }
                {osType === 2 &&
                    <>
                        <AppleIcon style={{ fontSize: 16 }} />
                    </>
                }
                {osType === 3 &&
                    <>
                        <BallotIcon style={{ fontSize: 16 }} />
                    </>
                }
                {osType === 4 &&
                    <>
                        <LanguageIcon style={{ fontSize: 16 }} />
                    </>
                }
                &nbsp;&nbsp;{duration}


            </TableCell>
            {
                agentId && <TableCell className={classes.agent}>{agentId && agentId.firstName}</TableCell>
            }
            {/* <TableCell className={classes.agent}>Agent</TableCell> */}
            <TableCell align={'center'} className={classes.tablecell}>
                <div className={classes.liveCallsDiv}>
                    <div>
                        <IconButton className={classes.volumeIcon}>
                            <Tooltip title="It is enabled">
                                <VolumeUpIcon className={classes.volume} />
                            </Tooltip>
                        </IconButton>
                        <Typography className={classes.typographytext}>Listen</Typography>
                    </div>
                    <div>
                        <IconButton className={classes.volumeIcon}>
                            <Tooltip title="It is enabled">
                                <PeopleIcon className={classes.volume} />
                            </Tooltip>
                        </IconButton>
                        <Typography className={classes.typographytext}>Wishper</Typography>
                    </div>
                    <div>
                        <IconButton className={classes.volumeIcon}>
                            <Tooltip title="It is enabled">
                                <VscMerge className={classes.volume} style={{ transform: "rotate(90deg)" }} />
                            </Tooltip>
                        </IconButton>
                        <Typography className={classes.typographytext}>Merge</Typography>
                    </div>
                    <div>
                        <IconButton className={classes.volumeIcon}>
                            <Tooltip title="It is enabled">
                                <BsRecord2Fill className={classes.recordThree} />
                            </Tooltip>
                        </IconButton>
                        <Typography className={classes.typographytext}>Record</Typography>
                    </div>
                </div>
            </TableCell>
        </TableRow>

    )
}

export default TableBodyData