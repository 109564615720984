export const activityDNDstatus = {
    GET_STATUS: 'GET_STATUS',
    GET_DND_STATUS_REQUEST: 'GET_DND_STATUS_REQUEST',
    GET_DND_STATUS_SUCCESS: 'GET_DND_STATUS_SUCCESS',
    ONCHANGE_DND_STATUS: 'ONCHANGE_DND_STATUS'


}
export const globalPopupActions = {
    INIT_GLOBAL_ERROR: 'INIT_GLOBAL_ERROR',
    CLEAR_GLOBAL_ERROR: 'CLEAR_GLOBAL_ERROR'
}
export const activityPatchDND = {
    SET_DND_STATUS: 'SET_DND_STATUS',
    SET_DND_STATUS_REQUEST: 'SET_DND_STATUS_REQUEST',
    SET_DND_STATUS_SUCCESS: 'SET_DND_STATUS_SUCCESS',
    ONCHANGE_DND_STATUS: 'ONCHANGE_DND_STATUS'
}
export const AuthActions = {
    INIT_AUTH: 'INIT_AUTH',
    INIT_AUTH_STATUS: 'INIT_AUTH_STATUS',
    INIT_AUTH_SUCCESS: 'INIT_AUTH_SUCCESS',
    INIT_AUTH_FAIL: 'INIT_AUTH_FAIL',
    INIT_AUTH_LOADING: 'INIT_AUTH_LOADING',
    INIT_AUTH_LOGOUT: 'INIT_AUTH_LOGOUT',
    INIT_AUTH_LOGOUT_SUCCESS: 'INIT_AUTH_LOGOUT_SUCCESS',
    INIT_AUTH_TIMEOUT: 'INIT_AUTH_TIMEOUT',
    INIT_REFRESH_TOKEN: 'INIT_REFRESH_TOKEN'
}
export const departmentModle = {
    FETCH_LOADING_DEPARTMENTS: 'FETCH_LOADING_DEPARTMENTS',
    FETCH_DEPARTMENTS_SUCCESS: 'FETCH_DEPARTMENTS_SUCCESS',
    ONCHANGE_SELECT_DEPARTMENT: 'ONCHANGE_SELECT_DEPARTMENT',
    ONCHANGE_DND_STATUS: 'ONCHANGE_DND_STATUS'

}
export const CallingModule = {
    GET_STATUS: 'GET_STATUS',
    GET_CALL_STATUS_REQUEST: 'GET_CALL_STATUS_REQUEST'
}
