import { Grid, withStyles, Switch,Typography, Divider } from "@material-ui/core";
import React from "react";
import ReactSelect from 'react-select';
import { MdOutlineFeedback } from 'react-icons/md'
import { useStyles } from "./styles";

function AppSettings() {
  const classes = useStyles();
  const AntSwitch = withStyles((theme) => ({
    root: {
      width: 28,
      height: 16,
      padding: 0,
      display: "flex",
    },
    switchBase: {
      padding: 2,
      color: theme.palette.grey[500],
      "&$checked": {
        transform: "translateX(12px)",
        color: theme.palette.common.white,
        "& + $track": {
          opacity: 1,
          backgroundColor: theme.palette.primary.main,
          borderColor: theme.palette.primary.main,
        },
      },
    },
    thumb: {
      width: 12,
      height: 12,
      boxShadow: "none",
    },
    track: {
      border: `1px solid ${theme.palette.grey[500]}`,
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor: theme.palette.common.white,
    },
    checked: {},
  }))(Switch);

  return (
    <>
      <div style={{padding:10}}>
        <Grid container >
          <Typography className={classes.notification}>Call & Message Notifications</Typography>
          <Grid item md={12}>
            <div className={classes.switchDiv} style={{marginTop:10}}>
                <Typography className={classes.typogaphyText}>Lorem ipsum dolor sit amet consectetur adipisicing elit.</Typography>
                <AntSwitch
                    // className={classes.typographyleft}
                    // checked={state.checkedC}
                    // onChange={handlecheckChange}
                    name="checkedC"
                />
            </div>
            <div className={classes.switchDiv} style={{marginTop:12}} >
                <Typography className={classes.typogaphyText}>Lorem ipsum dolor sit amet consectetur adipisicing elit.</Typography>
                <AntSwitch
                    // className={classes.typographyleft}
                    // checked={state.checkedC}
                    // onChange={handlecheckChange}
                    name="checkedC"
                />
            </div>
            <div className={classes.switchDiv} style={{marginTop:12}} >
                <Typography className={classes.typogaphyText}>Lorem ipsum dolor sit amet consectetur adipisicing elit.</Typography>
                <AntSwitch
                    // className={classes.typographyleft}
                    // checked={state.checkedC}
                    // onChange={handlecheckChange}
                    name="checkedC"
                />
            </div>
            <div className={classes.switchDiv} style={{marginTop:12}} >
                <Typography className={classes.typogaphyText}>Lorem ipsum dolor sit amet consectetur adipisicing elit.</Typography>
                <AntSwitch
                    // className={classes.typographyleft}
                    // checked={state.checkedC}
                    // onChange={handlecheckChange}
                    name="checkedC"
                />
            </div>
          
          </Grid>
        </Grid>
      </div>

      <Divider className={classes.divider} />

      <div style={{padding:10}}>
        <Typography className={classes.notification}>Ringing/Notification Audio</Typography>
        <Grid container spacing={2}>
          <Grid item md={9}>
              <div className={classes.playRinging}>
                  <Typography className={classes.sounds}>Play ringining/notification sounds from:</Typography>
                  <Typography className={classes.test}>Test</Typography>
              </div>
              <div>
                  <ReactSelect
                      className={classes.selectBox}
                      placeholder={'Select'}
                  //onChange={handleInputOnchange}
                  />
              </div>
          </Grid>
          <Grid item md={3}></Grid>
        </Grid>
        <Grid container spacing={2}>
            <Grid item md={9}>
                <Typography className={classes.ringtone}>Ringtone</Typography>
                <div>
                    <ReactSelect
                        className={classes.selectBox}
                        placeholder={'Select'}
                    />
                </div>
            </Grid>
            <Grid item md={3}>
            </Grid>
        </Grid>
      </div>

      <Divider className={classes.divider} />
      <div style={{padding:10}}>
        <Typography className={classes.notification}>Hardware Settings</Typography>
        <Grid container spacing={2}>
            <Grid item md={9}>
                <Typography className={classes.microphonetext}>Microphone</Typography>
                <Typography className={classes.microphone}>No Mircophones Detected</Typography>
                <div className={classes.speakerHeadset}>
                    <Typography className={classes.speakerSounds}>Speaker/Headset</Typography>
                    <Typography className={classes.test}>Test</Typography>
                </div>
                <div>
                    <ReactSelect
                        className={classes.selectBox}
                        placeholder={'Select'}
                    //onChange={handleInputOnchange}
                    />
                </div>
                <Typography className={classes.videoCamera}>Video Camera</Typography>
                <Typography className={classes.noCamera}>No Camera detected</Typography>
            </Grid>
            <Grid item md={3}></Grid>
        </Grid>
        <Typography className={classes.network}>Test Network & Audio</Typography>
        <div className={classes.feedbackDiv}>
          <Typography className={classes.sendFeedback}>Send Feedback</Typography>
          {/* <Icon icon={feedbackLine} className={classes.feedbackIcon} /> */}
          <MdOutlineFeedback />
        </div>
      </div>

  </>  
  );
}

export default AppSettings;
