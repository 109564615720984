
import { globalPopupReducerData } from './Reducer/GlobalPopUpReducer'
import { userDNDProfileData } from './Reducer/ActivityReducer'
import { departmentsReducerFunction } from './Reducer/DepartmentsReducer'
import { authReducer } from './Reducer/AuthReducer'
import { callingReducer } from './Reducer/CallingReducer'
const combineReducers = {
    authState: authReducer,
    errorState: globalPopupReducerData,
    profileData: userDNDProfileData,
    departmentModule: departmentsReducerFunction,
    CallingData: callingReducer


}

export default combineReducers;