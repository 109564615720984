import React from 'react';
import {
  Grid,
  Typography,
  Button,
  Table, TableBody, TableRow, TableCell,
  TextField,
} from '@material-ui/core';
import { BiMessageSquareAdd } from 'react-icons/bi'
import Select from 'react-select'
import { useStyles } from './styles';
import axiosInstance from '../../../environment/axiosInstances'
import { makeStyles, withStyles } from "@material-ui/core/styles";
import MuiTableCell from "@material-ui/core/TableCell";
// import Services from '../service'
import { AiOutlineCloseCircle } from 'react-icons/ai'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
toast.configure()

const UpdateContact = (props: any) => {

  const classes = useStyles();
  const [number, setNumber] = React.useState('')
  const [numId, setNumId] = React.useState('')
  const [numChange, setNumChange] = React.useState(false)
  const [numberList, setNumberList] = React.useState<any>([{ phoneNumber: '', contactType: '' }]);
  const [emailList, setEmailList] = React.useState<any>([{ email: '' }]);
  const [urlList, setUrlList] = React.useState<any>([{ url: '' }]);
  const [email, setEmail] = React.useState('')
  const [emailChange, setEmailChange] = React.useState(false)
  const [url, setUrl] = React.useState('')
  const [urlChange, setUrlChange] = React.useState(false)
  const [submitted, setSubmitted] = React.useState(false);
  const [data, setData] = React.useState<any>({
    "name": props.data.contactName,
    "phoneNumbers": props.data.phoneNumber,
    "contactType": "1",

  })

  const handleChangeData = (e: any) => {
    setData((prev: any) => {
      return {
        ...data,
        name: e.target.value
      }
    })
  }
  const handleChangeNumber = (e: any, i: any) => {
    // setNumChange(true)
    // setNumId(i)
    // e.preventDefault();
    // setNumber(e.target.value)
    numberList[i].phoneNumber = e.target.value
    setNumberList(JSON.parse(JSON.stringify(numberList)));
  }
  const setNumbers = () => {
    let numberCopyData = numberList;
    if (numberCopyData.length > 0) {
      numberCopyData.forEach((num: any) => {
        num.phoneNumber = number
      })
    }

    setNumberList(numberCopyData)
    setNumChange(false)
    setNumber('')
  }
  const handleContactOpt = (data: any) => {
    setData((prev: any) => {
      return {
        ...prev,
        contactType: data.value
      }
    })
  }



  const handleSubmit = () => {
    const sendData = {
      phoneNumbers: [{ phoneNumber: props.data.phoneNumber, contactType: data.contactType }],
      name: data.name
    }
    // data.phoneNumbers = [{ phoneNumber: props.data.phoneNumber, contactType: data.contactType }]

    setSubmitted(true);
    const errorData = validate(true);

    if (errorData.isValid) {
      axiosInstance.patch(`/contacts/${props.data.contactId}`, sendData).then((res) => {
        if (res.data) {
          toast.success(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000
          })
          props.handleModal()
          props.getCall()
        }
      }).catch((err) => {
        if (err.response?.status == 402) {
          window.location.href = "https://newbp.efone.ca"
          sessionStorage.removeItem("user")

        }
        toast.error(err.response.data.message, { position: toast.POSITION.TOP_RIGHT, autoClose: 1000 })
      })
    }
  }




  const contactOptions: any = [
    { label: "Mobile", value: "1" },
    { label: "Work", value: "2" },
    { label: "Home", value: "3" },
    { label: "Others", value: "4" },
  ]


  const validate = (submitted: any) => {
    const validData = {
      isValid: true,
      message: ""
    };
    const retData = {
      name: { ...validData },

    };
    let isValid = true;
    if (submitted) {


      if (data.name === " ") {
        retData.name = {
          isValid: false,
          message: "Please enter name"
        };
        isValid = false;
      }
      if (!/^[a-zA-Z\s]*$/g.test(data.name)) {
        retData.name = {
          isValid: false,
          message: "Please enter only name"
        };
        isValid = false;
      }
      if (!data.name) {
        retData.name = {
          isValid: false,
          message: "Please enter name"
        };
        isValid = false;
      }




    }
    return {
      ...retData,
      isValid
    };
  };
  const errors = validate(submitted);

  React.useEffect(() => {
    axiosInstance.get(`/contacts/${props.data.contactId}`).then((res: any) => {

      if (res.success) {
        const newData: any = res.data.data
        setData({
          "name": newData.name,
          "phoneNumbers": newData.phoneNumbers[0].phoneNumber,
          "contactType": newData.phoneNumbers[0].contactType,
        })


      }
    })
  }, [])


  return (
    <div style={{ padding: 8 }}>
      <Grid container spacing={2}>
        <Grid item md={12}>
          <Typography className={classes.contacttypography}>Contact Name</Typography>
          <TextField
            className={classes.contacttextfield}
            style={{ marginTop: 3 }}
            fullWidth
            value={data.name}
            size="small"
            name="name"
            placeholder="Enter Contact Name"
            variant="outlined"
            onChange={handleChangeData}
          />
          {
            !errors.name.isValid &&
            <p className={classes.errorMessage}>{errors.name.message}</p>
          }
          <Typography className={classes.contacttypography} style={{ marginTop: 8 }}>Phone Number</Typography>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item md={12}>
          {/* <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Typography className={classes.insertbody}>insert a pause with commas</Typography>
            <Typography className={classes.help}>Help center</Typography>
          </div> */}
          <Table style={{ border: "none", borderBottom: "none" }}>

            <TableBody>
              <TableRow style={{ borderBottom: "none" }}>
                <TableCell style={{ borderBottom: "none", padding: "4px" }}>
                  <>
                    {/* <TextField
                      style={{ marginTop: 5 }}
                      className={classes.contacttextfield}
                      fullWidth
                      name='phoneNumbers'
                      value={props.phoneNumber}
                      size="small"
                      placeholder="ex: (***) ***-***"
                      variant="outlined"
                    // onMouseLeave={setNumbers}
                    // name="phoneNumbers"


                    /> */}
                    <Typography className={classes.Numbertextfield}>{props.data.phoneNumber}</Typography>
                  </>
                </TableCell>
                <TableCell style={{ borderBottom: "none", padding: "4px" }}>
                  <>
                    <Select
                      options={contactOptions}
                      placeholder='mobile'
                      name="contactType"
                      value={contactOptions.filter((opt: any) => opt.value === JSON.stringify(data.contactType))}
                      className={classes.selectcontact}
                      onChange={handleContactOpt}
                    />

                  </>
                </TableCell>

              </TableRow>
            </TableBody>

          </Table>
        </Grid>



      </Grid >




      <Grid container spacing={2}>
        <Grid item md={12} >
          <Button
            style={{ float: 'right', marginTop: 6 }}

            className={classes.addcontactbutton1}
            onClick={handleSubmit}
          >
            Update Contact
          </Button>
        </Grid>
      </Grid>
    </div >

  )
}


export default UpdateContact