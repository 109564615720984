import { activityDNDstatus, activityPatchDND } from './Actions'
export const getInitStatus = () => {
    return {
        type: activityDNDstatus.GET_STATUS,

    }
}
export const getStatus = (status: any) => {
    return {
        type: activityDNDstatus.GET_DND_STATUS_REQUEST,
        status: status
    }
}

export const getStatusRequest = (profileData: any) => {
    return {
        type: activityDNDstatus.GET_DND_STATUS_SUCCESS,
        data: profileData
    }
}

export const getInitPatchStatus = (data: any) => {
    return {
        type: activityPatchDND.SET_DND_STATUS,
        data: data
    }
}
export const getPatchStatus = (status: any) => {
    return {
        type: activityPatchDND.SET_DND_STATUS_REQUEST,
        status: status
    }
}
export const onChangeDNDstatus = (status: any) => {
    return {
        type: activityPatchDND.ONCHANGE_DND_STATUS,
        DndStatus: status
    }
}

export const getPatchStatusRequest = (profileData: any) => {
    return {
        type: activityPatchDND.SET_DND_STATUS_SUCCESS,
        data: profileData
    }
}

