import { put, delay } from 'redux-saga/effects'
import * as authActionCreators from '../Actions/AuthActionCreator'
import * as globalpopupAlert from '../Actions/globalpopupAlert'
import sessionStorageContent from '../../services/localStorage'
import axiosInstance from '../../environment/axiosInstances'
import axios from 'axios'
import jwt_decode from "jwt-decode";
import { push } from "connected-react-router";
import Cookies from 'js-cookie'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { alignProperty } from '@mui/material/styles/cssUtils'
toast.configure()

export function* init_auth_saga(action: any): Generator {

    try {
        yield put(authActionCreators.AuthLoading(true))
        const authResponse: any = yield axios.post(`${process.env.REACT_APP_API_URL}/auth/user/login`, action.data);
        if (authResponse.data) {
            sessionStorage.setItem('user', JSON.stringify(authResponse.data.data))
            const { data } = authResponse.data;
            Cookies.set('access', JSON.stringify(data.access.token))
            Cookies.set('refresh', JSON.stringify(data.refresh.token))
            const userDataDecode = jwt_decode(data.access.token);
            const userData = { userData: userDataDecode, tokenData: data }

            // sessionStorageContent.setLocalUserData({ authenticated: true, ...userData })
            yield put(authActionCreators.authSuccess(userData))
            yield delay(0)
            // yield put(push('/'))
            // setTimeout(() => window.location.reload(), 2000)


            toast.success("Login successfully", {
                position: toast.POSITION.TOP_RIGHT,
                style: {
                    borderRadius: "2px",
                    border: "1px solid green"
                }, autoClose: 1000
            })
            yield put(globalpopupAlert.initglobalPopup(authResponse.data.message, 'LOGIN_ROUTE', 'success'))
            yield put(authActionCreators.authTimeout(data.access))
            yield put(authActionCreators.AuthLoading(false))


        } else {

            yield put(authActionCreators.AuthLoading(false))
            yield put(globalpopupAlert.initglobalPopup(authResponse.data.message, 'LOGIN_ROUTE', 'success'))
        }
    } catch (error: any) {

        toast.error(error.response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
            style: {
                borderRadius: "2px",
                border: "1px solid red"
            }, autoClose: 1000
        })
        if (error.response?.status == 402) {
            window.location.href = "https://newbp.efone.ca"
            sessionStorage.removeItem("user")
            yield Cookies.remove('access')
            yield Cookies.remove('refresh')
        }
        yield put(authActionCreators.AuthLoading(false))
        yield put(globalpopupAlert.initglobalPopup(error.message === 'Network Error' ? 'Network Error' : error.response.data.message, 'LOGIN_ROUTE_FAIL', 'error'))
    }
}

export function* init_auth_timeout(action: any): Generator {
    try {
        const timeout = (ms: any) => new Promise(resolve => setTimeout(resolve, ms));
        const { expires }: any = action.tokenData;
        var timeOut: any = Math.ceil(new Date(expires).getTime() - new Date().getTime());
        yield delay(timeOut);
        // yield timeout(timeOut);
        yield put(authActionCreators.initRefreshToken())
    } catch (error) {
    }
}
export function* init_refresh_token(): Generator {
    try {
        const refreshToken: any = yield Cookies.get('refresh')
        const parseRefreshData: any = refreshToken !== undefined ? JSON.parse(refreshToken) : undefined;
        if (refreshToken) {
            const responseTokenData: any = yield axiosInstance.post('/auth/refresh-tokens', { refreshToken: refreshToken.token ? JSON.stringify(refreshToken.token) : JSON.parse(refreshToken) });
            if (responseTokenData.data) {

                const { access, refresh } = responseTokenData.data.data;
                yield Cookies.remove('access')
                yield Cookies.remove('refresh')
                yield Cookies.set('access', JSON.stringify(access.token))
                yield Cookies.set('refresh', JSON.stringify(refresh.token))
                const userDataDecode = jwt_decode(access.token);
                // setTimeout(() => window.location.reload(), 2000)
                const userData = { userData: userDataDecode, tokenData: responseTokenData.data }
                sessionStorage.setItem('user', JSON.stringify(responseTokenData.data.data))
                // yield sessionStorageContent.setLocalUserData({ authenticated: true, ...userData })
                yield put(authActionCreators.authSuccess(responseTokenData.data.data))
                yield put(authActionCreators.authTimeout(access))
            } else {
                yield put(authActionCreators.initAuthLogout())
            }
        } else {
            yield put(authActionCreators.initAuthLogout())

        }
    } catch (error: any) {
        if (error.response?.status == 402) {
            window.location.href = "https://newbp.efone.ca"
            sessionStorage.removeItem("user")
            yield Cookies.remove('access')
            yield Cookies.remove('refresh')
        }
        yield put(authActionCreators.initAuthLogout())

    }
}