import React from 'react';
import { useStyles } from './Styles';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Card,
    CardContent,
    Divider,
    Grid,
    Avatar,
    Typography,
    TextField,
    Tooltip,
    TextareaAutosize,
    Switch,
    IconButton,
    MenuItem,
    Button,
    FormGroup,
    FormControlLabel,
    FormControl,
    CircularProgress,
    withStyles,

} from '@material-ui/core/';
import Slider from '@material-ui/core/Slider';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import PhoneIcon from '@material-ui/icons/Phone';
import axiosInstance from '../../environment/axiosInstances'
import { FaUserPlus } from 'react-icons/fa';
import AllTypes from './Alltypes'
import * as authActionCreators from '../../Store/Actions/AuthActionCreator'
import logo from '../../assets/icons/simple_loading.gif'
import { MdSend, MdInsertPhoto, MdScheduleSend, MdOutlineDialpad, MdCall, MdOutlineExpandMore, MdPhonePaused, MdCallEnd } from 'react-icons/md';
import { BiCheckDouble, BiMessageSquareAdd, BiDialpadAlt, BiChevronDown } from 'react-icons/bi';
import { RiUser3Fill, } from 'react-icons/ri';
import Ellipse from '../../assets/icons/Ellipse.png';
import { AiFillCloseCircle, AiFillStar, AiOutlineEdit, AiOutlinePause } from 'react-icons/ai';
import VoiceMail from './VoiceMail'
import { InputAdornment } from '@material-ui/core';
import { ImBlocked, ImPhoneHangUp } from 'react-icons/im';
import { useParams } from "react-router-dom";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import Grow from "@material-ui/core/Grow";
import MenuList from "@material-ui/core/MenuList";
import { BsFillCaretDownFill, BsCameraFill, BsDownload, BsFillMicMuteFill, BsPlusSquareFill, BsWifi, BsFillMicFill, BsFillTelephoneOutboundFill } from 'react-icons/bs';
import { CardHeader } from '../Header/CardHeader';
import SaveIcon from '@material-ui/icons/Save';
import Checkbox from '@material-ui/core/Checkbox';
import { useDispatch, useSelector } from 'react-redux';
import * as CallActionCreator from '../../Store/Actions/CallActionCreator'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Backdrop from '../../Components/Modal/Backdrop'
import AddModal from '../../Components/Modal/Modal'
import AddContact from './AddContact/AddContact';
import Modal from '@material-ui/core/Modal';
import UpdateContact from './AddContact/UpdateContact';
import { v1 as uuidv1 } from 'uuid';

function addPlayListModalStyle() {
    const top = 15;
    const left = 35;
    const right = 35;

    return {
        top: `${top}%`,
        left: `${left}%`,
        right: `${right}%`,
        transform: `translate(-${top}%, -${left}%, -${right}%)`,
    };
}

function getModalStyle() {
    const top = 15;
    const left = 30;
    const right = 30;

    return {
        top: `${top}%`,
        left: `${left}%`,
        right: `${right}%`,
        transform: `translate(-${top}%, -${left}%, -${right}%)`,
    };
}






const UserOne = (props: any): JSX.Element => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const callingData: any = useSelector((state: any) => state.CallingData);
    var local_user = sessionStorage.getItem("user")
    var current_user = local_user ? JSON.parse(local_user) : {}
    const token = Object.keys(current_user).length && current_user.access ? current_user.access.token : null;
    const [open, setOpen] = React.useState<boolean>(false);
    const [modalStyle] = React.useState(getModalStyle);
    const [callBlockOpen, setCallBlockOpen] = React.useState<boolean>(false);
    const [callModal, setCallModal] = React.useState(false)
    const listInnerRef: any = React.useRef();
    const [newdata, setNewData] = React.useState({})
    const [openModal, setModalOpen] = React.useState(false)
    const [editModal, setEditModal] = React.useState(false)
    const params = useParams();
    const [loading, setLoading] = React.useState<any>(false);
    const [Scrollloading, setScrollLoading] = React.useState<any>(false);
    const [paginate, setPaginate] = React.useState<any>({
        page: 1,
        totalDocs: 0,
        limit: 100
    })
    const [height, setHeight] = React.useState(0)
    const ref: any = React.createRef()

    const [message, setMessage] = React.useState('')
    const [sendMessage, setSendMessage] = React.useState({
        destination: "12262424818",
        source: "13653005064",
        message: "",
        smsType: 5,
        outboundMsgId: "34567890",
        accessToken: token
    })
    const [sendStatus, setSendStatus] = React.useState(false);
    const [data, setData] = React.useState<any>([])
    const [outboundData, setOutBoundData] = React.useState<any>([])
    const [filteredData, setFileterDate] = React.useState<any>({})
    const [option, setOption] = React.useState<any>()
    const [openTog, setOpenTog] = React.useState(false);
    const anchorRef: any = React.useRef(null);
    const [userdetails, setUserDetails] = React.useState<any>({})
    const [Updateid, setUpdateId] = React.useState<any>('')
    function handleListKeyDown(event: any) {
        if (event.key === "Tab") {
            event.preventDefault();
            setOpen(false);
        }
    }
    const handleToggle = () => {
        setOpenTog((prevOpen) => !prevOpen);
    };
    const handleClose = (event: any) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };
    const handlePopupModalOpen = () => {
        setModalOpen(!openModal);
    };
    const getOutboundCallers = () => {
        axiosInstance.get('/calls/outbound-caller-id').then((res) => {
            if (res.data) {
                const data = res.data.data
                setOutBoundData(data)
                setOption(data[0])
            }
        }).catch((err) => {
            if (err.response?.status == 402) {
                window.location.href = "https://newbp.efone.ca"
                sessionStorage.removeItem("user")

            }
            if (err.response?.status == 401) {
                dispatch(authActionCreators.initRefreshToken())
            }
        })
    }
    const handleChangeOpt = (val: any) => {
        setOption(val)
    }
    React.useEffect(() => {
        getOutboundCallers()
    }, [])




    const callHandleOpen = () => {
        setCallBlockOpen(true)
    }

    const handleOpen = () => {
        setOpen(true);
    }

    const handleCloseTog = () => {
        setOpenTog(false);
    }

    const [expanded, setExpanded] = React.useState<boolean>(true);

    const handleChangeAccrodian = () => (event: any) => {
        setExpanded(!expanded);
    };
    function getDifferenceInDays(date1: any, date2: any) {
        const diffInMs = Math.abs(date2 - date1);
        return diffInMs / (1000 * 60 * 60 * 24);
    }


    const handleSubmit = () => {
        // props.socketData.emit("eb_outboundSMS", sendMessage)
        // props.socketData.on("eb_outboundACK", (data: any) => {
        //     console.log("----message sent", data)
        // })
        const id = uuidv1();
        const sendData = {
            // destination: data[0]?.phoneNumber,
            // source: data[0]?.eFoneNumber,
            id: data[0]?.recordHdrId,
            message: message,
            smsType: 5,
            outboundMsgId: id,
            accessToken: token
        }

        props.socketData.emit("eb_outboundSMS", sendData)

        setMessage('')
        props.socketData.on("eb_outboundACK", (newData: any) => {
            console.log("----message sent", newData)
            if (newData.statusCode == 200 || newData.statusCode == 201) {
                const res = newData.data
                const myObj = {
                    recordType: 2,
                    textMessage: res.msg,
                    createdDate: res.time,
                    direction: res.type,
                    isReadMessage: res.isRead,
                    status: true

                }
                const newTodos = [...data];
                newTodos.push(myObj);
                let newar: any = {}

                //loop
                newTodos.forEach((a: any) => {
                    if (newar[new Date(Number(a.createdDate)).toLocaleDateString()] === undefined) {
                        newar[new Date(Number(a.createdDate)).toLocaleDateString()] = [a];
                    } else {
                        newar[new Date(Number(a.createdDate)).toLocaleDateString()].push(a)
                    }
                })
                setFileterDate(newar)
                setData(newTodos)

            }



        })
    }

    // connect((message: any) => {
    //     console.log("------------Connect", message);
    // });


    const getInformationById = (limit: any, type: any) => {
        setLoading(true)
        axiosInstance.get(`/my-activities/${params.id}/calls-sms-history/?limit=${limit}&recordType=${type}`).then((res) => {

            if (res.data) {
                setData(res.data.data)
                setUserDetails(res.data.header)
                let newar: any = {}

                //loop
                res.data.data.forEach((a: any) => {
                    if (newar[new Date(Number(a.createdDate)).toLocaleDateString()] === undefined) {
                        newar[new Date(Number(a.createdDate)).toLocaleDateString()] = [a];
                    } else {
                        newar[new Date(Number(a.createdDate)).toLocaleDateString()].push(a)
                    }
                })
                setFileterDate(newar)

                setLoading(false)
                setPaginate((prev: any) => {
                    return {
                        ...prev,
                        page: res.data.page,
                        totalDocs: res.data.totalDocs,
                        limit: res.data.limit
                    }
                })
            }
        }).catch((err) => {
            if (err.response?.status == 402) {
                window.location.href = "https://newbp.efone.ca"
                sessionStorage.removeItem("user")

            }
            if (err.response?.status == 401) {
                dispatch(authActionCreators.initRefreshToken())
            }
            setLoading(false)
        })
    }
    const getCall = () => {
        if (params.name === 'calls') {
            getInformationById(paginate.limit, 1)
        }
        else if (params.name === 'messages') {
            getInformationById(paginate.limit, 2)
        }
        else if (params.name === 'voiceMails') {
            getInformationById(paginate.limit, 3)
        }
    }

    React.useEffect(() => {
        if (params.name === 'calls') {
            getInformationById(paginate.limit, 1)
        }
    }, [params.id, params.name])
    React.useEffect(() => {
        if (params.name === 'messages') {
            getInformationById(paginate.limit, 2)
        }

    }, [params.id, params.name])
    React.useEffect(() => {
        if (params.name === 'voiceMails') {
            getInformationById(paginate.limit, 3)
        }

    }, [params.id, params.name])
    const handleCallModal = () => {
        setCallModal(!callModal)
    }
    const handleCall = (data: any, option: any) => {
        handleCallModal()
        setNewData({
            name: userdetails?.contactName ? userdetails.contactName : userdetails?.callerIdName,
            phoneNumber: userdetails?.phoneNumber
        })
        if (callingData?.callData && callingData?.callData?.phoneNumber !== "") {
            toast.error("Already you are in call", { position: toast.POSITION.TOP_RIGHT, autoClose: 2000 })
        }
        else {
            const oNumber = option.id ? option.id : ""
            const oName = option.name ? option.name : ""
            var Options: any = props.callOptions
            Options.extraHeaders = [`oNumber:${oNumber}`, `oName: ${oName} `]
            const newdata = {
                name: userdetails?.contactName ? userdetails.contactName : userdetails?.callerIdName,
                phoneNumber: userdetails?.phoneNumber,
                options: Options
            }
            dispatch(CallActionCreator.fetchStatus(newdata))
            dispatch(CallActionCreator.fetchData(true))


        }
    }

    const handleChangeMessage = (e: any) => {
        setSendMessage((prev: any) => {
            return {
                ...prev,
                message: e.target.value
            }
        })
        setMessage(e.target.value)
    }
    const handlePopupModalUpdate = () => {
        setUpdateId(params.id)
        setEditModal(!editModal)
    }
    React.useEffect(() => {
        if (props.messages.recordHdrId == data[0]?.recordHdrId) {
            const res = props.messages
            const myObj = {
                recordType: 2,
                textMessage: res.smsText,
                recordHdrId: props.messages.recordHdrId,
                createdDate: res.time,
                direction: res.type,
                duration: res.duration,
                callerIdName: res.callerIdName,
                phoneNumber: res.number,
            }
            const newTodos = [...data];
            newTodos.push(myObj);
            let newar: any = {}

            //loop
            newTodos.forEach((a: any) => {
                if (newar[new Date(Number(a.createdDate)).toLocaleDateString()] === undefined) {
                    newar[new Date(Number(a.createdDate)).toLocaleDateString()] = [a];
                } else {
                    newar[new Date(Number(a.createdDate)).toLocaleDateString()].push(a)
                }
            })
            setFileterDate(newar)
            setData(newTodos)
        }
    }, [props.messages])
    const getInformationonScrollById = (limit: any, type: any) => {
        setScrollLoading(true)
        axiosInstance.get(`/my-activities/${params.id}/calls-sms-history/?limit=${limit}&recordType=${type}`).then((res) => {

            if (res.data) {
                setData(res.data.data)
                let newar: any = {}

                //loop
                res.data.data.forEach((a: any) => {
                    if (newar[new Date(Number(a.createdDate)).toLocaleDateString()] === undefined) {
                        newar[new Date(Number(a.createdDate)).toLocaleDateString()] = [a];
                    } else {
                        newar[new Date(Number(a.createdDate)).toLocaleDateString()].push(a)
                    }
                })
                setFileterDate(newar)

                setScrollLoading(false)
                setPaginate((prev: any) => {
                    return {
                        ...prev,
                        page: res.data.page,
                        totalDocs: res.data.totalDocs,
                        limit: res.data.limit
                    }
                })
            }
        }).catch((err) => {
            if (err.response?.status == 402) {
                window.location.href = "https://newbp.efone.ca"
                sessionStorage.removeItem("user")

            }
            if (err.response?.status == 401) {
                dispatch(authActionCreators.initRefreshToken())
            }
            setScrollLoading(false)
        })
    }
    const loadMore = () => {
        if (params.name === 'calls') {
            if (paginate.totalDocs > paginate.limit) {
                getInformationonScrollById(paginate.limit + 50, 1)
            }
        }
        else if (params.name === 'messages') {
            if (paginate.totalDocs > paginate.limit) {
                getInformationonScrollById(paginate.limit + 50, 2)
            }
        }
        else if (params.name === 'voiceMails') {
            if (paginate.totalDocs > paginate.limit) {
                getInformationonScrollById(paginate.limit + 50, 3)
            }
        }
    }
    const onScroll = () => {
        if (listInnerRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
            const scrolledTop = Math.floor(scrollTop / 100)
            // console.log("---scrollTop", Math.floor(scrollTop / 100), clientHeight)
            if (scrolledTop == 0) {
                if (params.name === 'calls') {
                    if (paginate.totalDocs > paginate.limit) {
                        getInformationonScrollById(paginate.limit + 50, 1)
                    }
                }
                else if (params.name === 'messages') {
                    if (paginate.totalDocs > paginate.limit) {
                        getInformationonScrollById(paginate.limit + 50, 2)
                    }
                }
                else if (params.name === 'voiceMails') {
                    if (paginate.totalDocs > paginate.limit) {
                        getInformationonScrollById(paginate.limit + 50, 3)
                    }
                }
            }
            // if (scrollTop + clientHeight === scrollHeight) {
            //     console.log("reached bottom");
            // }
        }
    };

    return (
        <>
            {
                openModal && (
                    <div>
                        <Backdrop handlePopupModal={handlePopupModalOpen} />
                        <div style={{}}>
                            <AddModal title="Add New Contact" handlePopupModal={handlePopupModalOpen} >
                                <div className="add_user_popup" style={{ width: "500px" }}>
                                    <AddContact getCall={getCall} handleModal={handlePopupModalOpen} data={userdetails} paginate={paginate} />
                                </div>
                            </AddModal>
                        </div>
                    </div>

                )
            }
            {
                editModal && (
                    <div>
                        <Backdrop handlePopupModal={handlePopupModalUpdate} />
                        <div style={{}}>
                            <AddModal title="Update Contact" handlePopupModal={handlePopupModalUpdate}>
                                <div className="add_user_popup" style={{ width: "500px" }}>
                                    <UpdateContact getCall={getCall} handleModal={handlePopupModalUpdate} data={userdetails} phoneNumber={data[0]?.phoneNumber} paginate={paginate} id={Updateid} />
                                </div>
                            </AddModal>
                        </div>
                    </div>
                )
            }
            <CardContent>
                <Grid container spacing={1}>
                    <Grid item lg={8} md={8} sm={8}>
                        <Card className={classes.cardAlignment}>
                            <Card className={classes.cardBackground}>
                                <Grid container spacing={2}>
                                    <Grid item lg={8} md={8} sm={8}>
                                        <CardContent className={classes.cardContent1}>
                                            <Avatar className={classes.avatar}>{userdetails?.contactName ? userdetails?.contactName?.charAt(0) : userdetails?.callerIdName?.charAt(0)}</Avatar>
                                            <CardContent className={classes.align}>
                                                <div>
                                                    <Typography className={classes.headSmall} align="center">{userdetails?.contactName ? userdetails.contactName : userdetails?.callerIdName}</Typography>
                                                    <Typography align="center" className={classes.othersText}>{userdetails?.phoneNumber}</Typography>
                                                    {/* <Icon icon={starFilled} /> */}
                                                </div>
                                            </CardContent>
                                        </CardContent>
                                    </Grid>
                                    <Grid item lg={4} md={4} sm={4}>
                                        <div className={classes.divButtons}>
                                            {userdetails?.contactName === "" &&
                                                <div onClick={handlePopupModalOpen} style={{ cursor: "pointer" }}>
                                                    <FaUserPlus className={classes.userIcon} />
                                                </div>
                                            }

                                            <div

                                                className={classes.buttonicons}>
                                                <PhoneIcon className={classes.iconcolor} style={{ marginLeft: "11px", marginTop: "2px", cursor: "pointer" }} onClick={() => handleCall(data, option)} />
                                                <Divider orientation="vertical" flexItem className={classes.dividericon} />
                                                <IconButton onClick={handleToggle} ref={anchorRef}>
                                                    <ArrowDropDownIcon className={classes.iconcolor} style={{ marginLeft: "-11px" }} />
                                                </IconButton>
                                                <Popper
                                                    open={openTog}
                                                    anchorEl={anchorRef.current}
                                                    role={undefined}
                                                    transition
                                                >
                                                    {({ TransitionProps, placement }) => (
                                                        <Grow
                                                            {...TransitionProps}
                                                            style={{
                                                                transformOrigin:
                                                                    placement === "bottom" ? "center bottom" : "center bottom",

                                                            }}
                                                        >
                                                            <Paper className={classes.paper}>
                                                                <ClickAwayListener onClickAway={handleCloseTog}>
                                                                    <MenuList
                                                                        autoFocusItem={openTog}
                                                                        id="menu-list-grow"
                                                                        onKeyDown={handleListKeyDown}
                                                                    >


                                                                        <MenuItem>
                                                                            <FormControl>
                                                                                {outboundData.map((opt: any, i: any) => {
                                                                                    return <FormGroup key={opt.id}>
                                                                                        <FormControlLabel
                                                                                            control={<Checkbox onChange={() => handleChangeOpt(opt)} name={opt.id}
                                                                                                checked={opt.id == option?.id} />}
                                                                                            label={opt.value + "  (" + opt.name + ")"}
                                                                                        />

                                                                                    </FormGroup>
                                                                                })}

                                                                            </FormControl>
                                                                        </MenuItem>

                                                                    </MenuList>
                                                                </ClickAwayListener>

                                                            </Paper>
                                                        </Grow>
                                                    )}
                                                </Popper>
                                            </div>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Card>
                            <Divider className={classes.dividercolor} />
                            {Scrollloading &&
                                <div style={{ margin: 'auto', alignItems: "center", color: "lightgray", marginBottom: "80px", textAlign: 'center', width: "100%" }}>
                                    {/* <CircularProgress disableShrink /> */}
                                    <img src={logo} alt="loading..." width="30px" height="30px" />
                                </div>}
                            {loading ?
                                <div style={{ margin: 'auto', alignItems: "center", color: "lightgray", marginBottom: "80px", textAlign: 'center', width: "100%" }}>
                                    {/* <CircularProgress disableShrink /> */}
                                    <img src={logo} alt="loading..." width="30px" height="30px" />
                                </div> :
                                //  params.name === "calls"?
                                //     <Calls data={data} /> :
                                //     data[0]?.recordType === 2 ?
                                //         <Messages data={data} /> :
                                //         data[0]?.recordType === 3 ?
                                //             <VoiceMail data={data} /> : ""
                                <div
                                    // onScroll={onScroll}
                                    ref={listInnerRef}
                                    style={{ overflowY: "scroll", overflowX: "hidden", maxHeight: "400px", padding: "6px" }}>
                                    {/* <InfiniteScroll
                                        dataLength={paginate.totalDocs}
                                        next={loadMore}
                                        hasMore={true}
                                        loader={<CircularProgress />}
                                    > */}
                                    {Object.keys(filteredData).map((data: any) => {
                                        return (
                                            <>
                                                <Typography style={{ textAlign: "center" }}>
                                                    {Math.floor(getDifferenceInDays(new Date(), new Date(data))) === 0 && 'Today'}
                                                    {Math.floor(getDifferenceInDays(new Date(), new Date(data))) === 1 && 'Yesterday'}
                                                    {Math.floor(getDifferenceInDays(new Date(), new Date(data))) > 1 && data} </Typography>
                                                {filteredData[data].length > 0 && (
                                                    filteredData[data].map((item: any) => {
                                                        return (
                                                            <AllTypes data={item} paginate={paginate}
                                                            />

                                                        )
                                                    })
                                                )}
                                            </>

                                        )
                                    })}
                                    {/* </InfiniteScroll> */}
                                </div>
                            }
                            <Grid container spacing={2} style={{ marginTop: 20, alignItems: "center" }}>
                                <Grid item md={2}>
                                    <div style={{ display: "flex", alignItems: "center", }}>
                                        <IconButton>
                                            <BsCameraFill />
                                        </IconButton>
                                        <IconButton>
                                            <MdInsertPhoto type="file" />
                                        </IconButton>
                                    </div>
                                </Grid>
                                <Grid item md={9}>
                                    <input placeholder='Write message...' className={classes.sendIcon}
                                        value={message}
                                        autoFocus
                                        onChange={handleChangeMessage} />
                                </Grid>
                                {message === '' ?
                                    <Grid item md={1}>
                                        <IconButton >
                                            <MdSend className={classes.sendIconColor} />
                                        </IconButton>
                                    </Grid>
                                    :
                                    <Grid item md={1}>
                                        <IconButton >
                                            <MdSend className={classes.sendIconColor1} onClick={handleSubmit} />
                                        </IconButton>
                                    </Grid>
                                }
                            </Grid>
                        </Card>
                    </Grid>
                    {userdetails?.contactName &&
                        <Grid item lg={4} md={4} sm={4}>
                            <Card>
                                <CardContent>
                                    <CardContent>
                                        <AiFillCloseCircle className={classes.cirleFilled} />
                                        <div className={classes.cardContent1}>
                                            <Avatar className={classes.avatar}>{userdetails?.contactName ? userdetails?.contactName?.charAt(0) : userdetails?.callerIdName?.charAt(0)}</Avatar>
                                            <CardContent className={classes.align}>
                                                <div>
                                                    <Typography className={classes.headSmall} align="center">{userdetails?.contactName ? userdetails.contactName : userdetails?.callerIdName}</Typography>
                                                </div>
                                            </CardContent>
                                            <div className={classes.editContact} onClick={handlePopupModalUpdate} style={{ cursor: "pointer" }}>
                                                <Typography className={classes.editContentText}>Edit Contact</Typography>
                                                <AiOutlineEdit className={classes.editIconSpace} />
                                            </div>
                                        </div>
                                        <div>
                                            <div className={classes.userProfileIcons}>

                                                <RiUser3Fill className={classes.bxUserIcon} />

                                                <Typography className={classes.about}>About</Typography>
                                            </div>
                                        </div>
                                    </CardContent>
                                    <Typography className={classes.number}>{userdetails?.phoneNumber}</Typography>
                                    <Divider className={classes.dividercolor} />
                                    <CardContent>
                                        <Accordion >
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel1d-content"
                                                id="panel1d-header"
                                                onClick={handleChangeAccrodian}
                                            >
                                                <div className={classes.noteIcons} >
                                                    <MdScheduleSend className={classes.schudleSendIcon} />
                                                    <Typography className={classes.noteSchudleOne}>Notes & Schedule</Typography>
                                                </div>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <CardContent>
                                                    <Card className={classes.cardBackground}>
                                                        <AiFillStar className={classes.starIcon} />
                                                        <CardContent>
                                                            <div className={classes.richrdContent} >
                                                                <Typography className={classes.richrdText} >Richard</Typography>
                                                                <Typography className={classes.richardTime} >1:58</Typography>
                                                            </div>
                                                            <Typography className={classes.content} >Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sunt rem reprehenderit ab aliquam non atque commodi nulla alias animi accusantium incidunt explicabo.</Typography>
                                                        </CardContent>
                                                    </Card>
                                                    <Button
                                                        variant="contained"
                                                        size='small'
                                                        disableRipple
                                                        className={classes.squareAdd}
                                                        startIcon={<BiMessageSquareAdd />}
                                                        onClick={handleOpen}
                                                    >
                                                        Add Schedule
                                                    </Button>
                                                    <Card className={classes.cardBackground}>
                                                        <CardContent>
                                                            <div className={classes.richrdContent} >
                                                                <Typography className={classes.scheduleText} >Schedule for 22 may</Typography>
                                                                <Typography className={classes.scheduleTime} >1:58</Typography>
                                                            </div>
                                                            <Typography className={classes.content} >Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sunt rem reprehenderit ab aliquam non atque commodi nulla alias animi accusantium incidunt explicabo.</Typography>
                                                        </CardContent>
                                                    </Card>
                                                    <Divider className={classes.dividercolor} />
                                                    <Grid container spacing={2}>
                                                        <Grid item md={2}></Grid>
                                                        <Grid item md={8}>
                                                            <Button
                                                                variant="outlined"
                                                                size='small'
                                                                fullWidth
                                                                disableRipple
                                                                className={classes.blockbutton}
                                                                onClick={callHandleOpen}
                                                                startIcon={<ImBlocked className={classes.blockedContactIcon} />}
                                                            >
                                                                Block Contact
                                                            </Button>
                                                        </Grid>
                                                        <Grid item md={2}></Grid>
                                                    </Grid>
                                                </CardContent>
                                            </AccordionDetails>
                                        </Accordion>
                                    </CardContent>
                                    <Modal open={open}>
                                        <Card style={modalStyle} className={classes.paper}>
                                            <CardHeader
                                                name={'Add Schedule'}
                                                onButtonClick={handleOpen}
                                                onCloseClick={handleClose}
                                                close={true}
                                            />
                                            <Divider className={classes.divider} />
                                            <CardContent>
                                                <Grid container spacing={2}>
                                                    <Grid item md={6} >
                                                        <Typography>Select Date</Typography>
                                                        <TextField
                                                            variant='outlined'
                                                            type='date'
                                                            placeholder='Select Date'
                                                            fullWidth
                                                            size='small'
                                                        />
                                                    </Grid>
                                                    <Grid item md={6}>
                                                        <Typography>Select Time</Typography>
                                                        <TextField
                                                            variant='outlined'
                                                            type="time"
                                                            //defaultValue="07:30"
                                                            fullWidth
                                                            size='small'
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            inputProps={{
                                                                step: 300, // 5 min
                                                            }}
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <Grid container spacing={2}>
                                                    <Grid item md={12}>
                                                        <TextareaAutosize
                                                            className={classes.textArea}
                                                            placeholder="Add note here "
                                                            minRows={4}
                                                            maxRows={5}
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <Grid container spacing={2}>
                                                    <Grid item md={8}></Grid>
                                                    <Grid item md={4}>
                                                        <Button
                                                            variant="outlined"
                                                            color="primary"
                                                            size="small"

                                                            className={classes.button}
                                                            startIcon={<SaveIcon />}
                                                        >
                                                            Save
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </CardContent>
                                        </Card>
                                    </Modal>

                                </CardContent>
                            </Card>
                        </Grid>
                    }
                </Grid>
            </CardContent>




        </>
    );
};
export default UserOne