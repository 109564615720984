import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  contacttypography: {
    color: '#565656',
    fontSize: '15px',
    fontWeight: 600,
    paddingBottom: '0px',
    fontFamily: 'Work Sans',
  },
  contacttextfield: {
    color: '#BEBEBE',
  },
  selectcontact: {
    marginTop: 5
  },
  errorMessage: {
    color: 'red',
    fontSize: '0.8rem',
    fontWeight: 500,
    marginTop: 0,
    marginBottom: 0,
  },
  insertbody: {
    color: '#565656',
    fontSize: '12px',
    fontWeight: 400,
    fontFamily: 'Work Sans',
    marginTop: -5
  },
  help: {
    color: '#565656',
    fontSize: '12px',
    fontFamily: 'Work Sans',
    fontWeight: 600,
    marginTop: -5
  },
  deviceWidth: {
    width: 30,
    height: 30,
    borderRadius: 50,
    color: 'black',
    // backgroundColor: '#F3F5FF',
    // fill:'#4D72F8',
    padding: '8px',
  },
  addcontactbutton1: {
    padding: '6px 20px',
    fontSize: '12px',
    color: '#2645AD',
    background: '#DFDFDF',
    boxSizing: 'border-box',
    fontStyle: 'normal',
    marginTop: '10px',
    textAlign: 'center',
    fontFamily: 'Work Sans',
    fontWeight: 500,
    letterSpacing: '0.1px',
    textTransform: 'none',
    '&:hover': {
      background: '#DFDFDF',
      color: '#2645AD'
    }
  },
  addBox: {
    color: '#565656',
  },

}))