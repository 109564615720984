import axiosInstance from "../../environment/axiosInstances";
import RefreshTokenServices from '../../environment/RefreshToken'
import { toast } from 'react-toastify'
import * as userAuthActionCreators from '../../Store/Actions/AuthActionCreator'
import 'react-toastify/dist/ReactToastify.css'
toast.configure()

class ContactsServices {
    getContactsList = async (limit: any, page: any) => {
        try {
            const response = await axiosInstance.get(`/contacts?limit=${limit}&page=${page}`);
            if (response.data) {
                const data = response.data;
                return { success: true, data: data };
            } else {

                return { success: false, message: response.data.message }
            }

        } catch (error: any) {
            if (error.response?.status == 402) {
                window.location.href = "https://newbp.efone.ca"
                sessionStorage.removeItem("user")

            }

            return {
                success: false, status: error.response.status, message: error.message === "Network Error" ? "Network Error" : error.response.data.message,
            };
        }
    };
    getById = async (id: any) => {
        try {
            const response = await axiosInstance.get(`/contacts/${id}`);
            if (response.data) {
                const data = response.data;
                return { success: true, data: data };
            } else {
                return { success: false, message: response.data.message }
            }
        } catch (error: any) {
            if (error.response?.status == 402) {
                window.location.href = "https://newbp.efone.ca"
                sessionStorage.removeItem("user")

            }

            return {
                success: false, status: error.response?.status, message: error.message === "Network Error" ? "Network Error" : error.response.data.message,
            };
        }
    };

    getcontactsearch = async (limit: any, page: any, data: any) => {
        try {
            const response = await axiosInstance.get(`/contacts?limit=${limit}&page=${page}&key=${data.searchValue}`);
            if (response.data) {
                const data = response.data;
                return { success: true, data: data };
            } else {

                return { success: false, message: response?.data?.message, data: [] };
            }
        } catch (error: any) {
            // if (error.response.status == 401) {
            //     RefreshTokenServices.getRefreshToken()
            // }
            if (error.response?.status == 402) {
                window.location.href = "https://newbp.efone.ca"
                sessionStorage.removeItem("user")

            }

            return {

                success: false,
                status: error.response?.status,
                message:
                    error.message === "Network Error"
                        ? "Network Error"
                        : error.response.data.message,
            };
        }
    }

    updateContactList = async (id: any, data: any) => {
        try {
            const response = await axiosInstance.patch(`/contacts/${id}`, data);
            if (response.data) {
                const data = response.data;
                toast.success(response.data.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 1000
                })
                return { success: true, data: data };
            } else {
                return { success: false, message: response.data.message }
            }
        } catch (error: any) {
            if (error.response?.status == 402) {
                window.location.href = "https://newbp.efone.ca"
                sessionStorage.removeItem("user")

            }
            // if (error.response.status == 401) {
            //     RefreshTokenServices.getRefreshToken()
            // }
            toast.error(error.response.data.message, { position: toast.POSITION.TOP_RIGHT, autoClose: 1000 })
            return {
                success: false, message: error.message === "Network Error" ? "Network Error" : error.response.data.message,
            };
        }
    }

    deleteContactList = async (id: any) => {

        try {
            const response = await axiosInstance.delete(`/contacts/${id}`);

            if (response.data) {
                const data = response.data;
                toast.success(response.data.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 1000
                })
                return { success: true, data: data };
            } else {
                return { success: false, message: response.data.message }
            }
        } catch (error: any) {
            if (error.response?.status == 402) {
                window.location.href = "https://newbp.efone.ca"
                sessionStorage.removeItem("user")

            }
            // if (error.response.status == 401) {
            //     RefreshTokenServices.getRefreshToken()
            // }
            toast.error(error.response.data.message, { position: toast.POSITION.TOP_RIGHT, autoClose: 1000 })
            return {
                success: false, message: error.message === "Network Error" ? "Network Error" : error.response.data.message,
            };
        }
    };


}

const Services = new ContactsServices();
export default Services;