import { makeStyles } from '@material-ui/core/styles';
export const useStyles = makeStyles((theme) => ({
  activitytext: {
    fontSize: '24px',
    fontWeight: 700,
    fontFamily: 'Work Sans',
    padding: '10px',
  },
  button4: {
    fontSize: 12,
    fontFamily: 'Work Sans',
    fontStyle: 'normal',
    fontWeight: 500,
    textAlign: 'center',
    letterSpacing: 0.1,
    textTransform: 'none',
    color: '#D1D1D1',
    background: '#FFFFFF',
    borderRadius: '50px',
    border: '0.649129px solid #D1D1D1',
    boxSizing: 'border-box',
    padding: '8px 26px',
    '&:hover': {
      backgroundColor: '#FFFFFF',
    },
    '&:active': {
      backgroundColor: '#FFFFFF',
    },
  },
  dnddisplay: {
    display: 'flex',
    alignItems: 'center'
  },
  buttondisplay: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  reschedule: {

  },
  dndtext: {
    fontSize: '15px',
    color: '#565656',
    marginLeft: '10px',
    fontFamily: 'work sans'
  },
  typographyleft: {
    marginLeft: '10px',
  },
  serchBorder: {
    borderRadius: 50,
  },
  dividercolor: {
    borderTop: '1px solid #D2D2D2',
    margin: '15px 0',
  },
  dividerType2: {
    borderTop: '1px solid #D2D2D2',
    margin: '0 0 15px',
  },
  tabHeight: {
    height: '66px'
  },
  tabscoloractive: {
    color: '#2645AD',
    fontSize: '16px',
    fontWeight: 600,
    fontFamily: 'work Sans',
    fontStyle: 'SemiBold',
    textTransform: 'none',
    borderRight: '2px solid #DFDFDF',
  },
  tabscolor: {
    color: '#565656',
    fontSize: '16px',
    fontWeight: 600,
    fontFamily: 'work Sans',
    fontStyle: 'SemiBold',
    textTransform: 'none',
    borderRight: '2px solid #DFDFDF',
  },
  callEnd2: {
    borderWidth: 1,
    borderRadius: 50,
    minWidth: 0,
    padding: 5,
    margin: 10,
    background: '#AE5E00',
    border: '1px solid #EBEBEB',
    '&:hover': {
      backgroundColor: '#AE5E00',
    },
    [theme.breakpoints.down('md')]: {
      padding: 2
    }

  },
  callEndThree: {
    borderWidth: 1,
    borderRadius: 50,
    minWidth: 0,
    padding: 5,
    margin: 10,
    background: '#00A707',
    border: '1px solid #EBEBEB',
    '&:hover': {
      backgroundColor: '#00A707',
    },
    [theme.breakpoints.down('md')]: {
      padding: 2
    }

  },
  userIcon1: {
    width: 25,
    height: 25,
    color: '#FFFFFF',
  },
  people2: {
    borderWidth: 1,
    borderRadius: 50,
    minWidth: 0,
    padding: 5,
    margin: 10,
    background: '#12DC00',
    border: '1px solid #EBEBEB',
    '&:hover': {
      background: '#12DC00',
    },
    '&:active': {
      backgroundColor: '#12DC00',
    },
    [theme.breakpoints.down('md')]: {
      padding: 2
    }
  },
  volumeIcon2: {
    borderWidth: 1,
    borderRadius: 50,
    minWidth: 0,
    padding: 5,
    margin: 10,
    background: '#0C2DD1',
    border: '1px solid #EBEBEB',
    '&:hover': {
      background: '#0C2DD1',
    },
    [theme.breakpoints.down('md')]: {
      padding: 2
    }
  },
  recordVoice2: {
    borderWidth: 1,
    borderRadius: 50,
    minWidth: 0,
    padding: 5,
    margin: 10,
    background: '#AF0000',
    border: '1px solid #EBEBEB',
    '&:hover': {
      background: '#AF0000',
    },
    [theme.breakpoints.down('md')]: {
      padding: 2
    }
  },
  tablecell: {
    borderBottom: '10px',
    padding: '5px',
    color: '#838383',
    fontWeight: 500,
    fontFamily: 'Work Sans',
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      padding: 0
    }
  },
  tablebackgroundcolor: {
    backgroundColor: '#F6F6F6',
  },
  typographytext: {
    textAlign: 'center',
    fontSize: '10px',
    padding: '5px',
    color: '#838383',
    fontWeight: 500,
    fontFamily: 'Work Sans',
    marginRight: 10
  },
  number: {
    fontFamily: 'Work Sans',
    fontWeight: 500,
  },
  phonecallbackiconcolor: {
    color: '#27AE60',
    border: '1px solid #27AE60',
    width: 40,
    height: 40,
    [theme.breakpoints.down('md')]: {
      padding: 4
    }
  },
  phonecallmissediconcolor: {
    color: '#2F80ED',
    width: 40,
    height: 40,

    border: '1px solid #2F80ED',
    [theme.breakpoints.down('md')]: {
      padding: 4
    }
  },
  phonecallmissedicongreen: {
    color: 'green',
    width: 40,
    height: 40,
    padding: 1,
    border: '1px solid green',
    [theme.breakpoints.down('md')]: {
      padding: 4
    },
  },
  linkdisplay: {
    display: 'flex',
    justifyContent: 'space-around',
  },
  linkColor: {
    color: '#AF0000',
    fontSize: 15,
    fontWeight: 600
  }
}));
