import { CallingModule } from '../Actions/Actions';
const callingState = {
    callData: {},
    CallStatus: false
}
export const callingReducer = (state = callingState, action: any) => {
    switch (action.type) {
        case CallingModule.GET_STATUS:
            return {
                ...state,
                callData: action.data
            }
        case CallingModule.GET_CALL_STATUS_REQUEST:
            return {
                ...state,
                CallStatus: action.status
            }

        default:
            return state
    }
}