import React from 'react';
import {
    Card,
    CardContent,
    Grid,
    Typography,
    Divider,
    withStyles,
    Switch,
    Button,
    Tabs,
    Tab,
    TableContainer,
    Table,
    TableBody,
    TableRow,
    TextField,
    makeStyles,
    TableCell,
    CircularProgress,
    IconButton,
} from '@material-ui/core';
import { BsRecord2Fill, BsCardText } from 'react-icons/bs'
import { VscMerge } from 'react-icons/vsc'
import { AiOutlineCalendar } from 'react-icons/ai'
import Avatar from '@material-ui/core/Avatar';
import Ellipse from '../../assets/icons/Ellipse.png';
import TabPanel from '@material-ui/lab/TabPanel';
import TabContext from '@material-ui/lab/TabContext';
import { useStyles } from '../Styles';
import PhoneCallbackIcon from '@material-ui/icons/PhoneCallback';
import CallEndIcon from '@material-ui/icons/CallEnd';
import PeopleIcon from '@material-ui/icons/People';
import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver';
import VolumeUpIcon from '@material-ui/icons/VolumeUp';
import Blacksearchicon from '../../assets/icons/blacksearchicon.png';
import AppleIcon from '@material-ui/icons/Apple';
import SmsRoundedIcon from '@material-ui/icons/SmsRounded';
import AndroidOutlinedIcon from '@material-ui/icons/AndroidOutlined';
import BallotIcon from '@material-ui/icons/Ballot';
// import { MyactivityMessages } from '../../Dashboard/Messages';
// import { MyactivityCalls } from '../../Dashboard/Calls';
// import { MyactivityFollowup } from '../../Dashboard/Followup';
import { TableHeaderComponent } from '../../../Components/Table/Table'
import PhoneMissedIcon from '@material-ui/icons/PhoneMissed';
import PhoneIcon from '@material-ui/icons/Phone';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import AddIcCallIcon from '@material-ui/icons/AddIcCall';
import { Badge } from '@material-ui/core';
import ListAltSharpIcon from '@material-ui/icons/ListAltSharp';
import Tooltip from '@material-ui/core/Tooltip';
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import axiosInstance from '../../../environment/axiosInstances'
import ReactAudioPlayer from 'react-audio-player';
import { useParams } from "react-router-dom";
import * as authActionCreators from '../../../Store/Actions/AuthActionCreator'
import * as DepartmentActionCreators from '../../../Store/Actions/DepartmentsActionXCreators'
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux'
import Moment from 'moment'
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';

function Voicemail(props: any) {
    const classes = useStyles()
    const [id, setId] = React.useState<any>('')
    const params = useParams();
    const dispatch = useDispatch()
    const [VoiceMail, setVoicemail] = React.useState([])
    const [loading, setLoading] = React.useState<any>(false);
    const [paginate, setPaginate] = React.useState<any>({
        page: 1,
        totalDocs: 0,
        limit: 10
    })
    const fetchVoiceMail = (limit: any, page: any, type: any) => {
        setLoading(true)
        axiosInstance.get(`/departments/${params.id}/voice-mails?limit=${limit}&page=${page}&type=${type}`).then((res) => {

            if (res.data) {
                setVoicemail(res.data.data)
                setLoading(false)
                setPaginate((prev: any) => {
                    return {
                        ...prev,
                        page: res.data.page,
                        totalDocs: res.data.totalDocs,
                        limit: res.data.limit
                    }
                })
            }
        }).catch((err) => {
            setLoading(false)
            if (err.response?.status == 402) {
                window.location.href = "https://newbp.efone.ca"
                sessionStorage.removeItem("user")

            }
            if (err.response?.status == 401) {
                dispatch(authActionCreators.initRefreshToken())
            }
        })
    }
    React.useEffect(() => {
        fetchVoiceMail(paginate.limit, paginate.page, props.type)
    }, [props.type])
    //  React.useEffect(() => {
    //     fetchVoiceMail(paginate.limit, paginate.page, props.type)
    // }, [id])
    React.useEffect(() => {
        setId(params?.id)
    }, [])

    const handleLoadMetadata = (meta: any) => {
        const { duration } = meta.target;
        const styles = makeStyles({

        })
        console.log(duration);
    }
    const handleChangePage = (data: any, value: any) => {
        setPaginate((prev: any) => {
            return {
                ...prev,
                page: value
            }
        })

        fetchVoiceMail(paginate.limit, value, props.type)

    }
    const tableHeader = ['User', 'Phone Number', 'eFone Number', 'VoiceMail', 'Date']
    return (
        <>
            <TableContainer>
                <Table className={classes.table}>
                    <TableHeaderComponent theadData={tableHeader} />
                    <TableBody>
                        {VoiceMail && VoiceMail.length > 0 ? VoiceMail.map((item: any, i: any) => {
                            var data = parseInt(item?.createdDate)
                            var dataa = Moment(data).format('YYYY MMM DD hh:mm A')

                            return (
                                <TableRow className={classes.tablebackgroundcolor} key={item.id}>
                                    <TableCell className={classes.tablecell}>
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            <IconButton>
                                                <CallEndIcon />
                                            </IconButton>
                                            {item.userId ?
                                                <div>
                                                    {item.userId.firstName} {item.userId.lastName}
                                                </div>
                                                :
                                                item.deparmentId ?
                                                    <div>
                                                        {item.deparmentId.deparmentName}
                                                    </div>
                                                    : ""
                                            }
                                        </div>
                                    </TableCell>

                                    <TableCell style={{ marginTop: 10 }} className={classes.tablecell}>
                                        <Link to={{ pathname: `/department/userDetails/${'voiceMail'}/${item?.id}/${item?.departmentId?.id}` }} className={classes.linkUnderline} >
                                            {item.callerIdName}
                                        </Link>
                                    </TableCell>
                                    <TableCell className={classes.tablecell}>
                                        {item.phoneNumber}
                                        {/* <br></br>
                                        <span>{item.smsText}</span> */}
                                    </TableCell>

                                    <TableCell className={classes.tablecell}>
                                        <ReactAudioPlayer
                                            controls
                                            className={classes.audioPlayer}
                                            onLoadedMetadata={handleLoadMetadata}
                                            src={item.vmAudioPath}
                                        />
                                    </TableCell>
                                    <TableCell className={classes.tablecell}>{dataa}</TableCell>
                                </TableRow>
                            )
                        })
                            :

                            loading ? <div className='loader_class'>
                                <div style={{ display: 'grid', alignItems: 'center', margin: '30px 500px' }}>
                                    <CircularProgress disableShrink />
                                </div>
                            </div> : VoiceMail.length <= 0 &&
                            <TableRow>
                                <TableCell className={classes.tablecell}></TableCell>
                                <TableCell className={classes.tablecell}></TableCell>
                                <TableCell className={classes.tablecell}>No Data</TableCell>
                                <TableCell className={classes.tablecell}></TableCell>
                                <TableCell className={classes.tablecell}></TableCell>
                            </TableRow>
                            // <p style={{ textAlign: 'center' }}>No Data</p>

                        }

                        {/* <TableRow>
                        <TableCell className={classes.tablecell}>
                            <IconButton>
                                <CallEndIcon />
                            </IconButton>
                        </TableCell>
                        <TableCell className={classes.tablecell}>+1 (123)878 9998</TableCell>
                        <TableCell className={classes.tablecell}></TableCell>
                        <TableCell className={classes.tablecell}></TableCell>
                        <TableCell className={classes.prettoslider}>

                            {/* <PrettoSlider

                                valueLabelDisplay="auto"
                                aria-label="pretto slider"
                                defaultValue={20}
                            />&nbsp; &nbsp;00:50 */}
                        {/* </TableCell>
                        <TableCell className={classes.tablecell}>Tuesday</TableCell>
                    </TableRow> */}
                    </TableBody>
                </Table>
            </TableContainer>
            {VoiceMail.length <= 0 ? "" :
                <Stack spacing={2} style={{ marginTop: 25, marginBottom: 25, float: "right" }}>
                    <Pagination count={paginate.totalPages} page={paginate.page} onChange={handleChangePage} variant="outlined" color="secondary" />
                </Stack>
            }
        </>
    )
}

export default Voicemail