
import { useStyles } from './styles';
import { Grid } from '@material-ui/core';
import Livecalls from './Livecalls/index';
const Index = (props: any) => {
    const classes = useStyles();

    return (
        <>

            <div className={classes.container1}>
                <Grid container spacing={5}>
                    <Grid item md={12} sm={12} xs={12}>
                        <Livecalls
                            coolPhone={props.coolPhone}
                            callOptions={props.callOptions}
                            callStatus={props.callStatus} />
                    </Grid>
                </Grid>
            </div>
        </>

    );
};
export default Index