import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
    tablebackgroundcolor: {
        backgroundColor: '#F6F6F6',
    },
    profile: {
        width: 32,
        height: 28,
        background: 'white',
    },
    linkUnderline: {
        textDecoration: 'none',
    },
    tablecell: {
        borderBottom: '10px',
        padding: '5px',
        color: '#838383',
        fontWeight: 500,
        fontFamily: 'Work Sans',
        //textAlign: 'center'
    },
    items: {
        background: '#EBEBEB',
        color: '#565656',
        fontSize: 14
    },
    reschedule: {
        color: '#2645AD',
        marginLeft: 6,
        fontSize: 15,
        fontWeight: 500,
        fontFamily: 'Work Sans',

    },
    paper: {
        width: 300,
        background: '#F3F5FF',
        boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.25)',
        borderRadius: 5
    },
    modal: {
        position: "fixed",
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%)",
        /* width: 530px; */
        /* width: 530px; */
        backgroundColor: "#fff",
        zIndex: 1500,
        borderRadius: "6px",
    },
    missediconcolor: {
        color: '#AF0000'
    },
    dividericon: {
        height: '26px',
        borderRight: '2px solid #FFFFFF',
    },
    tableCell: {
        padding: 10,
        fontSize: 16,
        fontFamily: 'Work Sans',
        fontStyle: 'normal',
        fontWeight: 600,
        textAlign: "center",
        letterSpacing: 0.217535,
        color: '#545454',
        borderRight: '1px solid lightgrey',
    },
    table: {
        tableLayout: 'fixed',
    },
    tableHead: {
        backgroundColor: '#f4f4f4',
    },
    buttonicons: {
        height: '26px',
        display: "flex",
        width: '87px',
        borderRadius: '50px',
        backgroundColor: '#2645AD',
    },
    iconcolor: {
        color: '#FFFFFF',
        marginLeft: "-6px"
    },
    incomingiconcolor: {
        color: '#B79A00',
    },
    addcalliconcolor: {
        color: '#00A707',
    },
    outgoingcalliconcolor: {
        color: "#2645AD",
    },
}));