import React from 'react';
import {
    Card,
    CardContent,
    Grid,
    Typography,
    Divider,
    withStyles,
    Switch,
    Button,
    Tabs,
    Tab,
    TableContainer,
    Table,
    TableBody,
    TableRow,
    TableCell,
    IconButton,
    Link,
    TextField,

} from '@material-ui/core';
import { useStyles } from '../styles'
import PhoneCallbackIcon from '@material-ui/icons/PhoneCallback';
import AppleIcon from '@material-ui/icons/Apple';
import { BiPause } from 'react-icons/bi'
import PeopleIcon from '@material-ui/icons/People';
import { BiTransferAlt } from 'react-icons/bi';
import CallEndIcon from '@material-ui/icons/CallEnd';
import { HiPhoneOutgoing } from 'react-icons/hi'
import Moment from 'moment'
import globe from '../../../../assets/icons/globe.jpg'
import axiosInstance from '../../../../environment/axiosInstances'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import LanguageIcon from '@mui/icons-material/Language';
import AndroidOutlinedIcon from '@material-ui/icons/AndroidOutlined';
import BallotIcon from '@material-ui/icons/Ballot';
toast.configure()


function TableBodyData(props: any) {
    const classes = useStyles();
    const { callDirection, createdDt,phoneNumber, id, departmentId,eFoneNumber, duration, callerIdName, osType, answerStamp, formPhoneNumber, toPhoneNumber } = props.rowData;
    var data = parseInt(createdDt)
    var dataa = Moment(data).format('ddd MMM DD')
    return (
        <TableRow className={classes.tablebackgroundcolor} key={id ? id : props.id}>
            <TableCell className={classes.tablecell}>
                <IconButton className={classes.phonecallbackiconcolor}>
                    {callDirection === 1 &&
                        <>
                            <IconButton className={classes.phonecallmissediconcolor}>
                                <PhoneCallbackIcon />
                            </IconButton>
                        </>
                    }
                    {callDirection === 2 &&
                        <>
                            <IconButton className={classes.phonecallmissedicongreen}>
                                <HiPhoneOutgoing />
                            </IconButton>
                        </>
                    }
                </IconButton>
            </TableCell>
            <TableCell className={classes.tablecell}>
                <div>
                    <Typography className={classes.number}> {callerIdName}<br />
                    <p style={{ fontSize: "10px" }}>({phoneNumber})</p></Typography>
                </div>
            </TableCell>
            
            <TableCell className={classes.tablecell}> {eFoneNumber}
                {
                    departmentId && <Typography className={classes.typographytext}>{departmentId.departmentName}</Typography>
                }

            </TableCell>
            <TableCell className={classes.tablecell}  >
                {osType === 1 &&
                    <>
                        <AndroidOutlinedIcon style={{ fontSize: 15 }} />
                    </>
                }
                {osType === 2 &&
                    <>
                        <AppleIcon style={{ fontSize: 16 }} />
                    </>
                }
                {osType === 3 &&
                    <>
                        <BallotIcon style={{ fontSize: 16 }} />

                    </>
                }
                {osType === 4 &&
                    <>

                        <LanguageIcon style={{ fontSize: 16 }} />

                    </>
                }
                &nbsp;&nbsp;{duration}


            </TableCell>


            <TableCell align={'center'} className={classes.tablecell}>
                <IconButton className={classes.callEnd2}>
                    <BiPause className={classes.userIcon1} />
                </IconButton>
                <IconButton className={classes.people2}>
                    <PeopleIcon className={classes.userIcon1} />
                </IconButton>
                <IconButton className={classes.volumeIcon2}>
                    <BiTransferAlt className={classes.userIcon1} />
                </IconButton>
                <IconButton className={classes.recordVoice2}>
                    <CallEndIcon className={classes.userIcon1} />
                </IconButton>
            </TableCell>
        </TableRow>
    )
}

export default TableBodyData