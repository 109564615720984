import { AnyIfEmpty } from 'react-redux';
import axiosInstance from '../../environment/axiosInstances'
import * as userAuthActionCreators from '../../Store/Actions/AuthActionCreator'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
toast.configure()

class DepartmentServices {
    getDepartments = async () => {
        try {
            const response = await axiosInstance.get(`/departments`);
            if (response.data) {
                const data = response.data;
                return { success: true, data: data };
            } else {
                return { success: false, message: response?.data?.message, data: [] };
            }
        } catch (error: any) {
            if (error.response?.status == 402) {
                window.location.href = "https://newbp.efone.ca"
                sessionStorage.removeItem("user")

            }


            return {

                success: false,
                status: error.response?.status,
                message:
                    error.message === "Network Error"
                        ? "Network Error"
                        : error.response.data.message,
            };
        }
    }

    getDepartmentsData = async (id: any) => {
        try {
            const response = await axiosInstance.get(`/departments/${id}`);
            if (response.data) {
                const data = response.data;
                return { success: true, data: data };
            } else {
                return { success: false, message: response?.data?.message, data: [] };
            }
        } catch (error: any) {
            if (error.response?.status == 402) {
                window.location.href = "https://newbp.efone.ca"
                sessionStorage.removeItem("user")

            }

            return {
                success: false,
                status: error.response?.status,
                message:
                    error.message === "Network Error"
                        ? "Network Error"
                        : error.response.data.message,
            };
        }
    }

    updateDepartmentsData = async (id: any, data: any) => {
        try {
            const response = await axiosInstance.patch(`/departments/${id}/update-dnd`, data);
            if (response.data) {
                const data = response.data;
                toast.success("Department Updated successfully", {
                    position: toast.POSITION.TOP_RIGHT, autoClose: 3000,
                    style: {
                        color: "green",
                        borderRadius: "5px",
                        border: "3px solid green"
                    }

                })
                //console.log('--hi---',data);
                return { success: true, data: data };
            } else {
                return { success: false, message: response.data.message };
            }
        } catch (error: any) {
            toast.error("Error", { position: toast.POSITION.TOP_CENTER })
            if (error.response?.status == 402) {
                window.location.href = "https://newbp.efone.ca"
                sessionStorage.removeItem("user")

            }
            return {
                success: false,
                message:
                    error.message === "Network Error"
                        ? "Network Error"
                        : error.response.data.message,
            };
        }
    };




}
const departments = new DepartmentServices()
export default departments
