import React from 'react'
import { useStyles } from './Styles';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Card,
    CardContent,
    Divider,
    FormGroup,
    FormControlLabel,
    Grid,
    Avatar,
    Typography,
    TextField,
    Tooltip,
    TextareaAutosize,
    Table,

    Button,
    Switch,
    IconButton,
    withStyles,

} from '@material-ui/core/';
import Slider from '@material-ui/core/Slider';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import PhoneIcon from '@material-ui/icons/Phone';
import axiosInstance from '../../environment/axiosInstances'
import { FaUserPlus } from 'react-icons/fa';
import { MdSend, MdScheduleSend, MdOutlineDialpad, MdCall, MdOutlineExpandMore, MdPhonePaused, MdCallEnd } from 'react-icons/md';
import { BiCheckDouble, BiMessageSquareAdd, BiDialpadAlt, BiChevronDown } from 'react-icons/bi';
import { RiEditBoxFill, RiUser3Fill, RiPauseCircleFill } from 'react-icons/ri';
import Ellipse from '../../assets/icons/Ellipse.png';
import { AiFillCloseCircle, AiFillStar, AiOutlineEdit, AiOutlinePause } from 'react-icons/ai';

import { InputAdornment } from '@material-ui/core';
import { ImBlocked, ImPhoneHangUp } from 'react-icons/im';
import { useParams } from "react-router-dom";
import { BsFillCaretDownFill, BsCheck2All, BsDownload, BsFillMicMuteFill, BsPlusSquareFill, BsWifi, BsFillMicFill, BsFillTelephoneOutboundFill } from 'react-icons/bs';
import { FiSettings } from 'react-icons/fi';
import { CgUserAdd } from 'react-icons/cg';
import CallParkIcon from '../../assets/icons/call-park.png'
import { FcCallTransfer } from 'react-icons/fc';
import { GrAdd } from 'react-icons/gr';
import Modal from '@material-ui/core/Modal';
import { CardHeader } from '../Header/CardHeader';
import { ImRedo2 } from 'react-icons/im'
import SaveIcon from '@material-ui/icons/Save';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import { TiArrowForward } from 'react-icons/ti';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import Popup from "reactjs-popup";
import Checkbox from '@material-ui/core/Checkbox';
import KeyboardVoiceIcon from '@material-ui/icons/KeyboardVoice';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { idText } from 'typescript';
import { truncate } from 'fs';
import Moment from 'moment'
const PrettoSlider = withStyles({
    root: {
        color: '#2645AD',
        height: 8,
    },
    thumb: {
        height: 20,
        width: 20,
        backgroundColor: '#fff',
        border: '2px solid currentColor',
        marginTop: -8,
        marginLeft: -12,
        '&:focus, &:hover, &$active': {
            boxShadow: 'inherit',
        },
    },
    active: {},
    valueLabel: {
        left: 'calc(-50% + 4px)',
    },
    track: {
        height: 8,
        borderRadius: 4,
    },
    rail: {
        height: 8,
        borderRadius: 4,
    },
})(Slider);
function addPlayListModalStyle() {
    const top = 15;
    const left = 35;
    const right = 35;

    return {
        top: `${top}%`,
        left: `${left}%`,
        right: `${right}%`,
        transform: `translate(-${top}%, -${left}%, -${right}%)`,
    };
}
function getModalStyle() {
    const top = 15;
    const left = 30;
    const right = 30;

    return {
        top: `${top}%`,
        left: `${left}%`,
        right: `${right}%`,
        transform: `translate(-${top}%, -${left}%, -${right}%)`,
    };
}
export default function (props: any) {
    const classes = useStyles();
    const [open, setOpen] = React.useState<boolean>(false);
    const [location, setLocation] = React.useState('');
    const [modalStyle] = React.useState(getModalStyle);
    const [addPlaylistOpen, setAddPlaylistOpen] = React.useState<boolean>(false);
    const [playModalStyle] = React.useState(addPlayListModalStyle);
    const [appNoteopen, setAppNoteOpen] = React.useState<boolean>(false)
    const messagesEndRef: any = React.useRef(null)


    const handlePlaylistOpen = () => {
        setAddPlaylistOpen(true);
    }

    const handlePlaylistClose = () => {
        setAddPlaylistOpen(false);
    }
    const handleChange = (event: any) => {
        setLocation(event.target.value);
    };
    const appNotehandleOpen = () => {
        setAppNoteOpen(true);
    }

    const appNotehandleClose = () => {
        setAppNoteOpen(false);
    }

    var data = parseInt(props.data?.createdDate)
    var dataa = Moment(data).format(' ddd hh:mm A')
    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
    }

    React.useEffect(() => {
        scrollToBottom()
    }, [props.data]);
    return (
        <div ref={messagesEndRef} >

            <Grid container spacing={2}>
                <Grid item md={6}>
                    {
                        props.data.direction == 1 &&
                        <div style={{  display: "flex", justifyContent: "space-between", overflowWrap: 'break-word' }}>
                            <Card style={{padding: '6px 16px', borderRadius: "35px", backgroundColor: "white", }}>
                                <Typography >{props.data.textMessage}</Typography>
                                <div style={{ fontSize: "10px", float: "right",fontWeight:400 }}>
                                    {dataa}
                                </div>
                            </Card>
                        </div>
                    }
                </Grid>
                <Grid item md={6} >
                    {props.data.direction == 2 &&
                        <div style={{ float: "right", display: "flex", justifyContent: "space-between", overflowWrap: 'break-word' }}>
                            <Card style={{ backgroundColor: "#F0F8FF", padding: '6px 16px', borderRadius: "35px", }}>
                                <Typography >{props.data.textMessage}</Typography>
                                {props.data.isReadMessage === 1 ?
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <span style={{ fontSize: "10px", float: "right" }}> {dataa}</span> <BiCheckDouble className={classes.doubleCheck} />
                                    </div> :
                                    <div style={{ fontSize: "10px", float: "right",fontWeight:400 }}><span> {dataa}</span><BsCheck2All color="lightgrey" /></div>
                                }
                            </Card>
                        </div>
                    }

                </Grid>
            </Grid>


        </div>
    )
}
