import React from 'react';
import {
    TableContainer,
    Table,
    TableBody,
    TableRow,
    TableCell,
    IconButton,
    Button,
    Divider,
    Typography,
    Tooltip,
    Menu,
    MenuItem,
    FormControl,
    FormLabel,
    RadioGroup,
    FormControlLabel,
    Radio,
    Popper,
    Grow,
    Paper,
    ClickAwayListener,
    MenuList,
} from '@material-ui/core';
import { useStyles } from './Styles';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ListAltSharpIcon from '@material-ui/icons/ListAltSharp';
import PhoneIcon from '@material-ui/icons/Phone';
import Customer from '../../../assets/icons/customer.png';
import { BiCalendar } from 'react-icons/bi'
import { RiCheckDoubleLine, RiDeleteBinFill } from 'react-icons/ri'


function MyactivityFollowup(): JSX.Element {
    const classes = useStyles();


    const [open, setOpen] = React.useState(false);
    const anchorRef: any = React.useRef(null);

    function handleListKeyDown(event: any) {
        if (event.key === "Tab") {
            event.preventDefault();
            setOpen(false);
        }
    }
    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };
    const handleClose = (event: any) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };


    return (
        <>
            <TableContainer>
                <Table>
                    <TableBody>
                        <TableRow className={classes.tablebackgroundcolor}>
                            <TableCell className={classes.tablecell}>(123) 456-9998
                                <Typography className={classes.typographytext}>Richardson</Typography>
                            </TableCell>
                            <TableCell className={classes.tablecell}> Sechedule for 22 May&nbsp; &nbsp;12:00 pm </TableCell>
                            <TableCell className={classes.tablecell}>
                                <IconButton>
                                    <ListAltSharpIcon className={classes.listalticoncolor} />
                                </IconButton>
                            </TableCell>
                            <TableCell className={classes.tablecell}></TableCell>
                            <TableCell className={classes.tablecellOne}>
                                <Tooltip title="Add" placement="bottom">
                                    <img src={Customer} alt="Customer" />
                                </Tooltip>
                                <Typography className={classes.richard}> Richardson</Typography>
                            </TableCell>
                            <TableCell className={classes.tablecell}>
                                <Button
                                    variant="outlined"
                                    className={classes.buttonicons}>
                                    <IconButton><PhoneIcon className={classes.iconcolor} /></IconButton>
                                    <Divider orientation="vertical" flexItem className={classes.dividericon} />
                                    <IconButton><ArrowDropDownIcon className={classes.iconcolor} /></IconButton>
                                </Button>
                            </TableCell>
                            <TableCell className={classes.tablecell}>Today 4:45pm</TableCell>
                            <TableCell className={classes.tablecell}>
                                <Button
                                    ref={anchorRef}
                                    aria-controls={open ? "menu-list-grow" : undefined}
                                    aria-haspopup="true"
                                    onClick={handleToggle}
                                    className={classes.inconbutton}
                                >
                                    <Typography className={classes.text}>10</Typography>
                                </Button>
                                <Popper
                                    open={open}
                                    anchorEl={anchorRef.current}
                                    role={undefined}
                                    transition
                                >
                                    {({ TransitionProps, placement }) => (
                                        <Grow
                                            {...TransitionProps}
                                            style={{
                                                transformOrigin:
                                                    placement === "bottom" ? "center top" : "center bottom",
                                            }}
                                        >
                                            <Paper className={classes.paper}>
                                                <ClickAwayListener onClickAway={handleClose}>
                                                    <MenuList
                                                        autoFocusItem={open}
                                                        id="menu-list-grow"
                                                        onKeyDown={handleListKeyDown}
                                                    >
                                                        <MenuItem className={classes.items}>From (123) 123-3333 </MenuItem>
                                                        <MenuItem>
                                                            <FormControl style={{ fontSize: 8 }}>
                                                                <RadioGroup
                                                                    aria-labelledby="demo-radio-buttons-group-label"
                                                                    defaultValue="female"
                                                                    name="radio-buttons-group"
                                                                    style={{ fontSize: 8 }}

                                                                >
                                                                    <FormControlLabel style={{ fontSize: 8 }} value="female" control={<Radio size='small' style={{ fontSize: 8 }} />} label="Default (123) 123-3333" />
                                                                    <FormControlLabel value="male" control={<Radio size='small' />} label="Dailpad (123) 123-3333" />
                                                                    <FormControlLabel value="other" control={<Radio size='small' />} label="Block My Caller Id (Calling Only)" />
                                                                </RadioGroup>
                                                            </FormControl>
                                                        </MenuItem>
                                                        <MenuItem className={classes.items}>Options </MenuItem>
                                                        <MenuItem>
                                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                <BiCalendar />
                                                                <Typography className={classes.reschedule}>Reschedule</Typography>
                                                            </div>
                                                        </MenuItem>
                                                        <MenuItem>
                                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                <RiCheckDoubleLine className={classes.icons} />
                                                                <Typography className={classes.reschedule}>Completed</Typography>
                                                            </div>
                                                        </MenuItem>
                                                        <Divider />
                                                        <MenuItem>
                                                            <div style={{ display: 'flex', alignItems: 'center', marginLeft: '100px' }}>
                                                                <RiDeleteBinFill />
                                                            </div>

                                                        </MenuItem>


                                                    </MenuList>
                                                </ClickAwayListener>

                                            </Paper>
                                        </Grow>
                                    )}
                                </Popper>
                                {/* <Menu
                                    className={classes.menu}
                                    style={{width: '350px',marginTop: 35}}
                                    id="basic-menu"
                                    anchorEl={anchorEl}
                                    open={open}
                                    onClose={handleClose}
                                    MenuListProps={{
                                    'aria-labelledby': 'basic-button',
                                    }}
                                >
                                <MenuItem className={classes.items}>From (123) 123-3333 </MenuItem>
                                <MenuItem>(123) 123-3333</MenuItem>
                                <MenuItem>
                                    <FormControl>
                                        <RadioGroup
                                            aria-labelledby="demo-radio-buttons-group-label"
                                            defaultValue="female"
                                            name="radio-buttons-group"
                                        >
                                            <FormControlLabel value="female" control={<Radio size='small' />} label="Female" />
                                            <FormControlLabel value="male" control={<Radio size='small'  />} label="Male" />
                                            <FormControlLabel value="other" control={<Radio size='small'  />} label="Other" />
                                        </RadioGroup>
                                    </FormControl>
                                </MenuItem>
                                <MenuItem className={classes.items}>Options</MenuItem>
                            </Menu> */}

                            </TableCell>

                        </TableRow>
                        <TableRow >
                            <TableCell className={classes.tablecell}>(123) 456-9998
                                <Typography className={classes.typographytext}>Richardson</Typography>
                            </TableCell>
                            <TableCell className={classes.tablemissedcallcell}> Missedcall  </TableCell>
                            <TableCell className={classes.tablecell}>
                                <IconButton>
                                    <ListAltSharpIcon className={classes.listalticoncolor} />
                                </IconButton>
                            </TableCell>
                            <TableCell className={classes.tablecell}></TableCell>
                            <TableCell className={classes.tablecellOne}>
                                <Tooltip title="Add" placement="bottom">
                                    <img src={Customer} alt="Customer" />
                                </Tooltip>
                                <Typography className={classes.richard}> Richardson</Typography>
                            </TableCell>
                            <TableCell className={classes.tablecell}>
                                <Button
                                    variant="outlined"
                                    className={classes.buttonicons}>
                                    <IconButton><PhoneIcon className={classes.iconcolor} /></IconButton>
                                    <Divider orientation="vertical" flexItem className={classes.dividericon} />
                                    <IconButton><ArrowDropDownIcon className={classes.iconcolor} /></IconButton>
                                </Button>
                            </TableCell>
                            <TableCell className={classes.tablecell}>Today 4:45pm</TableCell>

                        </TableRow>
                        <TableRow className={classes.tablebackgroundcolor}>
                            <TableCell className={classes.tablecell}>(123) 456-9998
                                <Typography className={classes.typographytext}>Richardson</Typography>
                            </TableCell>
                            <TableCell className={classes.tablecell}> Sechedule for 22 May&nbsp; &nbsp;12:00 pm </TableCell>
                            <TableCell className={classes.tablecell}>
                                <IconButton>
                                    <ListAltSharpIcon className={classes.listalticoncolor} />
                                </IconButton>
                            </TableCell>
                            <TableCell className={classes.tablecell}></TableCell>
                            <TableCell className={classes.tablecellOne}>
                                <Tooltip title="Add" placement="bottom">
                                    <img src={Customer} alt="Customer" />
                                </Tooltip>
                                <Typography className={classes.richard}> Richardson</Typography>
                            </TableCell>
                            <TableCell className={classes.tablecell}>
                                <Button
                                    variant="outlined"
                                    className={classes.buttonicons}>
                                    <IconButton><PhoneIcon className={classes.iconcolor} /></IconButton>
                                    <Divider orientation="vertical" flexItem className={classes.dividericon} />
                                    <IconButton><ArrowDropDownIcon className={classes.iconcolor} /></IconButton>
                                </Button>
                            </TableCell>
                            <TableCell className={classes.tablecell}>Today 4:45pm</TableCell>

                        </TableRow>
                        <TableRow >
                            <TableCell className={classes.tablecell}>(123) 456-9998
                                <Typography className={classes.typographytext}>Richardson</Typography>
                            </TableCell>
                            <TableCell className={classes.tablemissedcallcell}> Missedcall  </TableCell>
                            <TableCell className={classes.tablecell}>
                                <IconButton>
                                    <ListAltSharpIcon className={classes.listalticoncolor} />
                                </IconButton>
                            </TableCell>
                            <TableCell className={classes.tablecell}></TableCell>
                            <TableCell className={classes.tablecellOne}>
                                <Tooltip title="Add" placement="bottom">
                                    <img src={Customer} alt="Customer" />
                                </Tooltip>
                                <Typography className={classes.richard}> Richardson</Typography>
                            </TableCell>
                            <TableCell className={classes.tablecell}>
                                <Button
                                    variant="outlined"
                                    className={classes.buttonicons}>
                                    <IconButton><PhoneIcon className={classes.iconcolor} /></IconButton>
                                    <Divider orientation="vertical" flexItem className={classes.dividericon} />
                                    <IconButton><ArrowDropDownIcon className={classes.iconcolor} /></IconButton>
                                </Button>
                            </TableCell>
                            <TableCell className={classes.tablecell}>Today 4:45pm</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}
export default MyactivityFollowup