import React from 'react';
import {
    Card,
    CardContent,
    Grid,
    Typography,
    Divider,
    withStyles,
    Switch,
    Button,
    Tabs,
    Tab,
    TableContainer,
    Table,
    TableBody,
    TableRow,
    TextField,
    TableCell,
    IconButton,
    CircularProgress
} from '@material-ui/core';
import { useParams } from "react-router-dom";
import { useStyles } from '../Styles';
import Avatar from '@material-ui/core/Avatar';
import axiosInstance from '../../../environment/axiosInstances'
import * as authActionCreators from '../../../Store/Actions/AuthActionCreator'
import Moment from 'moment'
import { TableHeaderComponent } from '../../../Components/Table/Table'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { useDispatch } from 'react-redux';
toast.configure()

function BlockCalls(props: any) {
    const classes = useStyles()
    const [id, setId] = React.useState<any>('')
    const params = useParams();
    const [loading, setLoading] = React.useState<any>(false);
    const [blockNumbers, setBlockNumbers] = React.useState<any>([])
    const dispatch = useDispatch()

    const fetchBlockNumbers = () => {
        setLoading(true)
        axiosInstance.get(`/departments/${params.id}/blocked-numbers`).then((res) => {
            if (res.data) {
                setBlockNumbers(res.data.data)
                setLoading(false)

            }
        }).catch((err) => {
            setLoading(false)
            if (err.response?.status == 402) {
                window.location.href = "https://newbp.efone.ca"
                sessionStorage.removeItem("user")

            }
            if (err.response?.status == 401) {
                dispatch(authActionCreators.initRefreshToken())
            }
        })
    }

    const delteBlockNumber = (delId: any) => {
        setLoading(true)
        axiosInstance.delete(`/departments/${params.id}/blocked-numbers/${delId}`).then((res) => {
            if (res.data) {
                fetchBlockNumbers()
                toast.success(res.data.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    style: {
                        borderRadius: "2px",
                        border: "1px solid green"
                    }, autoClose: 1000
                })
            } else {

            }
        }).catch((err) => {
            if (err.response?.status == 402) {
                window.location.href = "https://newbp.efone.ca"
                sessionStorage.removeItem("user")

            }
            toast.error(err.response.data.message, { position: toast.POSITION.TOP_RIGHT, autoClose: 1000 })
        })
    }

    React.useEffect(() => {
        fetchBlockNumbers()

    }, [props.type])



    React.useEffect(() => {
        setId(params?.id)
    }, [])

    const tableHeader = ['Phone Number', 'Call status', 'Block status', 'Date']
    return (
        <div>
            <TableContainer>
                <Table className={classes.table}>
                    <TableHeaderComponent theadData={tableHeader} />
                    <TableBody>
                        {
                            loading === false && blockNumbers && blockNumbers.length > 0 ? blockNumbers.map((item: any, i: any) => {
                                var data = parseInt(item?.blockDate)
                                var dataa = Moment(data).format('YYYY MMM DD hh:mm A')

                                var lastCall = parseInt(item?.lastCallReceived)
                                var CallReceived = Moment(lastCall).format('YYYY MMM DD hh:mm A')

                                return (
                                    <TableRow className={classes.tablebackgroundcolor} key={item.id}>
                                        <TableCell className={classes.tablecell}>
                                            <div className={classes.divContent}>
                                                <Avatar className={classes.avatarWidth} alt="Remy Sharp" src="/broken-image.jpg">
                                                    <Typography className={classes.avatarFontsize}>R</Typography>
                                                </Avatar>
                                                <Typography style={{ marginLeft: 10 }}>{item.phoneNumber}</Typography>
                                            </div>
                                        </TableCell>
                                        {/* <TableCell className={classes.tablecell}>{item.phoneNumber}</TableCell> */}
                                        <TableCell className={classes.tablecell}>Hang up</TableCell>
                                        <TableCell className={classes.tablecell} >
                                            <Button className={classes.unlockButton} disableRipple onClick={() => delteBlockNumber(item._id)}>UnBlock</Button>
                                        </TableCell>
                                        <TableCell className={classes.tablecell}>{dataa}</TableCell>
                                    </TableRow>
                                )
                            })
                                :

                                loading ? <div className='loader_class'>
                                    <div style={{ display: 'grid', alignItems: 'center', margin: '30px 500px' }}>
                                        <CircularProgress disableShrink />
                                    </div>
                                </div> : blockNumbers.length <= 0 &&
                                <TableRow>
                                    {/* <TableCell className={classes.tablecell}></TableCell> */}
                                    <TableCell className={classes.tablecell}></TableCell>
                                    <TableCell className={classes.tablecell}>No Data</TableCell>
                                    <TableCell className={classes.tablecell}></TableCell>
                                    {/* <TableCell className={classes.tablecell}></TableCell> */}
                                </TableRow>
                            //  <p style={{ textAlign: 'center' }}>No Data</p>

                        }
                        {/* <TableRow className={classes.tablebackgroundcolor}>
                        <TableCell className={classes.tablecell}>
                            <div className={classes.divContent}>
                                <Avatar className={classes.avatarWidth} alt="Remy Sharp" src="/broken-image.jpg">
                                    <Typography className={classes.avatarFontsize}>R</Typography>
                                </Avatar>
                                <Typography className={classes.space}>Richardson</Typography>
                            </div>
                        </TableCell>
                        <TableCell className={classes.tablecell}>Hang up</TableCell>
                        <TableCell className={classes.tablecell}>
                            <Typography className={classes.messeageUser}>By : User1</Typography>
                        </TableCell>
                        <TableCell className={classes.tablecell} >
                            <Button className={classes.unlockButton} disableRipple>Unblock</Button>
                        </TableCell>
                        <TableCell className={classes.tablecell}>Tuesday</TableCell>
                    </TableRow>
                    <TableRow >
                        <TableCell className={classes.tablecell}>
                            <div className={classes.divContent}>
                                <Avatar className={classes.avatarWidth} alt="Remy Sharp" src="/broken-image.jpg">
                                    <Typography className={classes.avatarFontsize}>R</Typography>
                                </Avatar>
                                <Typography className={classes.space}>Richardson</Typography>
                            </div>
                        </TableCell>
                        <TableCell className={classes.tablecell}>Voicemail</TableCell>
                        <TableCell className={classes.tablecell}>
                            <Typography className={classes.messeageUser}>By : User2</Typography>
                        </TableCell>
                        <TableCell className={classes.tablecell} >
                            <Button className={classes.unlockButton} disableRipple>Unblock</Button>
                        </TableCell>
                        <TableCell className={classes.tablecell}>Sun May 3</TableCell>
                    </TableRow> */}

                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    )
}

export default BlockCalls