import React from 'react'
import { Grid, Typography, CircularProgress } from '@material-ui/core'
import axiosInstance from '../../../environment/axiosInstances'
import { useDispatch } from 'react-redux';
import * as authActionCreators from '../../../Store/Actions/AuthActionCreator'
import { useStyles } from '../Styles';
import { Badge } from '@material-ui/core';
import Parser from 'html-react-parser';
export default function MoreDetails(props: any) {
    const classes = useStyles()
    const dispatch = useDispatch()
    const [loading, setLoading] = React.useState<any>(false);
    const [agentsData, setAgentsData] = React.useState<any>()
    const fetchAgentDetails = () => {
        setLoading(true)
        axiosInstance.get(`/departments/agent-info?agentName=${props.agent}`).then((res) => {

            if (res.data) {
                const data = res.data.data
                setAgentsData(Parser(data))
                setLoading(false)

            }
        }).catch((err) => {
            setLoading(false)
            if (err.response?.status == 402) {
                window.location.href = "https://newbp.efone.ca"
                sessionStorage.removeItem("user")

            }
            if (err.response?.status == 401) {
                dispatch(authActionCreators.initRefreshToken())
            }
        })
    }

    React.useEffect(() => {
        fetchAgentDetails()
    }, [])

    return (
        <div>
            {loading ? <div className='loader_class'>
                <div style={{ display: 'grid', alignItems: 'center', margin: '30px 290px' }}>
                    <CircularProgress disableShrink />
                </div>
            </div> :

                <Grid container spacing={2}>
                    <Grid item md={12}>
                        <Typography style={{ overflowWrap: 'break-word' }}>{agentsData}</Typography>
                    </Grid>

                </Grid>
            }
        </div>
    )
}
