

import jwt_decode from "jwt-decode";
import CryptoJS from 'crypto-js';

class sessionStorageData {
    setLocalUserData(userData: any) {

        sessionStorage.setItem('user', JSON.stringify(userData))
    }

    getLocalUserData() {
        const localUser: any = sessionStorage.getItem('user')
        const parseUserData = JSON.parse(localUser);
        return parseUserData;
    }

}

const sessionStorageContent = new sessionStorageData()

export default sessionStorageContent;