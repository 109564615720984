import { takeEvery } from 'redux-saga/effects'
import { activityDNDstatus, activityPatchDND } from '../Actions/Actions'
import { AuthActions } from '../Actions/Actions'
import { departmentModle } from '../Actions/Actions'
import {
    init_auth_saga,
    init_auth_timeout,
    init_refresh_token
} from './AuthSaga'
import {
    init_profile_data,
    init_patch_profile_data

} from './ActivitySaga'


export default function* indexSaga() {
    yield takeEvery(AuthActions.INIT_AUTH, init_auth_saga)
    yield takeEvery(AuthActions.INIT_AUTH_TIMEOUT, init_auth_timeout)
    yield takeEvery(activityDNDstatus.GET_STATUS, init_profile_data)

    yield takeEvery(activityPatchDND.SET_DND_STATUS, init_patch_profile_data)
    yield takeEvery(AuthActions.INIT_REFRESH_TOKEN, init_refresh_token)

}